import 'bootstrap/dist/css/bootstrap.css';
import '../../styles.css';
import '../cards/RatingCard.css'
import { Card } from 'react-bootstrap';
import { changeDateFormat } from '../../../config';

const RatingCard = (props) => {
    return (
        <Card className='user-rating-card'>
            <div className='user-image'>
                <Card.Img src={`${props?.userProfileImage ? props.userProfileImage : process.env.PUBLIC_URL}/images/profile.png`} />
            </div>
            <Card.Body className='rating-card-content'>
                <div className='rating-detail'>
                    <div className='user-name'>
                        <h6 className='text-capitalize'>
                            {props?.productRating.userName}
                        </h6>
                    </div>
                    <div className='review-time'>
                        <p>
                            {changeDateFormat(props?.productRating.createdAt)}
                        </p>
                    </div>
                </div>
                <div className='rated-star'>
                    {/* {[...Array(props?.productRating.rating)].map((_, index) => (
                        <span key={index} className="star">&#9733;</span>
                    ))} */}
                    {[...Array(5)].map((_, index) => (
                        <span key={index} className={`${index < props?.productRating.rating ? 'rated-star' : 'grey-star'}`}>&#9733;</span>
                    ))}
                </div>
                <div className='review-subject'>
                    <p>
                        {`${props?.productRating.rating < 3 ? 'Average' : props.productRating.rating < 4 ? 'Best' : "Excellent"}`}
                    </p>
                </div>
                <div className='review-para'>
                    <p>
                        {props?.productRating.review}
                    </p>
                </div>
                {/* <div className='like-review'>
                    <div className='like-img'>
                        <img src='images/product-page/icons/thumbs-up.png' alt='' />
                    </div>
                    <div className='like-txt'>
                        <p>Like</p>
                    </div>
                </div> */}
            </Card.Body>
        </Card>
    )
}

export default RatingCard;