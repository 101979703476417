// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-heading-color h1 {
    padding: 10px 0px 15px 0px;
}

.overview-points {
    padding-top: 7%;
}

.overview-points ul {
    padding-left: 1em;
}

.overview-img-slider-wrapper .overview-img{
    overflow: hidden;
    width: 100%;
    height: 300px;
}

.overview-img-slider-wrapper .overview-img img {
    height: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 767px) {
    .overview-heading-color h1 {
        padding: 15px 0px 10px 0px;
        font-size: 26px;
    }

    .overview-img {
        padding-top: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/common/tab/ProductOverview.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,0BAA0B;QAC1B,eAAe;IACnB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".overview-heading-color h1 {\n    padding: 10px 0px 15px 0px;\n}\n\n.overview-points {\n    padding-top: 7%;\n}\n\n.overview-points ul {\n    padding-left: 1em;\n}\n\n.overview-img-slider-wrapper .overview-img{\n    overflow: hidden;\n    width: 100%;\n    height: 300px;\n}\n\n.overview-img-slider-wrapper .overview-img img {\n    height: auto;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n@media (max-width: 767px) {\n    .overview-heading-color h1 {\n        padding: 15px 0px 10px 0px;\n        font-size: 26px;\n    }\n\n    .overview-img {\n        padding-top: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
