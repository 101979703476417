// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* My Wishlist Page Style Start  */

.wishlist-product .serial-no {
  width: 5%;
}

.wishlist-product .product-thumbnail {
  width: 10%;
  text-align: center;
}

.wishlist-product .name-rating-container {
  width: 40%;
}

.wishlist-product .product-price {
  width: 5%;
  text-align: center;
}

.move-to-cart {
  width: 20%;
  text-align: center;
}

@media (max-width: 576px) {
  .wishlist-product-index h2 {
    font-size: 50px;
  }

  .wishlist-product {
    column-gap: 30px !important;
    flex-wrap: wrap;
  }

  .wishlist-product .product-thumbnail {
    width: 30%;
  }

  .move-to-cart {
    width: 55%;
  }

  .add-to-cart-btn button {
    padding: 5px 20px !important;
  }

  .remove-product svg {
    font-size: 20px;
  }

  .product-price,
  .move-to-cart,
  .delete-icon {
    margin-top: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/user-profile/MyWishlist.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,SAAS;AACX;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,2BAA2B;IAC3B,eAAe;EACjB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,eAAe;EACjB;;EAEA;;;IAGE,gBAAgB;EAClB;AACF","sourcesContent":["/* My Wishlist Page Style Start  */\n\n.wishlist-product .serial-no {\n  width: 5%;\n}\n\n.wishlist-product .product-thumbnail {\n  width: 10%;\n  text-align: center;\n}\n\n.wishlist-product .name-rating-container {\n  width: 40%;\n}\n\n.wishlist-product .product-price {\n  width: 5%;\n  text-align: center;\n}\n\n.move-to-cart {\n  width: 20%;\n  text-align: center;\n}\n\n@media (max-width: 576px) {\n  .wishlist-product-index h2 {\n    font-size: 50px;\n  }\n\n  .wishlist-product {\n    column-gap: 30px !important;\n    flex-wrap: wrap;\n  }\n\n  .wishlist-product .product-thumbnail {\n    width: 30%;\n  }\n\n  .move-to-cart {\n    width: 55%;\n  }\n\n  .add-to-cart-btn button {\n    padding: 5px 20px !important;\n  }\n\n  .remove-product svg {\n    font-size: 20px;\n  }\n\n  .product-price,\n  .move-to-cart,\n  .delete-icon {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
