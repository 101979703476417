// import { createSlice } from "@reduxjs/toolkit";

// const wishListReducer = createSlice({
//     name: 'wishList',
//     initialState: {
//         wishListItems: [],
//     },

//     reducers: {
//         // action to get all wishlist products
//         getWishlistProductStart : (state) => {
//             state.isFetching  = true;
//         },

//         getWishlistProductSuccess : (state, action) => {
//             state.isFetching = false;
//             state.wishListItems = action.payload;
//         },

//         getWishlistProductFailure : (state) => {
//             state.isFetching = false;
//             state.error = true;
//         },
//         // // add to wishList actions
//         addProductToWishListStart: (state) => {
//             state.isFetching = true;
//         },
//         addProductToWishListSuccess: (state, action) => {
//             const newItem = action.payload;
//             const itemExists = state.wishListItems.some(item => item._id === newItem._id);
//             if (!itemExists) {
//                 // state.wishListItems.push(newItem);
//                 state.wishListItems = [ ...state.wishListItems, newItem ];

//             }
//             state.isFetching = false;
//             // const newItem = action.payload;
            
//         },

//         addProductToWishListFailure: (state) => {
//             state.isFetching = false;
//             state.error = true;
//         },

//         removeProductFromWishListStart: (state) => {
//             state.isFetching = true;
//         },

//         removeProductFromWishListSuccess: (state, action) => {
//             const itemId = action.payload;
//             const itemToRemove = state.wishListItems.find((item) => item._id === itemId);

//             if (itemToRemove) {
//                 state.wishListItems = state.wishListItems.filter((item) => item._id !== itemId);
//             }

//             // =============********** old methode when it is not saving in database *******=========
//             // const itemToRemove = state.wishListItems.find((item) => item._id === itemId);

//             // if (itemToRemove) {
//             //     state.wishListItems = state.wishListItems.filter((item) => item._id !== itemId);
//             // }

//         },

//         removeProductFromWishListFailure: (state) => {
//             state.isFetching = false;
//             state.error = true;
//         },

//         clearWishList: (state) => {
//             state.wishListItems = [];
//         }

//     },
// });

// export const {getWishlistProductStart, getWishlistProductSuccess, getWishlistProductFailure, addProductToWishListStart, addProductToWishListSuccess, addProductToWishListFailure, removeProductFromWishListStart, removeProductFromWishListSuccess, removeProductFromWishListFailure, clearWishList } = wishListReducer.actions;
// export default wishListReducer.reducer;



// ==============new code below======

import { createSlice } from "@reduxjs/toolkit";

const wishListReducer = createSlice({
    name: 'wishList',
    initialState: {
        wishListItems: [],
    },

    reducers: {
        // // add to wishList actions
        addProductToWishListStart: (state) => {
            state.isFetching = true;
        },
        addProductToWishListSuccess: (state, action) => {
            const newItem = action.payload;
            const itemExists = state.wishListItems.some(item => item._id === newItem._id);
            if (!itemExists) {
                state.wishListItems.push(newItem);
            }
            state.isFetching = false;
            // const newItem = action.payload;
            
        },

        addProductToWishListFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        removeProductFromWishListStart: (state) => {
            state.isFetching = true;
        },

        removeProductFromWishListSuccess: (state, action) => {
            // const index = state.wishListItems.findIndex(item => item.id === action.payload);
            // if (index !== -1) {
            //     state.wishListItems.splice(index, 1);
            // }

            const itemId = action.payload;
            const itemToRemove = state.wishListItems.find((item) => item._id === itemId);

            if (itemToRemove) {
                state.wishListItems = state.wishListItems.filter((item) => item._id !== itemId);
            }

        },

        removeProductFromWishListFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        clearWishList: (state) => {
            state.wishListItems = [];
        }

    },
});

export const { addProductToWishListStart, addProductToWishListSuccess, addProductToWishListFailure, removeProductFromWishListStart, removeProductFromWishListSuccess, removeProductFromWishListFailure, clearWishList } = wishListReducer.actions;
export default wishListReducer.reducer;