// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/about/about-banner.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/images/about/about-us.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inb ~ p {
  text-align: left;
}
.about_banner {
  display: flex;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 24.8vw;
  width: 100%;
  align-items: center;
}

@media (max-width: 1024px) {
  .abt-banner-row {
    padding-top: 15px;
  }

  .inb {
    font-size: 20px;
    width: 50%;
  }
}

@media screen and (max-width: 576px) {
  .about_banner {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    align-items: start;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
  }
  .about_banner .pt-50 {
    padding-top: 10px;
  }
  .about_banner .inb {
    font-size: 1.1rem;
    padding-right: 0 !important;
    padding-right: initial !important;
  }

  .inb {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/about/AboutBanner.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mDAAgE;EAChE,wBAAwB;EACxB,4BAA4B;EAC5B,2BAA2B;EAC3B,cAAc;EACd,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,UAAU;EACZ;AACF;;AAEA;EACE;IACE,mDAA6D;IAC7D,kBAAkB;IAClB,4BAA4B;IAC5B,sBAAsB;IACtB,YAAY;EACd;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;IACjB,2BAA+B;IAA/B,iCAA+B;EACjC;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".inb ~ p {\r\n  text-align: left;\r\n}\r\n.about_banner {\r\n  display: flex;\r\n  background: url(\"../../../public/images/about/about-banner.png\");\r\n  background-size: contain;\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n  height: 24.8vw;\r\n  width: 100%;\r\n  align-items: center;\r\n}\r\n\r\n@media (max-width: 1024px) {\r\n  .abt-banner-row {\r\n    padding-top: 15px;\r\n  }\r\n\r\n  .inb {\r\n    font-size: 20px;\r\n    width: 50%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 576px) {\r\n  .about_banner {\r\n    background: url(\"../../../public/images/about/about-us.webp\");\r\n    align-items: start;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    height: 60vh;\r\n  }\r\n  .about_banner .pt-50 {\r\n    padding-top: 10px;\r\n  }\r\n  .about_banner .inb {\r\n    font-size: 1.1rem;\r\n    padding-right: unset !important;\r\n  }\r\n\r\n  .inb {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
