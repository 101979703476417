import "./SchoolBundleIndia.css";
import { Link } from "react-router-dom";
import { FaPersonBooth } from "react-icons/fa";
import BannerOutside from "../component/bundle/BannerOutside";
import FlexiblePlan from "../component/bundle/FlexiblePlan";
import CardUni from "./CardUni";
import SchoolForm from "../component/TeacherPdp/SchoolForm";
import LogoSlider from "../component/common/logoSlider/LogoSlider";
import TabWrapper from "../component/common/tab/TabWrapper";
import HighSchoolBundleOptionOutside from "../component/bundle/HighSchoolBundleOptionOutside";
import PrimarySchoolBundleOutside from "../pages/outside-india-bundle/PrimarySchoolBundleOutside";
import PrimarySchoolBundleOptionOutside from "../component/bundle/PrimarySchoolBundleOptionOutside";
import MiddleSchoolBundleOptionOutside from "../component/bundle/MiddleSchoolBundleOptionOutside";
import VideoTestim from "../component/common/video-testimonial/VideoTestim";
import SchoolBundleCarousel from "../component/common/sb-carousel/SchoolBundleCarousel";
import ExploreMore from "../component/ExploreMore";
import { DataObject } from "@mui/icons-material";
import { useSelector } from "react-redux";
import LearningResourceBlogs from "../component/learningResourceBlogs/LearningResourceBlogs";

const titles = [
  "Primary School Bundle",
  "Middle School Bundle",
  "High School Bundle",
];
const paragraphs = [
  [
    "Subscription based classes ",
    "Pay on a per-student, per-session basis for 12, 24, or 30 weeks.",
    "We will bring the kits and send our trainer to your school to teach your students",
  ],
  [
    "Get your desired combination of kits ",
    "And we will train your school teachers",
  ],
  [
    "Purchase the kit combination that suits your needs.",
    "And we’ll send our trainer to your school to teach your students",
  ],
];
const dynamicDivs = [
  <Link to="/flexible-plan-1">
    Learn more about how our curated solutions have benefitted schools
  </Link>,
  <Link to="/flexible-plan-2">
    Find out how our creative solutions have made a difference in schools.
  </Link>,
  <Link to="/flexible-plan-3">
    Read about school success with our innovative solutions.
  </Link>,
];
const cardData1 = [
  {
    imageUrl: "images/SchoolBundle/India/icons/handshake.png",
    spantxt: " ",
    title: "Industry partnerships",
    paragraph:
      "Are your students seeking internships, expert webinars, or industry visits? We are excited to offer these incredible College and Career Readiness Opportunities for the benefit of your students.",
  },
  {
    imageUrl: "images/SchoolBundle/India/icons/graduation-cap.png",
    spantxt: "",
    title: " Scholarship ",
    paragraph:
      "We are pleased to announce that we will be awarding a Merit Scholarship to one student every quarter. Encourage your students to apply at no cost.",
  },
  {
    imageUrl: "images/SchoolBundle/India/icons/trophy.png",
    spantxt: "",
    title: "Competitions",
    paragraph:
      "Would you like your students to hone their skills? Connect with us and we can get your students to participate in the Indian Robotix League, Destination Zero Carbon, World Robotics Olympiad and many others.",
  },
];
const logos = [
  "images/SchoolBundle/logos/01-AISC.jpg",
  "images/SchoolBundle/logos/02-BVM-Global.jpg",
  "images/SchoolBundle/logos/03-TVS.jpg",
  "images/SchoolBundle/logos/04-Pickering-college.jpg",
  "images/SchoolBundle/logos/05-GEMS.jpg",
  "images/SchoolBundle/logos/06-Global-Indian.jpg",
  "images/SchoolBundle/logos/07-STEM-School.jpg",
  "images/SchoolBundle/logos/08-Kansas-State.jpg",
  "images/SchoolBundle/logos/09-The-Ashok-leyland.jpg",
  "images/SchoolBundle/logos/10-BVMI.jpg",
  "images/SchoolBundle/logos/11-CK-School.jpg",
  "images/SchoolBundle/logos/12-TVS.jpg",
  "images/SchoolBundle/logos/13-Wisdom.jpg",
  "images/SchoolBundle/logos/14-St-martin.jpg",
  "images/SchoolBundle/logos/15-CEDAR.jpg",
  "images/SchoolBundle/logos/16-Duapa.jpg",
  "images/SchoolBundle/logos/17-Little _lovely.jpg",
  "images/SchoolBundle/logos/18-Oakom-robotics.jpg",
  "images/SchoolBundle/logos/19-Unique-star.jpg",
  "images/SchoolBundle/logos/20-the-pearl.jpg",
  "images/SchoolBundle/logos/21-alphabet.jpg",
  "images/SchoolBundle/logos/22-elemenopy.jpg",
  "images/SchoolBundle/logos/23-Sboa.jpg",
  "images/SchoolBundle/logos/24-HUS.jpg",
  "images/SchoolBundle/logos/25-Indus.jpg",
  "images/SchoolBundle/logos/26-Maharishi.jpg",
  "images/SchoolBundle/logos/27-Kola perumal.jpg",
  "images/SchoolBundle/logos/28-DAV.jpg",
  "images/SchoolBundle/logos/29-Jagannath.jpg",
  "images/SchoolBundle/logos/30-Hindustan.jpg",
  "images/SchoolBundle/logos/31-Sri-RM-Jain.jpg",
  "images/SchoolBundle/logos/32-Pragyanam.jpg",
  "images/SchoolBundle/logos/33-Banyan.jpg",
  "images/SchoolBundle/logos/34-Schools.jpg",
  "images/SchoolBundle/logos/35-Shree niketan.jpg",
];
const responsiveConfig = {
  0: {
    items: 2,
  },
  768: {
    items: 2,
  },
  992: {
    items: 4,
  },
  1200: {
    items: 4,
  },
};

const categories = [
  {
    categoryId: 1,
    imgUrl: "images/SchoolBundle/India/learn-1.png",
    title: "Tips for Students",
  },
  {
    categoryId: 2,
    imgUrl: "images/SchoolBundle/India/learn-2.png",
    title: "Future of Learning",
  },
];

let contents = [<HighSchoolBundleOptionOutside />];
const SchoolBundleIndia = () => {
  const state = useSelector((state) => state);

  return (
    <div>
      <BannerOutside />

      <section className="pt-100">
        <ul className="school_bundle_tabs tab-list container">
          <Link to={"/PrimarySchoolBundleOutside"}>
            <li className="">Primary School Bundle</li>
          </Link>
          <Link to={"/MiddleSchoolBundleOutside"}>
            <li className="">Middle School Bundle</li>
          </Link>
          <Link to={"/HighSchoolBundleOutside"}>
            <li className="">High School Bundle</li>
          </Link>
        </ul>
      </section>

      <section className="bundle_partner_logo">
        <h2 className="bundle_heading text-center pt-100">
          Our School <span className="dPink">Partners</span>
        </h2>
        <LogoSlider logos={logos} responsiveConfig={responsiveConfig} />
      </section>

      <section>
        <div className="container">
          <h2 className="bundle_heading text-center pt-100 mb-50">
            Flexible<span> Plans</span>
          </h2>
          <div className="row">
            {titles.map((title, index) => (
              <div key={index} className="col-md-4 mb-3 mb-md-0">
                <FlexiblePlan
                  title={title}
                  paragraphs={paragraphs[index]}
                  dynamicDiv={dynamicDivs[index]}
                  planNo={index + 1}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="pb-100">
        <div className="container">
          <h2 className="bundle_heading text-center pt-100 mb-50">
            Our Value Added <span>Offerings</span>
          </h2>
          <div className="value_offering">
            <div className="row">
              {cardData1.map((card, index) => (
                <div className="col-md-4 mb-5 mb-md-0" key={index}>
                  <CardUni {...card} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="pt-50" style={{ background: "#f4f4f4" }}>
        {/* <div className="container">
          <VideoTestim sectionHeading={"Success "} headingSpan={"Stories"} />
        </div> */}
        <VideoTestim
          className="video-testim-wrapper pt-50"
          sectionHeading="What our learners are saying?"
          category="success"
        />
      </section>
      {/* <VideoTestim
              className="video-testim-wrapper pt-50"
              sectionHeading="What our learners are saying?"
              category="learnAI"
            /> */}

      <section className="pt-100 ">
        <SchoolBundleCarousel />
      </section>

      <section className="pt-100 pb-100">
        <LearningResourceBlogs />
      </section>
    </div>
  );
};

export default SchoolBundleIndia;
