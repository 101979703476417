import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const CheckoutComponents = () => {
    const cartItems = useSelector((state) => state.cart.cartItems);
    let auth;
    cartItems.length > 0 ? auth = false : auth = true;
    return auth ? <Outlet /> : <Navigate to='/cart' />
}

export default CheckoutComponents