import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Counter from "../common/counter/Counter";
import VideoModal from "./VideoModal";
import { FaPlayCircle } from "react-icons/fa";
import "./about.css";
function About() {
  const experienceTargets = [
    { value: 12, text: "Years of Experience" },
    { value: 100, text: "AI Courses & Projects created" },
    { value: 25, text: "STEM & Robotic Products developed" },
    { value: 75000, text: "End Users" },
    // Add more targets as needed
  ];
  const [count, setCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".about_home_counter_cont");
      const elementPosition = element?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementPosition < windowHeight && !isCounting) {
        setIsCounting(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isCounting]);

  useEffect(() => {
    if (isCounting) {
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isCounting]);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false); // Add this state
  return (
    <div>
      <section className="about_home pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4  p-lg-auto">
              <div className="video-container">
                <img src="images/home/video-poster.png" />
                {/* Add an onClick event handler to open the video modal */}
                <div
                  className="pulse"
                  onClick={() => setIsVideoModalOpen(true)}
                >
                  <FaPlayCircle size={50} className="play-icon" />
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <h1 className="about_home_heading">
                Empowering the <span>Future of Learning</span>
              </h1>
              {/* <p className="about_home_para ff  f-18 text-dark">
                <span className="fw-800 ">Meritus</span>: Empowering the Future
                of Learning
              </p> */}
              <p className="about_home_para">
                At Meritus, we are driven by a passion for empowering the
                futurer of learning. As a leading education technology (EdTech)
                company, we revolutionize education through innovation and
                technology. Our diverse range of courses in
                <strong>
                  &nbsp;AI, VR, Robotics, Coding, and STEM Learning&nbsp;
                </strong>
                which fosters creativity, critical thinking, and lifelong
                learning. With a commitment to accessibility and inclusivity, we
                provide transformative learning experiences for students of all
                ages and backgrounds. Join us on this journey of discovery and
                growth, as we nurture future innovators and equip them with the
                skills to excel in an ever-changing world. Meritus - Empowering
                Education, Empowering You.
              </p>
              <Counter targets={experienceTargets} />
              {/* 
              <Link to="/AboutUsPage" className="uniBtn uniBtnBlue">
                Know More
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
      />
    </div>
  );
}
export default About;
