import { createSlice } from "@reduxjs/toolkit";

const registeredUserReducer = createSlice({
    name: 'registeredUsers',
    initialState: {
        user: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        // getUsersStart: (state) => {
        //     state.isFetching = true;
        // },
        // getUsersSuccess: (state, action) => {
        //     state.isFetching = false;
        //     state.users = action.payload;
        // },
        // getUsersFailure: (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },

        // add admin actions 
        addUserStart: (state) => {
            state.isFetching = true;
        },

        addUserSuccess: (state, action) => {
            state.isFetching = false;
            const newUser = action.payload;
            state.user = [...state.user, newUser];
        },

        addUserFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        // deleteUserStart: (state) => {
        //     state.isFetching = true;
        // },

        // deleteUserSuccess: (state, action) => {
        //     state.isFetching = false;
        //     state.users.splice(
        //         state.users.findIndex((item) => item._id === action.payload), 1
        //     );
        // },

        // deleteUserFailure: (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },

        // Update user actions
        updateUserStart: (state) => {
            state.isFetching = true;
            
        },

        updateUserSuccess: (state, action) => {
            state.isFetching = false;
            // const adminIndex = state.user.findIndex((admin) => admin._id === action.payload._id);
            // if (adminIndex !== -1) {
            //     state.user[adminIndex] = action.payload;
            // }
            state.user = action.payload
        },

        updateUserFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // update user password actions
        updatePasswordStart : (state) => {
            state.isFetching = true;
            state.error = false;
        },

        updatePasswordSuccess : (state, action) => {
            state.isFetching = false;
            state.user = action.payload;
        },
        updatePasswordFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        clearRegistraionState : (state) => {
            state.user = []
        }
    },
});

export const {  addUserFailure, addUserStart, addUserSuccess, updateUserStart, updateUserSuccess, updateUserFailure, updatePasswordStart, updatePasswordSuccess, updatePasswordFailure, clearRegistraionState    } = registeredUserReducer.actions;
export default registeredUserReducer.reducer;

