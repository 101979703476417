import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const NonUserComponents = () => {
    const user = useSelector((state) => state.loginUser.currentUser);
    let auth;
    user ? auth = false : auth = true;
    return auth ? <Outlet /> : <Navigate to='/' />
}

export default NonUserComponents