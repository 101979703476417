import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CardUni from "../../pages/CardUni.jsx";
import "./Offering.css";

const Offering = () => {
  const cardData = [
    {
      imageUrl: "images/landing1/image-6.png",
      title: " education ",
      spantxt: "resources",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      backgroundColor: "#C3C5F8",
    },
    {
      imageUrl: "images/landing1/image-7.png",
      title: "Workshop ",
      spantxt: "and training",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      backgroundColor: "#C3C5F8",
    },
    // Add more card data as needed
  ];

  return (
    <div className="offering_value">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <h2>offering values</h2>
            <p>
              We specialize in creating age-appropriate curriculum & educational
              resources that are future-proof and involve experiential learning
              via:
            </p>
            <a href="/" className="uniBtn uniBtnBlue btnPLight">
              Learn More
            </a>
          </div>
          <div className="col-md-8 mt-4 mt-md-0">
            <OwlCarousel className="owl-theme" items={2} margin={40} loop dots>
              {cardData.map((card, index) => (
                <div className="item" key={index}>
                  <CardUni {...card} />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offering;
