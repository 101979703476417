import { createSlice } from "@reduxjs/toolkit";

const ratingReducer = createSlice({
    name: 'rating',
    initialState: {
        ratings: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getRatingsStart: (state) => {
            state.isFetching = true;
        },

        getRatingsSuccess: (state, action) => {
            state.isFetching = false;
            state.ratings = action.payload;
        },

        getRatingsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add admin actions 
        addRatingStart: (state) => {
            state.isFetching = true;
        },

        addRatingSuccess: (state, action) => {
            const newRating = action.payload;
            state.ratings = [...state.ratings, newRating];
        },

        addRatingFailure: (state) => {
            state.error = true;
            state.isFetching = false;
        },

        // Delete user actions
        // deleteUserStart: (state) => {
        //     state.isFetching = true;
        // },

        // deleteUserSuccess: (state, action) => {
        //     state.isFetching = false;
        //     state.users.splice(
        //         state.users.findIndex((item) => item._id === action.payload), 1
        //     );
        // },

        // deleteUserFailure: (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },

        // Update user actions
        // updateUserStart: (state) => {
        //     state.isFetching = true;
            
        // },

        // updateUserSuccess: (state, action) => {
        //     state.isFetching = false;
        //     // const adminIndex = state.user.findIndex((admin) => admin._id === action.payload._id);
        //     // if (adminIndex !== -1) {
        //     //     state.user[adminIndex] = action.payload;
        //     // }
        //     state.user = action.payload
        // },

        // updateUserFailure: (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },

        // // update user password actions
        // updatePasswordStart : (state) => {
        //     state.isFetching = true;
        //     state.error = false;
        // },

        // updatePasswordSuccess : (state, action) => {
        //     state.isFetching = false;
        //     state.user = action.payload;
        // },
        // updatePasswordFailure : (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },
    },
});

export const {  addRatingFailure, addRatingStart, addRatingSuccess, getRatingsStart, getRatingsSuccess, getRatingsFailure } = ratingReducer.actions;
export default ratingReducer.reducer;

