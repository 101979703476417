import React from "react";
import "./AboutUs.css";

const AboutUs = ({
  imageUrl,
  title,
  span,
  paragraph,
  paragraph2,
  btnText,
  btnLink,
  imageColClass,
  textColClass,
  reverse,
}) => {
  const updatedImageColClass = reverse
    ? `${imageColClass} order-md-2`
    : imageColClass;
  const updatedTextColClass = reverse
    ? `${textColClass} order-md-1`
    : textColClass;

  return (
    <div className="row pb-100">
      <div className={updatedImageColClass}>
        <img src={imageUrl} alt={title} className="img-fluid" />
      </div>
      <div className={updatedTextColClass}>
        <h2 className="inh2">
          {title}
          <span>{span}</span>
        </h2>
        <p className="text-start">{paragraph}</p>
        <p className="text-start">{paragraph2}</p>
        <a className="uniBtn uniBtnBlue btnPLight" href={btnLink}>
          {btnText}
        </a>
      </div>
    </div>
  );
};

export default AboutUs;
