import { FaPersonBooth } from "react-icons/fa";
import { Link } from "react-router-dom";
const MiddleSchoolBundleOption = () => {
  return (
    <div>
      <section className="pt-100">
        {/* <div className="container"> */}
        <h2 className="text-center bundle_heading">
          Middle School <span> Bundle</span>{" "}
        </h2>
        <h3 className="text-center bundle_sub_heading mb-50">
          A Unified AI & Robotics Education ecosystem for your school
        </h3>
        <div className="row">
          <div className="ps-5 col-md-6 offset-md-1">
            <div className="row">
              <div className="col-md-12 mb-2">
                <div className="school_bundle_usps">
                  <div>
                    <img src="images/SchoolBundle/India/icons/2-Icon.svg" />
                    <p>Supports 2 students in the classroom</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/5-Icon.svg" />
                    <p>24 + Hours of Lesson plans</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/3-Icon.svg" />
                    <p>Enabling hands-on STEM learning experiences</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/6-Icon.svg" />
                    <p>
                      Curriculum is aligned to NEP 2020 and to CBSE, ICSE &
                      State Boards
                    </p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/4-con.svg" />
                    <p>Teacher resources and Training Available</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/7-Icon.svg" />
                    <p>
                      Our kits are durable and have a service life of a minimum
                      of 5 years.
                    </p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/6-Icon.svg" />
                    <p>
                    Discover our AI curriculum: standards-aligned with the AI4K12 framework, endorsed by AAAI and CSTA.
                    </p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/8-Icon.svg" />
                    <p>
                      Learning resources are designed by in-house experts and
                      guided by our International Governing Board.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <h4 className="bundle_heading2 psb-sub-heading text-center">
                Create your own learning solution
              </h4>
              <h5 className="bundle_sub_heading2 text-center">
                We'll assist you in customizing and assembling your unique bund
              </h5>
              <div className="col-md-12 mt-3">
                <div class="school_bundle_usps2">
                  <div>
                    <div>
                      <h6>
                        <span>1. </span>Phiro Pro
                      </h6>
                      <p>Scratch, Snap and Phiro Code app enabled Robot</p>
                    </div>
                    <div>
                      <h6>
                        <span>2. </span>Micro:Code
                      </h6>
                      <p>Electronic kit enabled by sensors + Python</p>
                    </div>
                    <div>
                      <h6>
                        <span>3. </span>Lego Mindstorm
                      </h6>
                      <p>Programmable robotics</p>
                    </div>
                    <div>
                      <h6>
                        <span>4. </span>Space + Data Science
                      </h6>
                      <p>Co-curated with Microsoft USA</p>
                    </div>
                    <div>
                      <h6>
                        <span>5. </span>AI with Scratch AI extensions
                      </h6>
                      <p>Virtual and On-Line course</p>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h6>
                        <span>6. </span>AI Lite
                      </h6>
                      <p>Construction kit powered by MIT App Inventor</p>
                    </div>
                    <div>
                      <h6>
                        <span>7. </span>Data Science Pro
                      </h6>
                      <p>
                        Data loggers and sensors for real-world data analysis
                      </p>
                    </div>
                    <div>
                      <h6>
                        <span>8. </span>NXG Drone
                      </h6>
                      <p>Learn to build, code and fly drones!</p>
                    </div>
                    <div>
                      <h6>
                        <span>9. </span>Data Science for grades 7 &amp; 8
                      </h6>
                      <p>
                        AI kit is a learning platform, which will expose
                        students to AI, Machine Learning &amp; Data Science all
                        through the lens of self-driving cars
                      </p>
                    </div>
                    <a class="bundle_btn" href="/contact-us">
                      GET STARTED
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="school_bundle_img bundle_middle">
              <img src="images/SchoolBundle/gif/Middle-school-Bundle-(India).gif" />
            </div>
          </div>

          {/* </div> */}
        </div>
      </section>
    </div>
  );
};
export default MiddleSchoolBundleOption;
