// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-tab {
    background-color: var(--uPTPink);
}

.user-profile-tab li {
    color: var(--dPink);
}

.user-profile-tab .active {
    background-color: var(--dPink) !important;
    color: var(--white) !important;
}

@media (max-width: 767px) {
    .user-profile-tab {
        column-gap: 10px;
    }

    .user-profile-tab li {
        padding: 10px 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/user-profile/UserProfile.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yCAAyC;IACzC,8BAA8B;AAClC;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".user-profile-tab {\n    background-color: var(--uPTPink);\n}\n\n.user-profile-tab li {\n    color: var(--dPink);\n}\n\n.user-profile-tab .active {\n    background-color: var(--dPink) !important;\n    color: var(--white) !important;\n}\n\n@media (max-width: 767px) {\n    .user-profile-tab {\n        column-gap: 10px;\n    }\n\n    .user-profile-tab li {\n        padding: 10px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
