// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.school-logo-item{
  width: 100%;
  height: 90px;
}

.school-logo-item img{
  width: 100% !important;
  height: 100% !important;
}

.ql-editor img {
  max-width: 100%; /* Set maximum width to 100% of parent container */
  max-height: 100%; /* Set maximum height to 100% of parent container */
}

#rendered-html-box img{
  width: 800px;
  height: 500px;
  object-fit: contain;
  margin-left: 50px;
}

#rendered-html-box  p{
 text-align: justify;
}

.rating-section span{
  font-size: 22px;
}

#question-html-box img{
  height: 50px;
}

#question-html-box p{
  margin-bottom: 0px;
}

#question-html-box{
  display: flex;
  column-gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,eAAe,EAAE,kDAAkD;EACnE,gBAAgB,EAAE,mDAAmD;AACvE;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n.school-logo-item{\n  width: 100%;\n  height: 90px;\n}\n\n.school-logo-item img{\n  width: 100% !important;\n  height: 100% !important;\n}\n\n.ql-editor img {\n  max-width: 100%; /* Set maximum width to 100% of parent container */\n  max-height: 100%; /* Set maximum height to 100% of parent container */\n}\n\n#rendered-html-box img{\n  width: 800px;\n  height: 500px;\n  object-fit: contain;\n  margin-left: 50px;\n}\n\n#rendered-html-box  p{\n text-align: justify;\n}\n\n.rating-section span{\n  font-size: 22px;\n}\n\n#question-html-box img{\n  height: 50px;\n}\n\n#question-html-box p{\n  margin-bottom: 0px;\n}\n\n#question-html-box{\n  display: flex;\n  column-gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
