import "./EventBanner.css";
const EventBanner = (props) => {
  return (
    <div className="event_banner" style={{ backgroundImage: `url(${props.isLocalBanner ? process.env.PUBLIC_URL + '/images/banner/' + props.localBanner : props.categoryImage})` }}>
      {
        props.bannerTitle && (
          <div className="row align-items-center pt-50">
            <div className="col-lg-12">
              <h3 className="inh2 blog-banner-title">
                {props.bannerTitle}
              </h3>
            </div>
          </div>
        )
      }

    </div>
  );
};
export default EventBanner;
