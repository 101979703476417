import "bootstrap/dist/css/bootstrap.css";
import CommonButton from "../../component/common/Button/CommonButton";
import "../../styles.css";
import "../learn-ai/LearnAI.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TabWrapper from "../../component/common/tab/TabWrapper";
import SliderCard from "../../component/common/cards/SliderCard";
import AllBlogs from "../../component/allblogs/AllBlogs";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import VideoTestim from "../../component/common/video-testimonial/VideoTestim";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import { useEffect, useState } from "react";
import StemProductCard from "../../component/common/cards/StemProductCard";
import { publicRequest } from "../../requestMethod";
import LAIProductCard from "../../component/common/cards/LAIProductCard";
import MiniLoader from "../../component/common/Loader/miniLoader/MiniLoader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const LearnAI = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getProducts(dispatch);
  }, [dispatch]);

  const { isFetching } = useSelector((state) => state.product.isFetching);
  const products = useSelector((state) =>
    state.product.products.filter((item) => !item.isRobotixProduct)
  );
  const { isFetching: isRatingFetching, ratings } = useSelector(
    (state) => state.rating
  );

  // useEffect(() => {
  //   getAllRatings(dispatch);
  // }, [dispatch]);

  const [ageGroup, setAgeGroup] = useState([]);
  const [tabAgeGroupTitles, setTabAgeGroupTitles] = useState([]);
  const getAgeGroups = async () => {
    try {
      const res = await publicRequest.get("/ageGroup/all");
      if (res?.status === 200) {
        setAgeGroup(res.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getAgeGroups();
  }, [dispatch]);

  useEffect(() => {
    // Extract ageGroup property and update tabAgeGroupTitles
    setTabAgeGroupTitles(ageGroup.map((item) => item.ageGroup));
  }, [ageGroup]);

  // Load More
  const [displayIndices, setDisplayIndices] = useState({});

  // Define different card counts for tablet and non-tablet screens
  const defaultDisplayCount = window.innerWidth >= 992 ? 3 : 2;
  const loadMoreCount = window.innerWidth >= 992 ? 3 : 2;

  const handleLoadMore = (ageGroup) => {
    const currentDisplayIndex = displayIndices[ageGroup] || defaultDisplayCount;
    const newDisplayIndex = Math.min(
      currentDisplayIndex + loadMoreCount,
      products.length
    );
    setDisplayIndices({
      ...displayIndices,
      [ageGroup]: newDisplayIndex,
    });
  };

  let contents = [];


  ageGroup.map((item, key) => {
    const ageGroupDisplayIndex =
      displayIndices[item.ageGroup] || defaultDisplayCount;

    // // Declare filteredProducts here
    // const filteredProducts = products.filter(
    //   (product) => product.ageGroup === item.ageGroup
    // );

    // Declare filteredProducts here
    const filteredProducts = products.filter((product) =>
      product.ageGroup.some((age) => item.ageGroup.includes(age))
    );

    contents.push(
      isFetching === true ? (
        <MiniLoader />
      ) : (
        <section key={key}>
          <div className="container">
            <div className="row">
              {filteredProducts
                .slice(0, ageGroupDisplayIndex)
                .map((product, index) => {
                  const ratingForCurrentProduct = ratings.filter(
                    (item) => item.productId === product._id
                  );

                  return (
                    <div className="col-lg-4 col-sm-6" key={index}>
                      <LAIProductCard
                        key={index}
                        {...product}
                        SpcCustom="buy-yellow-btn"
                        ratingForCurrentProduct = {ratingForCurrentProduct}
                      />
                    </div>
                  )

                }
                  //   (
                  //   <div className="col-lg-4 col-sm-6" key={index}>
                  //     <LAIProductCard
                  //       key={index}
                  //       {...product}
                  //       SpcCustom="buy-yellow-btn"
                  //     />
                  //   </div>
                  // )
                )}
            </div>
          </div>
          <div className="load-more-products text-center">
            {ageGroupDisplayIndex < filteredProducts.length && (
              <button
                onClick={() => handleLoadMore(item.ageGroup)}
                className="btn btn-primary"
              >
                Load More
              </button>
            )}
          </div>
        </section>
      )
    );
  });

  // Check if all products are loaded for all age groups
  const allProductsLoaded = ageGroup.every(
    (item) => displayIndices[item.ageGroup] >= products.length
  );

  // Hide the Load More button if all products are loaded
  if (allProductsLoaded) {
    contents.push(
      <div className="text-center" key="all-products-loaded">
        <p>All products loaded.</p>
      </div>
    );
  }

  const age = ["Age 7 to 10", "Age 11 to 13", "Age 14+"];

  // Responsive breakpoint configuration for the gift card carousel
  const responsiveConfig = {
    0: {
      items: 1,
    },
    767: {
      items: 1,
    },
    992: {
      items: 2,
    },
    1024: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  };

  // Card Slider Section Carousel Customizations
  const options = {
    className: "owl-theme gift-cards-slider",
    loop: true,
    items: 1,
    center: true,
    margin: 30,
    autoplay: true,
    smartSpeed: 450,
    nav: true,
    responsive: responsiveConfig,
  };

  return (
    <div className="learn-ai-pg">
      <Helmet>
        <title>Best Ai Certification Courses Online | Meritus AI
        </title>
        <meta name="description" content="We offer the best AI certification courses online for kids from 7 to 14 and above. These will enhance the creative and analytical skills of your kids." />
        <link rel="canonical" href="https://meritus.ai/LearnAI" />
      </Helmet>
      <Container fluid>
        <Row>
          {/* Top banner  */}
          <Col lg={12} className="p-0">
            <div className="top-banner-lai">
              <div className="banner-txt-lai">
                <h1>
                  Learn <span>AI</span> Discover...
                </h1>
                <p>how AI is changing the way we learn, live and work.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* AI Courses */}
      <Container>
        <Row>
          <Col lg={12}>
            <div className="heading-npgs dark-pink-head pt-50">
              <h2>Explore the Wonders of Artificial Intelligence with us</h2>
            </div>
            <div className="sub-heading-npgs">
              <p>
                Transformational AI & Coding courses - Building a brighter
                future with AI
              </p>
            </div>

            {/* Tab to filter courses according to AGE  */}
            <TabWrapper
              titles={age}
              contents={contents}
              TabWrapperClass="stem-tab-wrapper"
              TabDataClass="stem-shop-tab lai-tab-wrapper"
            />
          </Col>
        </Row>
      </Container>
      {/* Coding Projects Section  */}
      <section className="pb-50">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="cps-bg-img">
                <div className="cps-section-content text-center">
                  <div className="heading-npgs p-rel cps-head">
                    <h2>
                      Discover <span>AI & Coding Projects</span>
                    </h2>
                  </div>
                  <div className="sub-heading-npgs cps-sub-head">
                    <p>
                      Unleash your creativity with projects that span from
                      building your very own AI Chatbot to mastering Face
                      Detection and much more in our <span>Project Hub</span> !
                    </p>
                  </div>

                  {/* Project Hub Button  */}
                  <Link
                    to="https://aiworldschool.com/projects/"
                    target="_blank"
                  >
                    <CommonButton
                      btnClass="explore-project-hub-btn"
                      btnName="Explore Project Hub Now"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Gift Options Section  */}
      {/* <section className="card-slider pt-50 pb-30">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-npgs dark-pink-head pb-30">
                <h2>Gifting Options</h2>
              </div>
            </Col>
          </Row> */}
      {/* caraousel  */}
      {/* <OwlCarousel {...options} responsive={responsiveConfig}> */}
      {/* Gift card -1  */}
      {/* <SliderCard
              CardImg="images/learn-ai/Gifting-option-1-meritus.jpg"
              CardTitle="Lorem ipsum dolor sit amet"
              CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
            /> */}
      {/* Gift card 2 */}
      {/* <SliderCard
              CardImg="images/learn-ai/Gifting-option-2-meritus.jpg"
              CardTitle="Lorem ipsum dolor sit amet"
              CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
            /> */}
      {/* Gift card 3 */}
      {/* <SliderCard
              CardImg="images/learn-ai/Gifting-option-3-meritus.jpg"
              CardTitle="Lorem ipsum dolor sit amet"
              CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
            /> */}
      {/* Gift card 4 */}
      {/* <SliderCard
              CardImg="images/learn-ai/Gifting-option-4-meritus.jpg"
              CardTitle="Lorem ipsum dolor sit amet"
              CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
            /> */}
      {/* Gift card -5  */}
      {/* <SliderCard
              CardImg="images/learn-ai/Gifting-option-1-meritus.jpg"
              CardTitle="Lorem ipsum dolor sit amet"
              CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
            />
          </OwlCarousel>
        </Container>
      </section> */}
      {/* Video testimonial section  */}
      <section className="learners-testimonials pt-50">
        <Container>
          <Row>
            <VideoTestim
              className="video-testim-wrapper pt-50"
              sectionHeading="What our learners are saying?"
              category="learnAI"
            />
          </Row>
        </Container>
      </section>
      {/* Blogs section  */}
      <section className="blogsLAI pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-npgs dark-pink-head pb-20">
                <h2>Real World Applications of AI</h2>
              </div>
            </Col>
          </Row>

          {/* All Blogs Component  */}
          <Row>
            <AllBlogs pageType="learnAI" />
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default LearnAI;
