import "./OurStory.css";
const OurStory = () => {
  return (
    <div className="our_story">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 position-relative">
            <img src="images/about/story.png" />
            <h2 className="inh2">
              Our <span>story</span>
            </h2>
          </div>
          <div className="col-lg-5 mt-md-5 mt-lg-0">
            <p>
              Meritus emerged from a vision that intertwines education with
              innovation. Rooted in the belief that learning is a lifelong
              pursuit, our journey began as a commitment to empower the future
              through technology. Over the years, we've evolved into a trusted
              name in the education sector, pioneering transformative solutions
              that prepare learners for an ever-changing world. Our story is one
              of dedication to excellence, passion for innovation, and the
              unwavering desire to shape a brighter future through education.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurStory;
