import React, { useEffect } from "react";
import "./CategoryBlogs.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import EventBanner from "../component/events/EventBanner";
import {
  getAllBlogCategory,
  getAllBlogs,
  getSpecificCategoryBlog,
} from "../redux/apiCalls";
import BlogCard from "../component/BlogCard";
import Loader from "../component/common/Loader/Loader";

const CategoryBlogs = () => {
  const location = useLocation();
  const blogCategoryTitle = decodeURIComponent(location.pathname.split("/")[2]);
  const { isFetching, blogs } = useSelector((state) => state.blog);
  // const state = useSelector((state) => state.);

  const blogCategories = useSelector(
    (state) => state.blogCategory.blogCategory
  );
  const currentBlogcategory =
    blogCategories &&
    blogCategories.find((item) => item.category === blogCategoryTitle);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   getAllBlogs(dispatch);
  // }, []);
  useEffect(() => {
    getSpecificCategoryBlog(dispatch, blogCategoryTitle);
  }, [blogCategoryTitle]);

  useEffect(() => {
    getAllBlogCategory(dispatch);
  }, [dispatch]);

  const blogsOfCurrentCategory =
    blogs && blogs.filter((item) => item.category === blogCategoryTitle);

  if (isFetching) {
    return <Loader />
  }

  return (
    <>
      <EventBanner
        categoryImage={currentBlogcategory && currentBlogcategory.categoryImage}
        bannerTitle={currentBlogcategory && currentBlogcategory.category}
      />
      <div className="container mt-5">
        <div className="row">
          {blogsOfCurrentCategory &&
            blogsOfCurrentCategory.map((blogCard, index) => {
              if (blogCard.status === true) {
                return (
                  <div className="col-lg-4 col-md-6" key={blogCard._id}>
                    <BlogCard blog={blogCard} />
                  </div>
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

export default CategoryBlogs;
