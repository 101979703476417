import { useDispatch, useSelector } from "react-redux";
import "./card2.css";
import { Link } from "react-router-dom";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import {
  addProductToWishListFailure,
  addProductToWishListStart,
  addProductToWishListSuccess,
  removeProductFromWishListFailure,
  removeProductFromWishListStart,
  removeProductFromWishListSuccess,
} from "../redux/wishListRedux";
import WishListHeart from "./common/WishListHeart";
import { useState } from "react";
import { truncateText } from "../config";

function Card2(props) {
  // Cheking user is logged or not=====
  const user = useSelector((state) => state.loginUser.currentUser);

  const product = useSelector((state) => state);
  const [isHeartExploded, setHeartExploded] = useState(false);
  // const [featuredProduct, setFeaturedProduct] = useState([]);

  const dispatch = useDispatch();

  const addProductToWishList = async (dispatch, product) => {
    dispatch(addProductToWishListStart());
    try {
      dispatch(addProductToWishListSuccess(product));
      setHeartExploded(true);
    } catch (error) {
      dispatch(addProductToWishListFailure());
    }
  };

  const handleAddProductToWishList = (product) => {
    addProductToWishList(dispatch, product);
  };

  const removeProductFromWishList = (dispatch, productId) => {
    dispatch(removeProductFromWishListStart());
    try {
      // const res = await userRequest.post('user/create', product);
      dispatch(removeProductFromWishListSuccess(productId));
      setHeartExploded(false);
      // if (res.status === 200) {
      //     setSuccess(true);
      //     setSuccessMessage("product has been added!");
      //     setFirstName('');
      //     setLastName('');
      //     setEmail('');
      //     setPhone('');
      //     setDateOfBirth('');
      // }
    } catch (error) {
      dispatch(removeProductFromWishListFailure());
      // if (error.response.status === 409) {
      //     setEmailError(true);
      //     setEmailErrorMessage(error.response.data);
      // }

      // else {
      //     setError(true);
      //     setErrorMessage("Something went wrong!");
      // }
    }
  };

  const wishlist = useSelector((state) => state.wishList.wishListItems);


  const handleRemoveProductFromWishList = (productId) => {
    removeProductFromWishList(dispatch, productId);
  };

  // Set the maximum length for the description
  const maxDescriptionLength = 100;

  // Truncate the description if it's longer than the maximum length
  // const truncatedDescription = ProductCardDesc.length > maxDescriptionLength
  //   ? ProductCardDesc.substring(0, maxDescriptionLength) + '...' // Add ellipsis for truncated text
  //   : ProductCardDesc;

  // Function to check if a product is in the wishlist
  const isProductInWishlist = (productId) => {
    return wishlist.some((product) => product._id === productId);
  };

  return (
    <div>
      <div className="card2_container mb-2">
        {/* <img src={ IMG_URL + props.productImg} /> */}

        <div className="card2-img-wrapper">
          <Link to={props.type === "robotix"  ? `/robotix-product/${props._id}` : `/product/${props._id}`}>
            <img src={props.productImg} />
          </Link>
        </div>

        <div className="card2-title-wrapper">
          <h2 className="ff fw-600 text-capitalize">{props.title}</h2>
          {user ? (
            isProductInWishlist(props._id) ? ( // Check if the product is in the wishlist
              <AiFillHeart
                className="heart-icon"
                onClick={() => handleRemoveProductFromWishList(props._id)}
              />
            ) : (
              <AiOutlineHeart
                onClick={() => handleAddProductToWishList(props)}
              />
            )
          ) : (
            // isHeartExploded ? (
            //   <AiFillHeart
            //     className="heart-icon"
            //     onClick={() => handleRemoveProductFromWishList(props._id)}
            //   />
            // ) : (
            //   <AiOutlineHeart
            //     onClick={() => handleAddProductToWishList(props)}
            //   />
            // )
            <Link to={"/login"}>
              <AiOutlineHeart />
            </Link>
          )}
        </div>
        <p className="mt-0">{props.shortDescription}</p>
        {/* <p className="mt-0">{truncateText(props.description, 100)}</p> */}
        <div className="card2_container_option">
          <div>
            <span className="fw-600">Age: {props.ageGroup}</span>
            <span>
              4.7 <span className="rating">★★★★★</span>
            </span>
          </div>
          <div>
            <span className="ff fw-600 f-15">₹ {props.price}</span>
            <span>
              {props.numberOfSessions
                ? `${props.numberOfSessions} Sessions`
                : "N.A."}
            </span>
          </div>
          <div>
            {
              props.type === "robotix" ? (
                <Link
                  to={`/robotix-product/${props._id}`}
                  className="uniBtn uniBtnBlue btnPLight w-100 text-center"
                >
                  Buy Now
                </Link>
              ) : (
                <>
                  <Link href="/" className="uniBtn uniBtnPinkBorder btnPLight ">
                    Try For Free
                  </Link>
                  <Link
                    to={`/product/${props._id}`}
                    className="uniBtn uniBtnBlue btnPLight"
                  >
                    Get Started
                  </Link>
                </>
              )
            }

          </div>
        </div>
      </div>
    </div>
  );
}
export default Card2;
