import "bootstrap/dist/css/bootstrap.css";
import "../../../styles.css";
import "../bought-courses/AllBCourses.css";
import ProductCard from "../../common/cards/ProductCard";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllOrders, getProducts } from "../../../redux/apiCalls";
import { calculateCompletionPercentage, encryptUniqueId, status, truncateText } from "../../../config";
import { useState } from "react";
import MiniLoader from "../../common/Loader/miniLoader/MiniLoader";

const AllBCourses = (props) => {

  const dispatch = useDispatch();
  const id = useSelector((state) => state.loginUser.currentUser._id);

  useEffect(() => {
    getAllOrders(dispatch, id);
  }, [dispatch]);

  useEffect(() => {
    getProducts(dispatch);
  }, [dispatch]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [allCombinedCourses, setAllCombinedCourses] = useState([]);


  const allProductAccess = useSelector((state) => state.product.products);
  const filteredAllProductAccess = selectedCategory
    ? allProductAccess.filter((item) => item.courseType === selectedCategory)
    : allProductAccess;
  // const filteredAllProductAccess = selectedCategory
  //   ? allProductAccess.filter(
  //       (item) =>
  //         item.productId !== null &&
  //         item.productId.courseType === selectedCategory
  //     )
  //   : allProductAccess;

  const { isFetching, orders } = useSelector((state) => state.order);

  const allOrders = orders?.filter((item) => item.paymentStatus === "success");

  const allProducts =
    allOrders &&
    allOrders.flatMap((order) =>
      order.products.filter((item) => !item.productId?.isRobotixProduct)
    );

  // const specificCourses = useSelector((state) => state.loginUser.currentUser?.specificCourseAccess);

  let specificAndOtherCourses;

  useEffect(() => {
    // if (specificCourses.length > 0) {
    // specificAndOtherCourses = [...,];
    setAllCombinedCourses(allProducts);
    // } else {
    // setFilteredCourses(allProducts)
    // setFilteredCourses(allProducts)
    // }
  }, []);

  const handleCategorySelect = (eventKey) => {
    setSelectedCategory(eventKey);
  };

  // const filteredCourses = selectedCategory
  //   ? allOrders?.filter(
  //       (item) =>
  //         // item.productId !== null &&
  //         item?.productId?.courseType === selectedCategory
  //     )
  //   : allOrders;


  // const uniqueCourseTypes = [
  //   ...new Set(
  //     allOrders?.map(
  //       (course) => course && course.productId?.courseType
  //     )
  //   ),
  // ];
  const filteredCourses = selectedCategory
    ? allCombinedCourses?.filter(
      (item) =>
        // item.productId !== null &&
        item?.productId?.courseType === selectedCategory
    )
    : allCombinedCourses;

  const uniqueCourseTypes = [
    ...new Set(
      allCombinedCourses?.map(
        (course) => course && course.productId?.courseType
      )
    ),
  ];

  if (isFetching) {
    return <MiniLoader />;
  }



  // return {
  if (props.tabType === "all-course") {
    return (
      <div className="all-courses-b">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading pl-20 up-tab-heading pt-30 pb-20">
                <h1>All Courses</h1>
              </div>
            </Col>

            {/* Filter courses buttons */}
            <div className="filter-btns">
              <div className="category-btn pb-50">
                <Dropdown
                  className="d-inline mx-3"
                  onSelect={handleCategorySelect}
                >
                  <Dropdown.Toggle>
                    {selectedCategory ? selectedCategory : "Categories"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="">All Categories</Dropdown.Item>
                    {uniqueCourseTypes.map((courseType) => (
                      <Dropdown.Item key={courseType} eventKey={courseType}>
                        {courseType}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="all-bought-courses p-0">
              <Row>
                {
                  // id === "648f50bdc3b82dd3ae7caadc"
                  //   ? filteredAllProductAccess.map((item) => {
                  //     // const productId = item.productId;
                  //     // if (!productId) {
                  //     //     return null; // Skip items with null productId
                  //     // }

                  //     return (
                  //       <Col lg={3} md={6}>
                  //         <Link to={`/course/${item._id}`}>
                  //           <ProductCard
                  //             ProductCardImage={item && item.productImg}
                  //             ProductCardTitle={item && item.title}
                  //             ProductCardDesc={`Say hello to ${item.title}`}
                  //             CompletePercent="0"
                  //           />
                  //         </Link>
                  //       </Col>
                  //     );
                  //   })
                  //   : 
                  filteredCourses?.map((item) => {
                    const productId = item.productId;
                    if (!productId?.title) {
                      return null; // Skip items with null productId
                    }

                    const courseProgress = calculateCompletionPercentage(productId)

                    return (
                      <Col lg={3} md={6}>
                        {/* <Link
                        to={`/course/${item.productId && item.productId._id}`}
                      > */}
                        <Link to={`/course/courseId?courseId=${encryptUniqueId(item._id)}`}>
                          <ProductCard
                            ProductCardImage={
                              item.productId && item.productId.productImg
                            }
                            ProductCardTitle={
                              item.productId &&
                              truncateText(item.productId.title, 80)
                            }
                            ProductCardDesc={
                              item.productId && item.productId?.shortDescription
                            }
                            CompletePercent={courseProgress}
                          />
                        </Link>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    )
  };

  if (props.tabType === "complete-course") {
    return (
      <div className="all-courses-b">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading pl-20 up-tab-heading pt-30 pb-20">
                <h1>Complete Courses</h1>
              </div>
            </Col>

            {/* Filter courses buttons */}
            <div className="filter-btns">
              <div className="category-btn pb-50">
                <Dropdown
                  className="d-inline mx-3"
                  onSelect={handleCategorySelect}
                >
                  <Dropdown.Toggle>
                    {selectedCategory ? selectedCategory : "Categories"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="">All Categories</Dropdown.Item>
                    {uniqueCourseTypes?.map((courseType) => (
                      <Dropdown.Item key={courseType} eventKey={courseType}>
                        {courseType}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="all-bought-courses p-0">
              <Row>
                {
                  filteredCourses?.map((item) => {
                    const productId = item.productId;
                    if (!productId) {
                      return null; // Skip items with null productId
                    }

                    const courseProgress = calculateCompletionPercentage(productId);
                    if (courseProgress === 100) {
                      return (
                        <Col lg={3} md={6}>
                          {/* <Link
                          to={`/course/${item.productId && item.productId._id}`}
                        > */}
                          <Link to={`/course/courseId?courseId=${encryptUniqueId(item._id)}`}>
                            <ProductCard
                              ProductCardImage={
                                item.productId && item.productId.productImg
                              }
                              ProductCardTitle={
                                item.productId &&
                                truncateText(item.productId.title, 10)
                              }
                              // {props?.shortDescription && (
                              //   <p className="m-0">
                              //     {truncateText(props?.shortDescription, 80)}
                              //   </p>
                              // )}
                              ProductCardDesc={
                                item.productId && item.productId.shortDescription
                              }
                              CompletePercent={courseProgress}
                            />
                          </Link>
                        </Col>
                      );
                    }
                  })}
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    )
  }

  return null;
};

export default AllBCourses;
