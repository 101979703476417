import BlogCard from "../component/BlogCard";
import "./PastEvents.css";
const pastEvents = () => {
  const items = [
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-1",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-2",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-3",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-4",
    },
  ];
  const blogcardData = [
    {
      id: 1,
      imageUrl: "images/latestNews/img-1.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and tyspesetting industry.",
      read: "/",
    },
    {
      id: 2,
      imageUrl: "images/latestNews/img-2.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
    {
      id: 3,
      imageUrl: "images/latestNews/img-3.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
  ];
  return (
    <div className="pastEvents_container">
      <div className="pastEvents_banner position-relative">
        <div className="container ">
          <img src="images/pastEvents/past_events.png" />
          <p className="fw-300 pSize">
            Events &gt; <span> Past Events</span>
          </p>
          <h2 className="inh2">
            Better future for children <span>lavale village</span>, pune
          </h2>
          <p className="fw-600 pSize">By Rachel Pannett</p>
          <p className="fw-300 pSize"> February 30,2020, 3:00pm</p>
        </div>
      </div>
      <div className="container">
        <div className="pastEvents-component pt-md-4">
          <img
            src="images/pastEvents/image-2.jpg"
            alt="pastEvents"
            className="full-width-image"
          />
          <div className="paragraphs pt-md-5">
            <p>
              PLorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy.Lorem
              Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has{" "}
            </p>
            <p>
              <b>
                been.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been. Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy.Lorem Ipsum
                is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has
              </b>
            </p>
            <p>
              been.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply
              dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy.Lorem Ipsum is simply dummy
              text of the printing and typesetting industry. Lorem Ipsum has{" "}
            </p>
            <p>
              been.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply
              dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy.Lorem Ipsum is simply dummy
              text of the printing and typesetting industry. Lorem Ipsum has{" "}
            </p>
          </div>
          <div className="pastEvents_section">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 pe-5">
                <p>
                  PLorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has{" "}
                </p>
                <p>
                  PLorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has{" "}
                </p>
                <p>
                  PLorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has{" "}
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src="images/pastEvents/image-1.jpg"
                  className="full-width-image img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pb-100 pt-100">
        <h2 className="container inh2 pb-md-4">
          Top <span>past Events</span>
        </h2>
        <div className="container">
          <div className="row">
            {blogcardData.map((blogCard, index) => (
              <div className="col-md-4" key={blogCard.id}>
                <BlogCard {...blogCard} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default pastEvents;
