// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-btns button {
    background-color: transparent;
    background-color: initial;
    border-radius: 50px;
    border: 2px solid #977CFF;
    padding: 10px 25px;
    color: var(--uPGrey);
}

.all-bought-courses .product-img {
    width: 100%;
    height: 200px;
}

.all-bought-courses .product-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

@media (max-width: 767px) {
    .filter-btns button {
        padding: 7px 15px;
        margin-bottom: 20px;
    }

    .category-btn {
        padding-bottom: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/user-profile/bought-courses/AllBCourses.css"],"names":[],"mappings":"AAAA;IACI,6BAAuB;IAAvB,yBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI;QACI,iBAAiB;QACjB,mBAAmB;IACvB;;IAEA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".filter-btns button {\r\n    background-color: unset;\r\n    border-radius: 50px;\r\n    border: 2px solid #977CFF;\r\n    padding: 10px 25px;\r\n    color: var(--uPGrey);\r\n}\r\n\r\n.all-bought-courses .product-img {\r\n    width: 100%;\r\n    height: 200px;\r\n}\r\n\r\n.all-bought-courses .product-img img{\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n    border-radius: 10px;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .filter-btns button {\r\n        padding: 7px 15px;\r\n        margin-bottom: 20px;\r\n    }\r\n\r\n    .category-btn {\r\n        padding-bottom: 0px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
