import { Link } from "react-router-dom";
import "./Banner.css";
function Banner() {
  return (
    <div>
      <section className="bundle_india px-lg-5 pb-4">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-7 order-1 order-lg-0">
              <h1 className="banner_txt dBlue">
                Primary School
                <br />
                <span className="dPink"> Bundle</span>
              </h1>
              <h2>
                A comprehensive AI & Robotics Education platform for your school
              </h2>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="banner_img">
                <img
                  src="images/SchoolBundle/outside/outside-banner-2.png"
                  alt="Hello"
                />
                {/* <img
                  src="images/SchoolBundle/outside/outside-banner-2.png"
                  alt="Hello"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Banner;
