// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trainers h2:first-child {
  font-size: var(--hSize);
  color: #264ec8;
}
.trainers h2:first-child span {
  color: #e20f6f;
}
.Trainers_container {
  text-align: left;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding-bottom: 20px;
  overflow: hidden;
}
.Trainers_container img {
  border-radius: 15px;
  height: 370px;
  width: 100%;
  object-fit: cover;
}
.Trainers_container_option {
  display: flex;
  justify-content: space-between;
}
.Trainers_container h2,
.Trainers_container p,
.Trainers_container_option {
  padding: 0px 25px;
}
.Trainers_container h2 {
  margin-top: 20px;
}
.Trainers_container p {
  color: #323232;
}
.Trainers_container_option span:first-child {
  padding-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/component/trainers.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,4CAA4C;EAC5C,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;;;EAGE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".trainers h2:first-child {\r\n  font-size: var(--hSize);\r\n  color: #264ec8;\r\n}\r\n.trainers h2:first-child span {\r\n  color: #e20f6f;\r\n}\r\n.Trainers_container {\r\n  text-align: left;\r\n  background: #ffffff;\r\n  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);\r\n  border-radius: 15px;\r\n  padding-bottom: 20px;\r\n  overflow: hidden;\r\n}\r\n.Trainers_container img {\r\n  border-radius: 15px;\r\n  height: 370px;\r\n  width: 100%;\r\n  object-fit: cover;\r\n}\r\n.Trainers_container_option {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.Trainers_container h2,\r\n.Trainers_container p,\r\n.Trainers_container_option {\r\n  padding: 0px 25px;\r\n}\r\n.Trainers_container h2 {\r\n  margin-top: 20px;\r\n}\r\n.Trainers_container p {\r\n  color: #323232;\r\n}\r\n.Trainers_container_option span:first-child {\r\n  padding-right: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
