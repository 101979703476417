// import React from "react";
import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import "./SchoolForm.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { countriesListWithCode } from "../../config";
import { publicRequest } from "../../requestMethod";
import { validateRequired } from "../../formValidation";

const SchoolForm = () => {
  // const [attendees, setAttendees] = useState(0);
  // const [anotherValue, setAnotherValue] = useState(0);

  // const handleChange = (event, setValue, minValue, maxValue) => {
  //   let newValue = parseInt(event.target.value, 10);

  //   // Check if the newValue is within the desired range
  //   if (!isNaN(newValue) && newValue >= minValue && newValue <= maxValue) {
  //     setValue(newValue);
  //   } else {
  //     // If not within the range, set the value to the nearest valid value
  //     setValue(Math.min(maxValue, Math.max(minValue, newValue)));
  //   }
  // };

  // const handleKeyDown = (event) => {
  //   // Prevent default behavior of the up and down arrow keys
  //   if (event.key === "ArrowUp" || event.key === "ArrowDown") {
  //     event.preventDefault();
  //   }
  // };

  // const handleWheel = (event, setValue, minValue, maxValue) => {
  //   // Prevent default behavior of mouse scroll
  //   event.preventDefault();

  //   // Calculate the new value based on wheel direction
  //   const delta = Math.sign(event.deltaY);
  //   setValue((prevValue) => {
  //     const newValue = prevValue - delta;
  //     return Math.min(maxValue, Math.max(minValue, newValue));
  //   });
  // };

  const [schoolName, setSchoolName] = useState(null)
  const [principleName, setPrincipleName] = useState(null)
  const [countryCode, setCountryCode] = useState("+91")
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)
  const [schoolAddress, setSchoolAddress] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [country, setCountry] = useState(null)
  const [workShopOne, setWorkShopOne] = useState(null)
  const [workShopOneAttendees, setWorkShopOneAttendees] = useState(null)
  const [workShopTwo, setWorkShopTwo] = useState(null)
  const [workShopTwoAttendees, setWorkShopTwoAttendees] = useState(null)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);


  const submitFormQuery = async (user) => {
    try {
      setIsLoaderActive(true);
      const res = await publicRequest.post("teacher-pdp-form/create", user);
      if (res && res.status === 200) {
        setIsLoaderActive(false);
        setSuccess(true);
        setSuccessMessage("We have recieved your query.");
      }
    } catch (error) {
      setIsLoaderActive(false);
      setError(true);
      setErrorMessage("Something went wrong!");
    }
  }

  // Validate function for form validation start =======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const schoolNameValidErr = validateRequired(schoolName);
    const principleNameValidErr = validateRequired(principleName);
    const phoneValidErr = validateRequired(phone, "phone");
    const schoolAddressValidErr = validateRequired(schoolAddress);
    const cityValidErr = validateRequired(city);
    const emailValidErr = validateRequired(email);
    const stateValidErr = validateRequired(state);
    const countryValidErr = validateRequired(country);
    const workShopOneValidErr = validateRequired(workShopOne);
    const workShopTwoValidErr = validateRequired(workShopTwo);
    const workShopOneAttendeesValidErr = validateRequired(workShopOneAttendees);
    const workShopTwoAttendeesValidErr = validateRequired(workShopTwoAttendees);

    if (schoolNameValidErr) {
      formIsValid = false;
      newErrors.schoolName = schoolNameValidErr;
    }
    if (principleNameValidErr) {
      formIsValid = false;
      newErrors.principleName = principleNameValidErr;
    }
    if (emailValidErr) {
      formIsValid = false;
      newErrors.email = emailValidErr;
    }
    if (phoneValidErr) {
      formIsValid = false;
      newErrors.phone = phoneValidErr;
    }
    if (schoolAddressValidErr) {
      formIsValid = false;
      newErrors.schoolAddress = schoolAddressValidErr;
    }
    if (cityValidErr) {
      formIsValid = false;
      newErrors.city = cityValidErr;
    }
    if (countryValidErr) {
      formIsValid = false;
      newErrors.country = countryValidErr;
    }
    if (stateValidErr) {
      formIsValid = false;
      newErrors.state = stateValidErr;
    }
    if (workShopOneValidErr) {
      formIsValid = false;
      newErrors.workShopOne = workShopOneValidErr;
    }
    if (workShopTwoValidErr) {
      formIsValid = false;
      newErrors.workShopTwo = workShopTwoValidErr;
    }
    if (workShopOneAttendeesValidErr) {
      formIsValid = false;
      newErrors.workShopOneAttendees = workShopOneAttendeesValidErr;
    }
    if (workShopTwoAttendeesValidErr) {
      formIsValid = false;
      newErrors.workShopTwoAttendees = workShopTwoAttendeesValidErr;
    }

    setErrors(newErrors);
    return formIsValid;
  };
  // Validate function for form validation end======

  const submitData = async () => {
    setSuccess(false);
    setError(false)
    if (validateForm()) {
      submitFormQuery({
        schoolName,
        principleName,
        email,
        countryCode,
        phone,
        schoolAddress,
        city,
        state,
        country,
        workShopOne,
        workShopTwo,
        workShopOneAttendees,
        workShopTwoAttendees
      });
    }
  };

  return (
    <div className="container mt-5 school_form_container" id="pdp_form">
      <h2 className="inb dPink text-center">
        Register here for 2 Free Workshops
      </h2>
      <p className="text-center">
        Elevate your teaching skills with our exclusive Teacher PDP Workshops!
      </p>
      <form className="teacher-pdp-form">
        <div className="mb-3 row">
          <label
            htmlFor="schoolName"
            className="d-flex justify-content-end col-sm-3 col-form-label"
          >
            School Name*
          </label>
          <div className="col-sm-7">
            <input
              type="text"
              id="schoolName"
              className="form-control"
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
            />
            <span className="error-text">{errors.schoolName}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="principalName"
            className="d-flex justify-content-end  col-sm-3 col-form-label"
          >
            Principal/Correspondent Name*
          </label>
          <div className="col-sm-7">
            <input
              type="text"
              id="principalName"
              className="form-control"
              value={principleName}
              onChange={(e) => setPrincipleName(e.target.value)}
            />
            <span className="error-text">{errors.principleName}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="principalPhone"
            className="d-flex justify-content-end col-sm-3 col-form-label"
          >
            Principal Phone No*
          </label>
          <div className="col-sm-2">
            <select id="countryCode" value={countryCode} className="form-control" onChange={(e) => setCountryCode(e.target.value)}>
              {
                countriesListWithCode.map((country) => {
                  return (
                    <option value={country.code}>{country.code}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="col-sm-5">
            <input
              type="text"
              id="principalPhone"
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <span className="error-text">{errors.phone}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="principalEmail"
            className=" d-flex justify-content-end col-sm-3 col-form-label"
          >
            Principal Email ID*
          </label>
          <div className="col-sm-7">
            <input
              type="email"
              id="principalEmail"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className="error-text">{errors.email}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label className="col-sm-3 col-form-label d-flex justify-content-end ">
            School Address
          </label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control mb-2"
              placeholder=""
              value={schoolAddress}
              onChange={(e) => setSchoolAddress(e.target.value)}
            />
            <span className="error-text">{errors.schoolAddress}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="city"
            className="col-sm-3 col-form-label d-flex justify-content-end "
          >
            City*
          </label>
          <div className="col-sm-3">
            <input type="text" id="city" className="form-control" value={city} onChange={(e) => setCity(e.target.value)} />
            <span className="error-text">{errors.city}</span>
          </div>
          <label
            htmlFor="state"
            className="col-sm-1 col-form-label d-flex justify-content-center"
          >
            State
          </label>
          <div className="col-sm-3">
            <input type="text" id="state" className="form-control" value={state} onChange={(e) => setState(e.target.value)} />
            <span className="error-text">{errors.state}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="country"
            className="col-sm-3 col-form-label d-flex justify-content-end "
          >
            Country*
          </label>
          <div className="col-sm-7">
            <input type="text" id="country" className="form-control" value={country} onChange={(e) => setCountry(e.target.value)} />
            <span className="error-text">{errors.country}</span>
          </div>
        </div>

        <div className="mb-3 row align-items-center">
          <label
            htmlFor="workshop1"
            className="col-sm-3 col-form-label d-flex justify-content-end align-items-center"
          >
            Workshop 1 *
          </label>
          <div className="col-sm-3 position-relative">
            <select id="workshop1" className="form-control" value={workShopOne} onChange={(e) => setWorkShopOne(e.target.value)}>
              <option value="">Select Workshop</option>
              <option value="workshopOption1">Chatgpt Workshop</option>
              {/* Add more options as needed */}
            </select>
            <span className="error-text">{errors.workShopOne}</span>
            <FaChevronDown className="workshop_icon position-absolute top-50 translate-middle-y" />
          </div>
          <label
            htmlFor="attendees1"
            className="col-sm-2 col-form-label d-flex justify-content-center"
          >
            No of Attendees *
          </label>
          <div className="col-sm-2">
            <input
              id="attendees1"
              className="form-control"
              value={workShopOneAttendees} onChange={(e) => setWorkShopOneAttendees(e.target.value)}
            />
            <span className="error-text">{errors.workShopOneAttendees}</span>
          </div>
        </div>

        <div className="mb-3 row align-items-center">
          <label
            htmlFor="workshop1"
            className="col-sm-3 col-form-label d-flex justify-content-end align-items-center"
          >
            Workshop 2 *
          </label>
          <div className="col-sm-3 position-relative">
            <select id="workshop1" className="form-control" value={workShopTwo} onChange={(e) => setWorkShopTwo(e.target.value)}>
              <option value="">Select Workshop</option>
              <option value="workshopOption1">AI Workshop</option>
              {/* Add more options as needed */}
            </select>
            <FaChevronDown className="workshop_icon position-absolute top-50 translate-middle-y" />
            <span className="error-text">{errors.workShopTwo}</span>
          </div>
          <label
            htmlFor="attendees1"
            className="col-sm-2 col-form-label d-flex justify-content-center"
          >
            No of Attendees *
          </label>
          <div className="col-sm-2">
            <input
              id="anotherInput"
              className="form-control"
              value={workShopTwoAttendees} onChange={(e) => setWorkShopTwoAttendees(e.target.value)}
            />
            <span className="error-text">{errors.workShopTwoAttendees}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            {success ? (
              <div className="alert alert-success">
                {successMessage}
              </div>
            ) : (
              error && (
                <div className="alert alert-danger">{errorMessage}</div>
              )
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 text-center school_form_submit">
            <button type="button" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={submitData}>
              <span>Click to Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SchoolForm;
