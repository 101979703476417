// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexible_plan h2 {
  position: relative;
  padding: 20px;
  font-size: 16px;
  color: #ffffff;
  background: var(--dBlue);
  z-index: 5;
}

.flexible_plan {
  height: 380px;
  position: relative;
  background: #f4f6fc;
  padding-bottom: 10px;
}
.flexible_plan p {
  padding: 5px 20px;
  text-align: center;
}
.flexible_plan_title {
  position: relative;
  padding-top: 30px;
  text-align: center;
}
.flexible_plan_footer {
  position: absolute;
  bottom: 20px;
  color: #ffffff;
  margin: 10px 20px;
  text-align: center;
  border-radius: 13px;
  background-color: rgb(12, 182, 253);
  box-shadow: 0px 3px 4.8px 0.2px rgba(19, 19, 19, 0.55);
  padding: 5px;
}

.flexible_plan_footer a {
  color: #ffffff;
}

.star_shape {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: var(--dBlue);
  left: 50%;
  transform: translate(-50%, -35%) rotate(45deg);
  z-index: -1;
}
@media only screen and (max-width: 768px) {
  .flexible_plan {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 992px) and (min-width: 577px) {
  .flexible_plan {
    height: 500px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/bundle/FlexiblePlan.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,cAAc;EACd,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,mCAAmC;EACnC,sDAAsD;EACtD,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,SAAS;EAET,8CAA8C;EAC9C,WAAW;AACb;AACA;EACE;IACE,mBAAmB;EACrB;AACF;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".flexible_plan h2 {\n  position: relative;\n  padding: 20px;\n  font-size: 16px;\n  color: #ffffff;\n  background: var(--dBlue);\n  z-index: 5;\n}\n\n.flexible_plan {\n  height: 380px;\n  position: relative;\n  background: #f4f6fc;\n  padding-bottom: 10px;\n}\n.flexible_plan p {\n  padding: 5px 20px;\n  text-align: center;\n}\n.flexible_plan_title {\n  position: relative;\n  padding-top: 30px;\n  text-align: center;\n}\n.flexible_plan_footer {\n  position: absolute;\n  bottom: 20px;\n  color: #ffffff;\n  margin: 10px 20px;\n  text-align: center;\n  border-radius: 13px;\n  background-color: rgb(12, 182, 253);\n  box-shadow: 0px 3px 4.8px 0.2px rgba(19, 19, 19, 0.55);\n  padding: 5px;\n}\n\n.flexible_plan_footer a {\n  color: #ffffff;\n}\n\n.star_shape {\n  position: absolute;\n  width: 40px;\n  height: 40px;\n  background-color: var(--dBlue);\n  left: 50%;\n  -webkit-transform: translateX(-50%);\n  transform: translate(-50%, -35%) rotate(45deg);\n  z-index: -1;\n}\n@media only screen and (max-width: 768px) {\n  .flexible_plan {\n    margin-bottom: 15px;\n  }\n}\n@media only screen and (max-width: 992px) and (min-width: 577px) {\n  .flexible_plan {\n    height: 500px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
