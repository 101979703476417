import "./PrivacyPolicy.css";
import "./CareerInternal.css";
import SimilarJobCard from "../component/common/similarJob/SimilarJobCard";
import { FaClock, FaLocationArrow } from "react-icons/fa";
const CareerInternal = () => {
  const jobs = [
    {
      id: 1,
      jobCategory: "Software Developer",
      category: "Engineering",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      remoteText: "Remote",
      fullTimeText: "Full Time",
      applyLink: "https://example.com",
    },
    {
      id: 2,
      jobCategory: "Data Analyst",
      category: "Data Science",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      remoteText: "Remote",
      fullTimeText: "Part Time",
      applyLink: "https://example.com",
    },
    {
      id: 3,
      jobCategory: "Graphic Designer",
      category: "Design",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      remoteText: "Remote",
      fullTimeText: "Contract",
      applyLink: "https://example.com",
    },
  ];
  return (
    <div className="privacy_internal">
      <div className="privacy_internal_banner position-relative">
        <div className="container ">
          <img src="images/news/news_banner.png" />
          {/* <p className="fw-300 pSize">
            Product <span>Designs</span>
          </p> */}
          <h2 className="inh2">
            Product <span>Designs</span>
          </h2>
          <div className="careerI_banner_icons">
            <div className="icon">
              <FaLocationArrow className="dPink" />
              <span className="fw-700">Remote</span>
            </div>
            <div className="icon">
              <FaClock className="dBlue" />
              <span className="fw-700">Full Time</span>
            </div>
          </div>
          <a href="/" className="uniBtn uniBtnBlue btnPLight mt-3">
            Apply to job
          </a>
        </div>
      </div>
      <section className="container">
        <div className="privacy_section pt-5 mt-4">
          <h2 className="">
            <span>Responsibilties</span>
          </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy.Lorem
            Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy.Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been. Lorem Ipsum
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy.Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Key Skills <span> Required</span>
          </h2>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the
          </p>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy.Lorem Ipsum is simply dummy text
            of the printing and typesetting industry. Lorem Ipsum has
          </p>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Perks at <span> Meritus</span>
          </h2>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the
          </p>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy.Lorem Ipsum is simply dummy text
            of the printing and typesetting industry. Lorem Ipsum has
          </p>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            About <span> Meritus</span>
          </h2>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the
          </p>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy.Lorem Ipsum is simply dummy text
            of the printing and typesetting industry. Lorem Ipsum has
          </p>
          <p>
            been.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </p>
        </div>
      </section>

      <section className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            {jobs.map((job) => (
              <div className="col-md-4">
                <SimilarJobCard
                  key={job.id}
                  jobCategory={job.jobCategory}
                  category={job.category}
                  para={job.para}
                  remoteText={job.remoteText}
                  fullTimeText={job.fullTimeText}
                  applyLink={job.applyLink}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
export default CareerInternal;
