import React from 'react'
import AllBlogs from '../../component/allblogs/AllBlogs'
import EventBanner from '../../component/events/EventBanner'

const AllCategoryBlog = () => {
    return (
        <>
            <EventBanner bannerTitle = "Blog" localBanner="blog-banner.jpg" isLocalBanner={true} />
            <section id="exploreBlogs" className="explore_container pt-50 pb-50">
                <div className="container">
                    <AllBlogs />
                </div>
            </section>
        </>
    )
}

export default AllCategoryBlog
