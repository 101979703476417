import "bootstrap/dist/css/bootstrap.css";
import "../CSTEM/CSTEM.css";
import { Container, Row, Col } from "react-bootstrap";

const CSTEM = () => {
  return (
    <div className="cstem-page">
      {/* Top banner section  */}
      <section className="cstem-top-banner">
        <Container>
          <Row>
            <Col lg={6} className="p-rel">
              <div className="cstem-banner-content">
                <div className="product-name-cstem">
                  <h1>C STEM&nbsp;</h1>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/CSTEM/icons/math-kit-icon.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="about-cstem">
                  <p>
                    CSTEM Math kit has been designed to empower young learners
                    to discover the mathematical concepts and their real world
                    applications.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}></Col>
          </Row>
        </Container>
      </section>

      {/* CSTEM Activates the whole brain  */}
      <section className="cstem-usp-banner pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <div className="usp-sec-heading">
                <h2>C STEM</h2>
                <img
                  src={`${process.env.PUBLIC_URL}/images/CSTEM/icons/math-kit-icon.png`}
                  alt=""
                  srcset=""
                />
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="usp-sec-heading-text">
                <p>Activates the whole brain</p>
              </div>
            </Col>
          </Row>

          {/* left right brain  */}
          <Row className="pt-10">
            <Col xs={6}>
              <div className="cstem-left">
                <p>left brain</p>
              </div>
            </Col>
            <Col xs={6}>
              <div className="cstem-right">
                <p>right brain</p>
              </div>
            </Col>
          </Row>
          <div className="usp-sec-banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/CSTEM/usp-banner.png`}
              alt=""
              srcset=""
            />
          </div>
        </Container>
      </section>

      {/* CTEM Maths Kit */}
      <section className="cstem-maths-kit pt-30 pb-50">
        <Container>
          <Row>
            <div className="about-cstem-kit">
              <p>
                CSTEM Math kit enables children to take ownership of their
                learning and add to their knowledge through hands on experience.
                The self paced experiential learning kit helps children to work
                on CSTEM concepts through constructing models enabling 21st
                Century learning.
              </p>
            </div>

            {/* table image */}
            <div className="cstem-table-image">
              <img
                src={`${process.env.PUBLIC_URL}/images/CSTEM/table.png`}
                alt=""
                srcset=""
              />
            </div>

            <div className="about-cstem-kit pt-30">
              <p>
                CSTEM Math kit has 104 colorful BRICKS which can be used to
                build 40+ math manipulatives & models using the lesson plans and
                additionally using their own imagination, kids can make an
                infinite number of models bringing their creations to life.
              </p>
            </div>

            {/* modal image */}
            <div className="bricks-modals-img">
              <img
                src={`${process.env.PUBLIC_URL}/images/CSTEM/brick-modals.png`}
                alt=""
              />
            </div>

            {/* CSTEM USP images  */}
            <div className="usp-images-wrapper p-rel">
              <Row>
                <Col lg={2} md={6}>
                  <div className="cstem-usp-img cui-border-a text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/CSTEM/icons/cstem-usp-icon-a.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>

                <Col lg={4} md={6}>
                  <div className="cstem-usp-img cui-border-b text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/CSTEM/icons/cstem-usp-icon-b.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>

                <Col lg={3} md={6}>
                  <div className="cstem-usp-img cs-img-c cui-border-c text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/CSTEM/icons/cstem-usp-icon-c.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>

                <Col lg={3} md={6}>
                  <div className="cstem-usp-img cs-img-c text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/CSTEM/icons/cstem-usp-icon-d.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </section>

      {/* Design Thinking  */}
      <section className="design-thinking pt-50 pb-50">
        <Container>
          <Row>
            <div class="col-lg-12">
              <div class="heading-npgs dark-blue-head pb-30 pbm">
                <h2>
                  Design <span>Thinking</span>
                </h2>
              </div>
            </div>
          </Row>

          {/* about design thinking */}
          <Row>
            <Col lg={12}>
              <div className="about-thiking-process">
                <p>
                  The Meritus design thinking process is a creative learning
                  spiral interspersed with storytelling. This unique combination
                  empowers children to explore magical, whimsical, imaginary
                  worlds, seamlessly blending with real life experiences. Design
                  thinking helps you first define a problem and then develop
                  solutions with the end user in mind. The main premise of
                  design thinking is to create and test ideas in order to learn
                  and improve on these ideas.
                </p>
              </div>
            </Col>
          </Row>

          {/* Learning spiral and learning steps  */}
          <div className="leraning-spiral-and-steps-wrapper">
            <Row className="pt-50">
              <Col lg={4}>
                {/* learning spiral image  */}
                <div className="cstem-learning-spiral">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/CSTEM/learning-spiral.png`}
                    alt=""
                    srcset=""
                  />
                </div>
              </Col>

              <Col lg={{ span: 7, offset: 1 }}>
                <div className="learning-steps">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/CSTEM/learning-steps.png`}
                    alt=""
                    srcset=""
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="about-thiking-process">
            <p>
              CSTEM Math kit helps children deepen their understanding of Math,
              by promoting the five strands of mathematical fluency.
            </p>
          </div>

          {/* CSTEM 5 Strands  */}
          <div className="five-strands">
            <ol>
              <li>
                Conceptual understanding refers to the integrated and functional
                grasp of mathematical ideas, which enables children to learn new
                ideas by connecting those ideas to what they already know. A few
                of the benefits of building conceptual understanding are that it
                supports retention, and prevents common errors.
              </li>
              <li>
                Procedural fluency is defined as the skill in carrying out
                procedures flexibly, accurately, efficiently, and appropriately.
              </li>
              <li>
                Strategic competence is the ability to formulate, represent, and
                solve mathematical problems.
              </li>
              <li>
                Adaptive reasoning is the capacity for logical thought,
                reflection, explanation, and justification.
              </li>
              <li>
                Productive disposition is the inclination to see mathematics as
                sensible, useful, and worthwhile, coupled with a belief in
                diligence and one’s own efficacy.
              </li>
            </ol>
          </div>

          <div className="about-thiking-process">
            <p>
              The benefits of building these fluencies are that it supports
              retention, and prevents common errors.
            </p>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default CSTEM;
