// import { useEffect } from 'react';
// import jwtDecode from 'jwt-decode'; // A library for decoding JWT tokens
// import { useDispatch } from 'react-redux';

// const CheckTokenExpiration = () => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     // Check if a token exists in local storage or cookies
//     const token = getCookie('userIn');

//     if (token) {
//       // Decode the token to get the expiration time
//       const decodedToken = jwtDecode(token);
//       const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

//       if (decodedToken.exp < currentTime) {
//         // Token has expired, show a popup or redirect to the login page
//         dispatch(logoutAction()); // You can implement a logout action to clear user data and tokens
//         alert('Your session has expired. Please log in again.'); // Show a popup message
//       }
//     }
//   }, [dispatch]);

//   return null;
// };

// export default CheckTokenExpiration;
