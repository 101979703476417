// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topics-card {
    background-color: var(--lblue);
    border-radius: 45px;
    border: medium none currentColor;
    border: initial;
    padding: 40px;
    width: 95%;
}

.topics-card-b,
.topics-card-c {
    background-color: var(--cBgPink);
}

.card-title h2 {
    color: var(--dBlue);
    text-transform: uppercase;
    line-height: 1.3;
    padding-bottom: 20px;
    font-size: var(--sHsize);
    font-weight: var();
}

.card-text p {
    color: var(--dBlue);
}

.pink-head-txt {
    color: var(--dPink);
}

.topic-number {
    font-size: 200px;
    font-weight: 500;
    color: var(--blue);
    position: absolute;
    top: 20%;
    left: -17%;
    transform: translate(-50%, -50%);
    opacity: 40%;
}

@media (max-width: 767px) {
    .topics-card {
        width: 100%;
        padding: 15px;
        margin-left: 2%;
    }

    .card-title h2 {
        font-size: 20px;
    }

    .topic-number {
        font-size: 110px;
        top: 15%;
        left: -15%;
    }

    .topics-card-b {
        margin-bottom: 50px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/common/cards/TopicsCard.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,gCAAa;IAAb,eAAa;IACb,aAAa;IACb,UAAU;AACd;;AAEA;;IAEI,gCAAgC;AACpC;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,oBAAoB;IACpB,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,gCAAgC;IAChC,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,gBAAgB;QAChB,QAAQ;QACR,UAAU;IACd;;IAEA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".topics-card {\n    background-color: var(--lblue);\n    border-radius: 45px;\n    border: unset;\n    padding: 40px;\n    width: 95%;\n}\n\n.topics-card-b,\n.topics-card-c {\n    background-color: var(--cBgPink);\n}\n\n.card-title h2 {\n    color: var(--dBlue);\n    text-transform: uppercase;\n    line-height: 1.3;\n    padding-bottom: 20px;\n    font-size: var(--sHsize);\n    font-weight: var();\n}\n\n.card-text p {\n    color: var(--dBlue);\n}\n\n.pink-head-txt {\n    color: var(--dPink);\n}\n\n.topic-number {\n    font-size: 200px;\n    font-weight: 500;\n    color: var(--blue);\n    position: absolute;\n    top: 20%;\n    left: -17%;\n    transform: translate(-50%, -50%);\n    opacity: 40%;\n}\n\n@media (max-width: 767px) {\n    .topics-card {\n        width: 100%;\n        padding: 15px;\n        margin-left: 2%;\n    }\n\n    .card-title h2 {\n        font-size: 20px;\n    }\n\n    .topic-number {\n        font-size: 110px;\n        top: 15%;\n        left: -15%;\n    }\n\n    .topics-card-b {\n        margin-bottom: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
