import React from "react";
import "./FlexiblePlan.css";
import { Link } from "react-router-dom";

const FlexiblePlan = ({ title, paragraphs, dynamicDiv, planNo }) => {
  return (
    <div className="flexible_plan">
      <div className="flexible_plan_title">
        <h2>
          {title} <div className="star_shape"></div>
        </h2>
      </div>

      {paragraphs.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
      <Link to={`/flexible-plan-${planNo}`}>
        <div className="flexible_plan_footer">{dynamicDiv}</div>
      </Link>
    </div>
  );
};

export default FlexiblePlan;
