import "bootstrap/dist/css/bootstrap.css";
import "./TabWrapper.css";
import { useState } from "react";

const TabWrapper = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className={`${props.TabWrapperClass} pt-50 pb-50`}>
      <ul className={`${props.TabDataClass} tab-list`}>
        {props.titles.map((title, index) => (
          <li
            key={index}
            className={index === activeTab ? "active" : ""}
            onClick={() => handleTabClick(index)}
          >
            {title}
          </li>
        ))}
      </ul>
      <div className="tab-content">{props.contents[activeTab]}</div>
    </div>
  );
};

export default TabWrapper;
