// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .thank-you-pg-wrapper {
  background-image: url("../../../public/images/thank-you-page/tpg-banner-img.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 40vw;
} */
.thank-you-page-banner img {
  height: auto;
  width: 100%;
}

.sub-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fdp-txt {
  background-color: #083a93;
  color: #ffffff;
  padding: 5px 20px;
  font-weight: 700;
  font-size: 20px;
  border-radius: 10px;
}

.more-txt-typ {
  padding: 0 20%;
}

.more-txt-typ p {
  color: #102e4a;
}

@media (max-width: 1024px) {
  .more-txt-typ {
    padding: 0 5%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/thank-you-pg/ThankYouPg.css"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* .thank-you-pg-wrapper {\n  background-image: url(\"../../../public/images/thank-you-page/tpg-banner-img.png\");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n  height: 40vw;\n} */\n.thank-you-page-banner img {\n  height: auto;\n  width: 100%;\n}\n\n.sub-content {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.fdp-txt {\n  background-color: #083a93;\n  color: #ffffff;\n  padding: 5px 20px;\n  font-weight: 700;\n  font-size: 20px;\n  border-radius: 10px;\n}\n\n.more-txt-typ {\n  padding: 0 20%;\n}\n\n.more-txt-typ p {\n  color: #102e4a;\n}\n\n@media (max-width: 1024px) {\n  .more-txt-typ {\n    padding: 0 5%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
