// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-card {
  background-color: var(--uPCBlue);
  border: none;
  border-radius: 30px;
  padding: 30px;
  margin-bottom: 50px;
  height: 285px;
}

.order-title h4 {
  color: var(--dBlue);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 20px;
}

.order-status p {
  color: var(--cSPink);
  margin-bottom: 0px;
}

.item-count p {
  margin-bottom: 0px;
}

.right-arrow-oc {
  /* padding: 15px 0px; */
  font-size: 40px;
  text-align: end;
}

.order-date p {
  font-size: 14px;
  color: var(--uPGrey);
}

.product-price h4 {
  padding-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/component/common/cards/OrderCard.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".order-card {\n  background-color: var(--uPCBlue);\n  border: none;\n  border-radius: 30px;\n  padding: 30px;\n  margin-bottom: 50px;\n  height: 285px;\n}\n\n.order-title h4 {\n  color: var(--dBlue);\n  font-weight: 500;\n  text-transform: capitalize;\n  font-size: 20px;\n}\n\n.order-status p {\n  color: var(--cSPink);\n  margin-bottom: 0px;\n}\n\n.item-count p {\n  margin-bottom: 0px;\n}\n\n.right-arrow-oc {\n  /* padding: 15px 0px; */\n  font-size: 40px;\n  text-align: end;\n}\n\n.order-date p {\n  font-size: 14px;\n  color: var(--uPGrey);\n}\n\n.product-price h4 {\n  padding-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
