import { FaLocationArrow, FaRegClock, FaAngleRight } from "react-icons/fa";
import CareerBanner from "../component/career/CareerBanner";
import Jobs from "../component/career/Jobs";
import "./Career.css";
const Career = () => {
  return (
    <div>
      <CareerBanner />
      {/* <div className="container pt-50">
        <form className="d-flex align-items-center mb-md-4">
          <input
            type="text"
            placeholder="Search for jobs or keywords"
            className="search_bar"
          />
          <input type="submit" className="border-0 btndefault" value="submit" />
        </form>
      </div>
 
      <div className="container">
        <div className="filter-btns">
          <div className="category-btn pb-50">
            <div className="dropdown d-inline mx-3">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="categoriesDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Categories
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="categoriesDropdown"
              >
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
              </div>
            </div>
            <div className="dropdown d-inline mx-3">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="progressDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Progress
              </button>
              <div className="dropdown-menu" aria-labelledby="progressDropdown">
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
              </div>
            </div>
            <div className="dropdown d-inline mx-3">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="instructorsDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Instructors
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="instructorsDropdown"
              >
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
                <a className="dropdown-item" href="#">
                  Menu Item
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Jobs
        jobTitle="Design 3"
        jobInfo={{
          title: "Title 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        }}
        jobTypes={[
          <span key="remote">
            <FaLocationArrow /> Remote 3
          </span>,
          <span key="part-time">
            <FaRegClock /> Part-time 3
          </span>,
        ]}
      />
      <Jobs
        jobTitle="Design 3"
        jobInfo={{
          title: "Title 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        }}
        jobTypes={[
          <span key="remote">
            <FaLocationArrow /> Remote 3
          </span>,
          <span key="part-time">
            <FaRegClock /> Part-time 3
          </span>,
        ]}
      />
      <Jobs
        jobTitle="Design 3"
        jobInfo={{
          title: "Title 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        }}
        jobTypes={[
          <span key="remote">
            <FaLocationArrow /> Remote 3
          </span>,
          <span key="part-time">
            <FaRegClock /> Part-time 3
          </span>,
        ]}
      />
  
      <div className="pagination container d-flex justify-content-center align-items-center">
        <span className="active">1</span>
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <FaAngleRight />
      </div>
      <span
        className="pSize text-center w-100 d-block"
        style={{ color: "#9D9D9D" }}
      >
        1 - 5 of 20 jobs{" "}
      </span>

      <div className="career_newslatter">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 position-relative">
              <h2 className="inh2">
                Be the first to know about<span>new jobs</span>
              </h2>
              <img
                src="images/career/subscription.png"
                className="subscription"
              />
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              <form className="d-flex align-items-center mb-md-4">
                <input type="text" className="search_bar" />
                <input
                  type="submit"
                  className="border-0 uniBtn uniBtnBlue btnPLight"
                  value="submit"
                />
              </form>
            </div>
          </div>
        </div>
      </div> */}
      <h4 className="text-center pb-100 pt-100">
        No Current Job Openings Please come back after a few days
      </h4>
    </div>
  );
};

export default Career;
