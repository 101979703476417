// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first_philosphy {
  position: relative;
}
.Philosphy {
  display: flex;
  align-items: center;
}
.elips {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: #c3c5f8;
}
.first_philosphy h2,
.sec_philosphy h2 {
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--dBlue);
  left: 50%;
  transform: translate(-50%, -50%);
}
.sec_philosphy {
  padding: 0px 60px;
}

@media (max-width: 1024px) {
  .Philosphy {
    padding-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .first_philosphy h2,
  .sec_philosphy h2 {
    font-size: 22px;
  }
  .sec_philosphy {
    padding: 0px 20px;
  }

  .elips {
    height: 65px;
    width: 65px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/about/Philosophy.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;AACA;;EAEE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EAER,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE;;IAEE,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".first_philosphy {\r\n  position: relative;\r\n}\r\n.Philosphy {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.elips {\r\n  width: 85px;\r\n  height: 85px;\r\n  border-radius: 50%;\r\n  background: #c3c5f8;\r\n}\r\n.first_philosphy h2,\r\n.sec_philosphy h2 {\r\n  font-size: 30px;\r\n  font-weight: 700;\r\n  position: absolute;\r\n  top: 50%;\r\n  -webkit-transform: translateY(-50%);\r\n  transform: translateY(-50%);\r\n  color: var(--dBlue);\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n}\r\n.sec_philosphy {\r\n  padding: 0px 60px;\r\n}\r\n\r\n@media (max-width: 1024px) {\r\n  .Philosphy {\r\n    padding-bottom: 10px;\r\n  }\r\n}\r\n\r\n@media (max-width: 576px) {\r\n  .first_philosphy h2,\r\n  .sec_philosphy h2 {\r\n    font-size: 22px;\r\n  }\r\n  .sec_philosphy {\r\n    padding: 0px 20px;\r\n  }\r\n\r\n  .elips {\r\n    height: 65px;\r\n    width: 65px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
