import "bootstrap/dist/css/bootstrap.css";
import "../../styles.css";
import "../cards/LAIProductCard.css";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { IoGameControllerOutline } from "react-icons/io5";
import { FaBookReader, FaUserTie } from "react-icons/fa";
import { SlBookOpen } from "react-icons/sl";
import CommonButton from "../Button/CommonButton";
import { Card } from "react-bootstrap";
import { MEDIA_URL, truncateText } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  addToCartFailure,
  addToCartStart,
  addToCartSuccess,
} from "../../../redux/cartRedux";
import { Link } from "react-router-dom";
import { userRequest } from "../../../requestMethod";

const LAIProductCard = (props) => {
  // Cheking user is logged or not=====
  const user = useSelector((state) => state.loginUser.currentUser);

  const { isFetching: isProductFetching, products } = useSelector((state) => state.product);
  const [isHeartExploded, setHeartExploded] = useState(false);
  // const [featuredProduct, setFeaturedProduct] = useState([]);

  // Calculate the sum of ratings
  const totalRating = props.ratingForCurrentProduct?.reduce(
    (acc, curr) => acc + curr.rating,
    0
  );
  // Calculate the average rating
  const averageRating = totalRating
    ? totalRating / props.ratingForCurrentProduct?.length
    : null;

  const dispatch = useDispatch();

  const addProductToCart = async (dispatch, cartItem) => {
    dispatch(addToCartStart());
    try {
      // const res = await userRequest.post('/cart/create', cartItem)
      // if (res.status === 200) {
      //   // Fetch the product data using productId
      //   const productId = res.data.productId;
      //   // const productRes = await userRequest.get(`/products/${productId}`);
      //   const productData = products.find((item) => item._id === productId);

      //   // Update the response data with the fetched product data
      //   const updatedData = { ...res.data };

      // dispatch(addToCartSuccess(updatedData));
      dispatch(addToCartSuccess(cartItem));
      // }
      // dispatch(addToCartSuccess(cartItem));
    } catch (error) {
      dispatch(addToCartFailure());
    }
  };

  const handleAddToCart = () => {
    // addProductToCart(dispatch, {  ...props._id, quantity: 1 });
    addProductToCart(dispatch, { ...props, quantity: 1 });
  };

  // Determine the button color based on the button name
  const buttonColor =
    props?.freeInIndia && props?.price === 0
      ? "var(--green)"
      : "var(--btnYellow)";

  return (
    <div className="lai-product-card">
      {/* Stem Product Card  */}
      <Card className="lai-custom">
        {/* Image Section of the card */}
        <Link to={`/product/${props.title}`}>
          <Card.Img variant="top" src={MEDIA_URL + props.productImg[0]} />
        </Link>
        {/* Title section of the card */}
        <Card.Body className="lai-content-wrapper">
          {/* top icons with text  */}
          <div className="lai-icon-wrapper">
            <div className="icon-wrapper-a">
              <div className="icon-txt-a lai-card-icon">
                <SlBookOpen />
              </div>
              <div className="lai-icon-txt">
                <p>{props.numberOfSessions} Sessions</p>
              </div>
            </div>
            <div className="icon-wrapper-b">
              <div className="icon-txt-a lai-card-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/learn-ai/student.png`}
                  alt=""
                />
              </div>
              <div className="lai-icon-txt">
                <p>{props?.totalEnrolledStudent} Students</p>
              </div>
            </div>
          </div>
          <Card.Title className="lai-title-wrapper" title={props?.title}>
            <div className="lai-title">
              <h3>{truncateText(props?.title, 80)}</h3>
            </div>
          </Card.Title>
          {/* Description section of Card  */}
          <Card.Text className="lai-text-rating-wrapper">
            <div className="product-desc m-0">
              {/* <p className="m-0">{truncateText(props?.shortDescription, 80)}</p> */}
              {props?.shortDescription && (
                <p className="m-0">
                  {truncateText(props?.shortDescription, 80)}
                </p>
              )}
            </div>
            {
              <div className="lai-rating-wrapper">
                {/* {averageRating && ( */}
                <>
                  {[...Array(5)].map((_, index) => {
                    // Calculate the difference between the index and the average rating
                    const diff = index + 0.5 - averageRating;
                    const className =
                      diff < 0
                        ? "rated-star"
                        : diff === 0
                          ? "rated-star"
                          : diff < 0.5
                            ? "half-star"
                            : "grey-star";

                    return (
                      <span key={index} className={className}>
                        &#9733;
                      </span>
                    );
                  })}
                  <span className="average-rating">
                    {averageRating?.toFixed(1)}
                  </span>
                </>
                {/* )} */}
              </div>
            }
          </Card.Text>
          {/* Price and Buy Button Section of the Card */}
          <div className="buy-price-wrapper">
            <div className="buy-btn-wrapper">
              {user ? (
                <Link to={"/cart"}>
                  <CommonButton
                    onClickAction={handleAddToCart}
                    btnClass="spc-buy-btn"
                    btnName={
                      props.freeInIndia && props.price === 0
                        ? "Learn Now"
                        : "Buy Now"
                    }
                    color={buttonColor}
                  />
                </Link>
              ) : (
                <Link to={"/login"}>
                  <CommonButton
                    btnClass="spc-buy-btn"
                    btnName={
                      props.freeInIndia && props.price === 0
                        ? "Learn Now"
                        : "Buy Now"
                    }
                    color={buttonColor}
                  />
                </Link>
              )}
              {/* <CommonButton btnClass="spc-buy-btn" btnName="Buy Now" /> */}
            </div>
            <div className="price-wrapper">
              {props.freeInIndia && props.price === 0 && (
                <p>
                  <strong>Free</strong>
                </p>
              )}

              {props.showPriceInRupees
                ? !props.freeInIndia &&
                !props.isForSubscription && (
                  <p>
                    <strong>&#8377;{props?.price}</strong>
                  </p>
                )
                : !props.freeOutOfIndia &&
                !props.isForSubscription && (
                  <p>
                    <strong>&#x24;{props?.price}</strong>
                  </p>
                )}
              {props.showPriceInRupees
                ? props.isForSubscription &&
                props.price !== 0 && (
                  <>
                    <p>Pay Only</p>
                    <p>
                      <strong>&#8377;{`${props.price}/Month`}</strong>
                    </p>
                  </>
                )
                : props.isForSubscription &&
                props.price !== 0 && (
                  <>
                    <p>Pay Only</p>
                    <p>
                      <strong>&#x24;{`${props.price}/Month`}</strong>
                    </p>
                  </>
                )}
              {/* {props.isForSubscription && props.price !== 0 && (
                <>
                  <p>Pay Only</p>
                  <p>
                    <strong>&#8377;{`${props.price}/Month`}</strong>
                  </p>
                </>
              )} */}
              {/* <p>
                <strong>&#8377;{props.price}</strong>
              </p> */}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LAIProductCard;
