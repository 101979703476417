// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trending-now {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 20px;
    background: #F4F9FE;
}
.trending-item {
    display: flex;
}
.serial-number {
    color: rgba(255, 86, 133, 0.32);
    font-size: 120px;
    font-weight: 600;
}
.item-text {
    font-size: var(--f20);
    color: var(--dBlue);
    font-weight: 700;
}
.trending-now {
    overflow: hidden;
    /* Add other necessary styles */
  }
  
  .ticker-container {
    display: flex;
    animation: ticker-animation 20s linear infinite;
  }
  
  .ticker-item {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: center;
}
  .ticker-item svg{
    color: #F8C5D7;
  }
  
  @keyframes ticker-animation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/component/common/trending/TrendingNow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,+BAA+B;EACjC;;EAEA;IACE,aAAa;IACb,+CAA+C;EACjD;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;EACE;IACE,cAAc;EAChB;;EAEA;IACE;MACE,2BAA2B;IAC7B;IACA;MACE,4BAA4B;IAC9B;EACF","sourcesContent":[".trending-now {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 50px 20px;\n    background: #F4F9FE;\n}\n.trending-item {\n    display: flex;\n}\n.serial-number {\n    color: rgba(255, 86, 133, 0.32);\n    font-size: 120px;\n    font-weight: 600;\n}\n.item-text {\n    font-size: var(--f20);\n    color: var(--dBlue);\n    font-weight: 700;\n}\n.trending-now {\n    overflow: hidden;\n    /* Add other necessary styles */\n  }\n  \n  .ticker-container {\n    display: flex;\n    animation: ticker-animation 20s linear infinite;\n  }\n  \n  .ticker-item {\n    display: flex;\n    flex-direction: row;\n    margin-right: 20px;\n    align-items: center;\n}\n  .ticker-item svg{\n    color: #F8C5D7;\n  }\n  \n  @keyframes ticker-animation {\n    0% {\n      transform: translateX(100%);\n    }\n    100% {\n      transform: translateX(-100%);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
