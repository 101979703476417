// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news_banner {
  background: #f9f9fe;
  /* padding: 100px; */
}
.news_container .paragraphs p {
  margin-bottom: 30px;
}
.news_container .news_banner .container {
  overflow: hidden;
  position: relative;
  padding: 50px 0px 10px 0px;
  z-index: 2;
}
.news_container .news_banner img {
  width: 100%;
  height: 116%;
  position: absolute;
  object-fit: contain;
  z-index: -1;
  top: 0px;
}

.blog-image-wrapper {
  width: 100%;
  height: 70vh;
  margin: 40px 0;
}

.blog-image-wrapper img {
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}


@media (max-width: 576px) {
  .news_container .news_banner .container {
    padding: 50px 20px;
  }
  .blog-image-wrapper {
    height: auto;
  }
  .blog-image-wrapper img {
    object-fit: cover;
    border-radius: 30px;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/BlogAndNewsFrame.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,0BAA0B;EAC1B,UAAU;AACZ;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;;;AAGA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,YAAY;EACd;EACA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;EACb;AACF","sourcesContent":[".news_banner {\n  background: #f9f9fe;\n  /* padding: 100px; */\n}\n.news_container .paragraphs p {\n  margin-bottom: 30px;\n}\n.news_container .news_banner .container {\n  overflow: hidden;\n  position: relative;\n  padding: 50px 0px 10px 0px;\n  z-index: 2;\n}\n.news_container .news_banner img {\n  width: 100%;\n  height: 116%;\n  position: absolute;\n  object-fit: contain;\n  z-index: -1;\n  top: 0px;\n}\n\n.blog-image-wrapper {\n  width: 100%;\n  height: 70vh;\n  margin: 40px 0;\n}\n\n.blog-image-wrapper img {\n  height: 100%;\n  object-fit: cover;\n  border-radius: 30px;\n}\n\n\n@media (max-width: 576px) {\n  .news_container .news_banner .container {\n    padding: 50px 20px;\n  }\n  .blog-image-wrapper {\n    height: auto;\n  }\n  .blog-image-wrapper img {\n    object-fit: cover;\n    border-radius: 30px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
