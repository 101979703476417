import 'bootstrap/dist/css/bootstrap.css';
import '../../styles.css';
import '../account-pgs-header/AcPageHeader.css';
import CommonButton from '../Button/CommonButton';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../redux/userRedux';
import { clearCart } from '../../../redux/cartRedux';
import { persistor } from '../../../redux/store';

const AcPageHeader = () => {

    const dispatch = useDispatch();

    const handleLogout = () => {
        localStorage.removeItem('persist:root');
        dispatch(logoutUser());
        dispatch(clearCart());
    }

    return (
        <div className='account-pg-header'>
            <Container fluid className='p-0'>
                <Row>
                    <Col lg={6} md={12} className='p-rel'>
                        <div className='txt-btn'>
                            <div className='heading ac-header-text'>
                                <h1>My <span>Profile</span></h1>
                            </div>
                            <div className='ac-header-btn'>
                                <CommonButton onClickAction={handleLogout}
                                    btnName='Sign Out'
                                    btnClass='signOutBtn'
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} className='p-0'>
                        <div className='user-img-ecllipse p-rel'>
                            <div className='ecllipse user-img text-center'>
                                <img src='images/user-profile-pgs/user-profile-picture.png' alt='user-pfp' />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AcPageHeader;