import "../SchoolBundleIndia.css";
import { Link } from "react-router-dom";
import { FaPersonBooth } from "react-icons/fa";
import BannerPrimaryBundle from "../../component/bundle/BannerPrimaryBundle";
import FlexiblePlan from "../../component/bundle/FlexiblePlan";
import CardUni from "../CardUni";
import SchoolForm from "../../component/TeacherPdp/SchoolForm";
import LogoSlider from "../../component/common/logoSlider/LogoSlider";
import TabWrapper from "../../component/common/tab/TabWrapper";
import HighSchoolBundleOption from "../../component/bundle/HighSchoolBundleOption";
import PrimarySchoolBundleOption from "../../component/bundle/PrimarySchoolBundleOption";
import VideoTestim from "../../component/common/video-testimonial/VideoTestim";
import SchoolBundleCarousel from "../../component/common/sb-carousel/SchoolBundleCarousel";
import ExploreMore from "../../component/ExploreMore";
import { DataObject } from "@mui/icons-material";
import FlexiblePlan1Page from "./Flexible/FlexiblePlan1Page";
import BundleForm from "../../component/bundle/BundleForm";

const titles = [
  "Primary School Bundle",
  "Middle School Bundle",
  "High School Bundle",
];
const flexible = ["Flexible Plan 1", "Flexible Plan 2", "Flexible Plan 3"];

const paragraphs = [
  [
    "Subscription based classes ",
    "Pay on a per-student, per-session basis for 12, 24, or 30 weeks.",
    "We will bring the kits and send our trainer to your school to teach your students",
  ],
  [
    "Get your desired combination of kits ",
    "And we will train your school teachers",
  ],
  [
    "Purchase the kit combination that suits your needs.",
    "And we’ll send our trainer to your school to teach your students",
  ],
];

const dynamicURLs = [
  "/flexible-plan-1",
  "/flexible-plan-2",
  "/flexible-plan-3",
];

const dynamicDivs = [
  "Learn more about how our curated solutions have benefitted schools",
  "Find out how our creative solutions have made a difference in schools.",
  "Read about school success with our innovative solutions.",
];

const cardData1 = [
  {
    imageUrl: "images/SchoolBundle/India/icons/handshake.png",
    spantxt: " ",
    title: "Industry partnerships",
    paragraph:
      "Are your students seeking internships, expert webinars, or industry visits? We are excited to offer these incredible College and Career Readiness Opportunities for the benefit of your students.",
  },
  {
    imageUrl: "images/SchoolBundle/India/icons/graduation-cap.png",
    spantxt: "",
    title: " Scholarship ",
    paragraph:
      "We are pleased to announce that we will be awarding a Merit Scholarship to one student every quarter. Encourage your students to apply at no cost.",
  },
  {
    imageUrl: "images/SchoolBundle/India/icons/trophy.png",
    spantxt: "",
    title: "Competitions",
    paragraph:
      "Would you like your students to hone their skills? Connect with us and we can get your students to participate in the Indian Robotix League, Destination Zero Carbon, World Robotics Olympiad and many others.",
  },
];
const logos = [
  "images/SchoolBundle/logos/01-AISC.jpg",
  "images/SchoolBundle/logos/02-BVM-Global.jpg",
  "images/SchoolBundle/logos/03-TVS.jpg",
  "images/SchoolBundle/logos/04-Pickering-college.jpg",
  "images/SchoolBundle/logos/05-GEMS.jpg",
  "images/SchoolBundle/logos/06-Global-Indian.jpg",
  "images/SchoolBundle/logos/07-STEM-School.jpg",
  "images/SchoolBundle/logos/08-Kansas-State.jpg",
  "images/SchoolBundle/logos/09-The-Ashok-leyland.jpg",
  "images/SchoolBundle/logos/10-BVMI.jpg",
  "images/SchoolBundle/logos/11-CK-School.jpg",
  "images/SchoolBundle/logos/12-TVS.jpg",
  "images/SchoolBundle/logos/13-Wisdom.jpg",
  "images/SchoolBundle/logos/14-St-martin.jpg",
  "images/SchoolBundle/logos/15-CEDAR.jpg",
  "images/SchoolBundle/logos/16-Duapa.jpg",
  "images/SchoolBundle/logos/17-Little _lovely.jpg",
  "images/SchoolBundle/logos/18-Oakom-robotics.jpg",
  "images/SchoolBundle/logos/19-Unique-star.jpg",
  "images/SchoolBundle/logos/20-the-pearl.jpg",
  "images/SchoolBundle/logos/21-alphabet.jpg",
  "images/SchoolBundle/logos/22-elemenopy.jpg",
  "images/SchoolBundle/logos/23-Sboa.jpg",
  "images/SchoolBundle/logos/24-HUS.jpg",
  "images/SchoolBundle/logos/25-Indus.jpg",
  "images/SchoolBundle/logos/26-Maharishi.jpg",
  "images/SchoolBundle/logos/27-Kola perumal.jpg",
  "images/SchoolBundle/logos/28-DAV.jpg",
  "images/SchoolBundle/logos/29-Jagannath.jpg",
  "images/SchoolBundle/logos/30-Hindustan.jpg",
  "images/SchoolBundle/logos/31-Sri-RM-Jain.jpg",
  "images/SchoolBundle/logos/32-Pragyanam.jpg",
  "images/SchoolBundle/logos/33-Banyan.jpg",
  "images/SchoolBundle/logos/34-Schools.jpg",
  "images/SchoolBundle/logos/35-Shree niketan.jpg",
];
const responsiveConfig = {
  0: {
    items: 2, // Show 1 item on screens between 0px and 767px (inclusive)
  },
  768: {
    items: 2, // Show 3 items on screens between 768px and 991px (inclusive)
  },
  992: {
    items: 4, // Show 4 items on screens between 992px and 1199px (inclusive)
  },
  1200: {
    items: 4, // Show 5 items on screens larger than 1200px
  },
};
const categories = [
  {
    categoryId: 1,
    imgUrl: "images/SchoolBundle/India/learn-1.png",
    title: "Tips for Students",
  },
  {
    categoryId: 2,
    imgUrl: "images/SchoolBundle/India/learn-2.png",
    title: "Future of Learning",
  },
];
let contents = [<HighSchoolBundleOption />];
const PrimarySchoolBundle = () => {
  return (
    <div>
      <BannerPrimaryBundle />

      <section className="pt-100">
        <ul className="school_bundle_tabs tab-list container">
          <Link to={"/PrimarySchoolBundle"}>
            <li className="">Primary School Bundle</li>
          </Link>
          <Link to={"/MiddleSchoolBundle"}>
            <li className="">Middle School Bundle</li>
          </Link>
          <Link to={"/HighSchoolBundle"}>
            <li className="">High School Bundle</li>
          </Link>
        </ul>
        <PrimarySchoolBundleOption />
      </section>

      <section>
        <div className="container">
          <h2 className="bundle_heading text-center pt-100 mb-50">
            Flexible<span> Plans</span>
          </h2>
          <div className="row">
            {flexible.map((title, index) => (
              <div key={index} className="col-md-4">
                <FlexiblePlan
                  title={title}
                  paragraphs={paragraphs[index]}
                  dynamicDiv={dynamicDivs[index]}
                  planNo={index + 1}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bundle_partner_logo">
        <h2 className="bundle_heading text-center pt-100">
          Our School <span className="dPink">Partners</span>
        </h2>
        <LogoSlider logos={logos} responsiveConfig={responsiveConfig} />
      </section>

      <section className="pb-100">
        <div className="container">
          <h2 className="bundle_heading text-center pt-100 mb-50">
            Our Value Added <span>Offerings</span>
          </h2>
          <div className="value_offering">
            <div className="row">
              {cardData1.map((card, index) => (
                <div className="col-md-4" key={index}>
                  <CardUni {...card} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="pt-50" style={{ background: "#f4f4f4" }}>
        <div className="container">
          <VideoTestim sectionHeading={"Success "} headingSpan={"Stories"} />
        </div>
      </section>
      <section className="pt-100 ">
        <SchoolBundleCarousel />
      </section>
      <section className="pt-100">
        <div className="container">
          <div className="heading-npgs dark-blue-head vt-heading">
            <h2 className="text-center mb-50">
              Learning <span className="dPink">Resources</span>
            </h2>
          </div>
          <div className="row">
            {categories.map((categoryData) => (
              <div key={categoryData.categoryId} className="col-md-6">
                <ExploreMore {...categoryData} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <BundleForm pageName = 'primary school bundle page' />
      </section>
    </div>
  );
};

export default PrimarySchoolBundle;
