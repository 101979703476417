import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { H5P } from 'h5p-standalone';
// import JSZip from 'jszip/dist/jszip.min.js'; // Import JSZip from the correct path
// import RenderHTMLContent from '../../RenderHTMLContent';
import Loader from '../common/Loader/Loader';
// import fs from 'fs-extra';
import './H5PViewer.css';
import { MEDIA_URL } from '../../config';
import { userRequest } from '../../requestMethod';


const H5PViewer = (props) => {
    const [contentJson, setContentJson] = useState(null);

    const [folderContent, setFolderContent] = useState(null);

    const fileURL = "https://meritus-media.blr1.digitaloceanspaces.com/deleting-files/h-file_1702640294812.zip";




    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             // Download the H5P file
    //             const response = await axios.get(fileURL, { responseType: 'arraybuffer' });
    //             const zipContent = response.data;

    //             // Use JSZip to load the entire zip content
    //             const zip = new JSZip();
    //             const unzipped = await zip.loadAsync(zipContent);

    //             // Set the contentJson in state
    //             setContentJson(unzipped);


    //             // Use the H5P library to render the content
    //             const el = document.getElementById('h5p-container');
    //             const options = {
    //                 h5pJsonPath: `${process.env.PUBLIC_URL}/test-file/hfile`,
    //                 frameJs: `${process.env.PUBLIC_URL}/assets/frame.bundle.js`,
    //                 frameCss: `${process.env.PUBLIC_URL}/assets/styles/h5p.css`,
    //             };


    //             // Pass the entire 'h-file' folder content to H5P library
    //             new H5P(el, options);
    //         } catch (error) {
    //             console.error('Error fetching or rendering H5P content:', error);
    //         }
    //     };

    //     fetchData();
    // }, [fileURL]);

    // const downloadFile = async (fileName, content) => {
    //     const blob = new Blob([content]);
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = fileName;
    //     link.click();
    // };

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             // Download the H5P file
    //             const response = await axios.get(fileURL, { responseType: 'arraybuffer' });
    //             const zipContent = response.data;

    //             // Use JSZip to load the entire zip content
    //             const zip = new JSZip();
    //             const unzipped = await zip.loadAsync(zipContent);

    //             const folders = {};

    //             // Iterate through each file in the zip
    //             unzipped.forEach((relativePath, file) => {
    //                 const folderName = relativePath.split('/')[0]; // Assuming folder structure
    //                 if (!folders[folderName]) {
    //                     folders[folderName] = [];
    //                 }

    //                 // Save the file content
    //                 file.async('text').then((content) => {
    //                     folders[folderName].push({ fileName: relativePath, content });
    //                 });
    //             });

    //             // Update state with the folder content
    //             setFolderContent(folders);

    //             // // Set up the container element
    //             // const el = document.getElementById('h5p-container');

    //             // // Set up the H5P options
    //             // const options = {
    //             //     frameJs: `${process.env.PUBLIC_URL}/assets/frame.bundle.js`,
    //             //     frameCss: `${process.env.PUBLIC_URL}/assets/styles/h5p.css`,
    //             // };

    //             // // Convert JSZip object to a simple object with file contents
    //             // const files = {};
    //             // for (const [relativePath, file] of Object.entries(unzipped.files)) {
    //             //     const content = await file.async('arraybuffer');
    //             //     files[relativePath] = content;
    //             // }

    //             // // Pass the entire folder structure to H5P library
    //             // new H5P(el, options, files);
    //         } catch (error) {
    //             console.error('Error fetching or rendering H5P content:', error);
    //         }
    //     };
    //     fetchData();
    // }, [fileURL]);

    // useEffect(() => {
    //     const fetchAndExtractH5P = async () => {
    //         const fileURL = "https://meritus-media.blr1.digitaloceanspaces.com/h5p-files/hfile.zip";

    //         try {
    //             // Download the H5P file
    //             const response = await axios.get(fileURL, { responseType: 'arraybuffer' });
    //             const zipContent = response.data;

    //             // Use JSZip to load the entire zip content
    //             const zip = new JSZip();
    //             const unzipped = await zip.loadAsync(zipContent);

    //             // Create an array to store promises
    //             const promises = [];

    //             // Iterate through each file in the zip
    //             for (const [relativePath, file] of Object.entries(files)) {
    //                 // Check if the object contains valid file data
    //                 if (file && file.name) {
    //                     // Normalize the path by removing leading directories
    //                     const normalizedPath = relativePath.replace(/^.*[\\\/]/, '');

    //                     // Save the file content promise
    //                     const promise = file.async('arraybuffer').then((fileContent) => {
    //                         // Check if normalizedPath is defined before saving to content
    //                         if (normalizedPath) {
    //                             return { fileName: normalizedPath, content: new Uint8Array(fileContent) };
    //                         }
    //                     });

    //                     // Push the promise to the array
    //                     promises.push(promise);
    //                 }
    //             }

    //             // Wait for all promises to resolve
    //             const validFiles = await Promise.all(promises);

    //             // Create an object to store file content
    //             const content = {};

    //             // Save the resolved file content
    //             validFiles.forEach(({ fileName, content: fileContent }) => {
    //                 content[fileName] = fileContent;
    //             });


    //             // Save the unzipped content in the browser
    //             const blob = new Blob([JSON.stringify(content)], { type: 'application/json' });
    //             const url = URL.createObjectURL(blob);

    //             // // Set up the container element
    //             // const el = document.getElementById('h5p-container');

    //             // // Set up the H5P options
    //             // const options = {
    //             //     frameJs: `${process.env.PUBLIC_URL}/assets/frame.bundle.js`,
    //             //     frameCss: `${process.env.PUBLIC_URL}/assets/styles/h5p.css`,
    //             //     // h5pJsonPath: url, // Use the URL of the saved content
    //             //                     h5pJsonPath: `${process.env.PUBLIC_URL}/test-file/h-file`,
    //             // };

    //             // // Pass the entire folder structure to H5P library
    //             // new H5P(el, options);
    //         } catch (error) {
    //             console.error('Error fetching or rendering H5P content:', error);
    //         }
    //     };

    //     // Trigger the download and extraction when the component mounts
    //     fetchAndExtractH5P();
    //     // Set up the container element
    //     const el = document.getElementById('h5p-container');

    //     // Set up the H5P options
    //     const options = {
    //         frameJs: `${process.env.PUBLIC_URL}/assets/frame.bundle.js`,
    //         frameCss: `${process.env.PUBLIC_URL}/assets/styles/h5p.css`,
    //         // h5pJsonPath: url, // Use the URL of the saved content
    //         // h5pJsonPath: `${process.env.PUBLIC_URL}/test-file/hfile`,
    //     };

    //     // Pass the entire folder structure to H5P library
    //     new H5P(el, options);
    // }, []);
    // const [fileUrl, setFileUrl] = useState(null);

    // useEffect(() => {
    //     const fetchAndUnzip = async () => {
    // const fileURL = "https://meritus-media.blr1.digitaloceanspaces.com/h5p-files/Chapter%203%20-%20Identify%20the%20Sign%20language%20using%20S4AIWS_final_1701163469203.h5p";
    // const fileURL = "https://meritus-media.blr1.digitaloceanspaces.com/deleting-files/h-file_1702640294812.zip";
    // try {

    //     const res = await fetch('https://meritus-media.blr1.digitaloceanspaces.com/099e0589-bb26-443d-b196-26e138c48891/hfile/h5p.json');

    //     setFileUrl(res.url)
    // const response = await axios.get(fileURL, { responseType: 'arraybuffer' });
    // const zipContent = response.data;


    // // Unzip the contents
    // const zip = new JSZip();
    // const unzipped = await zip.loadAsync(zipContent);

    // const blob = await unzipped.blob()


    // const blobURL = URL.createObjectURL(blob)
    // // Convert unzipped content to JSON (adjust based on H5P expectations)
    // // const serializedContent = JSON.stringify(unzipped);

    // // Create a data URL for the entire unzipped content
    // // const serializedDataURL = `data:application/json;charset=utf-8,${encodeURIComponent(serializedContent)}`;

    // // Check if content.json file is present inside the "h-file/content" directory
    // // if (unzipped.files && 'h-file/content/content.json' in unzipped.files) {
    // //     // Get the content.json file
    // //     const contentJsonFile = unzipped.files['h-file/content/content.json'];

    // //     // Extract and parse the content of content.json
    // //     const contentJsonContent = await contentJsonFile.async('text');

    // //     // Create a data URL
    // //     const serializedDataURL = `data:application/json;charset=utf-8,${encodeURIComponent(contentJsonContent)}`;

    // //     // Use the H5P library to render the content
    // //     const el = document.getElementById('h5p-container');
    // //     const options = {
    // //         h5pJsonPath: fileURL,
    // //         frameJs: `${process.env.PUBLIC_URL}/assets/frame.bundle.js`,
    // //         frameCss: `${process.env.PUBLIC_URL}/assets/styles/h5p.css`,
    // //         fullScreen: true
    // //     };

    // //     new H5P(el, options);
    // // } else {
    // //     console.error('No content.json file found in the "h-file/content" directory of the zip file.');
    // // }

    // Use the H5P library to render the content
    // if (fileUrl) {

    //                 const el = document.getElementById('h5p-container');
    //                 const options = {
    //                     //  h5pJsonPath: serializedDataURL,
    //                     h5pJsonPath: "https://meritus-media.blr1.digitaloceanspaces.com/099e0589-bb26-443d-b196-26e138c48891/hfile/h5p.json",
    //                     frameJs: `${process.env.PUBLIC_URL}/assets/frame.bundle.js`,
    //                     frameCss: `${process.env.PUBLIC_URL}/assets/styles/h5p.css`,
    //                     fullScreen: true,
    //                     embed : true
    //                 };

    //                 new H5P(el, options);
    //             // }


    //         } catch (error) {
    //             console.error('Error fetching or unzipping the file:', error);
    //         }
    //     };

    //     fetchAndUnzip();
    // }, []);



    // return <div id="h5p-container" />;
    // const parts = props.docData('/');

    // // Take the last part (file name) and log it
    // const fileName = parts[parts.length - 1];

    const [activeLoader, setActiveLoader] = useState(true)

    const [htmlContent, setHtmlContent] = useState(null);


    // const convertPPTtoHTML = async (fileUrl) => {
    //     try {
    //         const response = await userRequest.post('/upload/convert', { fileUrl });
    //         setHtmlContent(response.data.html);
    //     } catch (error) {
    //         console.error('Error converting PPT to HTML:', error);
    //     }
    // };

    // useEffect(() => {
    //     convertPPTtoHTML(MEDIA_URL + props.docData.url)
    // },[])



    useEffect(() => {
        const fetchHtmlContent = async () => {
            setActiveLoader(true)
            try {
                const response = await fetch(`${MEDIA_URL + props.docData.url}`);
                const data = await response.text();
                if (data) {
                    setHtmlContent(data);
                    setActiveLoader(false);
                    await props.updateResourceProgressFunction(props.docData.chapterIndex, props.docData.resourceIndex, 100)
                }
            } catch (error) {
                setActiveLoader(false)
                console.error('Error fetching HTML content:', error);
            }
        };
        fetchHtmlContent();
    }, []);

    const pdfUrl = MEDIA_URL + props?.docData?.url


    if (activeLoader && htmlContent === null) {
        return (
            <Loader />
        )
    } 

    return (
        <div>
            {
                htmlContent && (
                    <>
                        {
                            props.docData.fileType === 'html' ? (
                                <iframe
                                    title="H5P Content"
                                    // src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
                                    srcDoc={htmlContent}
                                    width="100%"  // Set your desired width (e.g., 800px)
                                    height="100%" // Set your desired height (e.g., 600px)
                                    id='h5p-viewer'
                                 style={{height : "400px !important"}}/>
                            ) : (
                                <embed
                                    frameborder="0"
                                    title="H5P Content"
                                    src={`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true#toolbar=0`}
                                    // src={htmlContent}
                                    width="800px"  // Set your desired width (e.g., 800px)
                                    height="600px" // Set your desired height (e.g., 600px)
                                    id='h5p-viewer'
                                    type="application/pdf"
                                />
                            )
                        }
                        {/* <h1>Hello</h1> */}
                    </>
                )
            }
        </div>
    );
    // return (
    //     // <RenderHTMLContent htmlContent={`${process.env.PUBLIC_URL}/test-file/test.html`} />
    // )
};

export default H5PViewer;




// import React, { useEffect } from 'react';
// import axios from 'axios';
// import { H5P } from 'h5p-standalone';
// import AdmZip from 'adm-zip';

// const H5PViewer = () => {
//     useEffect(() => {
//         const fetchH5PContent = async () => {
//             try {
//                 const response = await axios.get(`${process.env.PUBLIC_URL}/test-file/h-file.zip`, { responseType: 'arraybuffer' });
//                 const el = document.getElementById('h5p-container');

//                 // Use adm-zip to extract the content.json from the zip file
//                 const zip = new AdmZip(response.data);
//                 const zipEntries = zip.getEntries();
//                 let contentJson;

//                 zipEntries.forEach((entry) => {
//                     if (entry.entryName === 'content.json') {
//                         contentJson = JSON.parse(entry.getData().toString('utf8'));
//                     }
//                 });

//                 if (!contentJson) {
//                     throw new Error('No content.json found in the zip file.');
//                 }

//                 // Create the H5P viewer with the extracted content JSON
//                 const options = {
//                     frameJs: `${process.env.PUBLIC_URL}/assets/frame.bundle.js`,
//                     frameCss: `${process.env.PUBLIC_URL}/assets/styles/h5p.css`,
//                 };

//                 new H5P(el, options, contentJson);
//             } catch (error) {
//                 console.error(error);
//             }
//         };

//         fetchH5PContent();
//     }, []);

//     return (
//         <div id="h5p-container" className="h5p-container">
//             {/* Additional content or components can be placed here */}
//         </div>
//     );
// };

// export default H5PViewer;







// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { H5P } from 'h5p-standalone'; // ES6
// import { publicRequest } from '../../requestMethod';


// const H5PViewer = () => {

//     const [contentJson, setContentJson] = useState(null);


//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 // Make a POST request to the server endpoint for unzipping
//                 const response = await publicRequest.post(`/h5p/unzip`);


//                 // Assuming the server sends the unzipped H5P content in the response
//                 const h5pContent = response.data.contentJson;

//                 // Set the contentJson in state
//                 setContentJson(h5pContent);

//                 // Use the H5P library to render the content
//                 const el = document.getElementById('h5p-container');
//                 const options = {
//                     // h5pJsonPath: `${process.env.PUBLIC_URL}/test-file/h-file`, // Adjust the path if needed
//                     // h5pJsonPath: h5pContent ? h5pContent : '', // Adjust the path if needed
//                     frameJs: `${process.env.PUBLIC_URL}/assets/frame.bundle.js`,
//                     frameCss: `${process.env.PUBLIC_URL}/assets/styles/h5p.css`,
//                 };

//                 new H5P(el, options, h5pContent);
//             } catch (error) {
//                 console.error('Error fetching or rendering H5P content:', error);
//             }
//         };

//         fetchData();
//     }, []);


//     return (
//         <div id="h5p-container" className="h5p-container">
//             {/* Additional content or components can be placed here */}
//         </div>
//     );
// };


// export default H5PViewer;



// const fileKey = "ARTIFICIAL INTELLIGENCE_INTRO (6)_1694688306475.h5p";
// const encodedFileKey = encodeURIComponent(fileKey);

// const [h5pContent, setH5PContent] = useState(null);


// useEffect(() => {
//     const fetchH5pContent = async () => {
//         try {
//             const response = await axios.get(
//                 `http://localhost:3003/api/h5p/file/${encodedFileKey}`,
//                 { responseType: 'blob' } // Set responseType to 'blob'
//             );

//             const blob = response.data;
//             const dataUrl = URL.createObjectURL(blob);
//             setH5pContent(dataUrl);
//         } catch (error) {
//             console.error('Error fetching H5P content:', error);
//         }
//     };

//     fetchH5pContent();
// }, [fileKey]);

// const h5pFileUrl = 'https://meritus-media.blr1.digitaloceanspaces.com/course-files/ARTIFICIAL INTELLIGENCE_INTRO (6)_1694688306475.h5p'; // replace with your actual URL

// useEffect(() => {
//     const fetchH5PContent = async () => {
//         try {
//             const response = await fetch(encodeURIComponent(h5pFileUrl));
//             // const content = await response.json(); // adjust this based on the actual format of your H5P file
//             // setH5PContent(content);
//             setH5PContent(response.url);
//         } catch (error) {
//             console.error('Error fetching H5P content:', error);
//         }
//     };

//     fetchH5PContent();
// }, [h5pFileUrl]);

// if (!h5pContent) {
//     return <div>Loading...</div>;
// }






// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const H5PViewer = () => {
//   const fileKey = "ARTIFICIAL INTELLIGENCE_INTRO (6)_1694688306475.h5p";
//   const encodedFileKey = encodeURIComponent(fileKey);

//   const [h5pContent, setH5pContent] = useState(null);

//   useEffect(() => {
//     const fetchH5pContent = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:3003/api/h5p/file/${encodedFileKey}`,
//           { responseType: 'blob' } // Set responseType to 'blob'
//         );

//         const blob = response.data;
//         const dataUrl = URL.createObjectURL(blob);
//         setH5pContent(dataUrl);
//       } catch (error) {
//         console.error('Error fetching H5P content:', error);
//       }
//     };

//     fetchH5pContent();
//   }, [fileKey]);

//   return (
//     <div className="h5p-container">
//       {h5pContent && (mo
//         <iframe
//           title="H5P Content"
//           src={h5pContent}
//           width="100%"
//           height="600px"
//           frameBorder="0"
//         ></iframe>
//       )}
//     </div>
//   );
// };

// export default H5PViewer;



