import 'bootstrap/dist/css/bootstrap.css';
import '../../styles.css';
import './OrderCard.css';
import { Card } from 'react-bootstrap';
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import { encryptUniqueId, status } from '../../../config';
import { truncateText } from "../../../config";

const OrderCard = (props) => {
    const changeDateFormat = (date) => {
        const fetchedDate = new Date(date);
        const formattedDate = fetchedDate.toLocaleDateString();
        return formattedDate;
    }


    return (
        <Link to={`/OrderDetails/orderId?orderId=${encryptUniqueId(props?.order._id)}`}>
            <Card className='order-card'>
                <Card.Title title={props?.title}>
                    <div className='order-title'>
                        <h4>{`${props?.order?.products?.length > 1 ? truncateText(props?.order?.products[0]?.productId?.title, 20) + " + " + (props?.order?.products?.length - 1) + " more" : props?.order?.products[0]?.productId && truncateText(props.order?.products[0]?.productId?.title, 22)} `}</h4>
                    </div>
                </Card.Title>
                <Card.Body className='p-0'>
                    <div className='order-card-content'>
                        <div className='sub-head order-status'>
                            {
                                props?.order?.paymentStatus === status.success ? (
                                    <p className='text-capitalize'>Paid</p>
                                ) : props?.order?.paymentStatus === status.failed ? (
                                    <p className='text-capitalize'>failed</p>
                                ) : props?.order?.paymentStatus === status.pending ? (
                                    <p className='text-capitalize'>pending</p>
                                ) : (
                                    <p className='text-capitalize'>{props?.order?.paymentStatus}</p>
                                )
                            }
                            {/* {
                                props.order.paymentStatus === status.failed && (
                                    <p className='text-capitalize'>failed</p>
                                )
                            }
                            {
                                props.order.paymentStatus === status.pending && (
                                    <p className='text-capitalize'>pending</p>
                                )
                            } */}

                        </div>
                        <div className='item-count'>
                            <p>{props.order.products?.length} Items</p>
                        </div>
                        <div className='right-arrow-oc'>
                            <MdKeyboardArrowRight />
                        </div>
                        <div className='product-price'>
                            {
                                props.order?.currency === 'INR' && (
                                    <h4>&#8377;{props.order.amount}</h4>
                                )
                            }
                            {
                                props.order?.currency === 'USD' && (
                                    <h4>&#x24;{props.order.amount}</h4>
                                )
                            }
                            
                        </div>
                        <div className='order-date'>
                            <p>{changeDateFormat(props.order.createdAt)}</p>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Link>
    )
}

export default OrderCard;