// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-member {
  border-radius: 15px;
  background: rgba(195, 197, 248, 0.3);
  overflow: hidden;
  padding-bottom: 20px;
  margin-bottom: 50px;
}
.team-member img {
  border-radius: 25px;
}
.team-member-title {
  margin-top: 25px;
}
.team-member-title,
.team-member-paragraph,
.team-member-linkedin {
  padding: 0px 20px;
}
.team-member-linkedin {
  float: right;
}

@media only screen and (min-width: 1080px) and (max-width: 1200px) {
  .team-member {
    height: 440px;
  }
  .team-member img {
    height: auto;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/about/Team.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;;;EAGE,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".team-member {\n  border-radius: 15px;\n  background: rgba(195, 197, 248, 0.3);\n  overflow: hidden;\n  padding-bottom: 20px;\n  margin-bottom: 50px;\n}\n.team-member img {\n  border-radius: 25px;\n}\n.team-member-title {\n  margin-top: 25px;\n}\n.team-member-title,\n.team-member-paragraph,\n.team-member-linkedin {\n  padding: 0px 20px;\n}\n.team-member-linkedin {\n  float: right;\n}\n\n@media only screen and (min-width: 1080px) and (max-width: 1200px) {\n  .team-member {\n    height: 440px;\n  }\n  .team-member img {\n    height: auto;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
