import "bootstrap/dist/css/bootstrap.css";
import "./TaCoRobobricks.css";
import VideoTestim from "../../../component/common/video-testimonial/VideoTestim";
import { Col, Container, Row } from "react-bootstrap";

const TaCoRobobricks = () => {
  return (
    <div className="taco-robobricks-wrapper">
      {/* Top banner section  */}
      <section id="taCoRoboTopBanner" className="taCoRoboTopBanner"></section>

      {/* Empowering Fluid Play  */}
      <section
        id="empoweringFluidPlay"
        className="empoweringFluidPlay pt-50 pb-50"
      >
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={12}>
                  <div className="heading-npgs dark-blue-head">
                    <h2>
                      Empowering <span>Fluid Play</span>
                    </h2>
                  </div>
                  <div className="sub-heading-npgs pb-20">
                    <p>Making your Child Future Ready</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {/* About Roboobricks Pointers  */}
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {/* Pointers  */}
                <Col lg={6}>
                  <div className="about-robo-pointers">
                    <ul>
                      {/* point - 1 */}
                      <li>
                        <strong>Robobricks</strong> is an amazing new building
                        and screen-free coding robot for children ages 4 to 9.
                      </li>

                      {/* point -2 */}
                      <li>
                        The smart Robobricks blocks namely Brain block, Sensors,
                        Motors, Sound & Light blocks are compatible with Duplo®️
                        | Mega Construx&#174; | Morphun&#174; | Lego&#174;
                        bricks for hands-on tactile play.
                      </li>

                      {/* point -3 */}
                      <li>
                        Research from Tufts & MIT, shows that Tangible Coding
                        for kids ages 3 to 9 is best developmentally and most
                        age appropriate.
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="about-robo-pointers">
                    <ul>
                      {/* point -4 */}
                      <li>
                        Children bring their robot to Life using our easy to use
                        Tangible coding platform.
                      </li>

                      {/* Point - 5 */}
                      <li>
                        "Fluid Play" enabled by Robobricks : This unique
                        combination empowers children to explore magical,
                        whimsical, imaginary worlds, seamlessly blending with
                        real life and digital experiences.
                      </li>

                      {/* point - 6  */}
                      <li>
                        Kids can make an infinite number of interactive models
                        using smart blocks from Robobricks, along with the
                        familiar Duplo&#174; | Mega Construx&#174; |
                        Morphun&#174; | Lego&#174; bricks.
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* youtube video and authentication img  */}
      <section id="video-auth" className="video-auth p-rel">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={6} md={6}>
                  <div className="tcr-yt-video">
                    <iframe
                      width="100%"
                      height="280"
                      src="https://www.youtube.com/embed/l109JmqH3nA?si=nBpy8uvCjHDaPYea"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </Col>

                {/* Img col */}
                <Col lg={6} md={6}>
                  <div className="auth-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/taco-robricks/auth-stem.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Robobricks Kit */}
      <section className="robobricks pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={12}>
                  <div className="heading-npgs dark-blue-head">
                    <h2>
                      Robobricks <span>Kit</span>
                    </h2>
                  </div>
                  <div className="sub-heading-npgs pb-20">
                    <p>Build | Code | Bring bricks to life</p>
                  </div>
                </Col>
              </Row>

              {/* Content  */}
              <Row>
                <Col lg={12}>
                  <div className="robobricks-kit-content">
                    <ul className="text-justify">
                      <li>
                        C-STEM Robotics kit consists of a Brain Block, 2 Motors,
                        one Touch Sensor & one IR Sensor, Sound & Light Block,
                        Magic Wand & 22 Coding chips. The Brain Block & Wand
                        come with LiPo rechargeable batteries with in-built
                        charging solution. Children can build models using their
                        own Duplo or Morphun or Lego bricks, along with our
                        smart blocks.
                      </li>

                      <li>
                        Kids can program their model using the magic wand & the
                        22 coding chips and once the coding is done, the stored
                        program is transmitted wireless from the wand to the
                        Brain Block which will make the creation come to Life.
                      </li>

                      <li>Build a model | Share your story.</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              {/* Image  */}
              <Row>
                <Col lg={12}>
                  <div className="robobricks-kit-img lni-text-align-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/taco-robricks/taco2.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Robobricks Educator pack  */}
      <section className="robricks-edu pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={12}>
                  <div className="heading-npgs dark-blue-head">
                    <h2>
                      Robobricks <span>Educator Pack</span>
                    </h2>
                  </div>
                  <div className="sub-heading-npgs pb-20">
                    <p>Imaginative Play | Creative Robotics</p>
                  </div>
                </Col>
              </Row>

              {/* images row  */}
              <Row>
                <Col lg={6} md={6}>
                  <div className="robobricks-edu-img-a">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/taco-robricks/robobricks-edu-a.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="robobricks-edu-img-b">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/taco-robricks/robobricks-edu-b.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>
              </Row>

              {/* Youtube video and content  */}
              <Row className="pt-50">
                <Col lg={6}>
                  <div className="robobricks-yt-video">
                    <iframe
                      width="100%"
                      height="415"
                      src="https://www.youtube.com/embed/wC_ofcr4diM?si=Wces97Y2g-e29qk4"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="robobricks-yt-content-wrapper">
                    <ul >
                      <li className="text-justify">
                        The Robobricks classroom solutions include every thing
                        you need to get started to explore screen free &
                        easy-to-use coding platform along with the familiar
                        building blocks.
                      </li>
                      <li className="text-justify">
                        This kit consists of Brain Block, 2 Motors, IR Sensor &
                        Touch Sensor, Sound & Light Blocks, a Magic Wand & a set
                        of 22 Robobricks tangible Coding chips. The Brain Block
                        & Wand come with LiPo rechargeable batteries with
                        in-built charging solution.
                      </li>
                      <li className="text-justify">
                        Children can build models using existing Duplo or
                        Morphun or Lego bricks, along with our smart blocks.
                        Kids can program their model using the magic wand &
                        coding chips. Once the coding is done, the stored
                        program is transmitted wireless from the wand to the
                        Brain Block which will make the creation come to Life.
                      </li>
                      <li className="text-justify">
                        Enhance the learning opportunities to take your
                        classroom to new heights Making | Coding Simplified.
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Educators video testimonial  */}
      <section
        id="educators-testimony"
        className="educators-testimony pt-50 pb-50"
      >
        <Container>
          {/* Video testimonial  */}
          <Row>
            <VideoTestim
              sectionHeading="Watch what Educators"
              headingSpan="have to say !"
            />
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default TaCoRobobricks;
