import 'bootstrap/dist/css/bootstrap.css';
import '../styles.css';
import './AccountSetting.css';
import CommonButton from '../common/Button/CommonButton';
import { Container, Row, Col, Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updatePasswordFailure, updatePasswordStart, updatePasswordSuccess } from '../../redux/registeredUserRedux';
import { userRequest } from '../../requestMethod';
import { validateRequired } from '../../formValidation';

const AccountSetting = () => {

    const user = useSelector((state) => state.loginUser.currentUser);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState(false);
    const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    // const { isFetching, currentUser } = useSelector((state) => state.user);



    const dispatch = useDispatch();

    const updatePassword = async (dispatch, userId, passwordData) => {
        dispatch(updatePasswordStart());
        try {
            const res = await userRequest.put(`user/updatePassword/${userId}`, passwordData);
            if (res.status === 200) {
                setError(false);
                setSuccess(true);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setSuccessMessage("Password has been updated!");
            }
            dispatch(updatePasswordSuccess(res.data));

        } catch (error) {
            dispatch(updatePasswordFailure());
            if (error.response.status === 401) {
                setOldPasswordError(true);
                setOldPasswordErrorMessage(error.response.data);
            }

            else {
                setSuccess(false);
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    }

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const oldPasswordValidErr = validateRequired(oldPassword);
        const newPasswordValidErr = validateRequired(newPassword);
        const confirmPasswordValidErr = validateRequired(confirmPassword);
        // let confirmPassword;

        if (oldPasswordValidErr) {
            formIsValid = false;
            newErrors.oldPassword = oldPasswordValidErr;
        }

        if (newPasswordValidErr) {
            formIsValid = false;
            newErrors.newPassword = newPasswordValidErr;
        }

        if (confirmPasswordValidErr) {
            formIsValid = false;
            newErrors.confirmPassword = confirmPasswordValidErr;
        }

        if (newPassword !== confirmPassword) {
            formIsValid = false;
            newErrors.confirmPassword = "Password did not matched";
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleSubmit = () => {
        setOldPasswordErrorMessage(false);
        setError(false);
        setSuccess(false);
        if (validateForm()) {
            updatePassword(dispatch, user._id, { oldPassword, newPassword });
        }

    }

    const [isSectionVisible, setSectionVisible] = useState(false);
    const togglePwdFields = () => {
        setSectionVisible(!isSectionVisible);
    };

    return (
        <Container className='p-lr-10 p-lr-5-mob'>
            <Row>
                <Col lg={12} className='pt-40'>
                    <div className='heading up-tab-heading'>
                        <h1>Settings</h1>
                    </div>
                </Col>
                {
                    user && (
                        <>
                            <div className='details-edit pt-40'>
                                <div className='sub-head'>
                                    <h4>My details</h4>
                                </div>
                                <div className='edit-btn'>
                                    <Link to={`/edit-profile/${user._id}`}>
                                        edit
                                    </Link>
                                </div>
                            </div>
                            <div className='ac-detail-table'>
                                <table className='user-details-table'>
                                    <tr>
                                        <td className='table-data-l'>
                                            <h6>Email</h6>
                                            <p>{user.email}</p>
                                        </td>

                                        <td className='table-data-r'>
                                            <h6>Phone Number</h6>
                                            <p>{user.phone}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>First Name</h6>
                                            <p className='text-capitalize'>{user.firstName}</p>
                                        </td>
                                        {/* { */}
                                        {/* // user.gender && ( */}
                                        <td className='table-data-r'>
                                            <h6>Gender</h6>
                                            <p>{user.gender ? user.gender : "Not Available"}</p>
                                        </td>
                                        {/* ) */}
                                        {/* } */}

                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>Last Name</h6>
                                            <p className='text-capitalize'>{user.lastName}</p>
                                        </td>

                                        <td className='table-data-r'>
                                            <h6>Postal Code</h6>
                                            <p>{user.postalCode ? user.postalCode : "Not Available"}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>Date of Birth</h6>
                                            <p>{user.dateOfBirth}</p>
                                        </td>

                                        <td className='table-data-r'>
                                            <h6>Market</h6>
                                            <p>India</p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </>
                    )
                }

                {/* Privacy Section  */}
                <div className='privacy-sec pt-100 pb-50'>
                    <div className='sub-head'>
                        <h4>PRIVACY</h4>
                    </div>

                    {/* Change password section */}
                    <div className='link-txt'>
                        <Button onClick={togglePwdFields}>change password</Button>
                    </div>
                </div>

                {/* Fields to change password */}
                {isSectionVisible && (
                    <div className='change-password w-40 w-100-mob pb-50'>
                        <Form>
                            {/* Old Password */}
                            <FormGroup className="mb-3" controlId="oldPassword">
                                <FormLabel className='cb-form-label'>Old Password*</FormLabel>
                                <Form.Control className='cb-input-fields' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} type="password" placeholder="Old password" />
                                <span className='error-text'>{errors.oldPassword}</span>
                                {
                                    oldPasswordError && (
                                        <p className='error-text text-danger'>{oldPasswordErrorMessage}</p>
                                    )
                                }
                            </FormGroup>

                            {/* New Password */}
                            <FormGroup className="mb-3" controlId="oldPassword">
                                <FormLabel className='cb-form-label'>New Password*</FormLabel>
                                <Form.Control className='cb-input-fields' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" placeholder="New password" />
                                <span className='error-text'>{errors.newPassword}</span>
                            </FormGroup>

                            {/* Confirm New Password */}
                            <FormGroup className="mb-3" controlId="oldPassword">
                                <FormLabel className='cb-form-label'>Confirm New Password*</FormLabel>
                                <Form.Control className='cb-input-fields' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder="Confirm new password" />
                                <span className='error-text'>{errors.confirmPassword}</span>
                            </FormGroup>

                            <div className="col-12 mt-3">
                                {
                                    success ? (
                                        <div className='alert alert-success'>
                                            {successMessage}
                                        </div>
                                    ) : error && (
                                        <div className='alert alert-danger'>
                                            {errorMessage}
                                        </div>
                                    )
                                }
                            </div>

                            {/* Change Password Button */}
                            <div className='text-end'>
                                <CommonButton
                                    btnClass='change-pwd-btn'
                                    btnName='Change Password'
                                    onClickAction={handleSubmit}
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Row>
        </Container>
    )
}

export default AccountSetting;