import React from "react";
import "./FlexiblePlan.css";
const FlexiblePlan2Page = () => {
  return (
    <div className="flexible_plan_page">
      <header className="flexible_header">
        <h1 className="text-white fw-700">Case Study</h1>
        <p className="text-white">
        Tailored STEM, Coding and Robotics Solution for an Elementary School
        </p>
      </header>
      <h2 className="flexible_plan_page_title">Flexible Plan 2</h2>
      <section className="flexible_plan_page_body container ">
        <p>
          Our client, an elementary school in the USA, sought an integrated
          STEM, Robotics, and Coding program aligned with the NGSS, CSTA, and
          ISTE standards. With 400 students from grades 1 to 5, they aimed to
          provide a year-long, progressive learning journey, dedicating one hour
          per week for 18 weeks. The school was committed to screen-free
          education, and we jointly crafted a solution involving the supply of
          Robotic kits and an extensive "Train the Teacher" model:
        </p>
        <h2>
          Elementary School | Grades 1-5 | 100% Screen-Free Curriculum | 18
          weeks
        </h2>
        <ul>
          <li>
            Provided 15 STEM Maker Kits for the construction of simple and
            compound machines.
          </li>
          <li>
            Supplied 15 Brick Math Kits for math and numeracy skill development.
          </li>
          <li>
            Delivered 15 Playbits kits to introduce tangible coding concepts.
          </li>
          <li>
            Furnished 15 Phiro Unplugged kits with Keys and Swish Cards for
            fundamental robotics education.
          </li>
          <li>
            Conducted an on-line 12-hour "Train the Teacher" program spanning
            two days.
          </li>
          <li>
            Offered a comprehensive curriculum via a Learning Management System
            (LMS) covering all five grades for the four kits.
          </li>
          <li>Included a one-year built-in warranty with kit supply.</li>
          <li>
            Provided an Annual Maintenance Contract for years 2 to 5, ensuring
            long-term support and sustainability.
          </li>
        </ul>

        <p className="fw-600">
          The school appreciated the durability of the kits, which came with a
          clear service life of five years, resulting in an economical cost per
          student over 2000 students in five years.
        </p>

        <p className="fw-600">
          At the end of the 18-week program, the school showcased student
          capstone projects during a dedicated event. Their commitment to
          innovative and creative learning, supported by our tailored solution,
          led to impressive projects that consistently impressed parents and
          School District administrators. This case study underscores our
          ability to design customized educational solutions to meet the unique
          needs and aspirations of educational institutions.
        </p>
      </section>
    </div>
  );
};

export default FlexiblePlan2Page;
