import "./Testimonial.css";

export default function Testimonial(props) {
  return (
    <div>
      <div className="testimonials_item">
        {
          props.category === 'trainer' && (
            <h2 className="hSize fw-700 ff dBlue">
              what trainers <span>Say</span>
            </h2>
          )
        }
        {
          props.category === 'parent' && (
            <h2 className="hSize fw-700 ff dBlue">
              what parents <span>Say</span>
            </h2>
          )
        }

        <img
          src={props.testimonialImage}
          alt="img"
          className="mt-lg-5 mb-lg-4"
        />
        <h3 className="ff dPink fw-600 f-20">{props.parentName} </h3>
        <h4 className="ff fw-600 f-20 dBlue">{props.childName}</h4>
        <img src="images/q.svg" className="quotation" />
        <p className="text-start ff">{props.description}</p>
      </div>
    </div>
  );
}
