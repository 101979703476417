import { Link } from "react-router-dom";
import "./Banner.css";
function Banner() {
  return (
    <div>
      <section className="bundle_india bundle_india_main px-lg-5 pb-4">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-6 order-tab order-1 order-lg-0">
              <h1 className="banner_txt sbi-banner-txt dBlue">
                Embrace the future of education with
                <br />
                <span className="dPink">STEM, ROBOTICS and AI&nbsp;</span>
                powered solutions
              </h1>
              {/* <h2>
                A holistic Al & Robotics learning platform for your school
              </h2> */}
            </div>
            <div className="col-md-6 col-lg-6 p-rel">
              <div className="banner_img sbi-banner-img">
                <img
                  src="images/SchoolBundle/India/banner-main.png"
                  alt="Hello"
                />
                {/* <img src="images/home/mob-banner.jpg" alt="Hello" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Banner;
