import { Link } from "react-router-dom";
import {
  MEDIA_URL,
  changeDateFormat,
  extractFirstParagraph,
  isUrl,
  truncateText,
} from "../config";
import "../../src/component/BlogCard.css";
const NewsCard = ({ image, title, description, eventId }) => {
  return (
    <div className="blog-card">
      <Link to={`/news/${eventId}`}>
      <div className="event-img-wrapper blog-cover-img">
        {isUrl(image) ? (
          <img src={image} alt="news-img" className="blog-image" />
        ) : (
          <img src={MEDIA_URL + image} alt="news-img" className="blog-image" />
        )}
      </div>
      </Link>
      <div className="blog-content">
        {/* <p className="blog-date">{changeDateFormat(props.blog?.createdAt)}</p> */}
        <Link className="blog-title f-20 fw-700" to={`/news/${eventId}`}>
        {truncateText(title, 85)}
        </Link>
        <p className="blog-paragraph">{extractFirstParagraph(description)}</p>
        <Link to={`/news/${eventId}`} className="blog-read">
          Read More
        </Link>
      </div>
    </div>
  );
};

export default NewsCard;
