// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy_first {
  background: rgb(195 197 248 / 10%);
  padding: 50px 0px;
}

.privacy_section h2 {
  font-size: var(--f20);
  font-weight: 700;
  text-transform: uppercase;
  color: #264ec8;
  text-align: left;
  margin-bottom: 20px;
}
.privacy_section h2 span {
  color: #e20f6f;
}
.privacy p {
  font-size: var(--pSize);
  font-weight: 400;
  text-align: left;
  margin-bottom: 20px;
}
.privacy img {
  width: 30%;
}
.privacy img,
.privacy h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.privacy h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--dBlue);
  text-transform: uppercase;
  width: 100%;
}
.privacy h1 span {
  color: var(--dPink);
}
`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".privacy_first {\r\n  background: rgb(195 197 248 / 10%);\r\n  padding: 50px 0px;\r\n}\r\n\r\n.privacy_section h2 {\r\n  font-size: var(--f20);\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n  color: #264ec8;\r\n  text-align: left;\r\n  margin-bottom: 20px;\r\n}\r\n.privacy_section h2 span {\r\n  color: #e20f6f;\r\n}\r\n.privacy p {\r\n  font-size: var(--pSize);\r\n  font-weight: 400;\r\n  text-align: left;\r\n  margin-bottom: 20px;\r\n}\r\n.privacy img {\r\n  width: 30%;\r\n}\r\n.privacy img,\r\n.privacy h1 {\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n}\r\n.privacy h1 {\r\n  font-size: 50px;\r\n  font-weight: 700;\r\n  color: var(--dBlue);\r\n  text-transform: uppercase;\r\n  width: 100%;\r\n}\r\n.privacy h1 span {\r\n  color: var(--dPink);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
