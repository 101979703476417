import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./ThankYouPg.css";
import { Col, Container, Row } from "react-bootstrap";

const ThankYouPg = () => {
  return (
    <section className="thank-you-pg-wrapper text-center">
      <Container fluid className="p-0">
        {/* img row  */}
        <Row>
          <div className="thank-you-page-banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/thank-you-page/tpg-banner-img.png`}
              alt=""
            />
          </div>
        </Row>
      </Container>

      {/* Heading row  */}
      <Container>
        <Row>
          <Col lg={12}>
            <div class="heading-npgs dark-pink-head pt-30 pb-10">
              <h2>Congratulations</h2>
            </div>
            <p className="mbc-5">on successfully registering for the</p>
            <div className="sub-content">
              <p className="fdp-txt">Free Demo Program</p>
            </div>

            <div className="more-txt-typ">
              <p className="pt-10">
                The Free Demo Program is designed to provide you with valuable
                insights into our teaching methodologies, curriculum, and
                support services. It will also give you an opportunity to meet
                our experienced faculty members.
              </p>
              <p>
                This program will serve as a platform to address any questions
                or concerns you may have, ensuring that you start your classes
                with confidence and clarity.
              </p>
              <p className="pb-30">
                We look forward to meeting you and assisting you in reaching
                your academic goals. If you have any further queries, feel free
                to reach out to our support team.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ThankYouPg;
