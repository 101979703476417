import "bootstrap/dist/css/bootstrap.css";
import CommonButton from "../common/Button/CommonButton";
import "./ProductPage.css";
import "../../styles.css";
import "./ProductPageKit.css";
import TabWrapper from "../common/tab/TabWrapper";
import ProductOverview from "../common/tab/ProductOverview";
import ProductRating from "../common/product-rating/ProductRating";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// import Carousel from "react-grid-carousel";
import OwlCarousel from "react-owl-carousel";
import { BsCartPlus } from "react-icons/bs";
import LearningsKitTab from "../common/tab/LearningsKitTab";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, getSpecificCourseRating } from "../../redux/apiCalls";
import { useEffect } from "react";
import { addToCartFailure, addToCartStart, addToCartSuccess } from "../../redux/cartRedux";

const ProductPageCDots = ({ isActive }) => (
  <span
    style={{
      display: "inline-block",
      height: isActive ? "20px" : "8px",
      width: isActive ? "20px" : "8px",
      background: isActive
        ? "radial-gradient(50% 50.00% at 50% 50.00%, #E20F6F 0%, rgba(226, 15, 111, 0.00) 100%)"
        : "#E20F6F",
      borderRadius: isActive ? "50%" : "50%",
    }}
  ></span>
);

function ProductPageKit() {

  const dispatch = useDispatch();

  useEffect(() => {
    getProducts(dispatch);
  }, []);
  
  // Cheking user is logged or not=====
  const user = useSelector((state) => state.loginUser.currentUser);
  const location = useLocation();
  const productTitle = decodeURIComponent(location.pathname.split("/")[2]);


  const product = useSelector((state) =>
    state.product.products.find((item) => item.title == productTitle)
  );

  const productRatings = useSelector((state) => state?.rating?.ratings);


  useEffect(() => {
    getSpecificCourseRating(dispatch, product?._id)
  },[product]);

  const addProductToCart = async (dispatch, product) => {
    dispatch(addToCartStart());
    try {
      dispatch(addToCartSuccess(product));
    } catch (error) {
      dispatch(addToCartFailure());
    }
  };

  const handleAddToCart = () => {
    addProductToCart(dispatch, { ...product, quantity: 1 });
  };

  const titles = ["Overview", "What is in the box", "What will your child learn", "Reviews"];
  const contents = [
    <ProductOverview ProductOverviewImg="images/product-page/kit-img-b.png" overView = {product?.overview} />,
    product?.whatIsInTheBox,
    product?.whatWillChildLearn,
    <ProductRating productRatings = {productRatings} />,
  ];


  const responsiveConfig = {
    1200: {
      items: 1, // Show 5 items on screens larger than 1200px
    },
  };

  return (
    <div className="py-5">
      <Container>
        <Row>
          <Col lg={7} sm={12}>
            {/* <div className="trainning-kits-img">
              <Carousel
                cols={1}
                rows={1}
                gap={0}
                loop
                showDots={true}
                dot={ProductPageCDots}
                hideArrow={false}
              >
                <Carousel.Item>
                  <img width="100%" src="images/product-page/kit-img-a.png" />
                </Carousel.Item>
                <Carousel.Item>
                  <img width="100%" src="images/product-page/kit-img-a.png" />
                </Carousel.Item>
                <Carousel.Item>
                  <img width="100%" src="images/product-page/kit-img-a.png" />
                </Carousel.Item>
                <Carousel.Item>
                  <img width="100%" src="images/product-page/kit-img-a.png" />
                </Carousel.Item>
              </Carousel>
            </div> */}
            <OwlCarousel className="owl-theme" margin={50} dots={false} responsive={responsiveConfig}>
              {/* {
                props.skills.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <SkillsCard
                        CardImg={`${process.env.PUBLIC_URL}/images/product-page/icons/sequencing.png`}
                        CardTitle={item.skillName}
                        CardText={item.skillDetails}
                      />
                    </div>
                  )
                })
              } */}

              <div className="item">
                <img width="100%" src={`${process.env.PUBLIC_URL}/images/product-page/demo-video.png`} />
              </div>
              <div className="item">
                <img width="100%" src={`${process.env.PUBLIC_URL}/images/product-page/product-thumbnail.png`} />
              </div>
              <div className="item">
                <img width="100%" src={`${process.env.PUBLIC_URL}/images/product-page/demo-video-b.png`} />
              </div>
              <div className="item">
                <img width="100%" src={`${process.env.PUBLIC_URL}/images/product-page/overview-img.png`} />
              </div>
            </OwlCarousel>
          </Col>
          <Col lg={5} sm={12}>
            <div className="product-des">
              <div className="product-name heading">
                <h2>{product?.title}</h2>
              </div>
              <div className="product-info">
                <div className="product-age-group">
                  <p>Age: {product?.ageGroup}</p>
                </div>
                {/* <div className='session-count'>
                                    <p>20 Sessions</p>
                                </div> */}
                <div className="product-rating">
                  <span>4.7+ </span>
                  {[...Array(5)].map((star) => {
                    return <span className="star">&#9733;</span>;
                  })}
                </div>
              </div>
              <div className="product-price">
                <p>&#8377;3,999</p>
              </div>
              <div className="about-product">
                <p>{product?.description}</p>
              </div>
              <div className="add-to-cart-btn text-center">
                {/* <Link to="/Cart">
                  <CommonButton
                    btnClass="explore-course-btn"
                    btnName="add to cart"
                    btnIconClass="cart-icon"
                    btnIcon={<BsCartPlus />}
                  />
                </Link> */}
                {user ? (
                    <Link to={"/cart"}>
                      <CommonButton
                        onClickAction={handleAddToCart}
                        btnClass="explore-course-btn"
                        btnName="Buy Now"
                      />
                    </Link>
                  ) : (
                    <Link to={"/login"}>
                      <CommonButton
                        btnClass="explore-course-btn"
                        btnName="Buy Now"
                      />
                    </Link>
                  )}
              </div>
            </div>
          </Col>
          <div className="product-pg-tab">
            <TabWrapper titles={titles} contents={contents} />
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default ProductPageKit;
