
import React from 'react';
import './OrderSuccessWithPayment.css';
import { clearCart } from '../../redux/cartRedux';
import { useDispatch } from 'react-redux';
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const OrderSuccessWithPayment = () => {

  const params = new URLSearchParams(window.location.search);
  // Get the values
  const status = params.get('status');
  const orderId = params.get('orderId');
  const transactionId = params.get('transactionId');
  const transactAmount = params.get('transactAmount');
  const transactDate = params.get('transactDate');

  // const dispatch = useDispatch();
  // dispatch(clearCart());
  return (
    <div className='container'>
      <div className="row">
        <div className="col-12">
          <div className="payment-status-wrapper">
            {
              status === "success" && (
                <>
                  <div className="status-icon-wrapper">
                    <MdDone className='status-icon' />
                  </div>
                  <h1 class="about_home_heading">Payment <span>Successful!</span></h1>
                  <h2 className='status-titles transaction-id-text'>Transaction ID - <span className='status-values'>{transactionId}</span></h2>

                  <div className="dashed-rule"></div>
                  <div className="status-content-wrapper">
                    <div className="status-content">
                      <p className='status-titles'>Mode of payment</p>
                      <p className='status-values'>online payment</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Order ID</p>
                      <p className='status-values text-uppercase'>{orderId}</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Transaction Amount</p>
                      <p className='status-values'>{transactAmount}</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Status</p>
                      <p className='status-values'>{status}</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Purpose of Payment</p>
                      <p className='status-values'>Service Render</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Order Date</p>
                      <p className='status-values'>{transactDate}</p>
                    </div>
                  </div>
                </>
              )
            }

            {
              status === "failed" && (
                <>
                  <div className="status-icon-wrapper">
                    <RxCross2 className='status-icon' />
                  </div>
                  <h1 class="about_home_heading">Payment <span>Failed!</span></h1>
                  <h2 className='status-titles'>Transaction ID - <span className='status-values'>{transactionId}</span></h2>

                  <div className="dashed-rule"></div>
                  <div className="status-content-wrapper">
                    <div className="status-content">
                      <p className='status-titles'>Mode of payment</p>
                      <p className='status-values'>online payment</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Order ID</p>
                      <p className='status-values text-uppercase'>{orderId}</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Transaction Amount</p>
                      <p className='status-values'>{transactAmount}</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Status</p>
                      <p className='status-values'>{status}</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Purpose of Payment</p>
                      <p className='status-values'>Service Render</p>
                    </div>
                    <div className="status-content">
                      <p className='status-titles'>Order Date</p>
                      <p className='status-values'>{transactDate}</p>
                    </div>
                  </div>
                </>
              )
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSuccessWithPayment
