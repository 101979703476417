// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-courses-tab {
  background-color: transparent !important;
  background-color: initial !important;
  margin-top: -40px;
  width: 55%;
}

.my-courses-tab .active {
  background-color: var(--dPink);
  color: var(--white);
  width: 40%;
  border: medium none currentColor !important;
  border: initial !important;
  border-radius: 30px;
}

.my-courses-tab li {
  color: var(--dPink);
  border: 2px solid #977cff;
  border-radius: 50px;
  width: 40%;
  padding: 10px 20px;
  text-transform: capitalize;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .my-courses-tab {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .my-courses-tab {
    width: 100%;
    column-gap: 10px;
  }

  .courses-tab {
    margin-top: 15%;
  }

  .my-courses-tab li {
    width: 33%;
    padding: 10px 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/user-profile/MyLearning.css"],"names":[],"mappings":"AAAA;EACE,wCAAkC;EAAlC,oCAAkC;EAClC,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU;EACV,2CAAwB;EAAxB,0BAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,kBAAkB;EACpB;AACF","sourcesContent":[".my-courses-tab {\r\n  background-color: unset !important;\r\n  margin-top: -40px;\r\n  width: 55%;\r\n}\r\n\r\n.my-courses-tab .active {\r\n  background-color: var(--dPink);\r\n  color: var(--white);\r\n  width: 40%;\r\n  border: unset !important;\r\n  border-radius: 30px;\r\n}\r\n\r\n.my-courses-tab li {\r\n  color: var(--dPink);\r\n  border: 2px solid #977cff;\r\n  border-radius: 50px;\r\n  width: 40%;\r\n  padding: 10px 20px;\r\n  text-transform: capitalize;\r\n  font-weight: 600;\r\n}\r\n\r\n@media (max-width: 1024px) {\r\n  .my-courses-tab {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n@media (max-width: 767px) {\r\n  .my-courses-tab {\r\n    width: 100%;\r\n    column-gap: 10px;\r\n  }\r\n\r\n  .courses-tab {\r\n    margin-top: 15%;\r\n  }\r\n\r\n  .my-courses-tab li {\r\n    width: 33%;\r\n    padding: 10px 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
