import React, { useEffect, useState } from 'react'
import BlogAndNewsFrame from './BlogAndNewsFrame'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogs } from '../redux/apiCalls';
import { publicRequest } from '../requestMethod';

const SingleNews = () => {

    // const dispatch = useDispatch();

    // useEffect(() => {
    //   getAllBlogs(dispatch);
    // },[])

    const location = useLocation();
    const newsId = location.pathname.split('/')[2];
    const [currentNews, setCurrentNews] = useState({});

    // Fetch news and events data
    useEffect(() => {
        const fetchNewsAndEvents = async () => {
            try {
                // const newsResponse = await publicRequest.get("/news/all");
                const newsResponse = await publicRequest.get(`/news/find/${newsId}`);

                // setNewsData(newsResponse.data);
                setCurrentNews(newsResponse.data);
                // setNewsAndEvents(newsResponse.data, ...eventsResponse.data)
            } catch (error) {
            }
        };
        fetchNewsAndEvents();
    }, []);



  
    // const currentBlog = useSelector((state) => state.blog.blogs.find((item) => item._id === eventId));

  return (
    // <div>
    currentNews ? (
        <BlogAndNewsFrame image={currentNews.newsImage} description = {currentNews.description} author = {currentNews.author} itemType = "news" title={currentNews.title} />
    ) : (
        <h2>No content available!</h2>
    )
    // </div>
  )
}

export default SingleNews
