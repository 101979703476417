import "bootstrap/dist/css/bootstrap.css";
import "../../styles.css";
import "./SliderCard.css";
import { Card, CardImg } from "react-bootstrap";
import { Link } from "react-router-dom";
import { truncateText } from "../../../config";

const SliderCard = (props) => {
  return (
    <div className="slider-card-container">
      <Card className="slider-card ">
        {/* card Image */}
        {/* <Link to={`/blog/${props.blog?.title}/`} className="blog-read"></Link> */}
        <Link to={`/blog/${props.CardTitle}/`}>
        <CardImg className="sc-img" variant="top" src={props.CardImg} />
        </Link>
        {/* Card Body  */}
        <Card.Body>
          <Card.Title className={`${props.CardTitleClass} slider-card-name`}>
          <Link to={`/blog/${props.CardTitle}/`}>
            <h3>{truncateText(props?.CardTitle, 30)}</h3>
            </Link>
          </Card.Title>
          <Card.Text className="slider-card-desc">
            <p>{props.CardDesc}</p>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SliderCard;
