import 'bootstrap/dist/css/bootstrap.css';
import './Notes.css';
import { Col, Container, Row } from 'react-bootstrap';
import { SlNotebook } from "react-icons/sl";
import { BsDownload } from "react-icons/bs";
import { Link } from 'react-router-dom';

const Notes = (props) => {
    return (
        <div className='notes'>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='heading'>
                            <h1>Notes</h1>
                        </div>

                        {/* Notes List  */}
                        <ul className="course-notes pt-50">
                            <li className='unit-notes'>
                                <div className='notes-icon'>
                                    <SlNotebook />
                                </div>

                                <div className='notes-name'>
                                    <p>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>
                                </div>
                                <Link to={props.notes[0]}>
                                    <div className='download-notes'>
                                        <BsDownload />
                                    </div>
                                </Link>
                            </li>

                            <li className='unit-notes'>
                                <div className='notes-icon'>
                                    <SlNotebook />
                                </div>

                                <div className='notes-name'>
                                    <p>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>
                                </div>

                                <div className='download-notes'>
                                    <BsDownload />
                                </div>
                            </li>

                            <li className='unit-notes'>
                                <div className='notes-icon'>
                                    <SlNotebook />
                                </div>

                                <div className='notes-name'>
                                    <p>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>
                                </div>

                                <div className='download-notes'>
                                    <BsDownload />
                                </div>
                            </li>

                            <li className='unit-notes'>
                                <div className='notes-icon'>
                                    <SlNotebook />
                                </div>

                                <div className='notes-name'>
                                    <p>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>
                                </div>

                                <div className='download-notes'>
                                    <BsDownload />
                                </div>
                            </li>

                            <li className='unit-notes'>
                                <div className='notes-icon'>
                                    <SlNotebook />
                                </div>

                                <div className='notes-name'>
                                    <p>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>
                                </div>

                                <div className='download-notes'>
                                    <BsDownload />
                                </div>
                            </li>

                            <li className='unit-notes'>
                                <div className='notes-icon'>
                                    <SlNotebook />
                                </div>

                                <div className='notes-name'>
                                    <p>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>
                                </div>

                                <div className='download-notes'>
                                    <BsDownload />
                                </div>
                            </li>

                            <li className='unit-notes'>
                                <div className='notes-icon'>
                                    <SlNotebook />
                                </div>

                                <div className='notes-name'>
                                    <p>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>
                                </div>

                                <div className='download-notes'>
                                    <BsDownload />
                                </div>
                            </li>

                            <li className='unit-notes'>
                                <div className='notes-icon'>
                                    <SlNotebook />
                                </div>

                                <div className='notes-name'>
                                    <p>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>
                                </div>

                                <div className='download-notes'>
                                    <BsDownload />
                                </div>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Notes;