// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExploreCard_Container {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  overflow: hidden;
}
.ExploreCard_Container img
{
  height: 200px;
  overflow: hidden;
}
/* .ExploreCard_Container p{
  padding:40px;
} */
.ExploreCard_txt {
  padding: 40px;
  text-align: left;
}
.ExploreCard_txt p:last-child
{
  padding: 40px auto;
}
.ExploreCardBtn {
  display: flex;
  justify-content: space-between;
  margin-top:30px;
}
.ExploreCardBtn a:last-child {
  background-color: var(--dBlue);
  font-size: var(--pSize);
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 30px;
}
.ExploreCardBtn a:first-child
{
  font-size: var(--pSize);
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/component/ExploreCards.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2CAA2C;EAC3C,mBAAmB;EACnB,gBAAgB;AAClB;AACA;;EAEE,aAAa;EACb,gBAAgB;AAClB;AACA;;GAEG;AACH;EACE,aAAa;EACb,gBAAgB;AAClB;AACA;;EAEE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;AACA;EACE,8BAA8B;EAC9B,uBAAuB;EACvB,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;AACA;;EAEE,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".ExploreCard_Container {\r\n  background: #ffffff;\r\n  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);\r\n  border-radius: 15px;\r\n  overflow: hidden;\r\n}\r\n.ExploreCard_Container img\r\n{\r\n  height: 200px;\r\n  overflow: hidden;\r\n}\r\n/* .ExploreCard_Container p{\r\n  padding:40px;\r\n} */\r\n.ExploreCard_txt {\r\n  padding: 40px;\r\n  text-align: left;\r\n}\r\n.ExploreCard_txt p:last-child\r\n{\r\n  padding: 40px auto;\r\n}\r\n.ExploreCardBtn {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  margin-top:30px;\r\n}\r\n.ExploreCardBtn a:last-child {\r\n  background-color: var(--dBlue);\r\n  font-size: var(--pSize);\r\n  color: #ffffff;\r\n  padding: 5px 20px;\r\n  border-radius: 30px;\r\n}\r\n.ExploreCardBtn a:first-child\r\n{\r\n  font-size: var(--pSize);\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
