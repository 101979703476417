import { createSlice } from "@reduxjs/toolkit";

const blogCategoryReducer = createSlice({
    name : 'blogCategory',
    initialState : {
        blogCategory : [],
        isFetching : false,
        error : false,
    },

    reducers : {
        // action to get all blog category
        getBlogCategoryStart : (state) => {
            state.isFetching  = true;
        },

        getBlogCategorySuccess : (state, action) => {
            state.isFetching = false;
            state.blogCategory = action.payload;
        },

        getBlogCategoryFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },
    }
});

export const {addBlogCategoryStart, addBlogCategorySuccess, addBlogCategoryFailure, getBlogCategoryStart, getBlogCategorySuccess, getBlogCategoryFailure, deleteBlogCategoryStart, deleteBlogCategorySuccess, deleteBlogCategoryFailure  } = blogCategoryReducer.actions;
export default blogCategoryReducer.reducer;