import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MiniLoader from "../common/Loader/miniLoader/MiniLoader";
import { getAllBlogCategory } from "../../redux/apiCalls";
import ExploreMore from "../ExploreMore";

const LearningResourceBlogs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAllBlogCategory(dispatch);
  }, []);

  const state = useSelector((state) => state);
  const { isFetching, blogCategory: allBlogCategory } = useSelector(
    (state) => state.blogCategory
  );
  const schoolBundleBlogs = allBlogCategory.filter(
    (category) =>
      category.category === "Tips for Students" ||
      category.category === "Future of Learning"
  );

  return (
    // <section>
    <div className="container">
      <div className="heading-npgs dark-blue-head vt-heading">
        <h2 className="text-center mb-50">
          Learning <span className="dPink">Resources</span>
        </h2>
      </div>
      {}
      <div className="row">
        {isFetching ? (
          <MiniLoader />
        ) : (
          schoolBundleBlogs?.map((blogCategory, key) => {
            return (
              <div key={key} className="col-md-6">
                <ExploreMore
                  title={blogCategory.category}
                  imgUrl={blogCategory.categoryImage}
                  categoryTitle={blogCategory.category}
                  totalBlogs={blogCategory.blogCount}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
    // </section>
  );
};

export default LearningResourceBlogs;
