import { createSlice } from "@reduxjs/toolkit";

const lectureReducer = createSlice({
    name : 'lecture',
    initialState : {
        lectures : [],
        isFetching : false,
        error : false,
    },

    reducers : {
        // Get lecture actions
        getLecturesStart : (state) => {
            state.isFetching = true;
        },

        getLecturesSuccess : (state, action) => {
            state.isFetching = false;
            state.lectures = action.payload;
        },

        getLecturesFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        }
    },
});

export const { getLecturesStart, getLecturesSuccess, getLecturesFailure } = lectureReducer.actions;
export default lectureReducer.reducer