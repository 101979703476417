// VideoTestim.js
import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Col, Container, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { FaPlay, FaTimes } from "react-icons/fa";
import VimeoPlayer from "@vimeo/player";
import "./VideoTestim.css";
import { getAllSuccessStoriesVideo } from "../../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { MEDIA_URL } from "../../../config";

const VideoTestim = (props) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const playerRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllSuccessStoriesVideo(dispatch);
  }, [dispatch]);

  const allVideos = useSelector((state) => state.successStories.videos);

  const handleVideoClose = () => {
    setShowVideoPlayer(false);
    // initializeVimeoPlayer("",false)
  };
  const responsiveConfig = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  };
  const carouseloptions = {
    items: 1,
    loop: true,
    autoplay: true,
    dots: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: responsiveConfig,
  };

  const videos = [
    {
      id: 1,
      thumbnail: "/video/thumbnails/Learners-1.jpg",
      url: "/video/about.mp4",
    },
    {
      id: 2,
      thumbnail: "/video/thumbnails/Learners-2.jpg",
      url: "/video/about.mp4",
    },
    {
      id: 3,
      thumbnail: "/video/thumbnails/Learners-3.jpg",
      url: "/video/about.mp4",
    },
    {
      id: 4,
      thumbnail: "/video/thumbnails/Learners-3.jpg",
      url: "/video/about.mp4",
    },
  ];

  const playerContainer = document.getElementById(`player-container`);
  const initializeVimeoPlayer = (vimeoURL, status) => {
    if (playerContainer) {
      const player = new VimeoPlayer(playerContainer, {
        url: vimeoURL,
      });

      playerRef.current = player;
      if (status === false) {
        playerRef.current.destroy();
      }
    }
  };

  const handleVideoClick = (url, status) => {
    setShowVideoPlayer(status);
    initializeVimeoPlayer(url, status);
  };

  return (
    <div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="heading-npgs dark-blue-head vt-heading">
              <h2>
                {props.sectionHeading}&nbsp;
                <span>{props.headingSpan}</span>
              </h2>
            </div>
          </Col>
        </Row>
      </Container>
      <OwlCarousel
        className="owl-theme video-testim-carousel"
        // loop
        margin={30}
        responsive={responsiveConfig}
        carouseloptions = {true}
      >
        {allVideos.map((video) =>
          // <div key={video.id}>
          {
            if (video.category === props.category) {
              return (
                <div
                  key={video.id}
                  onClick={() => handleVideoClick(video.video, true)}
                >
                  <div className="video-wrapper">
                    <img
                      src={MEDIA_URL + video.thumbnailImage}
                      alt={`Video Image`}
                      className="video-thumbnail"
                    />
                    <div className="play-overlay">
                      <FaPlay />
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          }
        )}
      </OwlCarousel>

      {/* {showVideoPlayer && ( */}
      <div
        className="video-modal"
        style={showVideoPlayer ? { display: "flex" } : { display: "none" }}
      >
        <div className="video-player">
          <div
            ref={playerRef}
            className="player-container"
            id="player-container"
          ></div>
          {/* <div ref={playerRef} id="player-container"></div> */}
          {/* <video controls width="100%" onEnded={handleVideoClose}>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
          {/* <iframe width="560" height="315" src="https://youtu.be/hYE5GVhIuKA?si=P-XKzoeZuIamTgBn" frameborder="0" allowfullscreen></iframe> */}
          {/* <iframe width="100%" height="515" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          <button
            className="close-button"
            onClick={() => handleVideoClick(null, false)}
          >
            <FaTimes />
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default VideoTestim;
