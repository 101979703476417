import "./SuccessCard.css"
const SuccessCard = ({ image, schoolName, location, paragraph }) => {
  return (
    <div className="success-card row">
      <div className="image-container col-md-5">
        <img src={image} alt="Success" />
      </div>
      <div className="text-container position-relative col-md-6 offset-lg-1">
        {/* <img src="images/q.svg" alt="" className="" /> */}
        <h3 className="school-name f-20 fw-700 dPink">{schoolName}</h3>
        <p className="location f-15 dBlue fw-700">{location}</p>
        <p className="paragraph pSize">{paragraph}</p>
      </div>
    </div>
  );
};

export default SuccessCard;
