import "./card.css";
function Card({
  title,
  spanTxt,
  imageUrl,
  backgroundColor,
  customClasses,
  isPadding,
}) {
  const cardStyle = {
    backgroundColor: backgroundColor,
  };

  const cardClasses = `cardHome ${customClasses || ""}`;
  const cardTextCC = isPadding ? "custom-card-padding" : "";

  return (
    <div>
      <div style={cardStyle} className={cardClasses}>
        <div className={cardTextCC}>
          {spanTxt && <span>{spanTxt}</span>}
          <h2 className="text-capitalize">{title}</h2>
        </div>
        <img src={imageUrl} alt={title} />
      </div>
    </div>
  );
}
export default Card;
