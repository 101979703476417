// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills-card {
  border: 3px solid var(--cBBlue);
  border-radius: 20px;
  margin-top: 85px;
  height: 310px;
}

.skills-card-icon {
  background-color: var(--cIBBlue);
  border-radius: 50%;
  height: 150px;
  width: 150px;
  margin: auto;
  margin-top: -85px;
  padding: 20px;
}

.card-content {
  padding: 20px;
}

.skills-card-title {
  padding-bottom: 20px;
}

.skills-card-title span {
  color: var(--cSPink);
}

.skills-card-icon img {
  height: 100px;
  width: 100px !important;
  margin: 5px auto;
}

.skills-card-text {
  /* padding-bottom: 40px; */
}

.skills-card-title {
  padding-bottom: 5px;
  height: 70px;
}

.skills-card .card-title h2 {
  padding-bottom: 0 !important;
}

@media (max-width: 576px) {
  .skills-card-icon {
    height: 130px;
    width: 130px;
  }

  .skills-card-icon img {
    height: 90px !important;
    width: 90px !important;
  }

  .skills-card-title {
    padding-bottom: 0;
    padding-bottom: initial;
  }

  .skills-card-text {
    padding-bottom: 0;
    padding-bottom: initial;
  }

  .skills-card {
    height: 270px;
  }

  .skills-card-icon {
    margin-top: -70px;
  }

  .skills-card-icon img {
    margin: 0 auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/common/cards/SkillsCard.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,aAAa;IACb,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,iBAAqB;IAArB,uBAAqB;EACvB;;EAEA;IACE,iBAAqB;IAArB,uBAAqB;EACvB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".skills-card {\n  border: 3px solid var(--cBBlue);\n  border-radius: 20px;\n  margin-top: 85px;\n  height: 310px;\n}\n\n.skills-card-icon {\n  background-color: var(--cIBBlue);\n  border-radius: 50%;\n  height: 150px;\n  width: 150px;\n  margin: auto;\n  margin-top: -85px;\n  padding: 20px;\n}\n\n.card-content {\n  padding: 20px;\n}\n\n.skills-card-title {\n  padding-bottom: 20px;\n}\n\n.skills-card-title span {\n  color: var(--cSPink);\n}\n\n.skills-card-icon img {\n  height: 100px;\n  width: 100px !important;\n  margin: 5px auto;\n}\n\n.skills-card-text {\n  /* padding-bottom: 40px; */\n}\n\n.skills-card-title {\n  padding-bottom: 5px;\n  height: 70px;\n}\n\n.skills-card .card-title h2 {\n  padding-bottom: 0 !important;\n}\n\n@media (max-width: 576px) {\n  .skills-card-icon {\n    height: 130px;\n    width: 130px;\n  }\n\n  .skills-card-icon img {\n    height: 90px !important;\n    width: 90px !important;\n  }\n\n  .skills-card-title {\n    padding-bottom: unset;\n  }\n\n  .skills-card-text {\n    padding-bottom: unset;\n  }\n\n  .skills-card {\n    height: 270px;\n  }\n\n  .skills-card-icon {\n    margin-top: -70px;\n  }\n\n  .skills-card-icon img {\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
