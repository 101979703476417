import "./ExploreMore.css";
import "./ExploreMore";
import { Link } from "react-router-dom";
function ExploreMore({ imgUrl, title, categoryTitle, totalBlogs }) {
  return (
    <Link to={`/category-blog/${title}`}>
      <div className="ExploreMore">
        <img src={imgUrl} alt={title} />
        <div className="overlay_text">
          <h3 className="text-capitalize">{title}</h3>
          <p>{totalBlogs} Articles</p>
        </div>
      </div>
    </Link>
  );
}
export default ExploreMore;
