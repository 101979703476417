// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/cart/cart-icon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ccp-header {
    background-color: var(--uPCBlue);
    height: 390px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.ccp-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--uPCBlue);
    text-align: center;
}

.ccp-title h2 {
    text-transform: uppercase;
    font-size: 55px;
    color: var(--dBlue);
    font-weight: 700;
    padding: 10px 45px;
}

@media (max-width: 767px) {
    .ccp-title {
        width: 85%;
    }

    .ccp-title h2 {
        font-size: 45px;
        padding: 10px 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/common/cart-checkout-pay-header/CCPHeader.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,aAAa;IACb,yDAAmE;IACnE,wBAAwB;IACxB,4BAA4B;IAC5B,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".ccp-header {\r\n    background-color: var(--uPCBlue);\r\n    height: 390px;\r\n    background-image: url(../../../../public/images/cart/cart-icon.png);\r\n    background-size: contain;\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n    position: relative;\r\n}\r\n\r\n.ccp-title {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    background-color: var(--uPCBlue);\r\n    text-align: center;\r\n}\r\n\r\n.ccp-title h2 {\r\n    text-transform: uppercase;\r\n    font-size: 55px;\r\n    color: var(--dBlue);\r\n    font-weight: 700;\r\n    padding: 10px 45px;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .ccp-title {\r\n        width: 85%;\r\n    }\r\n\r\n    .ccp-title h2 {\r\n        font-size: 45px;\r\n        padding: 10px 0px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
