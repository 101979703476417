import { Link } from "react-router-dom";
import "./Banner.css";
import "./BannerPrimaryBundle.css";
function Banner() {
  return (
    <div>
      <section className="bundle_india px-lg-5 pb-4">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-4 col-lg-7 col-12 order-tab order-1 order-lg-0">
              <h1 className="banner_txt psb-banner-head dBlue">
                Primary School
                <br />
                <span className="dPink"> Bundle</span>
              </h1>
              <h2 className="psb-banner-para">
                A comprehensive AI & Robotics Education platform for your school
              </h2>
            </div>
            <div className="col-md-8 col-lg-5 col-12">
              <div className="banner_img psb-banner-img">
                <img
                  src="images/SchoolBundle/India/primary-banner.png"
                  alt="Hello"
                />
                {/* <img
                  src="images/SchoolBundle/India/primary-banner.png"
                  alt="Hello"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Banner;
