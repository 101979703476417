
import { createSlice } from "@reduxjs/toolkit";

const milestoneReducer = createSlice({
    name: 'milestone',
    initialState: {
        milestones: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All milestones
        getMilestoneStart: (state) => {
            state.isFetching = true;
        },
        getMilestoneSuccess: (state, action) => {
            state.isFetching = false;
            state.milestones = action.payload;
        },
        getMilestoneFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    },
});

export const { getMilestoneStart, getMilestoneSuccess, getMilestoneFailure } = milestoneReducer.actions;
export default milestoneReducer.reducer;