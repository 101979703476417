import "bootstrap/dist/css/bootstrap.css";
import "../../styles.css";
import "./ProductOverview.css";
import { Container, Row, Col } from "react-bootstrap";
import RenderHTMLContent from "../../../RenderHTMLContent";
import OwlCarousel from "react-owl-carousel";
import { MEDIA_URL } from "../../../config";

const ProductOverview = (props) => {
  return (
    <Container>
      <Row>
        <Col lg={12} className="p-0">
          <div className="overview-heading-color heading">
            <h1>Overview</h1>
          </div>
        </Col>
        <Col lg={5} sm={12} className="p-0">
          <div className="course-overview">
            <RenderHTMLContent htmlContent={props.overView} />
          </div>
        </Col>
        <Col lg={{ span: 5, offset: 1 }} sm={12}>
          <div className="overview-img-slider-wrapper">
            <OwlCarousel items={1} className="owl-theme" loop nav>
              {props.overViewImages?.map((item) => {
                return (
                  <div className="overview-img">
                    <img src={MEDIA_URL + item} alt="overview-img" />
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ProductOverview;
