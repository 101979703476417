import "bootstrap/dist/css/bootstrap.css";
import "../../styles.css";
import "../cards/SkillsCard.css";
import { Card } from "react-bootstrap";

const SkillsCard = (CardData) => {
  return (
    <Card className="skills-card">
      <div className="skills-card-icon text-center">
        <Card.Img variant="top" src={CardData.CardImg} />
      </div>
      <Card.Body className="card-content">
        <Card.Title className="skills-card-title text-center fw-500">
          <h2>
            {CardData.CardTitle}{" "}
            <span> {CardData.CardPinkTitle} </span>
          </h2>
        </Card.Title>
        <Card.Text className="skills-card-text">
          <p>{CardData.CardText}</p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default SkillsCard;
