import React from 'react';
import './OrderFailedWithPayment.css'
import { clearCart } from '../../redux/cartRedux';
import { useDispatch } from 'react-redux';

const OrderFailedWithPayment = () => {
    const dispatch = useDispatch();
    dispatch(clearCart());
  return (
    <div>
      <h1>Payment Failed!</h1>
    </div>
  )
}

export default OrderFailedWithPayment
