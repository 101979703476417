import "./Job.css";
import { BsArrowUpRight } from "react-icons/bs";
const Jobs = ({ jobTitle, jobInfo, jobTypes }) => {
  return (
    <div className="container">
      <div className="job-container">
        <div className="job-element">
          <span className="job-title fw-700 pSize pb-4">{jobTitle}</span>
          <span className="fw-700 pSize">
            View Job <BsArrowUpRight /> <i className="fas fa-arrow-right"></i>
          </span>
        </div>
        <div className="job-details">
          <div className="job-info">
            <h2 className="job-info-title f-20 dBlue fw-700 text-uppercase">
              {jobInfo.title}
            </h2>
            <p className="job-info-description pSize fw-400">
              {jobInfo.description}
            </p>
          </div>
          <div className="job-type d-flex">
            {jobTypes.map((type, index) => (
              <div className="job-type-section" key={index}>
                <h3 className="job-type-title fw-700 pSize">{type}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
