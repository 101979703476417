import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy ">
      <section className="privacy_first">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 position-relative">
              <img src="images/privacy.png" />
              <h1>
                Privacy <span>Policy</span>
              </h1>
            </div>
            <div className="col-lg-6">
              <p>
                This Privacy Policy section explains what information will be
                collected by Meritus (“Meritus” “we,” “us,” or “our”) when you
                access our websites, software, Laptop/Desktop/cloud
                applications, Tab/mobile applications, connected products or
                related services (“Product” or “Service”) and how the
                information will be used. We will not use or share your
                information with anyone except as described in this Privacy
                Policy. Each time you use our Service you are accepting the
                practices described in this Privacy Policy, as applicable, at
                that time. Meritus respects and protects the privacy of the
                individuals that access the information and use the services
                provided through them.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section pt-5 mt-4">
          <h2 className="">
            Information <span>Collection & Use</span>
          </h2>
          <p>
            Meritus is the sole owner of the Personal Information collected
            through our Web Sites or in connection with the Services.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Data Collected from <span>Registered Users</span>
          </h2>
          <p>
            We need to collect Personal Information such as name, phone number,
            email address, state, and country in order for you to register, to
            make payments, to use our Services or to respond to your requests
            for information that you may submit to us through the Web Site. We
            use this information to process your requests, to administer the
            Services and to provide information to you relating to our Services.
            We use this information to analyze interest in the Web Site and to
            improve the information we make available through the Web Sites. You
            should always review, and if necessary, adjust your privacy settings
            on third-party websites and services before linking or connecting
            them to the Meritus Service or website. User-generated
            content/audio/video using our mobile/laptop/tablet application is
            not monitored by us. For more details on our data collection
            practices for Children Users, please see the section on Children’s
            Privacy.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">Cookies</h2>
          <p>
            Cookies are files with a small amount of data, which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            web site and transferred to your device. We use cookies to collect
            information in order to improve our services for you. You can
            instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. The Help feature on most browsers provides
            information on how to accept cookies, disable cookies or to notify
            you when receiving a new cookie. If you do not accept cookies, you
            may not be able to use some features of our Service and we recommend
            that you leave them turned on.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Log <span>Data</span>
          </h2>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service ("Log Data"). This Log Data may include
            information such as your computer's Internet Protocol ("IP")
            address, browser type, browser version, and the pages of our Service
            that you visit, the time and date of your visit, the time spent on
            those pages and other statistics. In addition, we may use third
            party services such as Google Analytics, Facebook analytics etc.
            that collect, monitor and analyze this type of information in order
            to increase our Service's functionality. These third party service
            providers have their own privacy policies addressing how they use
            such information.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Service <span> Providers</span>
          </h2>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service, to provide the Service on our behalf, to perform
            Service-related services and/or to assist us in analyzing how our
            Service is used. These third parties have access to your Personal
            Information only to perform specific tasks on our behalf and are
            obligated not to disclose or use your information for any other
            purpose.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">Communications</h2>
          <p>
            We may use your Personal Information to contact you with
            newsletters, marketing or promotional materials and other
            information that may be of interest to you. You may opt out of
            receiving any, or all, of these communications from us by following
            the unsubscribe link or instructions provided in any email we send.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            International <span>Transfer</span>
          </h2>
          <p>
            Your information, including Personal Information, may be transferred
            to any country and maintained on computers located outside of your
            state, province, country or other governmental jurisdiction where
            the data protection laws may differ from those from your
            jurisdiction. We take appropriate steps to ensure that recipients of
            your Personal Data are bound to duties of confidentiality and we
            implement measures such as standard contractual clauses.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Compliance <span>with Law</span>
          </h2>
          <p>
            We will disclose your Personal Information where required to do so
            by law or if we believe that such action is necessary to comply with
            the law and the reasonable requests of law enforcement or to protect
            the security or integrity of our Service.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Business <span>Transaction</span>
          </h2>
          <p>
            Your personal information may be transferred as a business asset. In
            such cases where required, we will provide notice before your
            Personal Information is transferred and/or becomes subject to a
            different Privacy Policy
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Children’s <span>Privacy Policy</span>
          </h2>
          <p>
            Please note that while our services & products are targeted and
            aimed to be used by children as students studying in our Meritus
            Academy (Under the age of 18) or using our Meritus web services,
            Only persons age 18 or older under the category of parents, legal
            guardians, or other adults are allowed to make payments & or
            purchases on our website. Meritus makes reasonable efforts to ensure
            its website/apps comply with the Children’s Online Privacy
            Protection Act. Meritus does not knowingly collect information from
            children as defined by local law, and does not target its websites
            or mobile applications to children. If you are a parent or guardian
            and believe that a child under 13 might have provided us with
            Personal Information without your permission, or if you wish to
            view, request deletion of, or prohibit further collection of
            information about your child, please contact us at admin@meritus.ai.
            We urge parents to monitor their children’s use of all digital
            media.
          </p>
          <p>
            Meritus respects and protects the privacy of the individuals that
            access the information and use the services provided through them.
            Individually identifiable information about the User is not wilfully
            disclosed to any third party without first receiving the User’s
            permission, as covered in this Privacy Policy.
          </p>
          <p>
            Meritus respects and protects the privacy of the Users of Meritus
            Forum. Meritus as a policy asks its 3rd party vendors & service
            providers to guarantee data security & respect privacy concerns and
            parents are advised to bring to notice of any problem or issue they
            may have noticed or heard of.
          </p>
          <p>
            Users of Meritus web services must guarantee data security of the
            web portal. Users must respect and protect the privacy of the
            Meritus, its offline center, its web services, its staff, its
            teachers, its management, fellow students, fellow users, alumni and
            parents.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">
            Contact <span>Details</span>
          </h2>
          <h6 className="fw-700">
            MERITUS AI LEARNING EXPERIENCES PRIVATE LIMITED
          </h6>
          <p>
            Door.No.44, 2nd floor, First Main Rd, Gandhi Nagar, Chennai, 600020
            India <br /> Email ID : admin@meritus.ai
          </p>
          <p>
            <b>
              Contact us between 10.00 a.m.-6.00 p.m India time. Monday – Friday
            </b>
          </p>
        </div>
      </section>
      <section className="container">
        <div className="privacy_section">
          <h2 className="">IMPORTANT</h2>
          <p>
            By accessing and using the Meritus web services through the login
            section of the online web portal website you are agreeing to all the
            terms and conditions including any updated changes in terms and
            conditions from time to time through our website. By submitting a
            payment through the online-payment gateway of the website you are
            agreeing to all the terms and conditions including any updated
            changes in terms and conditions from time to time through our
            website.
          </p>
        </div>
      </section>
      <section className="container pb-5 pb-4">
        <div className="privacy_section">
          <h2 className="">
            Governing <span>Law</span>
          </h2>
          <p>
            Jurisdiction. This Agreement shall be governed by, and construed and
            interpreted in accordance with, the laws of India under the
            exclusive jurisdiction of the High Courts of Madras at Chennai,
            Tamil Nadu, India.
          </p>
          <p>
            By accepting the Privacy Policy and Terms of Use through our web
            services, you are agreeing to all the terms and conditions of the
            contract of agreement including any updated changes from time to
            time and also the Payment policy, Money back Guarantee and
            Scholarships policies. I/we agree and acknowledge that I/we have
            entered into this full and final settlement on my/our own free will
            and volition. Further I/we agree that we were not coerced to do so
            not under duress at the time of executing and signing this Privacy
            Policy and Terms of Use document. I/We agree that accepting the
            conditions and signing of this agreement is being entered into
            voluntarily and knowingly. I/We have read this and understood the
            above conditions. I/We accept and agree to abide by the Terms and
            conditions of this Privacy Policy and Terms of Use agreement.
          </p>
        </div>
      </section>
    </div>
  );
};
export default PrivacyPolicy;
