import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import TermOfUse from "./pages/TermOfUse.jsx";
import ContactUsPage from "./pages/ContactUsPage.jsx";
import ComingSoon from "./pages/ComingSoon.jsx";
import LifeAt from "./pages/LifeAt";
import LatestNews from "./pages/LatestNews";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import AboutUsPage from "./pages/AboutUsPage";
import Footer from "./component/Footer.jsx";
import Events from "./pages/NewsAndEvents";
import Career from "./pages/Career";
import Login from "./pages/Login";
import ProductPage from "./component/product-page/ProductPage";
import UserProfile from "./component/user-profile/UserProfile";
import Cart from "./component/cart-pages/Cart";
import Register from "./pages/register/Register";
import UserComponents from "./component/privateComponent/UserComponents";
import NonUserComponents from "./component/privateComponent/NonUserComponents";
import Checkout from "./component/checkout-page/Checkout";
import OrderDetails from "./component/order-details-pg/OrderDetails";
import MyWishlist from "./component/user-profile/MyWishlist";
import EditProfile from "./component/user-profile/EditProfile";
import TutorialVideos from "./component/course-page/TutorialVideos";
import LandingPage from "./landingPage/landing1/LandingPage";
import PastEvents from "./pages/PastEvents";
import TestimonialPage from "./pages/TestimonialPage";
import UpcomingEvents from "./pages/UpcomingEvents";
import Quiz from "./Quiz/Quiz";
import CareerInternal from "./pages/CareerInternal";
import Header3 from "./component/Header3";
import ScrollToTop from "./component/common/ScrollToTop";
import Blog from "./pages/Blog";
import io from "socket.io-client";
import { useEffect, useState } from "react";
import { publicRequest, userRequest } from "./requestMethod";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "./redux/store";
import { logoutUser } from "./redux/userRedux";
import { clearCart } from "./redux/cartRedux";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import CheckoutComponents from "./component/privateComponent/CheckoutComponents";
import News from "./pages/News";
import CheckTokenExpiration from "./component/privateComponent/CheckTokenExpiration";
import jwtDecode from "jwt-decode"; // A library for decoding JWT tokens
import OfflineMessage from "./pages/OfflineMessage";
import CategoryBlogs from "./pages/CategoryBlogs";
import ScrollToHashElement from "./component/common/ScrollToHashElement";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import ProductPageKit from "./component/product-page/ProductPageKit";
import Robotix from "./pages/robotix/Robotix";
// import SingleEvent from "./pages/SingleEvent";
import Event from "./pages/Event";
import NewsAndEvents from "./pages/NewsAndEvents";
import SingleNews from "./pages/SingleNews";
import AllCourse from "./pages/AllCourse";
import TeacherPdp from "./pages/TeacherPdp";
import SchoolBundleIndia from "./pages/SchoolBundleIndia";
import LearnAI from "./pages/learn-ai/LearnAI";
import StemShop from "./pages/stem-shop/StemShop";
import Phiro from "./pages/indivdual-product-pages/phiro/Phiro";
import TaCoRobobricks from "./pages/indivdual-product-pages/TaCo-Robobricks/TaCoRobobricks";
import TaCoPlaybits from "./pages/indivdual-product-pages/TaCo-Playbits/TaCoPlaybits";
import CSTEM from "./pages/indivdual-product-pages/CSTEM/CSTEM";
import CVProCompetitonKit from "./pages/indivdual-product-pages/CV Pro Competion Kit/CVProCompetitonKit";
import CVPro from "./pages/indivdual-product-pages/CV Pro/CVPro";
import STEMMakerKit from "./pages/indivdual-product-pages/STEM Maker Kit/STEMMakerKit";
import SchoolBundle from "./pages/SchoolBundle";
import PrimarySchoolBundleOutside from "./pages/outside-india-bundle/PrimarySchoolBundleOutside";
import MiddleSchoolBundleOutside from "./pages/outside-india-bundle/MiddleSchoolBundleOutside";
import HighSchoolBundleOutside from "./pages/outside-india-bundle/HighSchoolBundleOutside";
import PrimarySchoolBundle from "./pages/india-bundle/PrimarySchoolBundle";
import MiddleSchoolBundle from "./pages/india-bundle/MiddleSchoolBundle";
import HighSchoolBundle from "./pages/india-bundle/HighSchoolBundle";
import FlexiblePlan1Page from "./pages/india-bundle/Flexible/FlexiblePlan1Page";
import FlexiblePlan2Page from "./pages/india-bundle/Flexible/FlexiblePlan2Page";
import FlexiblePlan3Page from "./pages/india-bundle/Flexible/FlexiblePlan3Page";
import Certificate from "./component/certificate/Certificate";
import axios from "axios";
import AllCategoryBlog from "./pages/allCategoryBlog/AllCategoryBlog.jsx";
import { getAllRatings, getAllWishlistProducts, getProducts } from "./redux/apiCalls.js";
import CheckInternetConnection from "./component/checkInternetConnection/CheckInternetConnection.jsx";
import OrderSuccessWithPayment from "./pages/order-success/OrderSuccessWithPayment.jsx";
import OrderFailedWithPayment from "./pages/order-failed/OrderFailedWithPayment.jsx";
import OrderSuccessWithoutPayment from "./pages/order-success/OrderSuccessWithoutPayment.jsx";
import TokenExpirationInterceptor from "./component/global/TokenExpirationInterceptor.jsx";
import ThankYouPg from "./pages/thank-you-pg/ThankYouPg.jsx";
import { getUserDetails } from "./config.js";
import Error404 from "./pages/404-page/Error404.jsx";
import FlipBook from "./pages/flipbook/FlipBook.jsx";

function App() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const [ipAddress, setIpAddress] = useState(null);
  const [userLocation, setUserLocation] = useState("India");

  const handleClose = () => setIsTokenExpired(false);

  // const currentUser = useSelector((state) => state.loginUser.currentUser);
  // const accessToken = currentUser && currentUser.accessToken;

  const accessToken = Cookies.get("userIn");

  // const checkTokenValidity = async () => {

  //   if (accessToken) {
  //     try {
  //       const response = await userRequest.get("/general/token-expiration");
  //       // Token is valid, you can set the user data in your app state
  //     } catch (error) {
  //       if (error && error.response?.status === 401) {
  //         console.error("Token verification failed:", error.response.data);
  //         setIsTokenExpired(true);
  //         Cookies.remove("userIn");
  //         // localStorage.removeItem("persist:root");
  //         dispatch(logoutUser());
  //         // <Navigate to='/login' />
  //       }
  //     }
  //   } else {
  //     // Token doesn't exist, user is not authenticated, handle the logic here (e.g., redirect to login page)
  //     // <Navigate to='/login' />
  //   }
  // };

  // useEffect(() => {
  //   checkTokenValidity(); // Initial check
  // }, [accessToken]);

  // setInterval(() => {
  //   checkTokenValidity(); // Periodic check
  // }, 6000); // Check every 60 seconds (adjust as needed)

  // useEffect(() => {
  //   checkTokenValidity();
  // }, [accessToken]);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    getAllRatings(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // if(user){
    getProducts(dispatch)
    // }
  }, [])
  // useEffect(() => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // You can send this information to your server or perform any other actions.
      },
      (error) => {
        console.error(`Error getting location: ${error.message}`);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
  // },[])

  return (
    <>
      <TokenExpirationInterceptor />
      <div className="App">
        {/* {isOnline ? ( */}
        <>
          <ScrollToTop />
          {/* <ScrollToHashElement /> */}
          <Header3 />
          <CheckInternetConnection>

            <Routes>
              {/* <Route path="/OrderDetails" element ={<OrderDetails />} /> */}
              <Route element={<NonUserComponents />}>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/register" element={<Register />}></Route>
              </Route>

              <Route element={<UserComponents />}>
                {/* <Route element={<CheckoutComponents />}> */}
                <Route path="/checkout" element={<Checkout />}></Route>
                {/* </Route> */}
                <Route path="/my-profile" element={<UserProfile />} />
                <Route path="/edit-profile/:id" element={<EditProfile />} />
                <Route path="/cart" element={<Cart />}></Route>
                <Route path="/User" element={<UserProfile />} />
                <Route
                  path="/OrderDetails/:id"
                  element={<OrderDetails />}
                ></Route>
                <Route path="/wishlist" element={<MyWishlist />}></Route>
                <Route path="/course/:id" element={<TutorialVideos />}></Route>
                <Route
                  // exact
                  path="/order-success/status"
                  element={<OrderSuccessWithoutPayment />}
                ></Route>
                <Route
                  exact
                  path="/payment/status"
                  element={<OrderSuccessWithPayment />}
                ></Route>
                <Route
                  exact
                  path="/payment/failed"
                  element={<OrderFailedWithPayment />}
                ></Route>
              </Route>
              {/* <Route element={<CheckTokenExpiration />} /> */}

              {/* ======Public routes start============ */}
              <Route exact path="/" element={<Home />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/forgot-passowrd" element={<ForgotPassword />}></Route>
              <Route path="/product/:title" element={<ProductPage />}></Route>
              <Route path="/product/:title" element={<ProductPage />}></Route>
              <Route path="/robotix" element={<Robotix />}></Route>
              <Route
                path="/robotix-product/:title"
                element={<ProductPage />}
              ></Route>
              <Route path="/404" element={<Error404 />}></Route>
              <Route path="/all-courses" element={<AllCourse />}></Route>
              <Route path="/LearnAI" element={<LearnAI />}></Route>
              <Route path="/Stem-shop" element={<StemShop />}></Route>
              <Route path="/AboutUsPage" element={<AboutUsPage />}></Route>
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
              <Route path="/TermOfUse" element={<TermOfUse />}></Route>
              <Route path="/LifeAt" element={<LifeAt />}></Route>
              <Route path="/TeacherPdp" element={<TeacherPdp />}></Route>
              <Route
                path="/SchoolBundleIndia"
                element={<SchoolBundleIndia />}
              ></Route>
              <Route path="/Phiro" element={<Phiro />}></Route>
              <Route path="/Robobricks" element={<TaCoRobobricks />}></Route>
              <Route path="/Playbits" element={<TaCoPlaybits />}></Route>
              <Route path="/CSTEM" element={<CSTEM />}></Route>
              <Route
                path="/CVProCompetitonKit"
                element={<CVProCompetitonKit />}
              ></Route>
              <Route path="/StemMakerKit" element={<STEMMakerKit />}></Route>
              <Route path="/CVPro" element={<CVPro />}></Route>
              <Route path="/certificate" element={<Certificate />}></Route>
              <Route path="/Events" element={<Events />}></Route>
              <Route path="/Career" element={<Career />}></Route>
              <Route path="/broucher" element={<FlipBook />}></Route>

              {/* <Route component={() => <Redirect to="/login" />} /> */}

              {/* ======Public routes end============ */}
              <Route path="/thank-you" element={<ThankYouPg />}></Route>
              <Route path="/contact-us" element={<ContactUsPage />}></Route>
              <Route path="/contact-us/:title" element={<ContactUsPage />}></Route>
              <Route path="/Events" element={<NewsAndEvents />}></Route>
              <Route path="/event/:id" element={<Event />}></Route>
              <Route path="/Career" element={<Career />}></Route>
              <Route path="/blog/:id" element={<Blog />}></Route>
              <Route path="/news/:id" element={<SingleNews />}></Route>
              <Route path="/LandingPage" element={<LandingPage />}></Route>
              <Route path="/PastEvents" element={<PastEvents />}></Route>
              <Route
                path="/TestimonialPage"
                element={<TestimonialPage />}
              ></Route>
              <Route path="/UpcomingEvents" element={<UpcomingEvents />}></Route>
              <Route path="/Quiz" element={<Quiz />}></Route>
              <Route path="/CareerInternal" element={<CareerInternal />}></Route>
              <Route path="/ComingSoon" element={<ComingSoon />}></Route>
              <Route
                path="/category-blog/:title"
                element={<CategoryBlogs />}
              ></Route>
              <Route path="/category-blog" element={<AllCategoryBlog />}></Route>
              <Route
                path="/PrimarySchoolBundle"
                element={<PrimarySchoolBundle />}
              ></Route>
              <Route
                path="/MiddleSchoolBundle"
                element={<MiddleSchoolBundle />}
              ></Route>
              <Route
                path="/HighSchoolBundle"
                element={<HighSchoolBundle />}
              ></Route>
              <Route path="/SchoolBundle" element={<SchoolBundle />}></Route>
              <Route
                path="/PrimarySchoolBundleOutside"
                element={<PrimarySchoolBundleOutside />}
              ></Route>
              <Route
                path="/MiddleSchoolBundleOutside"
                element={<MiddleSchoolBundleOutside />}
              ></Route>
              <Route
                path="/HighSchoolBundleOutside"
                element={<HighSchoolBundleOutside />}
              ></Route>
              <Route
                path="/flexible-plan-1"
                element={<FlexiblePlan1Page />}
              ></Route>
              <Route
                path="/flexible-plan-2"
                element={<FlexiblePlan2Page />}
              ></Route>
              <Route
                path="/flexible-plan-3"
                element={<FlexiblePlan3Page />}
              ></Route>
              <Route
                path="/page-not-found"
                element={<Error404 />}
              ></Route>
              <Route path="*" element={<Navigate to="/page-not-found" replace />} />
            </Routes>
          </CheckInternetConnection>
          {/* <FixedBtns /> */}
          <Footer />
        </>
        {/* </TokenExpirationInterceptor> */}
      </div>
    </>
  );
}
export default App;
