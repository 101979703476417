import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HTMLFlipBook from "react-pageflip";
import "./FlipBook.css";

function FlipBook({ totalPages }) {
  const [currentPage, setCurrentPage] = useState(1); // Track current page

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      // Now `totalPages` should be defined }
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <section className="flipbook-section section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="flipbook-container">
                <HTMLFlipBook
                  width={460}
                  height={650}
                  // size="stretch"
                  minWidth={315}
                  maxWidth={1000}
                  minHeight={400}
                  maxHeight={1533}
                  maxShadowOpacity={0.5}
                  showCover={true}
                  mobileScrollSupport={true}
                  className="flipbook-wrapper"
                >
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-1.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-2.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-3.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-4.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-5.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-6.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-7.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-8.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-9.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-10.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-11.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-12.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-13.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-14.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-15.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-16.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-17.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-18.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-19.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-20.png`}
                      alt=""
                    />
                  </div>
                </HTMLFlipBook>
                <div className="flipbook-wrapper">
                  <Row>
                    <div className="col-12 col-lg-6">
                      <p>
                        <i>Click or swipe left for previous page.</i>
                      </p>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="fb-caption">
                        <p>
                          <i>Click or swipe right for next page.</i>
                        </p>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="flipbook-section-mob section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="flipbook-container">
                <HTMLFlipBook
                  width={550}
                  height={773}
                  size="stretch"
                  minWidth={315}
                  maxWidth={1000}
                  minHeight={400}
                  maxHeight={1533}
                  maxShadowOpacity={0.5}
                  showCover={true}
                  mobileScrollSupport={true}
                  className="flipbook-wrapper"
                >
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-1.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-2.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-3.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-4.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-5.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-6.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-7.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-8.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-9.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-10.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-11.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-12.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-13.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-14.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-15.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-16.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-17.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-18.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-19.png`}
                      alt=""
                    />
                  </div>
                  <div className="demoPage">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flipbook-imgs/meritus-ai-20.png`}
                      alt=""
                    />
                  </div>
                </HTMLFlipBook>
                <div className="flipbook-wrapper flipbook-wrapper-mob">
                  <div className="fb-left-txt">
                    <p>
                      <i>Click on left for previous page.</i>
                    </p>
                  </div>
                  <div className="fb-caption">
                    <p>
                      <i>Click on right for next page.</i>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default FlipBook;
