import React, { useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginFailure, loginStart, loginSuccess } from "../redux/userRedux";
import { publicRequest } from "../requestMethod";
import { FaUnlockAlt, FaUserCog } from "react-icons/fa";
import Cookies from 'js-cookie';
import WelcomePopup from "../component/common/popup/WelcomePopup";
import { validateRequired } from "../formValidation";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userNotFoundError, setUserNotFoundError] = useState(false);
  const [userNotFoundErrorMessage, setUserNotFoundErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [userName, setUserName] = useState('');
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const login = async (dispatch, user) => {
    dispatch(loginStart());
    setIsLoaderActive(true);
    try {
      const res = await publicRequest.post("/auth/login", user);
      dispatch(loginSuccess(res.data));
      setIsLoaderActive(false);
      // Cookies.set('userIn', res.data.accessToken, { expires: 3 });
      localStorage.setItem('popupDisplayed', 'true');
      Cookies.set('userIn', res.data.accessToken, { domain: '.meritus.ai', expires: 3, sameSite: 'None', secure: true});
      <Navigate to="/" />;
    } catch (error) {
      setIsLoaderActive(false);
      dispatch(loginFailure());
      if (error.response) {
        if (error.response.status === 404) {
          setUserNotFoundError(true);
          setUserNotFoundErrorMessage(error.response.data);
        }

        if (error.response.status === 401) {
          setPasswordError(true);
          setPasswordErrorMessage(error.response.data);
        }
      }
    }
  };


  // Validate function for form validation start=======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const emailValidErr = validateRequired(email);
    const passwordValidErr = validateRequired(password);

    if (emailValidErr) {
      formIsValid = false;
      newErrors.email = "Please enter your email";
    }
    if (passwordValidErr) {
      formIsValid = false;
      newErrors.password = "Please enter your password";
    }

    setErrors(newErrors);
    return formIsValid;
  }

  const handleLogin = async () => {
    if (validateForm()) {
      setUserNotFoundError(false);
      setPasswordError(false);
      login(dispatch, { email, password });
    }

  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && validateForm()) {
      handleLogin();
    }
  };

  const user = useSelector((state) => state.loginUser.currentUser);

  return (
    <>
      {/* <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <div className="login-topbar">
                            <div className="loginPageLogoWrapper">
                                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
                            </div>
                        </div>
                        <div className="login-announcement-bar">
                            <p>Kindly enter credentials to login</p>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="container pb-50">
        <div className="row d-flex align-items-center">
          <div className="col-md-6">
            <img src="../../images/pages/login.png" />
          </div>
          <div className="col-md-6">
            <div className="loginContainer">
              <div className="login-wrapper">
                <h1 className="text-uppercase my-5">
                  LogIn or <span>Register</span>
                </h1>
                <div className="w-100">
                  <label for="fullName" class="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    onKeyDown={handleKeyPress}
                  />
                  <p className="error-text text-danger mb-0 px-3 py-2">
                    {errors.email}
                  </p>
                  {userNotFoundError && (
                    <p className="error-text text-danger">
                      {userNotFoundErrorMessage}
                    </p>
                  )}
                </div>
                <div className="w-100">
                  <label for="fullName" class="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    name=""
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    onKeyDown={handleKeyPress}
                  />
                  <p className="error-text text-danger mb-0 px-3 py-2">
                    {errors.password}
                  </p>
                  {passwordError && (
                    <p className="error-text text-danger">
                      {passwordErrorMessage}
                    </p>
                  )}
                </div>
                <div className="login-btn-wrapper position-relative">
                  <div className="coupon-btn-wrapper ">
                    <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={handleLogin}><span>Login</span></button>
                  </div>
                  {/* <button type="submit" onClick={handleLogin}>
                    Login
                  </button> */}
                  <Link to={"/register"}>
                    <button type="submit">Register</button>
                  </Link>
                </div>
                <div className="forgot-link-wrapper text-center">
                  {/* <Link >register now</Link> */}
                  <Link to={'/forgot-passowrd'} className="text-capitalize">forgot password?</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="pb-100 pt-100 loginPage_login_signup">
        <div class="row d-flex justify-content-center m-5">
          <div class="col-md-4">
            <div class="text-center user-login-wrapper">
              <Link to={'/login'}>
                <div class="loginPage_icons">
                  <div>
                    <FaUnlockAlt size={40} />
                  </div>
                  <h5>Login</h5>
                  <p>Sign in to Your Account</p>
                </div>
              </Link>
            </div>
          </div>
          <div class="col-md-4">
            <div class="text-center">
              <Link to={'/register'}>
                <div class="loginPage_icons">
                  <div>
                    <FaUserCog size={40} />
                  </div>
                  <h5>Register</h5>
                  <p>Start Your Journey Here</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;