// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_page_form input,
.home_page_form textarea {
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 15px;
  border: 2px solid #c3c5f8;
}
.home_page_form button {
  padding: 10px 25px;
  background: var(--dBlue);
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  border-radius: 0;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 30px;
}
.home_form_btn {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 10px auto auto;
  text-align: center;
}
.col-md-7.home_page_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 0px 50px;
}
.home_page_form_row .form-group {
  margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/component/home/ContactUs.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,0BAAkB;EAAlB,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".home_page_form input,\r\n.home_page_form textarea {\r\n  padding: 10px;\r\n  width: 100%;\r\n  border: none;\r\n  border-radius: 15px;\r\n  border: 2px solid #c3c5f8;\r\n}\r\n.home_page_form button {\r\n  padding: 10px 25px;\r\n  background: var(--dBlue);\r\n  border: none;\r\n  color: #fff;\r\n  font-weight: 700;\r\n  font-size: 14px;\r\n  letter-spacing: 2px;\r\n  border-radius: 0;\r\n  text-transform: uppercase;\r\n  cursor: pointer;\r\n  border-radius: 30px;\r\n}\r\n.home_form_btn {\r\n  display: block;\r\n  width: fit-content;\r\n  text-align: center;\r\n  margin: 10px auto auto;\r\n  text-align: center;\r\n}\r\n.col-md-7.home_page_form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  /* align-items: center; */\r\n  padding: 0px 50px;\r\n}\r\n.home_page_form_row .form-group {\r\n  margin-top: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
