import { useState } from "react";
import { MEDIA_URL, changeDateFormat } from "../../config";
import "./MilestoneCard.css";
const MilestoneCard = ({ milestoneImage, title, createdAt, description }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncatedDescription = showFullDescription
    ? description
    : `${description.slice(0, 80)}...`;

  return (
    <div className="milestone-card">
      <img
        src={MEDIA_URL + milestoneImage}
        alt="Milestone"
        className="milestone-image"
      />
      <h2 className="milestone-title f-20 fw-700">{title}</h2>
      <p className="milestone-date">{changeDateFormat(createdAt)}</p>
      <p className="milestone-paragraph">{truncatedDescription}</p>
      {description.length > 80 && (
        <div className="rm-rl-wrapper text-end">
          <button className="read-more-less-btn" onClick={toggleDescription}>
            {showFullDescription ? "Read less" : "Read more..."}
          </button>
        </div>
      )}
    </div>
  );
};

export default MilestoneCard;
