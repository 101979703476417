import "./Header3.css";
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiUser, CiHeart, CiShoppingCart } from "react-icons/ci";
import { GrPowerShutdown } from "react-icons/gr";
import { persistor } from "../redux/store";
import { logoutUser } from "../redux/userRedux";
import { clearCart } from "../redux/cartRedux";
import { clearOrder } from "../redux/orderRedux";
import { clearRegistraionState } from "../redux/registeredUserRedux";
import Cookies from "js-cookie";
import { getAllCartProducts, getAllWishlistProducts } from "../redux/apiCalls";
const Header3 = () => {

  const user = useSelector((state) => state.loginUser.currentUser);
  // const user = Cookies.get('userIn');
  const dispatch = useDispatch();
  // const cartCount = useSelector((state) => state.cart.quantity);
  const cartCount = useSelector((state) => state.cart.cartItems);
  const wishListCount = useSelector((state) => state.wishList.wishListItems);
  const [activeProfilePopup, setActiveProfilePopup] = useState(false);


  const state = useSelector((state) => state);

  // useEffect(() => {
  //   if (user) {
  //     getAllWishlistProducts(dispatch, user._id)
  //   }
  // }, [user])

  // =======updated hide
  // useEffect(() => {
  //   if (user) {
  //     getAllCartProducts(dispatch, user._id)
  //   }
  // }, [user]);



  const menuBtnRef = useRef(null);
  const menuItemsRef = useRef(null);

  useEffect(() => {
    const menuBtn = $(menuBtnRef.current);
    const menuItems = $(menuItemsRef.current);

    const toggle = () => {
      $("body").toggleClass("overflow");
      $(".overlay").toggleClass("overlay--active");
      menuBtn.toggleClass("open");
      menuItems.toggleClass("open");
    };

    menuBtn.on("click", (e) => {
      e.stopPropagation();
      toggle();
    });

    $(window).on("keydown", (event) => {
      const key = event.key;
      const active = menuItems.hasClass("open");
      if (key === "Escape" && active) {
        toggle();
      }
    });

    $(document).on("click", (e) => {
      const target = e.target;
      const itsMenu =
        target === menuItems[0] || $.contains(menuItems[0], target);
      const itsHamburger = target === menuBtn[0];
      const menuIsActive = menuItems.hasClass("open");
      if (!itsMenu && !itsHamburger && menuIsActive) {
        toggle();
      }
    });

    $(".expand-btn").on("click", function () {
      $(this).toggleClass("open");
    });

    return () => {
      menuBtn.off("click");
      $(window).off("keydown");
      $(document).off("click");
      $(".expand-btn").off("click");
    };
  }, []);

  document.addEventListener("DOMContentLoaded", function () {
    var tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach(function (element) {
      new bootstrap.Tooltip(element);
    });
  });

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current) {
        if (!menuRef.current.contains(e.target)) {
          setActiveProfilePopup(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [menuRef]);

  const handleLogout = () => {
    Cookies.remove("userIn");
    localStorage.removeItem("popupDisplayed");
    dispatch(logoutUser());
    dispatch(clearCart());
    dispatch(clearOrder());
    dispatch(clearRegistraionState());
  };






  // const handleLogout = () => {
  //   Cookies.remove("userIn");
  //   persistor.purge().then(() => {
  //     dispatch(logoutUser());
  //     dispatch(clearCart());
  //     dispatch(clearOrder());
  //     dispatch(clearRegistraionState());
  //   });
  // };

  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    // Check if the flag exists in localStorage
    const popupDisplayed = localStorage.getItem("popupDisplayed");

    // If the flag is present in localStorage, hide the popup initially
    if (popupDisplayed) {
      setShowPopup(false);
    } else {
      // If the flag is not in localStorage, show the popup initially
      setShowPopup(true);

      // Set the flag in localStorage to prevent showing the popup on future refreshes
      localStorage.setItem("popupDisplayed", "true");
    }
  }, []);

  // useEffect(() => {
  //   setShowPopup[true]
  // },[user])

  // }, [user]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false); // Close the popup after 5 seconds
    }, 5000);

    return () => {
      clearTimeout(timer); // Clear the timer if the component unmounts
    };
  }, []);


  // useEffect(() => {
  //   // if(user){
  //     getAllWishlistProducts(dispatch, "648f50bdc3b82dd3ae7caadc")
  //   // }
  // },[dispatch])

  // Function to close the welcome popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <header className="navbar sticky">
        <Link to={"/"} className="logo">
          <img src={`${process.env.PUBLIC_URL}/images/brand-logo.svg`} />
        </Link>
        <div className="menu-btn" ref={menuBtnRef}>
          <div className="menu-btn__lines"></div>
        </div>
        <ul className="menu-items" ref={menuItemsRef}>
          <li className="dropdown">
            <Link to="/LearnAI" className="menu-item first-item">
              Learn AI
            </Link>
          </li>
          <li className="dropdown">
            <Link to="/stem-shop" className="menu-item first-item">
              STEM Shop
            </Link>
          </li>
          <li className="dropdown">
            <Link to="/SchoolBundleIndia" className="menu-item first-item">
              School Solutions
            </Link>
          </li>

          <li className="dropdown">
            <Link to={"/TeacherPdp"} className="menu-item first-item">
              Teacher Training
            </Link>

            <ul className="dropdown-menu sample"></ul>
          </li>
          <li className="dropdown">
            <Link to="/ComingSoon" className="menu-item first-item">
              Partner with Us
            </Link>
            {/* <Link to="/ComingSoon" className="menu-item first-item expand-btn">
              Partner with Us
            </Link> */}
            {/* <ul className="dropdown-menu sample">
              <li>
                <a href="#" className="menu-item">
                  Distributor
                </a>
              </li>

              <li>
                <a href="#" className="menu-item">
                  Franchise
                </a>
              </li>
            </ul> */}
          </li>
        </ul>
        <div className="right-nav-wrapper">
          {user ? (
            <>
              <ul>
                <li className="welcome-text text-capitalize">
                  <p>{`Hi, ${user.firstName}`}</p>
                </li>

                <li className="position-relative user-icon" ref={menuRef}>
                  <Link
                    to={"/my-profile"}
                    className="text-capitalize profile-icon right-nav-icon"
                  >
                    <CiUser />
                  </Link>
                  {activeProfilePopup && (
                    <ul className="profile-ul">
                      <Link to={"/my-profile"}>
                        <li>View Profile</li>
                      </Link>

                      <li onClick={handleLogout}>Logout</li>
                    </ul>
                  )}
                </li>
                <li className="">
                  <Link
                    to={"/wishlist"}
                    className="text-capitalize position-relative right-nav-icon"
                  >
                    <CiHeart />
                    {wishListCount && wishListCount.length > 0 && (
                      <span className="cart-count">{wishListCount.length}</span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Cart"}
                    className="text-capitalize position-relative right-nav-icon"
                  >
                    <CiShoppingCart />
                    {cartCount && cartCount.length > 0 && (
                      <span className="cart-count">{cartCount.length}</span>
                    )}
                  </Link>
                </li>
                <li title="Logout">
                  <Link
                    className="text-capitalize position-relative right-nav-icon"
                    onClick={handleLogout}
                  >
                    <GrPowerShutdown className="power-btn" />
                  </Link>
                </li>
              </ul>
            </>
          ) : (
            <>
              <Link to={"/login"} className="right-nav-icon dPink login-header">
                <a class="uniBtn uniBtnBlue btnPLight" href="/ContactUsPage">
                  Get Started
                </a>
              </Link>
            </>
          )}
        </div>
      </header>
    </>
  );
};

export default Header3;
