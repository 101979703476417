import 'bootstrap/dist/css/bootstrap.css';
import './Faq.css';
import { Col, Container, Row } from 'react-bootstrap';

const Faq = () => {
    return (
        <div className="faqs">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='heading'>
                            <h1>FAQS</h1>
                        </div>

                        {/* Question and answers */}
                        <div className='course-faqs'>
                            <ul className="faq" >
                                {/* question and answer -1 */}
                                <li className='que-ans'>
                                    {/* Question */}
                                    <p className='question'>
                                        Q. Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>

                                    {/* answer */}
                                    <p className='answer'>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.
                                    </p>
                                </li>

                                {/* question and answer -2 */}
                                <li className='que-ans'>
                                    {/* Question */}
                                    <p className='question'>
                                        Q. Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>

                                    {/* answer */}
                                    <p className='answer'>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.
                                    </p>
                                </li>

                                {/* question and answer -3 */}
                                <li className='que-ans'>
                                    {/* Question */}
                                    <p className='question'>
                                        Q. Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>

                                    {/* answer */}
                                    <p className='answer'>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.
                                    </p>
                                </li>

                                {/* question and answer -4 */}
                                <li className='que-ans'>
                                    {/* Question */}
                                    <p className='question'>
                                        Q. Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities
                                    </p>

                                    {/* answer */}
                                    <p className='answer'>
                                        Introduction to Self-Driving Cars: Overview of autonomous vehicles, their significance, and the role of AI in enabling self-driving capabilities Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Faq;