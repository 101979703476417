import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";

function CommonButton(props) {
  return (
    <Button
      onClick={props.onClickAction}
      variant="primary"
      className={props.btnClass}
      style={{ backgroundColor: props.color }}
    >
      {props.btnName}
    </Button>
  );
}

export default CommonButton;
