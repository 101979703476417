// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-notes {
    list-style: none;
}

.unit-notes {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 10px 0px;
}

.notes-name p {
    font-weight: 700;
    margin-bottom: 0px;
}

.notes-icon svg {
    font-size: 40px;
}

.download-notes svg {
    font-size: 25px;
}`, "",{"version":3,"sources":["webpack://./src/component/course-page/cp-tab/Notes.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".course-notes {\r\n    list-style: none;\r\n}\r\n\r\n.unit-notes {\r\n    display: flex;\r\n    align-items: center;\r\n    column-gap: 20px;\r\n    padding: 10px 0px;\r\n}\r\n\r\n.notes-name p {\r\n    font-weight: 700;\r\n    margin-bottom: 0px;\r\n}\r\n\r\n.notes-icon svg {\r\n    font-size: 40px;\r\n}\r\n\r\n.download-notes svg {\r\n    font-size: 25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
