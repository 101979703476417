import React, { useEffect, useState } from 'react'
import BlogAndNewsFrame from './BlogAndNewsFrame'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogs } from '../redux/apiCalls';
import { publicRequest } from '../requestMethod';

const Event = () => {

    const location = useLocation();
    const eventId = location.pathname.split('/')[2];
    const [currentEvent, setCurrentEvent] = useState({});

    // Fetch news and events data
    useEffect(() => {
        const fetchNewsAndEvents = async () => {
            try {
                // const newsResponse = await publicRequest.get("/news/all");
                const eventsResponse = await publicRequest.get(`/event/find/${eventId}`);

                // setNewsData(newsResponse.data);
                setCurrentEvent(eventsResponse.data);
                // setNewsAndEvents(newsResponse.data, ...eventsResponse.data)
            } catch (error) {
            }
        };
        fetchNewsAndEvents();
    }, []);



  
    // const currentBlog = useSelector((state) => state.blog.blogs.find((item) => item._id === eventId));

  return (
    // <div>
    currentEvent ? (
        <BlogAndNewsFrame image={currentEvent.eventImage} description = {currentEvent.description} author = {currentEvent.author} itemType = "event" title={currentEvent.title} />
    ) : (
        <h2>No content available!</h2>
    )
    // </div>
  )
}

export default Event
