// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 68%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.video-modal-content {
  background: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  max-width: 90%;
  width: 70vw;
}
.video-modal-content video {
  width: 100%;
}

.close-button-about {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: #ffffff;
  border-radius: 50px;
  background: var(--dPink);
}
@media (max-width: 576px) {
  .video-modal-content {
    width: 100vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/home/VideoModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,WAAW;AACb;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".video-modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgb(0 0 0 / 68%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 999;\n}\n.video-modal-content {\n  background: white;\n  padding: 30px;\n  border-radius: 5px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);\n  text-align: center;\n  position: relative;\n  max-width: 90%;\n  width: 70vw;\n}\n.video-modal-content video {\n  width: 100%;\n}\n\n.close-button-about {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  cursor: pointer;\n  background: none;\n  border: none;\n  font-size: 16px;\n  color: #ffffff;\n  border-radius: 50px;\n  background: var(--dPink);\n}\n@media (max-width: 576px) {\n  .video-modal-content {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
