import { Link } from "react-router-dom";
import "./Banner.css";
function Banner() {
  return (
    <div>
      <section className="pdp_banner  pb-4">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-6 order-1 order-lg-0">
              <span className="">TEACHER</span>
              <h1 className="banner_txt">Professional Development programme</h1>
              <h2 className="pdp_banner_subtxt">Free Al & ChatGPT workshops</h2>
              <Link class="banner_btn mt-md-3 d-inline-block" to={"#pdp_form"}>
                Sign Up
              </Link>
            </div>
            <div className="col-md-6 col-lg-6 order-md-2">
              <div className="banner_img"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Banner;
