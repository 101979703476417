import AboutUs from "./AboutUs.jsx";
import OurStory from "../component/about/OurStory.jsx";
import PhilosophyComponent from "../component/about/Philosophy.jsx";
import MilestoneCard from "../component/about/MilestoneCard.jsx";
import Team from "../component/about/Team.jsx";
import ContactUs from "../component/home/ContactUs.jsx";
import AboutBanner from "../component/about/AboutBanner.jsx";
import Core from "../component/about/Core.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllMilestones } from "../redux/apiCalls.js";
const AboutUsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAllMilestones(dispatch);
  }, []);

  const MilestoneCardDataFetching = useSelector(
    (state) => state.milestones.isFetching
  );
  const milestoneCardData = useSelector(
    (state) => state.milestones?.milestones
  );
  const dynamicComponentPropsArray = [
    {
      imageUrl: "images/about/Who-we-are.jpg",
      title: "Who ",
      span: "we are",
      paragraph:
        "Meritus stands as a beacon of innovation in the realm of education. We are a pioneering EdTech company committed to reshaping the future of learning. With a fervent belief in the transformative potential of education, we harness technology and imagination to empower students, educators, parents, and lifelong learners. Our name resonates with excellence and innovation, representing a trusted destination for cutting-edge educational solutions.",
      // btnText: "Click Here",
      // btnLink: "#",
      imageColClass: "col-md-6",
      textColClass:
        "col-md-6 d-flex flex-column align-items-start justify-content-center mt-3 mt-md-0",
      reverse: true,
    },
    {
      imageUrl: "images/about/What-we-do.jpg",
      title: "What ",
      span: "we do",
      paragraph:
        "At Meritus, we are architects of learning experiences that transcend boundaries. Our mission is to create a dynamic and interactive educational environment where technology and creativity converge. Through our comprehensive range of courses spanning AI, VR, Robotics, Coding, and STEM Learning, we unlock the potential of every learner. We cater to diverse interests, ages, and learning styles, ensuring that education is accessible, engaging, and empowering for all.",
      // btnText: "Click Here",
      // btnLink: "google.com",
      imageColClass: "col-md-6",
      textColClass:
        "col-md-6 d-flex flex-column align-items-start justify-content-center mt-3 mt-md-0",
      reverse: false,
    },
  ];
  const dynamicComponentPropsArray1 = [
    {
      imageUrl: "images/about/AI-Education.jpeg",
      title: "TODAY’S NEED FOR ",
      span: "AI EDUCATION",
      paragraph:
        "Children today are surrounded by and brought up around AI-powered devices. Artificial Intelligence is used so much in our everyday lives that we need to make sure that our children understand its impact and potential for the future of work and learning.",
      paragraph2:
        " Artificial intelligence is expected to fuel the global digital economy, and skills required to flourish in future careers are going to be very different from what it is today. A recent Gartner report predicts one in five workers will have some form of artificial intelligence as a co-worker.",
      // btnText: "Click Here",
      // btnLink: "google.com",
      imageColClass: "col-md-6",
      textColClass:
        "col-md-6 d-flex flex-column align-items-start justify-content-center mt-3 mt-md-0",
      reverse: true,
    },
  ];

  const philosophyData = [
    {
      title: "Mission",
      desc: "Enabling Lifelong Learning and Empowering Future Innovators through Accessible and Engaging Educational Solutions.",
    },
    {
      title: "Vision",
      desc: "Empowering the Future of Learning Through Technology , Creativity & Innovation.",
    },
  ];
  const TeamData = [
    {
      imageUrl: "images/about/Dr-Wolfgang-Slany.jpg",
      title: "Dr. Wolfgang Slany",
      date: "12/4",
      paragraph: "Professor Graz University Of Technology, Austria",
      linkedin: "https://www.linkedin.com/in/wolfgangslany/",
    },
    {
      imageUrl: "images/about/Dr-U-N-Umesh.jpg",
      title: "Dr. U. N. Umesh",
      date: "12/4",
      paragraph: "Professor Washington State University, USA",
      linkedin: "https://www.linkedin.com/in/u-n-umesh-983b4/",
    },
    {
      imageUrl: "images/about/Dr-Donna-Knoell.jpg",
      title: "Dr. Donna Knoell",
      date: "12/4",
      paragraph: "Globally Recognised Stem School Education Expert, USA",
      linkedin: "https://www.linkedin.com/in/donna-knoell-ph-d-9a773a7/",
    },
    {
      imageUrl: "images/about/Dr-S-Swaminathan.jpg",
      title: "Dr. S. Swaminathan",
      date: "12/4",
      paragraph: "Dean, Sastra University,",
      paragraph2: "TN, India",
      linkedin: "https://www.linkedin.com/in/swaminathan-sethuraman-56734311/",
    },
    {
      imageUrl: "images/about/Aditi-Prasad.jpg",
      title: "Aditi Prasad",
      date: "12/4",
      paragraph: "​COO, Robotix USA | India",
      linkedin: "https://www.linkedin.com/in/aditi-prasad-5573911a/",
    },
    {
      imageUrl: "images/about/Dr-Pratyush-Kumar.jpg",
      title: "Dr Pratyush Kumar ",
      date: "12/4",
      paragraph: "Asst. Professor, IIT Madras",
      linkedin: "https://www.linkedin.com/in/pratyush-kumar-8844a8a3/",
    },
    {
      imageUrl: "images/about/Dr-Ken-Kahn.jpg",
      title: "Dr. Ken Kahn",
      date: "12/4",
      paragraph: "Professor, Oxford University, UK",
      linkedin: "https://www.linkedin.com/in/ken-kahn-997a225/",
    },
  ];

  return (
    <div id="AboutUsPage">
      <AboutBanner />
      <section className="we_are_do pt-100" id="whoWeAre">
        <div className="container">
          {dynamicComponentPropsArray.map((card, index) => (
            <AboutUs key={index} {...card} />
          ))}
        </div>
      </section>

      <OurStory />

      <section className="pt-100 pb-100" id="our-philosophy">
        <div className="container">
          <h2 className="inh2 text-center mb-5">
            Our <span>philosophy</span>
          </h2>
          <div className="row">
            {philosophyData.map((philosophy, index) => (
              <div className="col-12 col-lg-6" key={index}>
                <PhilosophyComponent {...philosophy} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Core />

      <section className="pt-100 about_us_need">
        <div className="container">
          {dynamicComponentPropsArray1.map((card, index) => (
            <AboutUs key={index} {...card} />
          ))}
        </div>
      </section>

      <section id="milestone">
        <div className="container">
          <h2 className="inh2 pb-md-4">Milestones</h2>
          <div className="row">
            {milestoneCardData.map((milestoneCard, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <MilestoneCard {...milestoneCard} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="our-advisory-board">
        <div className="container">
          <h2 className="inh2 pb-md-4">
            OUR <span> ADVISORY</span> BOARD
          </h2>
          <div className="row">
            {TeamData.map((team, index) => (
              <div className="col-12 col-md-6 col-lg-3" key={index}>
                <Team {...team} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="contact_us pb-50">
        <div className="container">
          <ContactUs />
        </div>
      </section>
    </div>
  );
};

export default AboutUsPage;
