// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 576px) and (max-width: 992px) {
  .about_us_need img {
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;EACrB;AACF","sourcesContent":["@media only screen and (min-width: 576px) and (max-width: 992px) {\n  .about_us_need img {\n    height: 100%;\n    object-fit: cover;\n    border-radius: 25px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
