import "bootstrap/dist/css/bootstrap.css";
import "../../../styles.css";
import "../cards/ProductCard.css";
import { HiEllipsisVertical } from "react-icons/hi2";
import { Card, ProgressBar } from "react-bootstrap";
import { MdPlayCircle, MdPlayCircleFilled } from "react-icons/md";
import { BsFillPlayFill } from "react-icons/bs";
import { MEDIA_URL, isUrl, truncateText } from "../../../config";

const ProductCard = (ProductCardData) => {
  return (
    <Card className="product-card mtb-20 p-rel">
      <div className="vertical-ellipsis">
        <HiEllipsisVertical />
      </div>
      <div className="product-img position-relative">
        {isUrl(ProductCardData.ProductCardImage) ? (
          <Card.Img variant="top" src={ProductCardData.ProductCardImage} />
        ) : (
          <Card.Img
            variant="top"
            src={MEDIA_URL + ProductCardData.ProductCardImage}
          />
        )}

        <div className="card-play-btn-wrapper">
          <BsFillPlayFill className="card-play-button" />
        </div>
      </div>
      <Card.Body className="product-card-content">
        <div className="product-card-top">
          <Card.Title
            className="pc-title"
            title={ProductCardData?.ProductCardTitle}
          >
            {/* <h2 title={ProductCardData?.title}>
            {ProductCardData.ProductCardTitle}
          </h2> */}
            <h2 className="title-hover" title={ProductCardData?.ProductCardTitle}>
              {truncateText(ProductCardData?.ProductCardTitle, 80)}
            </h2>
          </Card.Title>
          <div className="product-desc">
            <p>{truncateText(ProductCardData?.ProductCardDesc, 80)}</p>
          </div>
        </div>

        <div className="product-card-bottom">
          <div className="course-progress-pc">
            <ProgressBar now={ProductCardData.CompletePercent} />
          </div>
          <div className="course-rating">
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
          </div>
          <div className="comp-percent-rating">
            <div className="progress-percent">
              <p>{ProductCardData.CompletePercent}% Complete</p>
            </div>
            <div className="course-rating-txt text-center">
              <div className="leave-rating-txt">
                <p>Leave Rating</p>
              </div>
            </div>
          </div>
        </div>

      </Card.Body>
    </Card>
  );
};

export default ProductCard;
