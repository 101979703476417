// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our_story {
  background: rgb(254 188 8 / 10%);
  padding: 50px 0px;
}
.our_story img {
  width: 20%;
}
.our_story img,
.our_story .inh2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
  .our_story img {
    width: 10%;
  }
}

@media (max-width: 576px) {
  .our_story .position-relative {
    height: 100px;
  }

  .our_story {
    padding: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/about/OurStory.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,iBAAiB;AACnB;AACA;EACE,UAAU;AACZ;AACA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".our_story {\r\n  background: rgb(254 188 8 / 10%);\r\n  padding: 50px 0px;\r\n}\r\n.our_story img {\r\n  width: 20%;\r\n}\r\n.our_story img,\r\n.our_story .inh2 {\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n}\r\n\r\n@media (max-width: 1024px) {\r\n  .our_story img {\r\n    width: 10%;\r\n  }\r\n}\r\n\r\n@media (max-width: 576px) {\r\n  .our_story .position-relative {\r\n    height: 100px;\r\n  }\r\n\r\n  .our_story {\r\n    padding: 0px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
