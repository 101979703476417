import 'bootstrap/dist/css/bootstrap.css';
import '../../styles.css';
import '../user-profile/MyLearning.css'
import TabWrapper from '../common/tab/TabWrapper';
import AllBCourses from './bought-courses/AllBCourses';


const MyLearning = () => {
    const titles = ['All Courses', 'Completed',];
    const contents = [<AllBCourses tabType = "all-course" />, <AllBCourses tabType = "complete-course" />,]
    return (
        <div className='my-learning'>
            <div className='courses-tab'>
                <TabWrapper
                    titles={titles}
                    contents={contents}
                    TabDataClass='my-courses-tab'
                />
            </div>
        </div>
    )
}

export default MyLearning;