import React from 'react';
// import IconButton from '@mui/material/IconButton';
import {MdStar} from 'react-icons/md';
import {MdStarBorder} from 'react-icons/md';

const StarButton = ({ selected, onClick }) => {
  return (
    <div onClick={onClick}>
      {selected ? <MdStar cursor="pointer" color="primary" /> : <MdStarBorder cursor="pointer" color="primary" />}
    </div>
  );
};

export default StarButton;
