import Trainers from "../../component/Trainers";
import AboutUs from "../../pages/AboutUs";
import CardUni from "../../pages/CardUni";
import LandingBanner from "./LandingBanner";
import Offering from "./Offering";
import Success from "./Success";
// import Carousel from "react-grid-carousel";
import OwlCarousel from "react-owl-carousel";
import "./LandingPage.css";
import Accordion from "../../component/common/accordian/Accordian";
import HowItWork from "./HowItWork";
import Card from "../../component/Card";

// import SkillsCard from "../../component/common/cards/SkillsCard";
const LandingPage = () => {
  const cardData = [
    {
      title: "Highlight",
      imageUrl: "images/lifeAt/cta.png",
      spanTxt: "1",
      backgroundColor: "#7380FF",
    },
    {
      spanTxt: "2",
      title: "Highlight",
      imageUrl: "images/home/card-2.png",
      backgroundColor: "#264EC8",
    },
    {
      spanTxt: "3",
      title: "Highlight",
      imageUrl: "images/home/card-3.png",
      backgroundColor: "#FEBC08",
    },
    {
      spanTxt: "4",
      title: "Highlight",
      imageUrl: "images/landing1/landing_card4.png",
      backgroundColor: "#FF289C",
    },
  ];
  const accordionItems = [
    {
      title: "Accordion Item #1",
      content: (
        <div>
          <strong>This is the first item's accordion body.</strong> It is shown
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the
          <code>.accordion-body</code>, though the transition does limit
          overflow.
        </div>
      ),
    },
    {
      title: "Accordion Item #2",
      content: (
        <div>
          <strong>This is the second item's accordion body.</strong> It is
          hidden by default, until the collapse plugin adds the appropriate
          classes that we use to style each element. These classes control the
          overall appearance, as well as the showing and hiding via CSS
          transitions. You can modify any of this with custom CSS or overriding
          our default variables. It's also worth noting that just about any HTML
          can go within the
          <code>.accordion-body</code>, though the transition does limit
          overflow.
        </div>
      ),
    },
    {
      title: "Accordion Item #3",
      content: (
        <div>
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the
          <code>.accordion-body</code>, though the transition does limit
          overflow.
        </div>
      ),
    },
  ];
  const landingData = [
    {
      imageUrl: "images/landing1/image-4.png",
      title: "About Our  ",
      span: "Curriculum",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
      btnText: "Click Here",
      btnLink: "google.com",
      imageColClass: "col-md-6",
      textColClass:
        "col-md-6 d-flex flex-column align-items-start justify-content-center",
      reverse: true,
    },
  ];
  const whyData = [
    {
      imageUrl: "images/landing1/icon/icon-1.png",
      // spantxt: " Cross-Functional ",
      title: " Efficiency and automation",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ",
      backgroundColor: "transperent",
    },
    {
      imageUrl: "images/landing1/icon/icon-6.png",
      // spantxt: " Continuous ",
      title: "Enhanced Learning  ",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ",

      backgroundColor: "transperent",
    },
    {
      imageUrl: "images/landing1/icon/icon-5.png",
      // spantxt: " Continuous ",
      title: " Scalability and Flexibility",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ",

      backgroundColor: "transperent",
    },
  ];
  const cardData1 = [
    {
      imageUrl: "images/lifeAt/icon_1.png",
      spantxt: " Cross-Functional ",
      title: " Collaboration",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      // iconImageUrl:"images/q.svg",
      backgroundColor: "transperent",
    },
    {
      imageUrl: "images/lifeAt/icon_2.png",
      spantxt: " Continuous ",
      title: "Professional Development",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      // iconImageUrl:"images/q.svg",
      backgroundColor: "transperent",
    },
    {
      imageUrl: "images/lifeAt/icon_2.png",
      spantxt: " Continuous ",
      title: "Professional Development",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      // iconImageUrl:"images/q.svg",
      backgroundColor: "transperent",
    },
  ];
  const landingData1 = [
    {
      imageUrl: "images/landing1/image-1.png",
      title: "Get in Touch with ",
      span: "our team Now",
      paragraph:
        "AI World School offers an online self-paced learning platform providing AI experiences to students at home and to K12 schools. We seek to empower children to explore, learn & create with AI in an age-appropriate environment.",
      btnText: "Schedule a Call ",
      btnLink: "google.com",
      imageColClass: "col-md-6",
      textColClass:
        "col-md-6 d-flex align-items-start justify-content-center flex-column",
      reverse: true,
    },
  ];
  const trainersData = [
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-1.jpg",
      backgroundColor: "#2ECC40",
      TrainersCourse: "Virtual Driverless",
      Trainersrating: 5,
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-2.jpg",
      TrainersCourse: "AI Novus",
      Trainersrating: 5,
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-3.jpg",
      backgroundColor: "#2ECC40",
      TrainersCourse: "AI Primus",
      Trainersrating: 5,
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainer-4.jpg",
      backgroundColor: "#2ECC40",
      TrainersCourse: "AI Meritus",
      Trainersrating: 5,
    },
  ];
  return (
    <div>
      <LandingBanner />
      <section className="landing_card1 pt-100">
        <div className="container">
          <div className="row">
            {cardData.map((card, index) => (
              <div className="col-4 col-lg-3 item" key={index}>
                <Card {...card} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className=" pt-100">
        <div className="container">
          {landingData.map((card, index) => (
            <AboutUs key={index} {...card} />
          ))}
        </div>
      </section>
      <section className="why_choose_us pb-100 ">
        <div className="container">
          <h2 className="inh2">
            Why <span>choose US?</span>
          </h2>
          <div className="row">
            {whyData.map((card, index) => (
              <div className="col-md-4">
                <CardUni {...card} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <Offering />
      <HowItWork />
      <Success />
      <section className=" pt-100">
        <div className="container">
          {landingData1.map((card, index) => (
            <AboutUs key={index} {...card} />
          ))}
        </div>
      </section>

      <section className="trainers">
        <div className="container">
          <h2 class="pb-50 fw-600 ff inh2 text-start">
            Our <span>Trainers</span>
          </h2>
          <div className="row">
            {" "}
            {trainersData.map((trainer, index) => (
              <div className="col-lg-3">
                <Trainers key={index} {...trainer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="testimonials mt-100 mb-50">
        <div className="container">
          <h2 className="inh2 pb-lg-4">Testimonials</h2>
          <div className="row">
            <div className="col-md-12">
              <OwlCarousel
                className="owl-theme"
                items={3}
                margin={40}
                loop
                dots
              >
                {cardData1.map((card, index) => (
                  <div className="item" key={index}>
                    <CardUni {...card} />
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <section className="book_exp">
        <div className="container">
          <h2 className="inh2 text-center">
            Book an <span>experience</span>
          </h2>
          <p className="text-center p-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy.Lorem
            Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum.
          </p>
          <div className="book_btns">
            <a to="/" className="uniBtn uniBtnBlue btnPLight ms-3">
              Workshop for Parents
            </a>
            <a to="/" className="uniBtn uniBtnBlue btnPLight ms-3">
              Workshop for Parents
            </a>
            <a to="/" className="uniBtn uniBtnBlue btnPLight ms-3">
              Workshop for Parents
            </a>
          </div>
        </div>
      </section>
      <section className="book_exp">
        <div className="container">
          <h2 className="inh2 text-center">
            Book an <span>experience</span>
          </h2>
          <p className="text-center p-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy.Lorem
            Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum.
          </p>
          <div className="book_btns">
            <a to="/" className="uniBtn uniBtnBlue btnPLight ms-3">
              Workshop for Parents
            </a>
            <a to="/" className="uniBtn uniBtnBlue btnPLight ms-3">
              Workshop for Parents
            </a>
            <a to="/" className="uniBtn uniBtnBlue btnPLight ms-3">
              Workshop for Parents
            </a>
          </div>
        </div>
      </section>
      <section className="faq pt-50 pb-100">
        <div className="container">
          <h2 className="inh2 pb-lg-4">
            Frequently asked<span>questions</span>{" "}
          </h2>
          <Accordion items={accordionItems} />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
