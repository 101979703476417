import React, { useEffect } from 'react'
import ExploreMore from '../ExploreMore';
import { getAllBlogCategory, getAllBlogs } from '../../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import MiniLoader from '../common/Loader/miniLoader/MiniLoader';

const AllBlogs = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllBlogCategory(dispatch);
    }, [dispatch]);

    // useEffect(() => {
    //     getAllBlogs(dispatch);
    // }, [dispatch]);


    const {isFetching : isBlogCatgeoryFetching, blogCategory :blogCategories } = useSelector(
        (state) => state.blogCategory
    );

    const {isFetching : isBlogsFetching, blogs :allBlogs} = useSelector((state) => state.blog);

    if(isBlogCatgeoryFetching){
        return(
            <MiniLoader />
        )
    }

    return (

        <div className="row">
            {blogCategories &&
                blogCategories.map((blogCategory) => {

                    // const totalBlogs = allBlogs?.filter(blog => blog)
                    // const blogsOfCurrentCategory = allBlogs && allBlogs.filter((item) => item.category === blogCategory?.category && item.status === true);
                    if(props.pageType === 'learnAI'){
                        if (blogCategory.status === true && (blogCategory._id === '654dee7f424866e746e6b7cd' || blogCategory._id === '65530538424866e746e705a8' ||blogCategory._id === '65535c91424866e746e73811' ||blogCategory._id === '654e1443424866e746e6cfb9' ||blogCategory._id === '654e077f424866e746e6c5db' ||blogCategory._id === '6554b3d5424866e746e76f1e') ) {
                            return (
                                <div
                                    className="col-6 col-md-4 mb-3"
                                    key={blogCategory.category} // Add a unique key for each category
                                >
                                    <ExploreMore
                                        title={blogCategory.category}
                                        imgUrl={blogCategory.categoryImage}
                                        categoryTitle={blogCategory.category}
                                        totalBlogs={blogCategory.blogCount}
                                    />
                                </div>
                            );
                        }
                        else{
                            return null;
                        }
                    } else{
                        if (blogCategory.status === true) {
                            return (
                                <div
                                    className="col-6 col-md-4 mb-3"
                                    key={blogCategory.category} // Add a unique key for each category
                                >
                                    <ExploreMore
                                        title={blogCategory.category}
                                        imgUrl={blogCategory.categoryImage}
                                        categoryTitle={blogCategory.category}
                                        totalBlogs={blogCategory.blogCount}
                                    />
                                </div>
                            );
                        }
                        else {
                            return null;
                        }
                    }
                    // if (blogCategory.status === true) {
                    //     return (
                    //         <div
                    //             className="col-6 col-md-4 mb-3"
                    //             key={blogCategory.category} // Add a unique key for each category
                    //         >
                    //             <ExploreMore
                    //                 title={blogCategory.category}
                    //                 imgUrl={blogCategory.categoryImage}
                    //                 categoryTitle={blogCategory.category}
                    //                 totalBlogs={blogCategory.blogCount}
                    //             />
                    //         </div>
                    //     );
                    // }
                    // else{
                    //     return null;
                    // }
                })}
        </div>
    )
}

export default AllBlogs
