// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-container{
  padding-right: 0px;
  margin-right: 0px!important;
}
.landing_banner .banner_txt {
    font-family: "Poppins", sans-serif;
    font-size: var(--bHsize);
    font-weight: 700;
    color: var(--dBlue);
    text-align: left;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }

  .landing_banner .banner_txt span {
    color: #ff75b3;
  }
  .landing_banner a.landing_banner_btn {
    background: var(--dBlue);
    border-radius: 50px;
    padding: 12px 30px;
    color: #fff;
    text-decoration: none;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    float: left;
    margin-top: 25px;
  }
  .landing_banner .banner_img img:first-child {
    display: block;
  }
  .landing_banner .banner_img img:last-child {
    display: none;
  }
  
  @media (max-width: 576px) {
    .landing_banner .banner_txt {
      text-align: center;
      margin: 20px 0px;
    }
    .landing_banner a.landing_banner_btn {
      font-size: var(--pSize);
      text-align: center;
      float: none;
    }
    .landing_banner .banner_img img:first-child {
      display: none;
    }
    .landing_banner .banner_img img:last-child {
      display: block;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/landingPage/landing1/LandingBanner.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B;AACA;IACI,kCAAkC;IAClC,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,6CAA6C;EAC/C;;EAEA;IACE,cAAc;EAChB;EACA;IACE,wBAAwB;IACxB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,qBAAqB;IACrB,gBAAgB;IAChB,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;EAClB;EACA;IACE,cAAc;EAChB;EACA;IACE,aAAa;EACf;;EAEA;IACE;MACE,kBAAkB;MAClB,gBAAgB;IAClB;IACA;MACE,uBAAuB;MACvB,kBAAkB;MAClB,WAAW;IACb;IACA;MACE,aAAa;IACf;IACA;MACE,cAAc;IAChB;EACF","sourcesContent":[".custom-container{\n  padding-right: 0px;\n  margin-right: 0px!important;\n}\n.landing_banner .banner_txt {\n    font-family: \"Poppins\", sans-serif;\n    font-size: var(--bHsize);\n    font-weight: 700;\n    color: var(--dBlue);\n    text-align: left;\n    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);\n  }\n\n  .landing_banner .banner_txt span {\n    color: #ff75b3;\n  }\n  .landing_banner a.landing_banner_btn {\n    background: var(--dBlue);\n    border-radius: 50px;\n    padding: 12px 30px;\n    color: #fff;\n    text-decoration: none;\n    text-align: left;\n    width: fit-content;\n    float: left;\n    margin-top: 25px;\n  }\n  .landing_banner .banner_img img:first-child {\n    display: block;\n  }\n  .landing_banner .banner_img img:last-child {\n    display: none;\n  }\n  \n  @media (max-width: 576px) {\n    .landing_banner .banner_txt {\n      text-align: center;\n      margin: 20px 0px;\n    }\n    .landing_banner a.landing_banner_btn {\n      font-size: var(--pSize);\n      text-align: center;\n      float: none;\n    }\n    .landing_banner .banner_img img:first-child {\n      display: none;\n    }\n    .landing_banner .banner_img img:last-child {\n      display: block;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
