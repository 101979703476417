// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.career_banner {
  /* height: 550px; */
  background: #F9F9FE;;
  position: relative;
  overflow: hidden;

}
.career_banner_img {
  position: absolute;
  right: -20px;
  bottom: -20px;
}
/* .career_banner .inh2 {
  position: absolute;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 200px;
}
 */`, "",{"version":3,"sources":["webpack://./src/component/career/CareerBanner.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;;AAElB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;AACA;;;;;;;EAOE","sourcesContent":[".career_banner {\n  /* height: 550px; */\n  background: #F9F9FE;;\n  position: relative;\n  overflow: hidden;\n\n}\n.career_banner_img {\n  position: absolute;\n  right: -20px;\n  bottom: -20px;\n}\n/* .career_banner .inh2 {\n  position: absolute;\n  width: 50%;\n  top: 50%;\n  transform: translateY(-50%);\n  padding-left: 200px;\n}\n */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
