import { Link } from "react-router-dom";
import {
  MEDIA_URL,
  changeDateFormat,
  extractFirstParagraph,
  isUrl,
  truncateText,
} from "../config";
import "./BlogCard.css";

const BlogCard = (props) => {
  return (
    <div className="blog-card">
      <Link to={`/blog/${props.blog?.title}/`} className="blog-img-wrapper">
        <div className="blog-cover-img">
          {isUrl(props.blog?.blogImage) ? (
            <img
              src={props.blog?.blogImage}
              alt="Milestone"
              className="blog-image"
            />
          ) : (
            <img
              src={MEDIA_URL + props.blog?.blogImage}
              alt="Milestone"
              className="blog-image"
            />
          )}

        </div>
      </Link>

      <div className="blog-content">
        <div className="blog-content-top">
          <Link to={`/blog/${props.blog?.title}/`} className="blog-title f-20 fw-600">
              {truncateText(props.blog?.title, 85)}
          </Link>
          <p className="blog-paragraph">
            {extractFirstParagraph(props.blog?.description)}
          </p>
        </div>

        <Link to={`/blog/${props.blog?.title}/`} className="blog-read">
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
