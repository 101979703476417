// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connection-lost-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    width: 100%;
    height: 60vh;
    padding: 50px 0;
    justify-content: center;
    align-items: center;
}

.connection-img-wrapper {
    width: 100%;
    height: 200px;
}

.connection-img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.coneection-icon{
    font-size: 15rem;
    text-align: center;
    color: var(--dPink);
}`, "",{"version":3,"sources":["webpack://./src/component/checkInternetConnection/CheckInternetConnection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".connection-lost-wrapper{\n    display: flex;\n    flex-direction: column;\n    row-gap: 50px;\n    width: 100%;\n    height: 60vh;\n    padding: 50px 0;\n    justify-content: center;\n    align-items: center;\n}\n\n.connection-img-wrapper {\n    width: 100%;\n    height: 200px;\n}\n\n.connection-img-wrapper img{\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n}\n\n.coneection-icon{\n    font-size: 15rem;\n    text-align: center;\n    color: var(--dPink);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
