import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Testimonial from "../home/Testimonial";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethod";
import "./Testimonial.css";

const TestimonialCarousel = () => {

  // const testimonials = useSelector((state) => state.testimonial)
  const [testimonials, setTestimonials] = useState([]);
  const getTestimonials = async () => {
    try {
      const response = await publicRequest.get('/testimonial/all');
      if (response && response.status == 200) {
        setTestimonials(response.data)
      }
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    getTestimonials();
  }, [])


  return (
    <div className="TestimonialCarousel_value testimonials_container pt-100">
      <div className="row align-items-center" style={{background: "linear-gradient(to right, #FFF7FB 50%, #F2F2FE)"}}>
        <div className="col-md-6 mt-4 mt-md-0 p-0 parent-say-home">
          <OwlCarousel items={1} className="owl-theme" loop nav dots>
            {testimonials && testimonials.filter((testimonial) => testimonial.category === 'parent' && testimonial.status === true).map((testimonial, index) => (
              <div key={index}>
                {/* <Testimonial props={testimonial} /> */}
                <div className="testimonials_item">
                  <h2 className="hSize fw-700 ff dBlue text-uppercase">
                    what parents <span>Say</span>
                  </h2>
                  <img
                    src={testimonial.testimonialImage ? testimonial.testimonialImage : `${process.env.PUBLIC_URL}/images/profile.png`}
                    alt="img"
                    className="mt-lg-5 mb-lg-4 testimonial-img"
                  />
                  <h3 className="ff dPink fw-600 f-20 text-capitalize">{`Parent of ${testimonial.childName}`} </h3>
                  <h4 className="ff fw-600 f-20 dBlue text-capitalize">{testimonial.parentName}</h4>
                  <img src="images/q.svg" className="quotation" />
                  <p className="text-start ff">{testimonial.description}</p>
                </div>
              </div>
            ))}
          </OwlCarousel>
          {/* <OwlCarousel items={1} className="owl-theme" loop nav dots>
            {testimonialData.map((testimonial, index) => (
              <div key={index}>
                <Testimonial {...testimonial} />
              </div>
            ))}
          </OwlCarousel> */}
        </div>
        <div className="col-md-6 mt-4 mt-md-0 p-0 trainer-say-home">
          <OwlCarousel items={1} className="owl-theme" loop nav dots>
            {testimonials && testimonials.filter((testimonial) => testimonial.category === 'trainer' && testimonial.status === true).map((testimonial, index) => (
              <div key={index}>
                {/* <Testimonial props = {testimonial} /> */}
                <div className="testimonials_item">
                  <h2 className="hSize fw-700 ff dBlue text-uppercase">
                    what trainers <span>Say</span>
                  </h2>
                  <img
                    src={testimonial.testimonialImage ? testimonial.testimonialImage : `${process.env.PUBLIC_URL}/images/profile.png`}
                    alt="img"
                    className="mt-lg-5 mb-lg-4 testimonial-img"
                  />
                  <h3 className="ff dPink fw-600 f-20 text-capitalize">{testimonial.parentName} </h3>
                  <h4 className="ff fw-600 f-20 dBlue text-capitalize">{testimonial.childName}</h4>
                  <img src="images/q.svg" className="quotation" />
                  <p className="text-start ff">{testimonial.description}</p>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
