import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Trainers from "../Trainers";

const Trainer = () => {
  const isMobile = window.innerWidth <= 768; // Set the breakpoint for mobile screens

  const TrainercardData = [
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-1.jpg",
      backgroundColor: "#2ECC40",
      Trainersrating: "★★★★★",
      TrainersCourse: "Virtual Driverless",
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-2.jpg",
      Trainersrating: "★★★★★",
      TrainersCourse: "AI Novus",
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-3.jpg",
      backgroundColor: "#2ECC40",
      TrainersCourse: "AI Primus",
      Trainersrating: "★★★★★",
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainer-4.jpg",
      backgroundColor: "#2ECC40",
      TrainersCourse: "AI Meritus",
      Trainersrating: "★★★★★",
    },
  ];

  if (isMobile) {
    return (
      <OwlCarousel className="owl-theme" items={3} margin={40} loop dots>
        {TrainercardData.map((card, index) => (
          <div className="item" key={index}>
            <Trainers {...card} />
          </div>
        ))}
      </OwlCarousel>
    );
  }

  // Render all cards on desktop
  return (
    <div className="container">
      <div className="row">
        {TrainercardData.map((card, index) => (
          <div className="col-3" key={index}>
            <Trainers {...card} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Trainer;
