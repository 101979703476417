import { Link } from "react-router-dom";
import {
  MEDIA_URL,
  changeDateFormat,
  extractFirstParagraph,
  isUrl,
  truncateText,
} from "../config";
import "../../src/component/BlogCard.css";
const EventCard = ({ image, title, description, eventId }) => {
  return (
    <div className="blog-card">
      <Link to={`/event/${eventId}`} >
        <div className="event-img-wrapper blog-cover-img">
          {isUrl(image) ? (
            <img src={image} alt="event-img blog-image" />
          ) : (
            <img src={MEDIA_URL + image} alt="event-img blog-image" />
          )}
        </div>
      </Link>
      <div className="blog-content">
        {/* <p className="blog-date">{changeDateFormat(props.blog?.createdAt)}</p> */}
        <Link to={`/event/${eventId}`} className="blog-title f-20 fw-700" >
          {truncateText(title, 85)}
        </Link>
        <p className="blog-paragraph">{extractFirstParagraph(description)}</p>
        <Link to={`/event/${eventId}`} className="blog-read">
          Read More
        </Link>
      </div>
    </div>
  );
};

export default EventCard;
