import React from 'react';
import './OfflineMessage.css'

const OfflineMessage = () => {
    return (
        <div className="offline-message">
            <p>You are currently offline. Please check your internet connection.</p>
        </div>
    );
};

export default OfflineMessage;
