import 'bootstrap/dist/css/bootstrap.css';
import '../../styles.css';
import '../product-rating/ProductRating.css'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import RatingCard from '../cards/RatingCard';
import { useSelector } from 'react-redux';
import MiniLoader from '../Loader/miniLoader/MiniLoader';

const ProductRating = ({ productRatings }) => {
    const [isAllReviewVisible, setIsAllReviewVisible] = useState(false)


    const handleViewAllReviewButton = () => {
        setIsAllReviewVisible(!isAllReviewVisible)
    }

    const isFetching = useSelector((state) => state?.rating?.isFetching)

    // Calculate the sum of ratings
    const totalRating = productRatings?.reduce((acc, curr) => acc + curr.rating, 0);

    // Calculate the average rating
    const averageRating = totalRating / productRatings?.length;


    // Initialize rating percentages to 0 for all rating numbers from 1 to 5
    const ratingPercentages = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0
    };

    // Count occurrences of each rating number
    const ratingCounts = {};
    productRatings?.forEach(({ rating }) => {
        ratingCounts[rating] = (ratingCounts[rating] || 0) + 1;
    });

    // Calculate the total number of ratings
    const totalRatings = productRatings?.length;

    // Calculate the percentage of each rating number
    for (const rating in ratingCounts) {
        const count = ratingCounts[rating];
        const percentage = (count / totalRatings) * 100;
        ratingPercentages[rating] = Math.round(percentage);
    };

    // // Reverse the rating percentages
    // const reversedRatingPercentages = {};
    // const ratings = Object.keys(ratingPercentages).map(Number); // Extract keys as numbers
    // ratings.reverse().forEach(rating => {
    //     reversedRatingPercentages[rating] = ratingPercentages[rating];
    // });

    const renderRatingBars = () => {
        const ratingBars = [];
        for (let index = 4; index >= 0; index--) {
            const rating = index + 1;
            const percentage = ratingPercentages[rating];
            ratingBars.push(
                <div className='progress-bar-percent' key={index}>
                    <ProgressBar now={percentage} />
                    <div className='star-count'>
                        {[...Array(5)].map((_, starIndex) => (
                            <span key={starIndex} className={starIndex < rating ? "rated-star" : "grey-star"}>&#9733;</span>
                        ))}
                    </div>
                    <p className='star-percent'>{percentage}%</p>
                </div>
            );
        }
        return ratingBars;
    };

    if (isFetching) {
        return (
            <MiniLoader />
        )
    }

    return (
        <Container>
            <Row>
                <Col lg={12} className='pb-50'>
                    <div className='overview-heading-color heading'>
                        <h1>Customer <span>Feedback</span></h1>
                    </div>
                </Col>
                {
                    productRatings.length > 0 ? (
                        <>
                            <Col lg={3}>
                                <Card className='overall-pr text-center'>
                                    <Card.Title className='total-rating'>
                                        <h2>{averageRating?.toFixed(1)}</h2>
                                    </Card.Title>
                                    <Card.Body className='result-rating-card'>
                                        <Card.Subtitle className='product-page-star-rating'>
                                            {[...Array(5)].map((_, index) => {
                                                // Calculate the difference between the index and the average rating
                                                const diff = index + 0.5 - averageRating;
                                                const className =
                                                    diff < 0
                                                        ? "rated-star"
                                                        : diff === 0
                                                            ? "rated-star"
                                                            : diff < 0.5
                                                                ? "half-star"
                                                                : "grey-star";

                                                return (
                                                    <span key={index} className={className}>
                                                        &#9733;
                                                    </span>
                                                );
                                            })}
                                        </Card.Subtitle>
                                        <Card.Text className='pr-card-text'>
                                            <p>Product Rating</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={{ span: 8, offset: 1 }}>
                                <div className='rating-percent'>
                                    {
                                        renderRatingBars()
                                    }
                                </div>
                            </Col>

                            <Col lg={12} className='pt-50 pb-50'>
                                <div className='overview-heading-color heading'>
                                    <h1>Reviews</h1>
                                </div>
                            </Col>
                            <Col lg={12}>
                                {
                                    productRatings?.map((productRating, key) => {

                                        if (isAllReviewVisible) {
                                            return (
                                                <RatingCard
                                                    productRating={productRating}
                                                    key={key}
                                                />
                                            )
                                        } else if (key < 3) {
                                            return (
                                                <RatingCard
                                                    productRating={productRating}
                                                    key={key}
                                                />
                                            )
                                        } else {
                                            return null
                                        }


                                    })
                                }
                                {
                                    productRatings?.length > 3 && (
                                        <div className='view-all-review-btn'>
                                            <button className='uniBtn uniBtnBlue btnPLight mt-3' onClick={handleViewAllReviewButton}>{`${isAllReviewVisible ? 'View Less' : ' View More '}`}</button>
                                        </div>
                                    )
                                }
                            </Col>
                        </>
                    ) : (
                        <p className='no-reviews-text'>No reviews available!</p>
                    )
                }
            </Row>
        </Container>
    )
}

export default ProductRating;