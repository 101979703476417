import "bootstrap/dist/css/bootstrap.css";
import "./AccountSetting.css";
import "../styles.css";
import OrderCard from "../common/cards/OrderCard";
import { Col, Container, Row } from "react-bootstrap";
import { getAllOrders } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import MiniLoader from "../common/Loader/miniLoader/MiniLoader";

const MyPurchases = () => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.loginUser.currentUser._id);

  useEffect(() => {
    getAllOrders(dispatch, id);
  }, [dispatch]);

  const { isFetching, orders: allOrders } = useSelector((state) => state.order);


  if (isFetching) {
    return <MiniLoader />;
  }

    return (
        <Container className='p-lr-10'>
            <Row className='gx-5'>
                <Col lg={12}>
                    <div className='heading up-tab-heading pt-30 pb-70'>
                        <h1>My Purchases</h1>
                    </div>
                </Col>
                {
                    allOrders && allOrders.map((order) => {
                        if(!order.isItFromSpecificAccess){
                            return (
                                <Col lg={4} md={6}>
                                    <OrderCard order={order}/>
                                </Col>
                            )
                        }
                        
                    })
                }
            </Row>
        </Container>
    )
}

export default MyPurchases;
