import React, { useState } from 'react';
import './CourseContent.css'
import { Accordion } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { BsFillPlayCircleFill, BsFillLockFill } from 'react-icons/bs';
import Quiz from '../../../Quiz/Quiz';
import { userRequest } from '../../../requestMethod';
import { GoFileMedia } from "react-icons/go";
import { BsFiletypePdf } from "react-icons/bs";
import { AiFillFileZip } from "react-icons/ai";
import { MdClose, MdDownloadForOffline, MdPreview } from "react-icons/md";
import { MdQuiz } from "react-icons/md";
import { async } from '@vimeo/player';
import axios from 'axios';
import { MEDIA_URL } from '../../../config';
import H5PViewer from '../../h5p-viewer/H5PViewer';
import { FaFileAlt } from "react-icons/fa";
import PDFViewer from '../../pdf-viewer/PDFViewer';
// import PDFViewer from '../../pdf-viewer/PdfViewer';


const CourseContent = (props) => {
    const [showQuizPopup, setShowQuizPopup] = useState(false);

    const handleOpenQuiz = () => {
        setShowQuizPopup(true);
    };

    const handleCloseQuiz = () => {
        setShowQuizPopup(false);
    };

    const quizData = [
        {
            question: "Current Year",
            options: ["2020", "2021", "2022", "2023"],
            answer: "2020",
        },
        {
            question: "Current Month?",
            options: ["jan", "feb", "mar", "apr"],
            answer: "apr",
        },
    ];

    // const convertPptToHtml = (fileurl) => {

    // }

    const preventContextMenu = (event) => {
        event.preventDefault();
    };

    const [downloadUrl, setDownloadUrl] = useState(null);

    const downloadFile = async (fileName, chapterIndex, resourceIndex) => {
        // const currentFileName = extractFileName(fileUrl)
        // if (currentFileName) {
        //     try {
        //         const res = await userRequest.get(`/upload/get-presigned-url/${encodeURIComponent(currentFileName)}`);
        //         // const res = await userRequest.get(`/upload/get-presigned-url?fileUrl=${encodeURIComponent(fileUrl)}`);
        //         if (res.statusText === 'OK') {
        //             setDownloadUrl(res.data.url)
        //             // window.open(downloadUrl, '_blank');
        //             if (downloadUrl) {
        //                 const fileName = "new-file.zip"
        //                 const aTag = document.createElement("a");
        //                 aTag.href = downloadUrl;
        //                 aTag.setAttribute("download", fileName);
        //                 document.body.appendChild(aTag);
        //                 aTag.click();
        //                 aTag.remove();
        //             }
        //         }

        //     } catch (error) {
        //     }
        // }
        try {
            // Make a GET request to the DigitalOcean Spaces endpoint
            const response = await axios.get(MEDIA_URL + fileName, {
                responseType: 'blob', // Specify the response type as a blob
            });

            // Create a download link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);

            // Append the link to the body and trigger the download
            document.body.appendChild(link);
            link.click();
            const newProgress = 100;
            await props.updateResourceProgressFunction(chapterIndex, resourceIndex, newProgress)

            // Remove the link from the body
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            // Handle error, e.g., show an error message to the user
        }
    };

    const [isDocViewerVisible, setIsDocViewerVisible] = useState(false);
    const [documentData, setDocumentData] = useState([]);


    const handleDocViewer = (url, fileType, fileName) => {
        const data = { url, fileType, fileName };
        setIsDocViewerVisible(true);
        setDocumentData(data)
    }

    const closeDocViewer = () => {
        setIsDocViewerVisible(false);
    }

    const percent = 50


    return (
        <div className={props.AccordionClass}>
            <div className='course-progress-container'>
                <p>Course Progress</p>
                <ProgressBar now={props.courseProgress} label={`${props.courseProgress}%`}  />
                {/* <input type="text" placeholder='Search lecture...' className='' /> */}
            </div>
            <Accordion className='course-content-list'>
                {/* Course unit -1 */}
                {
                    props.lectureDetails && props.lectureDetails.map((chapter, chapterIndex) => {
                        // const videoId = item.uri.split('/')[2];
                        let progressOfLastResourceOfPreviousChapter = null;
                        if (chapterIndex > 0) {
                            const prevChapter = props.lectureDetails[chapterIndex - 1];
                            const lastResourceIndex = prevChapter.resources.length - 1;
                            progressOfLastResourceOfPreviousChapter = prevChapter.resources[lastResourceIndex].progress;
                        }

                        return (
                            <Accordion.Item eventKey={chapterIndex} className='course-unit'>
                                <Accordion.Header className='unit-name'>
                                    {/* Lecture {chapterIndex + 1} */}
                                    {`${chapter.title}`}
                                </Accordion.Header>
                                <Accordion.Body className='ff p-0'>
                                    {/* List of lessons in a unit */}
                                    <ul className='course-lessons p-0 m-0'>
                                        {/* Lesson -1 */}
                                        {
                                            chapter.resources.map((resource, resourceIndex) => {
                                                // Check if there is a previous resource and its progress is 100
                                                const prevResource = chapter.resources[resourceIndex - 1];
                                                const prevProgress = prevResource ? prevResource.progress : null;

                                                if (resource.type === 'video') {
                                                    return (
                                                        <div className="lesson-wrapper">
                                                            {
                                                                (progressOfLastResourceOfPreviousChapter === 100 && resourceIndex === 0) || prevProgress === 100 || (chapterIndex === 0 && resourceIndex === 0) ? (
                                                                    <li key={resourceIndex} className='lesson' onClick={() => props.onClickAction(resource.url, chapterIndex, resourceIndex)} >
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFillPlayCircleFill />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ) : (
                                                                    <li key={resourceIndex} className='lesson' >
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                {/* <BsFillPlayCircleFill /> */}
                                                                                <BsFillLockFill />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }

                                                if (resource.type === 'pdf') {
                                                    return (
                                                        <div className="lesson-wrapper">
                                                            {
                                                                (progressOfLastResourceOfPreviousChapter === 100 && resourceIndex === 0) || prevProgress === 100 || (chapterIndex === 0 && resourceIndex === 0) ? (
                                                                    <li key={resourceIndex} className='lesson' onClick={() => props.handleDocViewer(resource.url, resource.type, resource.title, chapterIndex, resourceIndex)}>
                                                                        {/* <li key={resourceIndex} className='lesson' onClick={() => downloadFile(resource.file)} > */}
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFiletypePdf />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <button className="file-view-btn" >
                                                                            <MdPreview />
                                                                        </button>
                                                                    </li>

                                                                ) : (
                                                                    <li key={resourceIndex} className='lesson'>
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFillLockFill />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        {/* <button className="file-view-btn" >
                                                                        <MdPreview />
                                                                    </button> */}
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }

                                                if (resource.type === 'ppt') {
                                                    return (
                                                        <div className="lesson-wrapper">
                                                            {
                                                                (progressOfLastResourceOfPreviousChapter === 100 && resourceIndex === 0) || prevProgress === 100 || (chapterIndex === 0 && resourceIndex === 0) ? (
                                                                    <li key={resourceIndex} className='lesson' >
                                                                        {/* <li key={resourceIndex} className='lesson' onClick={() => downloadFile(resource.file)} > */}
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFiletypePdf />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <button className="file-view-btn" onClick={() => props.handleDocViewer(resource.url, resource.type, resource.title, chapterIndex, resourceIndex)}>
                                                                            <MdPreview />
                                                                        </button>
                                                                    </li>
                                                                ) : (
                                                                    <li key={resourceIndex} className='lesson' >
                                                                        {/* <li key={resourceIndex} className='lesson' onClick={() => downloadFile(resource.file)} > */}
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFillLockFill />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }

                                                if (resource.type === 'zip') {
                                                    return (
                                                        <div className="lesson-wrapper">
                                                            {
                                                                (progressOfLastResourceOfPreviousChapter === 100 && resourceIndex === 0) || prevProgress === 100 || (chapterIndex === 0 && resourceIndex === 0) ? (
                                                                    <li key={resourceIndex} className='lesson' onClick={() => downloadFile(resource.url, chapterIndex, resourceIndex)} >
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <AiFillFileZip />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <button className="download-btn">
                                                                            <MdDownloadForOffline />
                                                                        </button>
                                                                    </li>
                                                                ) : (
                                                                    <li key={resourceIndex} className='lesson' >
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFillLockFill />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                                if (resource.type === 'sb3') {
                                                    return (
                                                        <div className="lesson-wrapper">
                                                            {
                                                                (progressOfLastResourceOfPreviousChapter === 100 && resourceIndex === 0) || prevProgress === 100 || (chapterIndex === 0 && resourceIndex === 0) ? (
                                                                    <li key={resourceIndex} className='lesson' onClick={() => downloadFile(resource.url, chapterIndex, resourceIndex)} >
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <AiFillFileZip />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <button className="download-btn">
                                                                            <MdDownloadForOffline />
                                                                        </button>
                                                                    </li>
                                                                ) : (
                                                                    <li key={resourceIndex} className='lesson' >
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFillLockFill />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                                if (resource.type === 'html') {
                                                    return (
                                                        <div className="lesson-wrapper" >
                                                            {
                                                                (progressOfLastResourceOfPreviousChapter === 100 && resourceIndex === 0) || prevProgress === 100 || (chapterIndex === 0 && resourceIndex === 0) ? (
                                                                    <li key={resourceIndex} className='lesson' onClick={() => props.handleDocViewer(resource.url, resource.type, resource.title, chapterIndex, resourceIndex)}>
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <FaFileAlt />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <button className="file-view-btn" >
                                                                            <MdPreview />
                                                                        </button>
                                                                    </li>
                                                                ) : (
                                                                    <li key={resourceIndex} className='lesson' >
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFillLockFill />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                                if (resource.type === 'quiz') {
                                                    return (
                                                        <div className="lesson-wrapper">
                                                            {
                                                                (progressOfLastResourceOfPreviousChapter === 100 && resourceIndex === 0) || prevProgress === 100 || (chapterIndex === 0 && resourceIndex === 0) ? (
                                                                    <li key={resourceIndex} className='lesson' >
                                                                        {/* <li key={resourceIndex} className='lesson' onClick={() => downloadFile(resource.file)} > */}
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <MdQuiz />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <button className="play-quiz-btn" onClick={handleOpenQuiz}>
                                                                            start
                                                                        </button>
                                                                        {
                                                                            showQuizPopup && (
                                                                                <Quiz quizData={resource} onClose={handleCloseQuiz} updateProgress={props.updateResourceProgressFunction} chapterIndex={chapterIndex} resourceIndex={resourceIndex} />
                                                                            )
                                                                        }
                                                                    </li>
                                                                ) : (
                                                                    <li key={resourceIndex} className='lesson' >
                                                                        {/* <li key={resourceIndex} className='lesson' onClick={() => downloadFile(resource.file)} > */}
                                                                        <div className='d-flex align-items-center lesson-title'>
                                                                            <div className='video-icon'>
                                                                                <BsFillLockFill />
                                                                            </div>
                                                                            <div className='lesson-name'>
                                                                                <p className='p-0 m-0 text-capitalize'>
                                                                                    {resource.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        {/* <button className="play-quiz-btn" onClick={handleOpenQuiz}>
                                                                            start
                                                                        </button> */}
                                                                        {/* {
                                                                            showQuizPopup && (
                                                                                <Quiz quizData={resource} onClose={handleCloseQuiz} />
                                                                            )
                                                                        } */}
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            }
                                            )}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
            {/* {
                isDocViewerVisible && (
                    <div className="pdf-viewr" onContextMenu={(e) => e.preventDefault()}>
                        <div className="pdf-inner-wrapper">
                            <button className="close-viewer-btn" onClick={closeDocViewer}>
                                <MdClose />
                            </button>
                            {
                                documentData.fileType === 'html' ? (
                                    <H5PViewer docData={documentData} />
                                ) : (
                                    <div onContextMenu={preventContextMenu}>
                                        <PDFViewer docData={documentData} />
                                    </div>

                                )
                            }
                        </div>
                    </div>
                )
            } */}
        </div>
    )
}

export default CourseContent;