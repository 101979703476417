// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.school_form {
  padding: 100px 300px;
}
.workshop_icon {
  right: 25px;
}
@media only screen and (max-width: 576px) {
  .school_form_container .justify-content-end,
  .school_form_container .justify-content-center {
    justify-content: flex-start !important;
  }

  #countryCode {
    font-size: 12px;
    height: 38px;
    padding: 10px 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/TeacherPdp/SchoolForm.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,WAAW;AACb;AACA;EACE;;IAEE,sCAAsC;EACxC;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,iBAAiB;EACnB;AACF","sourcesContent":[".school_form {\n  padding: 100px 300px;\n}\n.workshop_icon {\n  right: 25px;\n}\n@media only screen and (max-width: 576px) {\n  .school_form_container .justify-content-end,\n  .school_form_container .justify-content-center {\n    justify-content: flex-start !important;\n  }\n\n  #countryCode {\n    font-size: 12px;\n    height: 38px;\n    padding: 10px 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
