// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AwardSection {
  padding: 0px 0px 50px 0px;
}
.AwardSection img {
  height: 400px;
  object-fit: contain;
}
.Rectangle_3 {
  border-width: 4px;
  border-color: rgb(110, 90, 200);
  border-style: solid;
  border-radius: 22px;
  position: absolute;
  left: 215px;
  top: 577px;
  width: 164px;
  height: 42px;
  z-index: 450;
}
@media (max-width: 576px) {
  .AwardSection {
    padding: 0px 0px 50px 0px;
  }
}

@media only screen and (min-width: 1080px) and (max-width: 1200px) {
  .AwardSection img {
    height: 310px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/home/AwardSection.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,+BAA+B;EAC/B,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,YAAY;AACd;AACA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".AwardSection {\n  padding: 0px 0px 50px 0px;\n}\n.AwardSection img {\n  height: 400px;\n  object-fit: contain;\n}\n.Rectangle_3 {\n  border-width: 4px;\n  border-color: rgb(110, 90, 200);\n  border-style: solid;\n  border-radius: 22px;\n  position: absolute;\n  left: 215px;\n  top: 577px;\n  width: 164px;\n  height: 42px;\n  z-index: 450;\n}\n@media (max-width: 576px) {\n  .AwardSection {\n    padding: 0px 0px 50px 0px;\n  }\n}\n\n@media only screen and (min-width: 1080px) and (max-width: 1200px) {\n  .AwardSection img {\n    height: 310px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
