// LogoSlider.js
import React from "react";
import OwlCarousel from "react-owl-carousel";

const LogoSlider = ({ logos, responsiveConfig }) => {
  return (
    <div className="pt-50">
      <div className="container">
        <OwlCarousel
          className="owl-theme"
          // loop
          // slideBy={5}
          margin={50}
          autoplay="true"
          // autoplaySpeed={300}
          // autoWidth="true"
          // stagePadding={50}
          // smartSpeed={200}
          // autoPlay={false}
          dots={false}
          responsive={responsiveConfig}
        >
          {logos.length > 0 &&
            logos.map((logo, index) => (
              <div className="item school-logo-item" key={index}>
                <img src={logo} alt={`Logo ${index}`} />
              </div>
            ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default LogoSlider;
