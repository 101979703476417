import React from 'react'
import BlogAndNewsFrame from './BlogAndNewsFrame'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const News = () => {

    const dispatch = useDispatch();

    const location = useLocation();
    const blogId = location.pathname.split('/')[2];

    const currentBlog = useSelector((state) => state.blog.blogs.find((item) => item._id === blogId));

    // const blogcardData = [
    //     {
    //         id: 1,
    //         imageUrl: "images/latestNews/img-1.jpg",
    //         title: "Better future for children lavale village, pune",
    //         date: "Feb 2020",
    //         paragraph:
    //             "Lorem Ipsum is simply dummy text of the printing and tyspesetting industry.",
    //         read: "/",
    //     },
    //     {
    //         id: 2,
    //         imageUrl: "images/latestNews/img-2.jpg",
    //         title: "Better future for children lavale village, pune",
    //         date: "Feb 2020",
    //         paragraph:
    //             "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //         read: "/",
    //     },
    //     {
    //         id: 3,
    //         imageUrl: "images/latestNews/img-3.jpg",
    //         title: "Better future for children lavale village, pune",
    //         date: "Feb 2020",
    //         paragraph:
    //             "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //         read: "/",
    //     },
    // ];

    return (
        // <div>
        currentBlog ? (
            <BlogAndNewsFrame item={currentBlog} itemType="blog" />
        ) : (
            <h2>No content available!</h2>
        )
        // </div>
    )
}

export default News
