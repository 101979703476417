
import { createSlice } from "@reduxjs/toolkit";

const successStoriesReducer = createSlice({
    name: 'successStories',
    initialState: {
        videos: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Videos
        getStoriesVideoStart: (state) => {
            state.isFetching = true;
        },
        getStoriesVideoSuccess: (state, action) => {
            state.isFetching = false;
            state.videos = action.payload;
        },
        getStoriesVideoFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getStoriesVideoStart, getStoriesVideoSuccess, getStoriesVideoFailure } = successStoriesReducer.actions;
export default successStoriesReducer.reducer;