import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SuccessCard from "./SuccessCard.jsx";
import "./Offering.css";

const Success = () => {
  const schoolData = [
    {
      image: "images/landing1/image-3.png",
      schoolName: "School A",
      location: "City A, Country A",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed consectetur tellus eget ultrices laoreet.",
    },
    {
      image: "images/landing1/image-3.png",
      schoolName: "School B",
      location: "City B, Country B",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed consectetur tellus eget ultrices laoreet.",
    },
    {
      image: "images/landing1/image-3.png",
      schoolName: "School C",
      location: "City C, Country C",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed consectetur tellus eget ultrices laoreet.",
    },
    // Add more dummy objects as needed
  ];

  return (
    <div className="success">
      <div className="container">
        <h2 className="inh2 pb-md-4">
          {" "}
          Success <span> stories</span>{" "}
        </h2>
        <div className="row align-items-center">
          <div className="col-md-12">
            <OwlCarousel
              className="owl-theme"
              items={1}
              margin={40}
              loop
              dots
              autoplay
            >
              {schoolData.map((card, index) => (
                <div className="item" key={index}>
                  <SuccessCard {...card} />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
