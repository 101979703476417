import React from "react";
import "./Loader.css";

const Loader = (props) => {
  return (
    <div className="loader-wrapper">
      <div class="circle"></div>
      <p>{props.title ? props.title : "Loading"}</p>
    </div>
  );
};

export default Loader;
