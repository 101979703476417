import "./CareerBanner.css";
const CareerBanner = () => {
  return (
    <div className="career_banner mb-100">
      <div className="container">
        <div className="row align-items-center pt-50 container">
          <div className="col-lg-6">
            <h3 className="inh2">
              Reach new heights
              <span> in your profession</span>
            </h3>
            <p className="pSize">
              Explore the current opening and give a boost to your career
            </p>
            {/* <a href="/" className="btndefault"></a> */}
          </div>
          <div className="col-lg-6 position-relative career_banner_img">
            <img src="images/banner/career.png" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CareerBanner;
