// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-to-top-button{
    display: none;
    position: fixed;
    right: 30px;
    bottom: 14%;
    background: var(--dPink);
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 5px;
    border: 0;
    cursor: pointer;
}

.back-to-top-button svg{
    font-size: 20px;
    transition: all 0.2s ease-in;
}

.back-to-top-button:hover svg{
    margin-bottom: 20px;
    transition: all 0.2s ease-in;
}

.back-to-top-button.visible{
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/component/common/BackToTopButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,WAAW;IACX,WAAW;IACX,wBAAwB;IACxB,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".back-to-top-button{\n    display: none;\n    position: fixed;\n    right: 30px;\n    bottom: 14%;\n    background: var(--dPink);\n    color: #fff;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    padding: 5px;\n    border: 0;\n    cursor: pointer;\n}\n\n.back-to-top-button svg{\n    font-size: 20px;\n    transition: all 0.2s ease-in;\n}\n\n.back-to-top-button:hover svg{\n    margin-bottom: 20px;\n    transition: all 0.2s ease-in;\n}\n\n.back-to-top-button.visible{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
