import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.css";
import "../../styles.css";
import "./Skills.css";
import SkillsCard from "../cards/SkillsCard";
import { Container, Row, Col } from "react-bootstrap";

const Skills = (props) => {
  const responsiveConfig = {
    0: {
      items: 1, // Show 1 item on screens between 0px and 767px (inclusive)
    },
    768: {
      items: 2, // Show 3 items on screens between 768px and 991px (inclusive)
    },
    992: {
      items: 2, // Show 4 items on screens between 992px and 1199px (inclusive)
    },
    1200: {
      items: 3, // Show 5 items on screens larger than 1200px
    },
  };

  return (
    <Container>
      <Row>
        <Col lg={12} className="pb-50">
          <div className="heading pt-40">
            <h1>
              Skills<span> your kids will gain</span>
            </h1>
          </div>
        </Col>
        <Col lg={12} className="skill-card-carousel p-0">
          <OwlCarousel
            className="owl-theme"
            margin={50}
            dots={false}
            nav={true}
            responsive={responsiveConfig}
          >
            {props.skills.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <SkillsCard
                    CardImg={`${process.env.PUBLIC_URL}/images/product-page/icons/sequencing.png`}
                    CardTitle={item.skillName}
                    CardText={item.skillDetails}
                  />
                </div>
              );
            })}
            {/* {
              props.skills.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <SkillsCard
                      SkillsCardImg={`${process.env.PUBLIC_URL}/images/product-page/icons/sequencing.png`}
                      SkillsCardTitle={item.skillName}
                      SkillsCardText={item.skillDetails}
                    />
                  </div>
                )
              })
            } */}

            {/* Add more card items here */}
          </OwlCarousel>
        </Col>
      </Row>
    </Container>
  );
};

export default Skills;
