import { Link } from "react-router-dom";
import "./banner.css";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
function Banner() {
  const user = useSelector((state) => state.loginUser.currentUser);

  return (
    <div>
      <section className="home_banner px-lg-5 pb-4">
        <div className="container">
          <div className="row d-flex align-items-lg-center">
            <div className="col-md-8 col-lg-8 order-1 order-lg-0">
              <div className="homepage_txt_container">
                {/* <img src="images/home/bird.svg" className="home_bird" /> */}
                <h1 className="banner_txt">Inspiring Minds,</h1>
                <h1 className="banner_txt">
                  <span className="dPink fw-800"> Shaping Futures</span> through
                </h1>
                <h1 className="banner_txt ver-line">
                  STEM -
                  <span className="violet-c fw-800">
                    Robotics,
                    <br />
                    Coding & AI
                  </span>
                </h1>
                <p>Start your journey today</p>
                {/* {!user && (
                  <Link to={"/register"} className="banner_btn">
                    Sign Up
                  </Link>
                )} */}
              </div>
            </div>
            <div className="col-md-4 col-lg-4 order-2"></div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Banner;
