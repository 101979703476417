// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-viewr {
    width: 100%;
    height: 100%;
    /* height: 100vh; */
    /* position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: #fff; */
}

.pdf-container {
    width: 100%;
    height: 100%;
    /* position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */

}

.close-viewer-btn {
    color: #fff;
    background-color: var(--dBlue);
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 2;
}

.pdf-inner-wrapper {
    width: 100%;
    height: 100%;
    /* overflow: scroll; */
    position: relative;
}

.pdf-inner-wrapper > div:first-child {
    height: 100%; /* Set height to 100% of the parent .pdf-inner-wrapper */
  }

iframe .WACStatusBarContainer {
    display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/component/pdf-viewer/PDFViewer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB;;;;;uBAKmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ;;;;uCAImC;;AAEvC;;AAEA;IACI,WAAW;IACX,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,YAAY,EAAE,wDAAwD;EACxE;;AAEF;IACI,wBAAwB;AAC5B","sourcesContent":[".pdf-viewr {\n    width: 100%;\n    height: 100%;\n    /* height: 100vh; */\n    /* position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1000;\n    background: #fff; */\n}\n\n.pdf-container {\n    width: 100%;\n    height: 100%;\n    /* position: absolute;\n    top: 50%;\n    left: 50%;\n    -webkit-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%); */\n\n}\n\n.close-viewer-btn {\n    color: #fff;\n    background-color: var(--dBlue);\n    border: none;\n    border-radius: 50%;\n    position: absolute;\n    top: 5%;\n    right: 5%;\n    z-index: 2;\n}\n\n.pdf-inner-wrapper {\n    width: 100%;\n    height: 100%;\n    /* overflow: scroll; */\n    position: relative;\n}\n\n.pdf-inner-wrapper > div:first-child {\n    height: 100%; /* Set height to 100% of the parent .pdf-inner-wrapper */\n  }\n\niframe .WACStatusBarContainer {\n    display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
