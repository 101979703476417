import { createSlice } from "@reduxjs/toolkit";

const quizReducer = createSlice({
    name: 'quiz',
    initialState: {
        quiz: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Product
        getQuizStart: (state) => {
            state.isFetching = true;
        },
        getQuizSuccess: (state, action) => {
            state.isFetching = false;
            state.quiz = action.payload;
        },
        getQuizFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
});

export const { getQuizStart, getQuizSuccess, getQuizFailure } = quizReducer.actions;
export default quizReducer.reducer;