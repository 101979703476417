// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-summary {
    background-color: var(--uPCBlue);
}

.sub-head-odp h4 {
    font-weight: 700;
    padding-top: 10px;
    text-transform: uppercase;
}

.cancel-order-btn {
    background-color: var(--dBlue);
    color: var(--white);
    border-radius: 50px;
    font-weight: 600;
    font-size: 18px;
    padding: 15px 35px;
}


.total-txt,
.total-amt {
    font-size: 22px;
    font-weight: 700;
}

.total-bill-amt {
    padding-top: 20px;
}

@media (max-width: 767px) {
    .cancel-order-btn {
        padding: 5px 20px;
        margin-bottom: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/order-details-pg/OrderSummary.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;;AAGA;;IAEI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,iBAAiB;QACjB,mBAAmB;IACvB;AACJ","sourcesContent":[".order-summary {\r\n    background-color: var(--uPCBlue);\r\n}\r\n\r\n.sub-head-odp h4 {\r\n    font-weight: 700;\r\n    padding-top: 10px;\r\n    text-transform: uppercase;\r\n}\r\n\r\n.cancel-order-btn {\r\n    background-color: var(--dBlue);\r\n    color: var(--white);\r\n    border-radius: 50px;\r\n    font-weight: 600;\r\n    font-size: 18px;\r\n    padding: 15px 35px;\r\n}\r\n\r\n\r\n.total-txt,\r\n.total-amt {\r\n    font-size: 22px;\r\n    font-weight: 700;\r\n}\r\n\r\n.total-bill-amt {\r\n    padding-top: 20px;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .cancel-order-btn {\r\n        padding: 5px 20px;\r\n        margin-bottom: 20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
