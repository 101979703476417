import React from "react";
import "./FlexiblePlan.css";
const FlexiblePlan1Page = () => {
  return (
    <div className="flexible_plan_page">
      <header className="flexible_header">
        <h1 className="text-white fw-700">Case Study</h1>
        <p className="text-white">
          Custom STEM, Robotics, Coding, and AI Solution for a School
        </p>
      </header>
      <h2 className="flexible_plan_page_title">Flexible Plan 1</h2>
      <section className="flexible_plan_page_body container ">
        <p>
          One of our school clients from India sought an integrated STEM,
          Robotics, Coding, and AI program aligned with the National Education
          Policy (NEP) 2020 and the CBSE curriculum. The school, accommodating
          1800 students from grades 1 to 12, envisioned a progressive, year-long
          learning journey, with one hour of dedicated instruction per week for
          30 weeks.
          <br /> <br /> The school requested that we handle the providing of the
          kits and send our trainer to ensure there was no upfront capital
          expenditure on the school's part. However, they were willing to pay on
          a per-student, annual fee basis. To ensure an optimal learning
          experience, the school requested that the curriculum for grades 1 to 3
          be entirely screen-free. Collaboratively, we designed a tailored
          solution :
        </p>
        <h2>Primary | Grades 1-3 - 100% Screen-Free Curriculum (24 weeks)</h2>
        <ul>
          <li>
            6 classes of STEM Maker Kit to teach the construction of simple
            machines
          </li>
          <li>
            6 classes of Brick Math Kit for math and numeracy skill development
          </li>
          <li>6 classes of Playbits to introduce tangible coding concepts</li>
          <li>
            6 classes of Phiro Unplugged with Keys for fundamental robotics
            education
          </li>
        </ul>

        <h2 className="fp-Pheading">
          Primary | Grades 4-5 - 100% Screen-Free Curriculum (24 weeks)
        </h2>
        <ul>
          <li>
            6 classes of STEM Maker Kit to learn the construction of compound
            machines
          </li>
          <li>6 classes of Playbits for exploration of advanced coding</li>
          <li>
            6 classes of Phiro Unplugged with Swish Cards to delve into basics
            of robotics
          </li>
          <li>
            6 classes of online AI sessions with Scratch AI extensions,
            conducted in the computer science lab
          </li>
        </ul>

        <h2>Middle School | Grades 6-8 (24 weeks)</h2>
        <ul>
          <li>
            6 classes of AI Lite, a construction kit using MIT App Inventor
          </li>
          <li>
            6 classes with Data Sciens Pro - data loggers and sensors for
            real-world data analysis
          </li>
          <li>
            6 classes of Phiro Pro Robotics using Scratch and Phiro Code app
          </li>
          <li>
            6 classes of online AI sessions with Scratch AI extensions in the
            computer science lab
          </li>
        </ul>

        <h2 className="fp-Pheading">High School | Grades 9-12 (24 weeks)</h2>
        <ul>
          <li>
            12 classes of CV Pro AI kit to explore Autonomous Vehicle Technology
          </li>
          <li>
            12 classes of online AI sessions with Python, conducted in the
            computer science lab
          </li>
        </ul>

        <p className="fw-500">
          At the end of the 24-week program, the school showcased students'
          capstone projects during a project day. The school's commitment to
          innovative and creative learning, coupled with our tailored solution,
          led to impressive projects that consistently impressed parents. This
          success enhanced the school's reputation and contributed to a steady
          increase in annual admissions. This case study highlights our ability
          to create customized educational solutions to meet the unique needs
          and goals of educational institutions.
        </p>
      </section>
    </div>
  );
};

export default FlexiblePlan1Page;
