import "bootstrap/dist/css/bootstrap.css";
import "../../../styles.css";
import "../CV Pro/CVPro.css";
import { Col, Container, Row, Card } from "react-bootstrap";
import { IoIosCheckbox } from "react-icons/io";

const CVPro = () => {
  return (
    <div className="cv-pro">
      {/* Top banner  */}
      <section className="cv-pro-top-banner p-rel">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="cvpro-banner-content-wrapper">
                <p className="m-0 cvpro-intro">Introducing</p>
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/cvprologo.png`}
                  alt=""
                  srcset=""
                />
                <p className="abt-cvpro">
                  An awesome Educational kit to explore AI, Machine Learning
                  <br /> and Data Science, using the lens of Autonomous Vehicles
                </p>
              </div>
            </Col>
            <Col lg={6}></Col>
          </Row>
        </Container>
      </section>

      {/* Meet CV Pro  */}
      <section id="meet-cvpro" className="meet-cvpro pt-50">
        <Container>
          <Row>
            <Col lg={12}>
              {/* heading  */}
              <div class="heading-npgs dark-blue-head pbm">
                <h2>
                  Meet<span> CV Pro</span>
                </h2>
              </div>

              {/* sub heading  */}
              <div class="sub-heading-npgs cvpro-sub-head pb-20">
                <p>
                  <span class="purple-text">AUTONOMOUS </span>|
                  <span class="blue-text"> CONNECTED </span>|
                  <span class="green-text"> ELECTRIC </span>
                </p>
              </div>
            </Col>
          </Row>

          {/* image  */}
          <Row>
            <Col lg={12}>
              <div className="char-img text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro/meet-cv.png`}
                  alt=""
                  srcset=""
                />
              </div>
            </Col>
          </Row>

          {/* Text  */}
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="about-cv-pro">
                <p>
                  CV Pro is a cutting-edge development and education platform
                  with smart computer vision technology that utilizes the
                  extensive sensor suites, powerful computational abilities and
                  state-of-the-art communication channels enabling users to
                  learn the intricacies of advanced AI technologies such as
                  Machine Learning, applied deep learning and neural networks
                  utilized in real-time Autonomous Vehicles.
                </p>
              </div>
            </Col>
          </Row>

          {/* CV pro app and compatibility  */}
          <section>
            <Container>
              <Row>
                <div className="col-lg-10 mx-auto d-flex cvpro-comp-wrapper">
                  {/* <Col lg={4} md={4}> */}
                  <div className="cvpro-app-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/cv-pro/cv-pro-app-a.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                  {/* </Col> */}

                  {/* <Col lg={4} md={4}> */}
                  <div className="cvpro-app-img cvpro-img-b">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/cv-pro/cv-pro-app-b.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                  {/* </Col> */}

                  {/* <Col lg={4} md={4}> */}
                  <div className="cvpro-app-img cvpro-img-c">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/cv-pro/cv-pro-app-c.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                  {/* </Col> */}
                </div>
              </Row>
            </Container>
          </section>

          {/* Video  */}
          <div className="cv-pro-meet-vid">
            <iframe
              src="https://player.vimeo.com/video/846507330?h=f2908f1944&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="KS 1. (Go simple, easy, pro)CV Pro Concept intro vid wit musiq alone"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </Container>
      </section>

      {/* 3 CV Pro different packs  */}
      <section className="cvpro-packs pt-30 pb-30 mb-50">
        <Container className="packs-container">
          <Row>
            <Col lg={12}>
              <div class="sub-heading-npgs cvpro-subhead">
                <p>Make It Yours</p>
              </div>

              {/* heading  */}
              <div class="heading-npgs dark-blue-head dark pb-50">
                <h2>
                  CV Pro comes in 3 different <span>packs</span>
                </h2>
              </div>
            </Col>
          </Row>

          {/* Packs cards  */}
          <Row className="pt-100">
            <Col lg={10} className="mx-auto">
              <Row>
                {/* Pack - 1 */}
                <Col lg={4} md={6}>
                  <div className="cvpro-pack-card-wrapper">
                    <Card className="cvpro-pack-card cvpro-pack-a p-rel">
                      <Card.Img
                        variant="top"
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/cvpro-pack-1.png`}
                      />
                      <Card.Body className="pt-100">
                        <Card.Title className="cvpro-pack-name">
                          Self Driving Car pack
                        </Card.Title>
                        <Card.Text className="abt-cvpro-packs">
                          <p className="text-center">Includes</p>
                          <ul>
                            <li>CV Pro Al kit</li>
                            <li>In-Built rechargeable battery</li>
                            <li>Ultra-Sonic Sensor</li>
                            <li>Phone Mount</li>
                            <li>CV Pro App - IOS & Android</li>
                            <li>Digital Instructions & training videos</li>
                            <li>Free Virtual Driverless Car</li>
                          </ul>
                          <p className="course-duration">Course - 20 Hours</p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <div className="cvpro-no-txt cvpro-no-txt-a">
                      <p>x1</p>
                    </div>
                  </div>
                </Col>

                {/* Pack - 2 */}
                <Col lg={4} md={6}>
                  <div className="cvpro-pack-card-wrapper">
                    <Card className="cvpro-pack-card cvpro-pack-b p-rel">
                      <Card.Img
                        variant="top"
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/cvpro-pack-2.png`}
                      />
                      <Card.Body className="pt-100">
                        <Card.Title className="cvpro-pack-name">
                          AV + Platooning pack
                        </Card.Title>
                        <Card.Text className="abt-cvpro-packs">
                          <p className="text-center">Includes</p>
                          <ul>
                            <li>CV Pro Al kit</li>
                            <li>In-Built rechargeable battery</li>
                            <li>Ultra-Sonic Sensor</li>
                            <li>Phone Mount</li>
                            <li>CV Pro App - IOS & Android</li>
                            <li>Digital Instructions & training videos</li>
                            <li>Free Virtual Driverless Car</li>
                          </ul>
                          <p className="course-duration">Course - 20 Hours</p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <div className="cvpro-no-txt cvpro-no-txt-b">
                      <p>x2</p>
                    </div>
                  </div>
                </Col>

                {/* Pack - 3 */}
                <Col lg={4} md={6}>
                  <div className="cvpro-pack-card-wrapper">
                    <Card className="cvpro-pack-card cvpro-pack-c p-rel">
                      <Card.Img
                        variant="top"
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/cvpro-pack-3.png`}
                      />
                      <Card.Body className="pt-100">
                        <Card.Title className="cvpro-pack-name cvpro-pack-name-c">
                          Classroom pack
                        </Card.Title>
                        <Card.Text className="abt-cvpro-packs">
                          <p className="text-center">Includes</p>
                          <ul>
                            <li>CV Pro Al kit</li>
                            <li>In-Built rechargeable battery</li>
                            <li>Ultra-Sonic Sensor</li>
                            <li>Phone Mount</li>
                            <li>CV Pro App - IOS & Android</li>
                            <li>Digital Instructions & training videos</li>
                            <li>Packed in Stackable Tubs</li>
                            <li>Free Digital Lesson Plans</li>
                            <li>Free Virtual Driverless Car</li>
                          </ul>
                          <p className="course-duration">Course - 20 Hours</p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <div className="cvpro-no-txt cvpro-no-txt-c">
                      <p>x3</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="get-cvpro text-center">
                <p>Get your CV Pro today!</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Addons section  */}
      <section className="cvpro-pack-addons pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <div className="training-mat-wrapper">
                <div className="cvpro-mat-txt-wrapper">
                  <div className="training-mat-txt">
                    <p>
                      <strong>
                        Smart Training Mat <br />
                      </strong>
                      Comes with Accessories
                    </p>
                  </div>

                  <div className="addons-txt">
                    <p>Add-ons</p>
                  </div>
                </div>

                {/* CV pro training mat image  */}
                <div className="training-mat-img text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/cv-pro/cvpro-training-mat.png`}
                    alt=""
                    srcset=""
                  />
                </div>

                {/* Sign boards  */}
                <div className="sign-boards-img text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/cv-pro/cvpro-signs.png`}
                    alt=""
                  />
                </div>

                <div className="cvpro-tm-txt text-center">
                  <p>Avail the exclusive Kickstarter add-on reward.</p>
                </div>
              </div>
            </Col>

            <Col lg={6} md={6}>
              <div className="mecanum-wheels-wrapper">
                <div className="cvpro-mat-txt-wrapper">
                  <div className="training-mat-txt">
                    <p>
                      <strong>
                        Mecanum Wheels <br />
                      </strong>
                      Adapter Included*
                    </p>
                  </div>

                  <div className="addons-txt">
                    <p>Add-ons</p>
                  </div>
                </div>

                {/* CV pro with mecanum wheels img  */}
                <div className="cvpro-mecan-wheels-img text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/cv-pro/cvpro-mecanum.png`}
                    alt=""
                  />
                </div>

                {/* text  */}
                <div className="cvpro-mw-text-wrapper text-center pt-30">
                  <p className="m-0">
                    CV Pro with <br /> Mecanum Wheels
                  </p>
                  <p>Move in all directions</p>
                </div>

                <div className="wheel-text-wrapper">
                  <div className="macanum-wheel-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/cv-pro/mecanum-wheels.png`}
                      alt=""
                    />
                  </div>

                  {/* x4 text  */}
                  <div className="x4-text">
                    <p>x4</p>
                  </div>

                  {/* wheel connector  */}
                  <div className="wheel-connect-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/cv-pro/wheel-con.png`}
                      alt=""
                    />
                  </div>

                  {/* x4 text  */}
                  <div className="x4-text">
                    <p>x4</p>
                  </div>
                </div>

                {/* availbility text  */}
                <div className="availability-text text-center pt-30">
                  <p>*Colours may vary based on availability</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* CV Pro at Global events */}
      <section className="global-events pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={7}>
                  {/* heading  */}
                  <div class="heading-npgs dark-blue-head dark pb-20">
                    <h2>
                      CV Pro at Global <span>Events</span>
                    </h2>
                  </div>
                </Col>
                <Col lg={5}></Col>
              </Row>
            </Col>
          </Row>

          {/* image and text  */}
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={7}>
                  {/* Gloabal event image  */}
                  <div className="global-event-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/cv-pro/global-event.png`}
                      alt=""
                    />
                  </div>
                </Col>

                {/* text  */}
                <Col lg={5}>
                  <div className="g-event-txt">
                    <p className="event-que">
                      What will you learn with CV Pro?
                    </p>
                    <p className="event-ans">
                      The major tech concepts and operational techniques of
                      advanced computer vision technologies used in real-time
                      self-driving cars.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Operational techniques  */}
      <section className="operational-techniques pt-50 pb-50">
        <Container className="ot-container">
          <Row>
            <Col lg={12}>
              {/* heading  */}
              <div class="heading-npgs dark-blue-head dark">
                <h2>
                  Get to learn the <span>operational techniques</span>
                </h2>
              </div>

              {/* sub heading  */}
              <div class="sub-heading-npgs fw-700">
                <p>
                  of advanced computer vision technologies used in real time
                  self-driving vehicles such as:
                </p>
              </div>
            </Col>
          </Row>

          {/* points  */}
          <Row>
            <Col lg={6} md={6}>
              <div className="ot-wrapper">
                <ul>
                  <li>
                    <IoIosCheckbox /> Vehicle or person-following
                  </li>
                  <li>
                    <IoIosCheckbox /> Slow down for kerbs
                  </li>
                  <li>
                    <IoIosCheckbox /> Stop at traffic lights & Stop signs
                  </li>
                  <li>
                    <IoIosCheckbox /> Platooning - follow vehicles
                  </li>
                  <li>
                    <IoIosCheckbox /> Allowing vehicles to stay in lane
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={6} md={6}>
              <div className="ot-wrapper">
                <ul>
                  <li>
                    <IoIosCheckbox /> Lane detection & lane changing
                  </li>
                  <li>
                    <IoIosCheckbox /> Prevent many types of collision with
                    object identification & emergency braking
                  </li>
                  <li>
                    <IoIosCheckbox /> Yield for crossing
                  </li>
                  <li>
                    <IoIosCheckbox /> Go around obstacles etc
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* With CV Pro you get to work with & Why CV Pro? */}
      <section className="cvpro-work-with pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={6} md={6}>
                  {/* heading  */}
                  <div class="heading-npgs dark-blue-head dark pt-30">
                    <h2>
                      With CV Pro
                      <br />
                      you get to <span>work with</span>
                    </h2>
                  </div>

                  {/* logos */}
                  <div className="cvpro-work-with-logos-wrapper">
                    {/* first row  */}
                    <div className="cvpro-work-with-logos">
                      {/* logo -1 */}
                      <div className="cvpro-work-with-logo-a text-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/open-cv.svg`}
                          alt=""
                        />
                      </div>
                      {/* logo -2  */}
                      <div className="cvpro-work-with-logo-b text-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/python-b.png`}
                          alt=""
                        />
                      </div>
                    </div>
                    {/* second row */}
                    <div className="cvpro-work-with-logos">
                      {/* logo -1 */}
                      <div className="cvpro-work-with-logo-a cvpro-flutter text-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/flutter.svg`}
                          alt=""
                        />
                      </div>
                      {/* logo -2  */}
                      <div className="cvpro-work-with-logo-b text-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/tensorflow.svg`}
                          alt=""
                        />
                      </div>
                    </div>

                    {/* Third row  */}
                    <div className="cvpro-work-with-logos">
                      {/* logo -1 */}
                      <div className="cvpro-work-with-logo-a text-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/tensorflow-lite.svg`}
                          alt=""
                        />
                      </div>
                      {/* logo -2  */}
                      <div className="cvpro-work-with-logo-b text-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/android-studio.svg`}
                          alt=""
                        />
                      </div>
                    </div>

                    {/* fourth row  */}
                    <div className="cvpro-work-with-logos cv-border-bottom">
                      {/* logo -1 */}
                      <div className="cvpro-work-with-logo-a text-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/deep-learning.svg`}
                          alt=""
                        />
                      </div>
                      {/* logo -2  */}
                    </div>
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  {/* heading  */}
                  <div class="heading-npgs dark-blue-head dark pt-30">
                    <h2>
                      Why CV <span>Pro?</span>
                    </h2>
                  </div>

                  <div className="why-cv-pro-txt">
                    <p>
                      CV Pro is a plug-and-play robot powered by an Open Source
                      Technology stack that gives users the power to experience
                      the world with ever greater richness.
                    </p>
                  </div>

                  {/* CV Pro image  */}
                  <div className="cvpro-img-wrapper text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/cv-pro/cvpro-mob.png`}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Autonomous navigation tech  */}
      <section className="autonomous-nav-tech pt-30 pb-30">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Col lg={12}>
                {/* heading  */}
                <div class="heading-npgs dark-blue-head dark pb-30">
                  <h2>
                    The go-to platform to discover
                    <span> Autonomous Navigation Tech</span>
                  </h2>
                </div>
              </Col>
            </Col>
          </Row>

          {/* image with text row  */}
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {/* card -1  */}
                <Col lg={3} md={6}>
                  <div className="tech-img-text-wrapper">
                    <div className="tech-image-wrap">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/self-driving-car.png`}
                        alt=""
                      />
                    </div>
                    <div className="tech-content-wrap">
                      <p>
                        Program your
                        <br /> own Self-Driving
                        <br /> Car
                      </p>
                    </div>
                  </div>
                </Col>

                {/* card -2 */}
                <Col lg={3} md={6}>
                  <div className="tech-img-text-wrapper">
                    <div className="tech-image-wrap">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/platooning-basic.png`}
                        alt=""
                      />
                    </div>
                    <div className="tech-content-wrap">
                      <p>
                        Explore Basics
                        <br /> of
                        <br /> Platooning
                      </p>
                    </div>
                  </div>
                </Col>

                {/* card -3  */}
                <Col lg={3} md={6}>
                  <div className="tech-img-text-wrapper">
                    <div className="tech-image-wrap">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/autonomous.png`}
                        alt=""
                      />
                    </div>
                    <div className="tech-content-wrap">
                      <p>
                        Discover Autonomous
                        <br /> Vehicles
                        <br /> Technology
                      </p>
                    </div>
                  </div>
                </Col>

                {/* card -4  */}
                <Col lg={3} md={6}>
                  <div className="tech-img-text-wrapper">
                    <div className="tech-image-wrap">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/platooning-adv.png`}
                        alt=""
                      />
                    </div>
                    <div className="tech-content-wrap">
                      <p>
                        Learn Advanced
                        <br /> Platooning
                        <br /> Techniques
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Who is CV Pro for  */}
      <section className="cvpro-for pt-50">
        <Container>
          <Row>
            <Col lg={12}>
              {/* heading  */}
              <div class="heading-npgs dark-blue-head dark pb-30">
                <h2>
                  Who is
                  <span> CV Pro for</span>
                </h2>
              </div>
            </Col>
          </Row>

          {/* first row of images and text  */}
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {/* card - 1 */}
                <Col lg={3} md={3}>
                  <div className="who-is-cvpro-for-card-wrapper text-center">
                    {/* icon  */}
                    <div className="who-is-cvpro-img-wrapper">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/uni-students.svg`}
                        alt=""
                      />
                    </div>

                    {/* caption  */}
                    <div className="who-is-cvpro-text-wrapper">
                      <p>
                        High School,
                        <br /> College & University Students
                      </p>
                    </div>
                  </div>
                </Col>

                {/* Card - 2  */}
                <Col lg={3} md={3}>
                  <div className="who-is-cvpro-for-card-wrapper text-center">
                    {/* icon  */}
                    <div className="who-is-cvpro-img-wrapper">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/ai-enthusiast.svg`}
                        alt=""
                      />
                    </div>

                    {/* caption  */}
                    <div className="who-is-cvpro-text-wrapper">
                      <p>AI Enthusiasts</p>
                    </div>
                  </div>
                </Col>

                {/* Card - 3 */}
                <Col lg={3} md={3}>
                  <div className="who-is-cvpro-for-card-wrapper text-center">
                    {/* icon  */}
                    <div className="who-is-cvpro-img-wrapper wciw-custom-height">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/wro.svg`}
                        alt=""
                      />
                    </div>

                    {/* caption  */}
                    <div className="who-is-cvpro-text-wrapper">
                      <p>
                        WRO Future
                        <br /> Engineers Competition
                      </p>
                    </div>
                  </div>
                </Col>

                {/* Card - 4  */}
                <Col lg={3} md={3}>
                  <div className="who-is-cvpro-for-card-wrapper text-center">
                    {/* icon  */}
                    <div className="who-is-cvpro-img-wrapper">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/robotics.svg`}
                        alt=""
                      />
                    </div>

                    {/* caption  */}
                    <div className="who-is-cvpro-text-wrapper">
                      <p>Robotics Hobbyists</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* second row of images and text  */}
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {/* card - 1 */}
                <Col lg={3} md={3}>
                  <div className="who-is-cvpro-for-card-wrapper text-center">
                    {/* icon  */}
                    <div className="who-is-cvpro-img-wrapper">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/global-autobot.svg`}
                        alt=""
                      />
                    </div>

                    {/* caption  */}
                    <div className="who-is-cvpro-text-wrapper">
                      <p>Global Autobot Contests</p>
                    </div>
                  </div>
                </Col>

                {/* Card - 2  */}
                <Col lg={3} md={3}>
                  <div className="who-is-cvpro-for-card-wrapper text-center">
                    {/* icon  */}
                    <div className="who-is-cvpro-img-wrapper">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/makers-tinkeres.svg`}
                        alt=""
                      />
                    </div>

                    {/* caption  */}
                    <div className="who-is-cvpro-text-wrapper">
                      <p>Makers & Tinkerers</p>
                    </div>
                  </div>
                </Col>

                {/* Card - 3 */}
                <Col lg={3} md={3}>
                  <div className="who-is-cvpro-for-card-wrapper text-center">
                    {/* icon  */}
                    <div className="who-is-cvpro-img-wrapper">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/hw-sw-developers.svg`}
                        alt=""
                      />
                    </div>

                    {/* caption  */}
                    <div className="who-is-cvpro-text-wrapper">
                      <p>
                        Hardware &<br /> Software
                        <br /> Developers
                      </p>
                    </div>
                  </div>
                </Col>

                {/* Card - 4  */}
                <Col lg={3} md={3}>
                  <div className="who-is-cvpro-for-card-wrapper text-center">
                    {/* icon  */}
                    <div className="who-is-cvpro-img-wrapper">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/industry.svg`}
                        alt=""
                      />
                    </div>

                    {/* caption  */}
                    <div className="who-is-cvpro-text-wrapper">
                      <p>Industry</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CVPro;
