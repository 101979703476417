// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-events-custom {
  padding: 0px;
}

@media (max-width: 1024px) {
  .news-events-custom li {
    padding: 10px 20px;
  }
}

@media (max-width: 576px) {
  .news-events-custom li.active {
    padding: 10px 0px;
  }
  .news-events-custom li {
    padding: 10px 0px;
  }

  .news-events-custom {
    column-gap: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/NewsAndEvents.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".news-events-custom {\n  padding: 0px;\n}\n\n@media (max-width: 1024px) {\n  .news-events-custom li {\n    padding: 10px 20px;\n  }\n}\n\n@media (max-width: 576px) {\n  .news-events-custom li.active {\n    padding: 10px 0px;\n  }\n  .news-events-custom li {\n    padding: 10px 0px;\n  }\n\n  .news-events-custom {\n    column-gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
