// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .learning div:first-child > h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dBlue);
} */
section.we_are_do img {
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}
.life_cta {
  position: relative;
  /* height:300px; */
  padding: 100px 0px;
  width: 100%;
  background: rgb(160 208 255 / 10%);
}
.life_cta img {
  width: 30%;
  object-fit: cover;
  position: absolute;
  bottom: 0px;
}
section.stand_for {
  background: rgb(195 197 248 / 10%);
}
.stand_for .card_uni,
.learning .card_uni {
  height: 400px;
}
#LifeAtPage .home_form_btn {
  display: block;
}

@media (max-width: 576px) {
  .stand_for .card_uni,
  .learning .card_uni {
    height: 340px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/LifeAt.css"],"names":[],"mappings":"AAAA;;;;;GAKG;AACH;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,kCAAkC;AACpC;AACA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,kCAAkC;AACpC;AACA;;EAEE,aAAa;AACf;AACA;EACE,cAAc;AAChB;;AAEA;EACE;;IAEE,aAAa;EACf;AACF","sourcesContent":["/* .learning div:first-child > h2 {\r\n  font-size: 30px;\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n  color: var(--dBlue);\r\n} */\r\nsection.we_are_do img {\r\n  height: 100%;\r\n  object-fit: cover;\r\n  border-radius: 25px;\r\n}\r\n.life_cta {\r\n  position: relative;\r\n  /* height:300px; */\r\n  padding: 100px 0px;\r\n  width: 100%;\r\n  background: rgb(160 208 255 / 10%);\r\n}\r\n.life_cta img {\r\n  width: 30%;\r\n  object-fit: cover;\r\n  position: absolute;\r\n  bottom: 0px;\r\n}\r\nsection.stand_for {\r\n  background: rgb(195 197 248 / 10%);\r\n}\r\n.stand_for .card_uni,\r\n.learning .card_uni {\r\n  height: 400px;\r\n}\r\n#LifeAtPage .home_form_btn {\r\n  display: block;\r\n}\r\n\r\n@media (max-width: 576px) {\r\n  .stand_for .card_uni,\r\n  .learning .card_uni {\r\n    height: 340px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
