// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExploreMore {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.6s ease-in;
  border-radius: 15px;
}

.ExploreMore:hover img{
  transform: scale(1.2);
  transition: all 0.6s ease-in;
}

.ExploreMore img {
  height: 170px;
  width: 100%;
  object-fit: cover;

  transition: all 0.9s;
  transform: scale(1);
}

.ExploreMore:hover img {
  transform: scale(1.3);
}

.ExploreMore .overlay_text {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  bottom: 0px;
  text-align: center;
  width: 100%;
  border-radius: 15px;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(#0000008f, #3d29296e);
}
.card2 h2:first-child,
.card3 h2:first-child {
  font-size: var(--hSize);
  color: #264ec8;
}
.explore_container h2 span {
  color: var(--dPink);
}
@media screen and (max-width: 576px) {
  .ExploreMore {
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/ExploreMore.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,4BAA4B;EAC5B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;;EAEjB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,iDAAiD;AACnD;AACA;;EAEE,uBAAuB;EACvB,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".ExploreMore {\r\n  position: relative;\r\n  overflow: hidden;\r\n  cursor: pointer;\r\n  transition: all 0.6s ease-in;\r\n  border-radius: 15px;\r\n}\r\n\r\n.ExploreMore:hover img{\r\n  transform: scale(1.2);\r\n  transition: all 0.6s ease-in;\r\n}\r\n\r\n.ExploreMore img {\r\n  height: 170px;\r\n  width: 100%;\r\n  object-fit: cover;\r\n\r\n  transition: all 0.9s;\r\n  transform: scale(1);\r\n}\r\n\r\n.ExploreMore:hover img {\r\n  transform: scale(1.3);\r\n}\r\n\r\n.ExploreMore .overlay_text {\r\n  position: absolute;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  color: #fff;\r\n  bottom: 0px;\r\n  text-align: center;\r\n  width: 100%;\r\n  border-radius: 15px;\r\n  height: 100%;\r\n  overflow: hidden;\r\n  background: radial-gradient(#0000008f, #3d29296e);\r\n}\r\n.card2 h2:first-child,\r\n.card3 h2:first-child {\r\n  font-size: var(--hSize);\r\n  color: #264ec8;\r\n}\r\n.explore_container h2 span {\r\n  color: var(--dPink);\r\n}\r\n@media screen and (max-width: 576px) {\r\n  .ExploreMore {\r\n    margin-bottom: 10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
