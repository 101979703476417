import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, getRobotixProducts } from '../../redux/apiCalls';
import Card2 from '../../component/Card2';
import { Dropdown } from 'react-bootstrap';

const Robotix = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getRobotixProducts(dispatch);
    }, [dispatch]);

    const allProducts = useSelector((state) => state.robotix.robotixProducts);
    const [selectedCategory, setSelectedCategory] = useState('');


    const products = allProducts
        .filter((item) => item.status === true)
        .filter((item) => !selectedCategory || (item.productId && item.productId.courseType === selectedCategory));


    const [selectedSort, setSelectedSort] = useState('');
    const [sortingOption, setSortingOption] = useState('');

    // Define a function to handle sorting
    const handleSort = (sortType) => {
        setSelectedSort(sortType);
        setSortingOption(sortType);
    };


    // Mapping of sorting options to their display text
    const sortingOptions = {
        'lowToHigh': 'Low to High (Price)',
        'highToLow': 'High to Low (Price)',
        'atoz': 'A to Z',
        'ztoa': 'Z to A',
    };

    // Sorting logic
    let sortedCourses = [...products];

    if (selectedCategory) {
        sortedCourses = sortedCourses.filter((item) => item.productId !== null && item.productId.courseType === selectedCategory);
    }

    // if (selectedSort === 'lowToHigh') {
    //     sortedCourses.sort((a, b) => a.price - b.price);
    // } else if (selectedSort === 'highToLow') {
    //     sortedCourses.sort((a, b) => b.price - a.price);
    // } else if (selectedSort === 'atoz') {
    //     sortedCourses.sort((a, b) => (a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1));
    // } else if (selectedSort === 'ztoa') {
    //     sortedCourses.sort((a, b) => (b.name?.toLowerCase() < a.name?.toLowerCase() ? -1 : 1));
    // }

    if (selectedSort === 'lowToHigh') {
        sortedCourses.sort((a, b) => a.price - b.price);
    } else if (selectedSort === 'highToLow') {
        sortedCourses.sort((a, b) => b.price - a.price);
    } else if (selectedSort === 'atoz') {
        sortedCourses.sort((a, b) => {
            if (a.title && b.title) {
                return a.title.localeCompare(b.title, undefined, { sensitivity: 'base' });
            }
            return 0;
        });
    } else if (selectedSort === 'ztoa') {
        sortedCourses.sort((a, b) => {
            if (a.title && b.title) {
                return b.title.localeCompare(a.title, undefined, { sensitivity: 'base' });
            }
            return 0;
        });
    }

    return (
        <>
            <div className='container-fluid p-0'>
                <div className="row">
                    <div className="col-12">
                        <div className="collection-banner">
                            <img src={`${process.env.PUBLIC_URL}/images/robotix/robotix-banner.png`} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div class="col-lg-12">
                        <div class="heading pl-20 up-tab-heading pt-30 pb-20 d-flex justify-content-between">
                            <h1>All Products</h1>
                            <div className='filter-btns'>
                                <div className='category-btn'>
                                    <Dropdown className='d-inline mx-3' onSelect={handleSort}>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            {sortingOption ? sortingOptions[sortingOption] : 'Sort By'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="lowToHigh">Low to High (Price)</Dropdown.Item>
                                            <Dropdown.Item eventKey="highToLow">High to Low (Price)</Dropdown.Item>
                                            <Dropdown.Item eventKey="atoz">A to Z</Dropdown.Item>
                                            <Dropdown.Item eventKey="ztoa">Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        {/* Filter courses buttons */}

                    </div>
                </div>
                <div className="col-12 py-5">
                    <div className="product-wrapper">
                        <div className="row">
                            {sortedCourses.length > 0 &&
                                sortedCourses.map((product, index) => (
                                    <div className="col-4" key={index}>
                                        <Card2 type="robotix" {...product} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default Robotix
