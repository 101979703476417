import React from 'react'
import SkillsCard from '../cards/SkillsCard'
// import SkillsCard from '../../cards/SkillsCard'

const LearningsKitTab = () => {
  return (
   <SkillsCard />
  )
}

export default LearningsKitTab
