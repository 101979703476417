import "bootstrap/dist/css/bootstrap.css";
import "./Certificate.css";
import { Col, Container, Row } from "react-bootstrap";

const Certificate = (props) => {
  return (
    <div className="certificate-container">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="certi-img p-rel text-center">
              {/* Brand logo */}
              <div className="brand-logo-certi">
                <img
                  src={`${process.env.PUBLIC_URL}/images/brand-logo.svg`}
                  alt=""
                />
              </div>

              {/* certificate of completion  */}
              <div className="coc-text">
                <h3>certificate of completion</h3>
                <p>Presented to</p>
              </div>

              {/* student name */}
              <div className="student-name certi-content content-position">
                <h2>{props.firstName} {props.lastName}</h2>
              </div>

              {/* completed course */}
              <div className="completed-course content-position">
                <p>
                  This certificate is awarded for successful completion of
                  course
                </p>
              </div>

              {/* course name */}
              <div className="course-name certi-content content-position">
                <h2>{props.courseTitle}</h2>
              </div>

              {/* Course completion date  */}
              <div className="completion-date certi-content content-position">
                <h2>{props.compeletionDate}</h2>
              </div>

              {/* aiws stamp */}
              <div className="aiws-stamp-img content-position">
                <img
                  src={`${process.env.PUBLIC_URL}/images/certificates/aiws-stamp.png`}
                  alt=""
                />
              </div>

              {/* Unique token number */}
              <div className="unique-ceri-id content-position">
                {/* <p>#543876AJKHGFKHGJKLGKJ</p> */}
              </div>

              {/* Signature image, name and designation  */}
              <div className="mangt-wrapper content-position">
                {/* signature */}
                <div className="certi-sign-img">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/certificates/signature.png`}
                    alt=""
                  />
                </div>

                {/* certifier name  */}
                <div className="certifier-name">
                  <h6>Mr. Ramana Prashad</h6>
                </div>

                {/* Certifier designation  */}
                <div className="certifier-designation">
                  <p>Chairman, AI World School</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Certificate;
