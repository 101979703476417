import { FaClock, FaLocationArrow } from "react-icons/fa";

const SimilarJobCard = ({
  jobCategory,
  category,
  para,
  remoteText,
  fullTimeText,
  applyLink,
}) => {
  return (
    <div className="similar-job-card">
      <div className="job-info">
        <p>{jobCategory}</p>
        <p>{category}</p>
        <p>{para}</p>
      </div>
      <div className="job-icons mb-3">
        <div className="icon">
          <FaLocationArrow className="dPink" />
          <span className="fw-700">{remoteText}</span>
        </div>
        <div className="icon">
          <FaClock />
          <span className="fw-700">{fullTimeText}</span>
        </div>
      </div>
      <a
        href={applyLink}
        target="_blank"
        rel="noopener noreferrer"
        className="uniBtn uniBtnBlue btnPLight"
      >
        Apply Now
      </a>
    </div>
  );
};

export default SimilarJobCard;
