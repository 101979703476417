import { Link } from "react-router-dom";
import "./Banner.css";
function Banner() {
  return (
    <div>
      <section className="bundle_india bundle_india_main px-lg-5 pb-4">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-5 col-lg-5 sb-txt-order order-1 order-lg-0">
              <h1 className="banner_txt dBlue">
                Embrace the future of education with{" "}
                <span className="dPink"> STEM, ROBOTICS and Al </span>powered
                solutions
              </h1>
            </div>
            <div className="col-md-7 col-lg-7 p-rel">
              <div className="banner_img">
                <img
                  src="images/SchoolBundle/outside/outside-banner-1.png"
                  alt="Hello"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Banner;
