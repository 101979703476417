// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1024px) {
  .psb-banner-img {
    bottom: 37%;
    left: 68%;
  }

  .psb-banner-head {
    font-size: 30px !important;
  }

  .psb-banner-para {
    font-size: 22px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/bundle/BannerPrimaryBundle.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,SAAS;EACX;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["@media (max-width: 1024px) {\n  .psb-banner-img {\n    bottom: 37%;\n    left: 68%;\n  }\n\n  .psb-banner-head {\n    font-size: 30px !important;\n  }\n\n  .psb-banner-para {\n    font-size: 22px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
