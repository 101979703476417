// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tab li {
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    border: 1px solid #977cff;
    padding: 10px 40px;
    border-radius: 25px;
    /* margin-right: 50px; */
  }
  .custom-tab li:hover {
    cursor: pointer;
  }
  .custom-tab li.active {
    background: var(--dBlue);
    padding: 10px 40px;
    color: #fff;
    border-radius: 25px;
  }
  /* .tab-list {
    padding-left: 1rem;
} */
@media(max-width:576px)
{
  .custom-tab li {
    font-size: 12px;
    font-weight: 600;
    padding: 10px 26px;
    width: 50%;
}
.custom-tab li.active {
  width: 50%;
  padding: 10px 16px;
}
}`, "",{"version":3,"sources":["webpack://./src/component/common/tab/TabWrapperone.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;EAC1B;EACA;IACE,eAAe;EACjB;EACA;IACE,wBAAwB;IACxB,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;EACA;;GAEC;AACH;;EAEE;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;AACd;AACA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA","sourcesContent":[".custom-tab li {\n    font-size: 18px;\n    font-weight: 600;\n    display: inline-block;\n    border: 1px solid #977cff;\n    padding: 10px 40px;\n    border-radius: 25px;\n    /* margin-right: 50px; */\n  }\n  .custom-tab li:hover {\n    cursor: pointer;\n  }\n  .custom-tab li.active {\n    background: var(--dBlue);\n    padding: 10px 40px;\n    color: #fff;\n    border-radius: 25px;\n  }\n  /* .tab-list {\n    padding-left: 1rem;\n} */\n@media(max-width:576px)\n{\n  .custom-tab li {\n    font-size: 12px;\n    font-weight: 600;\n    padding: 10px 26px;\n    width: 50%;\n}\n.custom-tab li.active {\n  width: 50%;\n  padding: 10px 16px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
