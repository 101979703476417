import React, { useState, useEffect } from "react";

const Counter = ({ targets }) => {
  const [counts, setCounts] = useState(targets.map(() => 0));
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".about_home_counter_cont");
      const elementPosition = element?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementPosition < windowHeight && !isCounting) {
        setIsCounting(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isCounting]);

  useEffect(() => {
    if (isCounting) {
      const intervals = targets.map((targetObj, index) =>
        setInterval(() => {
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            const { value: target, text } = targetObj;
            if (newCounts[index] < target) {
              newCounts[index] += Math.ceil(target / 100);
              if (newCounts[index] > target) {
                newCounts[index] = target;
              }
            }
            return newCounts;
          });
        }, 40)
      );

      return () => {
        intervals.forEach((interval) => clearInterval(interval));
      };
    }
  }, [isCounting, targets]);

  return (
    <div className="about_home_counter_cont">
      {targets.map((targetObj, index) => (
        <div className="about_home_counter_item" key={index}>
          <h2 className="ff fw-600 pink-c">
            {isCounting ? counts[index] : "0"}+
          </h2>
          <p>{targetObj.text}</p>
        </div>
      ))}
    </div>
  );
};

export default Counter;
