// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-image img{
    height: 150px;
    width: 150px;
    border: 1px solid var(--blue);
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.change-pfp {
    position: absolute;
    top: 65%;
    left: 55%;
    transform: translate(-50%, -50%);
}

.change-pfp svg {
    color: var(--dBlue);
    font-size: 35px;
}

.discard-changes-btn,
.save-changes-btn {
    padding: 10px 25px !important;
    font-weight: 600;
    border-radius: 30px;
}

/* .discard-changes-btn,  .discard-changes-btn:hover{
    background: none !important;
    border: 2px solid var(--cBBlue);
    color: var(--dPink);
    font-weight: 600;
} */

@media (max-width: 767px) {
    .profile-image-container {
        margin-top: 3rem !important;
    }

    .change-pfp {
        left: 70%;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/user-profile/EditProfile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;;IAEI,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;;;;;GAKG;;AAEH;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,SAAS;IACb;AACJ","sourcesContent":[".profile-image img{\r\n    height: 150px;\r\n    width: 150px;\r\n    border: 1px solid var(--blue);\r\n    border-radius: 50%;\r\n    object-fit: cover;\r\n    object-position: top;\r\n}\r\n\r\n.change-pfp {\r\n    position: absolute;\r\n    top: 65%;\r\n    left: 55%;\r\n    transform: translate(-50%, -50%);\r\n}\r\n\r\n.change-pfp svg {\r\n    color: var(--dBlue);\r\n    font-size: 35px;\r\n}\r\n\r\n.discard-changes-btn,\r\n.save-changes-btn {\r\n    padding: 10px 25px !important;\r\n    font-weight: 600;\r\n    border-radius: 30px;\r\n}\r\n\r\n/* .discard-changes-btn,  .discard-changes-btn:hover{\r\n    background: none !important;\r\n    border: 2px solid var(--cBBlue);\r\n    color: var(--dPink);\r\n    font-weight: 600;\r\n} */\r\n\r\n@media (max-width: 767px) {\r\n    .profile-image-container {\r\n        margin-top: 3rem !important;\r\n    }\r\n\r\n    .change-pfp {\r\n        left: 70%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
