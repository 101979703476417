import React from "react";
import "./FlexiblePlan.css";
const FlexiblePlan3Page = () => {
  return (
    <div className="flexible_plan_page">
      <header className="flexible_header">
        <h1 className="text-white fw-700">Case Study</h1>
        <p className="text-white">
        Tailored STEM, Coding and Robotics Solution for an Elementary School
        </p>
      </header>
      <h2 className="flexible_plan_page_title">Flexible Plan 3</h2>
      <section className="flexible_plan_page_body container ">
        <p>
          Our client, a K12 school, aspired to implement a cohesive AI and
          Robotics program in accordance with the International curriculum and
          AI4K12 standards. With 600 students spanning grades 6 to 8, the
          objective was to provide a progressive educational journey, dedicating
          one hour per week for 20 weeks. The school held a strong commitment to
          AI education.
          <br /> <br />
          In collaboration, we devised a solution that encompassed the supply of
          Robotics kits, AI curriculum accessible via a Learning Management
          System (LMS), and trainers from Meritus.
        </p>
        <h2>Primary | Grades 1-3 - 100% Screen-Free Curriculum (24 weeks)</h2>
        <ul>
          <li>
            Provided 20 AI Lite robotics construction kits utilizing MIT App
            Inventor.
          </li>
          <li>
            Supply of 20 Phiro Pro kits with compatibility for advanced robotics
            education, with capability to work with Scratch, Snap!, and Phiro
            Code apps.
          </li>
          <li>
            Delivery of a comprehensive AI Learning curriculum through LMS,
            spanning all three grades, for online AI sessions featuring Scratch
            AI extensions in the computer science lab.
          </li>
          <li>
            Conducted an 18-hour "Train the Teacher" program conducted online
            over three days.
          </li>
          <li>A one-year built-in warranty accompanied the kit supply.</li>
        </ul>

        <p className="fw-600">
          The school acknowledged the kits' durability, with a clear service
          life of five years, leading to an economical cost per student over a
          span of five years, benefiting over 3000 students.
        </p>

        <p className="fw-600">
          Upon the conclusion of the 20-week program, the school presented
          student capstone projects during a dedicated event. Their unwavering
          commitment to innovative and creative learning, bolstered by our
          curated solution, consistently impressed parents and administrators.
          This case study exemplifies our capability to develop personalized
          educational solutions meeting the distinctive requirements and
          aspirations of educational institutions.
        </p>
      </section>
    </div>
  );
};

export default FlexiblePlan3Page;
