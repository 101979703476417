import "bootstrap/dist/css/bootstrap.css";
import "./Error404.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <section className="404page-wrapper">
      <Container fluid>
        <Row>
          <Col lg={12} className="p-0">
            <div className="error-img-wrapper">
              <img
                src={`${process.env.PUBLIC_URL}/images/404/404.png`}
                alt=""
              />
            </div>
          </Col>
        </Row>
        {/* buttons */}
        {/* <Row>
          <Col lg={6} className="p-0">
            <div className="back-to-previous-btn">
              <Link to="">
                <button>
                  Back to <span>previous</span>
                </button>
              </Link>
            </div>
          </Col>
          <Col lg={6} className="p-0">
            <div className="back-to-home">
              <Link to="/">
                <button>
                  Go back <span>home</span>
                </button>
              </Link>
            </div>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default Error404;
