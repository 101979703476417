import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useLocation, useHistory } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname, hash } = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });

        // Delay scrolling to the section
        setTimeout(() => {
            if (hash) {
                const targetSection = document.querySelector(hash);
                if (targetSection) {
                    targetSection.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, 100); // Adjust the delay as needed
    }, [pathname, hash]);

    // Manually trigger the scroll when clicking the same link
    const scrollToSection = () => {
        if (hash) {
            const targetSection = document.querySelector(hash);
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return null;
}

export default ScrollToTop
