import React from "react";
import "./HowItWork.css";
const HowItWork = () => {
  return (
    <div className="how_it_work pt-100 pb-100">
      <div className="container">
        <div className="row d-flex align-items-center ">
          <div className="col-md-5 ">
            <h2 className="inh2 pb-md-4">How it works ?</h2>
            <p className="pSize pb-md-4">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took.
            </p>
            <div className="how_it_work_btn">
              <a className="uniBtn uniBtnPinkBorder btnPLight ">Free Demo</a>
              <a className="uniBtn uniBtnBlue btnPLight ms-3">Customize Demo</a>
            </div>
          </div>
          <div className="col-md-6 offset-lg-1">
            <img src="images/landing1/image-2.png" alt="How It Works" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
