import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import userReducer from "./userRedux";
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import productRedux from "./productRedux";
import userRedux from "./userRedux";
import cardSlice from "./cartRedux";
import registeredUserRedux from "./registeredUserRedux";
import lectureRedux from "./lectureRedux";
import orderRedux from "./orderRedux";
import wishListRedux from "./wishListRedux";
import blogRedux from "./blogRedux";
import blogCategoryRedux from "./blogCategoryRedux";
import ratingRedux from "./ratingRedux";
import quizRedux from "./quizRedux";
import robotixProductRedux from "./robotixProductRedux";
import customSectionRedux from "./customSectionRedux";
import successStoriesRedux from "./successStoriesRedux";
import milestoneRedux from "./milestoneRedux";
// import persistReducer from "redux-persist/es/persistReducer";
// import persistStore from "redux-persist/es/persistStore";
// import productReducer from './productRedux'
// import registeredUserRedux from "./registeredUserRedux";
// import orderRedux from "./orderRedux";
// import discountRedux from "./discountRedux";
// import ageGroupRedux from "./ageGroupRedux";
// import courseTypeRedux from "./courseTypeRedux";


const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['loginUser'],
}



const rootReducer = combineReducers({ product: productRedux, loginUser: userRedux, cart: cardSlice, userRegistration: registeredUserRedux, lecture: lectureRedux, order: orderRedux, wishList: wishListRedux, blog: blogRedux, blogCategory: blogCategoryRedux, rating: ratingRedux, quiz: quizRedux, robotix: robotixProductRedux, customSection: customSectionRedux, successStories: successStoriesRedux, milestones: milestoneRedux });

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({reducer : rootReducer});

//  export default store;


// export let persistor = persistStore(store);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            // serializableCheck : {
            //     ignoreActions : [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // },
        }),
    // devTools: process.env.NODE_ENV !== 'production',
    // devTools: false,
});

export let persistor = persistStore(store);