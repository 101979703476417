import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, Outlet } from 'react-router-dom'
import { getCookie } from '../../config';
import jwtDecode from 'jwt-decode'; // A library for decoding JWT tokens
import { logoutUser } from '../../redux/userRedux';
import Cookies from 'js-cookie'; // Import the js-cookie library
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const UserComponents = () => {

    const dispatch = useDispatch();
    // const [auth, setAuth] = useState("");
    const user = useSelector((state) => state.loginUser.currentUser);
    const [isTokenExpired, setIsTokenExpired] = useState(false);

  const handleClose = () => setIsTokenExpired(false);

    const token = Cookies.get('userIn'); // Use Cookies.get to retrieve the cookie
    // useEffect(() => {
    //     // Check if a token exists in local storage or cookies
    //     if (token) {
    //         // Decode the token to get the expiration time
    //         const decodedToken = jwtDecode(token);
    //         const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

    //         if (decodedToken.exp < currentTime) {
    //             // Token has expired, show a popup or redirect to the login page
    //             dispatch(logoutUser()); // You can implement a logout action to clear user data and tokens
    //             // Cookies.remove('userIn');
    //             // setAuth(false);
    //             alert('Your session has expired. Please log in again.'); // Show a popup message
    //         }
    //     }
    // }, [user]);

    let auth;
    user ? auth = true : auth = false;
    return auth ? <Outlet /> : <Navigate to={'/'} />
    // const user = useSelector((state) => state.loginUser.currentUser);
    // let auth;
    // user ? auth = true : auth = false;
    // return auth ? <Outlet /> : <Navigate to='/' />
}

export default UserComponents



{/* <Modal show onHide={handleClose} className="login-popup">
        <Modal.Header closeButton>
            <Modal.Title>Session Expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please login again to continue.</Modal.Body>
        <Modal.Footer>
            <Link to={'/login'} onClick={handleClose}>
                <Button className="uniBtnBlue">
                    Login
                </Button>
            </Link>
        </Modal.Footer>
    </Modal> */}