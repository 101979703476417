import "bootstrap/dist/css/bootstrap.css";
import "../styles.css";
import "../order-details-pg/OrderDetails.css";
import CommonButton from "../common/Button/CommonButton";
import OrderSummary from "./OrderSummary";
import { Col, Container, Row } from "react-bootstrap";
import { MdKeyboardArrowRight, MdOutlineCheckCircle } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeDateFormat } from "../../config";
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { getAllOrders } from "../../redux/apiCalls";
import MiniLoader from "../common/Loader/miniLoader/MiniLoader";
import Loader from "../common/Loader/Loader";

const OrderDetails = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.loginUser.currentUser._id);


  useEffect(() => {
    getAllOrders(dispatch, userId);
  }, []);

  // const isOrderFetching = true;
  const isOrderFetching = useSelector((state) => state.order.isFetching);

  const location = useLocation();
  const id = location.pathname.split("/")[2];
  // const originalId = CryptoJS.AES.decrypt(id, process.env.REACT_APP_UNIQUE_ID_SEC_KEY);


  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const orderParam = params.get("orderId");
    const hashedOrderId = CryptoJS.AES.decrypt(
      orderParam,
      process.env.REACT_APP_UNIQUE_ID_SEC_KEY
    );
    const originalOrderId = hashedOrderId.toString(CryptoJS.enc.Utf8);


    setOrderId(originalOrderId);
  }, [orderId]);

  // const currentOrderInfo = useSelector((state) => state.order.orders.find((item) => item._id === id));
  const currentOrderInfo = useSelector((state) => state.order.orders.find((item) => item._id === orderId));


  if (isOrderFetching) {
    return(
      <Loader />
    )
  }

  //     "current Order Info  000 ===> ",
  //     currentOrderInfo?.products[0].productId.title
  //   );

  return (
    <div className="order-details pb-50">
      <Container>
        <Row>
          <Col lg={12} className="p-0">
            <div className="heading up-tab-heading pt-30 pb-70">
              <h1>
                <span className="right-arrow-od">
                  <MdKeyboardArrowRight />
                </span>
                Order <span>Details</span>
              </h1>
            </div>
          </Col>
          {currentOrderInfo && (
            <>
              {/* <div className="order-no-date">
                <div className="order-number">
                  <h4>My Orders</h4>
                  {currentOrderInfo?.products?.map((item, index) => {
                    return (
                      <p className="purchased-order-name m-0">
                        {index + 1}.&nbsp;
                        {item?.productId?.title}
                      </p>
                    );
                  })}
                  <p className="text-uppercase purchased-order-id">{currentOrderInfo?._id}</p>
                </div>
                <div className="order-date">
                  <h4>Order Date</h4>
                  <p>{changeDateFormat(currentOrderInfo?.createdAt)}</p>
                </div>
              </div> */}
              <div className="order-no-date">
                <div className="order-number">
                  <h4>My Orders</h4>
                  {/* {currentOrderInfo?.products?.map((item, index) => {
                    return (
                      <p className="purchased-order-name m-0">
                        {index + 1}.&nbsp;
                        {item?.productId?.title}
                      </p>
                    );
                  })} */}
                  {/* <p className="text-uppercase purchased-order-id">{currentOrderInfo?._id}</p> */}
                </div>
                <div className="order-date">
                  <h4>Order Date</h4>
                  {/* <p>{changeDateFormat(currentOrderInfo?.createdAt)}</p> */}
                </div>
              </div>
              {
                currentOrderInfo?.products?.map((product, index) => {
                  return (
                    <div className="order-list-wrapper">
                      <div className="order-list-header d-flex justify-content-between">
                        <p className="purchased-order-name m-0">
                          {index + 1}.&nbsp;
                          {product?.productId?.title}
                        </p>
                        <div className="order-list-right-side">
                          <p>{changeDateFormat(currentOrderInfo?.createdAt)}</p>
                        </div>
                      </div>
                      {
                        currentOrderInfo?.paymentStatus === "success" && (
                          <div className="order-delivery-wrapper">
                            <div className="delivery-method">
                              <h4>Delivery Method</h4>
                              <p>Standard Delivery</p>
                            </div>

                            <div className="order-status-od">
                              <div className="order-info">
                                <div className="status-txt">
                                  <h4>
                                    <span className="status-done">
                                      <MdOutlineCheckCircle />
                                    </span>
                                    Order Received
                                  </h4>
                                </div>
                                <div className="order-receive-date">
                                  <p>{changeDateFormat(currentOrderInfo?.createdAt)}</p>
                                </div>
                              </div>
                              <div className="order-info">
                                <div className="status-txt">
                                  <h4>
                                    <span className="status-done">
                                      <MdOutlineCheckCircle />
                                    </span>
                                    Processed
                                  </h4>
                                </div>
                                <div className="order-receive-date">
                                  <p>{changeDateFormat(currentOrderInfo?.createdAt)}</p>
                                </div>
                              </div>
                              <div className="order-info">
                                <div className="status-txt">
                                  <h4>
                                    <span className="status-done">
                                      <MdOutlineCheckCircle />
                                    </span>
                                    Dispatched
                                  </h4>
                                </div>
                                <div className="order-receive-date">
                                  <p>{changeDateFormat(currentOrderInfo?.createdAt)}</p>
                                </div>
                              </div>
                              <div className="order-info">
                                <div className="status-txt">
                                  <h4>
                                    <span className="order-delivered">
                                      <img
                                        src={`${process.env.PUBLIC_URL}/images/user-profile-pgs/icons/p-checkmark.svg`}
                                      />
                                    </span>
                                    Delivered
                                  </h4>
                                  <p className="pl-60">
                                    Your order has been delivered to you
                                  </p>
                                </div>
                                <div className="order-receive-date">
                                  <p>{changeDateFormat(currentOrderInfo?.createdAt)}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {
                        currentOrderInfo?.paymentStatus === "pending" && (
                          <div className="delivery-method">
                            <h4>Order Status</h4>
                            <div class="alert alert-danger" role="alert">
                              Your payment was failed. Kindly place order again.
                            </div>
                          </div>
                        )
                      }

                    </div>
                  )
                })
              }

            </>
          )}
        </Row>
      </Container>

      {/* OrderSummary Component */}
      <div>
        <OrderSummary summary={currentOrderInfo && currentOrderInfo} />
      </div>

      {/* Customer Info  */}
      <div className="customer-info pt-50">
        <Container>
          <div className="sub-head">
            <h4>my details</h4>
          </div>
          {currentOrderInfo?.address && (
            <>
              <div className="customer-name">
                <p>{currentOrderInfo?.address?.name}</p>
              </div>
              {/* <div className='customer-email'>
                                    <p>manyasharma456@gmail.com</p>
                                </div> */}
            </>
          )}
        </Container>
      </div>

      {/* Customer Billing Address */}
      <div className="customer-billing-add mt-3">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="sub-head">
                <h4>Billing Address</h4>
              </div>
            </Col>
            <Col lg={2}>
              <div className="user-add">
                {currentOrderInfo?.address && (
                  <>
                    <p className="text-capitalize"> {currentOrderInfo.address.firstName} {currentOrderInfo.address?.lastName}</p>
                    <p className="mb-0">{currentOrderInfo.address.address}</p>
                    <p className="mb-0">{currentOrderInfo.address.city}</p>
                    <p className="mb-0">{currentOrderInfo.address.pincode}</p>
                    <p className="mb-0">
                      {currentOrderInfo.address.phoneNumber}
                    </p>
                  </>
                )}
              </div>
            </Col>
          </Row>

          {/* Receipt Button */}
          {/* <Row>
                        <Col lg={12} md={12} className='text-center'>
                            <CommonButton
                                btnClass="receipt-btn fw-600"
                                btnName="Open receipt as pdf"
                            />
                            <p className='size-warning'>
                                File size is Max 500 MB
                            </p>
                        </Col>
                    </Row> */}
        </Container>
      </div>
    </div>
  );
};

export default OrderDetails;
