import 'bootstrap/dist/css/bootstrap.css';
import './EditProfile.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FaUserEdit } from "react-icons/fa";
import CommonButton from '../common/Button/CommonButton';
import { Form, FormLabel, FormGroup, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploadRequest, userRequest } from '../../requestMethod';
import { validateRequired } from '../../formValidation';
import { updateUserFailure, updateUserStart, updateUserSuccess } from '../../redux/userRedux';
import { MEDIA_URL } from '../../config';

const EditProfile = () => {

    // const location = useLocation();
    // const userId = location.pathname.split('/')[2];
    // const userDetails = useSelector((state) => state.allUsers.users.find((user) => user._id === userId))

    const dispatch = useDispatch();

    const user = useSelector((state) => state.loginUser.currentUser);


    const state = useSelector((state) => state);

    const [firstName, setFirstName] = useState(user?.firstName);
    const [lastName, setLastName] = useState(user?.lastName);
    const [email, setEmail] = useState(user?.email);
    const [phone, setPhone] = useState(user?.phone);
    const [address, setAddress] = useState({ flatNo: user?.address?.flatNo, area: user?.address?.area, landmark: user?.address?.landmark, city: user?.address?.city, pincode: user?.address?.pincode, state: user?.address?.state, country: user?.address?.country });
    const [profileImage, setProfileImage] = useState(user?.profileImg);
    const [imagePreview, setImagePreview] = useState(user?.profileImg);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoaderActive, setIsLoaderActive] = useState(false);



    const handleAddressChange = (e) => {
        setAddress((prevAddress) => ({
            ...prevAddress,
            [e.target.name]: e.target.value
        }));
    };


    const updateProfile = async (dispatch, id, profileData) => {
        dispatch(updateUserStart());
        try {
            const res = await userRequest.put(`/user/${id}`, profileData);
            // dispatch(updateProductSuccess({_id : id, ...product}));
            dispatch(updateUserSuccess(res.data));
            if (res && res.status === 200) {
                setError(false);
                setSuccess(true);
                setSuccessMessage("Profile has been updated!");
                setIsLoaderActive(false);
            }
        } catch (error) {
            dispatch(updateUserFailure());
            setIsLoaderActive(false);
            if (error.response && error.response.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }
            else {
                setError(true);
                setErrorMessage("Something went wrong!");

            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const firstNameErr = validateRequired(firstName);
        const lastNameErr = validateRequired(lastName);
        const emailValidErr = validateRequired(email);
        const phoneValidErr = validateRequired(phone);

        newErrors.address = {};

        const flatValidErr = validateRequired(address.flatNo);
        const areaValidErr = validateRequired(address.area);
        const landmarkValidErr = validateRequired(address.landmark);
        const cityValidErr = validateRequired(address.city);
        const pincodeValidErr = validateRequired(address.pincode);
        const stateValidErr = validateRequired(address.state);
        const countryValidErr = validateRequired(address.country);
        //  const emailValidErr = validateRequired(email);
        // let email;

        if (firstNameErr) {
            formIsValid = false;
            newErrors.firstName = firstNameErr;
        }

        if (lastNameErr) {
            formIsValid = false;
            newErrors.lastName = lastNameErr;
        }

        if (emailValidErr) {
            formIsValid = false;
            newErrors.email = emailValidErr;
        }

        if (phoneValidErr) {
            formIsValid = false;
            newErrors.phone = phoneValidErr;
        }
        if (flatValidErr) {
            formIsValid = false;
            newErrors.address.flatNo = flatValidErr;
        }
        if (areaValidErr) {
            formIsValid = false;
            newErrors.address.area = areaValidErr;
        }
        if (landmarkValidErr) {
            formIsValid = false;
            newErrors.address.landmark = landmarkValidErr;
        }
        if (cityValidErr) {
            formIsValid = false;
            newErrors.address.city = cityValidErr;
        }
        if (pincodeValidErr) {
            formIsValid = false;
            newErrors.address.pincode = pincodeValidErr;
        }
        if (stateValidErr) {
            formIsValid = false;
            newErrors.address.state = stateValidErr;
        }
        if (countryValidErr) {
            formIsValid = false;
            newErrors.address.country = countryValidErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    // Function to handle file input change
    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // You can perform additional checks on the file type, size, etc. here if needed

            // Set the selected image in state
            setProfileImage(file);
            // Generate a preview URL for the selected image
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const uploadProductImageToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                const fileUrl = response.data.fileUrl;
                // setProductImage(fileUrl); // Set the product image URL in state
                return fileUrl
            }
        } catch (error) {
            console.error('Error uploading product image:', error);
        }
    };




    const sendUpdate = async (e) => {
        e.preventDefault();
        setSuccess(false);
        setError(false);
        setTitleError(false);
        if (validateForm()) {
            let profileImgURL;
            setIsLoaderActive(true)
            if (profileImage?.name) {
                profileImgURL = await uploadProductImageToSpace(profileImage);
                updateProfile(dispatch, user._id, { firstName, lastName, email, profileImg: profileImgURL, phone, address });
            } else {
                updateProfile(dispatch, user._id, { firstName, lastName, email, profileImg: profileImgURL, phone, address });
            }

        }
    }
    return (
        <div className="edit-profile pb-50">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='heading'>
                            <h1>Edit Profile</h1>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="profile-image-container p-rel">
                            <div className="profile-image text-center">
                                {/* <div className="profile-image-wrapper"> */}
                                {
                                    profileImage?.name ? (
                                        <img src={imagePreview || `${process.env.PUBLIC_URL}/images/profile.png`} alt="Profile-img" />
                                    ) :  !profileImage && !imagePreview ?(
                                        <img src={`${process.env.PUBLIC_URL}/images/profile.png`} alt="Profile-img" />
                                    ) : imagePreview ? (
                                        <img src={MEDIA_URL + imagePreview} alt="Profile-img" />
                                    ) : (
                                        <></>
                                    )
                                }
                                  
                                {/* </div> */}
                            </div>
                            {/* <div className='change-pfp'>
                                <FaUserEdit />
                            </div> */}
                            <label htmlFor="profileImageInput" className="change-pfp">
                                <FaUserEdit />
                            </label>
                            <input
                                type="file"
                                name='file'
                                id="profileImageInput"
                                accept="image/*" // Allow only image files
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />

                        </div>
                        <Form>
                            <div className='billing-detail-form pt-40'>
                                <div className='name-ip-field'>
                                    <Row>
                                        {/* First name Field */}
                                        <Col lg={6} md={12}>
                                            <FormGroup row className="mb-3">
                                                <Form.Label className='cb-form-label'>
                                                    First Name:
                                                </Form.Label>
                                                <Form.Control value={firstName} onChange={(e) => setFirstName(e.target.value)} className='cb-input-fields' type='text'></Form.Control>
                                                <span className='error-text'>{errors.firstName}</span>
                                            </FormGroup>
                                        </Col>

                                        {/* Last name field */}
                                        <Col lg={6} md={12}>
                                            <FormGroup row className="mb-3">
                                                <Form.Label className='cb-form-label'>
                                                    Last Name:
                                                </Form.Label>
                                                <Form.Control value={lastName} onChange={(e) => setLastName(e.target.value)} className='cb-input-fields' type='text'></Form.Control>
                                                <span className='error-text'>{errors.lastName}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Email Field */}
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='cb-form-label'>
                                        Email
                                    </Form.Label>
                                    <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} className='checkout-email-ip cb-input-fields' type="email" />
                                    <span className='error-text'>{errors.email}</span>
                                    {
                                        titleError && (
                                            <p className='error-text text-danger'>{titleErrorMessage}</p>
                                        )
                                    }
                                </Form.Group>

                                {/* Start Phone Number Input field */}
                                <Form.Label className='cb-form-label'>
                                    Phone Number
                                </Form.Label>
                                <InputGroup row className="mb-3 ph-country-code">
                                    <DropdownButton
                                        className='ph-country-code'
                                        variant="outline-secondary"
                                        title="+91"
                                        id="input-group-dropdown-1"
                                    >
                                        <Dropdown.Item href="#">+92</Dropdown.Item>
                                        <Dropdown.Item href="#">+93</Dropdown.Item>
                                        <Dropdown.Item href="#">+94</Dropdown.Item>
                                    </DropdownButton>
                                    <Form.Control value={phone} onChange={(e) => setPhone(e.target.value)} className='cb-input-fields ph-no-input-fields' aria-label="Phone Number" />
                                    <span className='error-text'>{errors.phone}</span>
                                </InputGroup>
                                {/* End Phone Number Input field */}

                                {/* User Address Field */}
                                <Form.Group className="mb-3" controlId="buildingNo">
                                    <Form.Label className='cb-form-label'>
                                        Flat, House no., Building, Company, Apartment
                                    </Form.Label>
                                    <Form.Control name='flatNo' value={address.flatNo} onChange={handleAddressChange} className='cb-input-fields ' aria-label="buildingNo" />
                                    <span className='error-text'>{errors.address?.flatNo}</span>
                                </Form.Group>

                                <Form.Group className='mb-3' controlId="userArea">
                                    <FormLabel className='cb-form-label'>
                                        Area, Street, Sector, Village
                                    </FormLabel>
                                    <Form.Control name='area' value={address.area} onChange={handleAddressChange} className='cb-input-fields' type='text' />
                                    <span className='error-text'>{errors.address?.area}</span>
                                </Form.Group>

                                <Form.Group className='mb-3' controlId="userLandmark">
                                    <FormLabel className='cb-form-label'>
                                        Landmark
                                    </FormLabel>
                                    <Form.Control name='landmark' value={address.landmark} onChange={handleAddressChange} className='cb-input-fields' type='text' />
                                    <span className='error-text'>{errors.address?.landmark}</span>
                                </Form.Group>

                                <div className='city-pincode'>
                                    <Row>
                                        {/* User City Field */}
                                        <Col lg={6} md={12}>
                                            <Form.Group className='mb-3' controlId="userCity">
                                                <FormLabel className='cb-form-label'>
                                                    City Name
                                                </FormLabel>
                                                <Form.Control name='city' value={address.city} onChange={handleAddressChange} className='cb-input-fields' type='text' />
                                                <span className='error-text'>{errors.address?.city}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <Form.Group className='mb-3' controlId="userPincode">
                                                <FormLabel className='cb-form-label'>
                                                    Pin Code
                                                </FormLabel>
                                                <Form.Control name='pincode' value={address.pincode} onChange={handleAddressChange} className='cb-input-fields' type="number" />
                                                <span className='error-text'>{errors.address?.pincode}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>

                                {/* User country */}
                                <Row>
                                    {/* User City Field */}
                                    <Col lg={6} md={12}>
                                        <Form.Group className='mb-3' controlId="userState">
                                            <FormLabel className='cb-form-label'>
                                                State
                                            </FormLabel>
                                            <Form.Control name='state' value={address.state} onChange={handleAddressChange} className='cb-input-fields' type='text' />
                                            <span className='error-text'>{errors.address?.state}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12}>
                                        <FormLabel className='cb-form-label'>
                                            Country
                                        </FormLabel>
                                        {/* <InputGroup value={address.country} onChange={handleAddressChange} className='cb-dropdown-field'>
                                            <DropdownButton>
                                                <DropdownItem>
                                                    India
                                                </DropdownItem>
                                                <DropdownItem>
                                                    Canada (CA)
                                                </DropdownItem>
                                                <DropdownItem>
                                                    Australia (AU)
                                                </DropdownItem>
                                                <DropdownItem>
                                                    New Zealand (NZ)
                                                </DropdownItem>
                                                <DropdownItem>
                                                    United Kingdom (UK)
                                                </DropdownItem>
                                                <DropdownItem>
                                                    United States of America (USA)
                                                </DropdownItem>
                                            </DropdownButton>
                                        </InputGroup> */}
                                        <Form.Control name='country' value={address.country} onChange={handleAddressChange} className='cb-input-fields' type='text' />
                                        <span className='error-text'>{errors.address?.country}</span>
                                    </Col>
                                </Row>

                                <div className="col-12 mt-3">
                                    {
                                        success ? (
                                            <div className='alert alert-success'>
                                                {successMessage}
                                            </div>
                                        ) : error && (
                                            <div className='alert alert-danger'>
                                                {errorMessage}
                                            </div>
                                        )
                                    }
                                </div>


                                {/* BAck to Cart And Payement page Button  */}
                                <div className='cart-payement-pg-btns mt-50'>
                                    {/* <CommonButton
                                        btnClass='discard-changes-btn'
                                        btnName='Discard Changes'
                                    /> */}

                                    {/* <CommonButton
                                        btnClass='save-changes-btn'
                                        btnName='Save Changes'
                                        onClickAction={sendUpdate}
                                    /> */}
                                    <button
                                        className={`${isLoaderActive
                                            ? "button-loader load"
                                            : "submit-coupon"
                                            }`}
                                        onClick={sendUpdate}
                                    >
                                        <span>Save Changes</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default EditProfile;