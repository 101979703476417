// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Start Details Tab Section */
.tab-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  background-color: var(--lPink);
  padding-left: 0px;
  border-radius: 15px;
  column-gap: 50px;
  cursor: pointer;
}

.tab-list .active {
  background-color: var(--blue);
  color: var(--white);
  width: 100%;
  border-radius: 15px;
}

.tab-list li {
  color: var(--dBlue);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  width: 100%;
  text-align: center;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* End Details Tab Section */

@media (max-width: 767px) {
  .tab-list li {
    font-size: 14px;
  }

  .tab-list .active {
    border-radius: 30px;
  }

  .pt-50-mob {
    padding-top: 50px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/common/tab/TabWrapper.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,qBAAqB;EACrB,8BAA8B;EAC9B,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA,4BAA4B;;AAE5B;EACE;IACE,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":["/* Start Details Tab Section */\n.tab-list {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  list-style-type: none;\n  background-color: var(--lPink);\n  padding-left: 0px;\n  border-radius: 15px;\n  column-gap: 50px;\n  cursor: pointer;\n}\n\n.tab-list .active {\n  background-color: var(--blue);\n  color: var(--white);\n  width: 100%;\n  border-radius: 15px;\n}\n\n.tab-list li {\n  color: var(--dBlue);\n  font-weight: 700;\n  text-transform: uppercase;\n  font-size: 20px;\n  width: 100%;\n  text-align: center;\n  padding: 15px 0px;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n}\n\n/* End Details Tab Section */\n\n@media (max-width: 767px) {\n  .tab-list li {\n    font-size: 14px;\n  }\n\n  .tab-list .active {\n    border-radius: 30px;\n  }\n\n  .pt-50-mob {\n    padding-top: 50px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
