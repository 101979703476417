import 'bootstrap/dist/css/bootstrap.css';
import '../cards/TopicsCard.css';
import TopicsCard from '../cards/TopicsCard';
import { Container, Row, Col } from 'react-bootstrap';

const Topics = (props) => {
    return (
        <Container className='pd-l'>
            <Row>
                <Col lg={12} className="p-0">
                    <div className='heading pt-40 pb-40'>
                        <h1>Topics to be <span>covered</span></h1>
                    </div>
                </Col>
                {
                    props.topics && props.topics.map((item, index) => {
                        return (
                            <Col lg={6} sm={12} className={`${index % 2 == 0 ? 'gx-5 p-lr-mob' : 'gx-5 gy-5 pt-100 pt-100-mob'}`}>
                                <TopicsCard topicDetails={item} index={index}
                                    TopicsCardClass="topics-card-a"
                                    TopicsCardNumber="1"
                                    TopicsCardHeading="Introduction to"
                                    TopicsCardPinkTxt=" self driving"
                                    TopicsCardText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries"
                                />
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}

export default Topics;