import "./TermOfUse.css";
const TermOfUse = () => {
  return (
    <div className="term">
      <section className="term_first">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 position-relative">
              <img src="images/privacy.png" />
              <h1>
                TERMS AND <span>CONDITIONS</span>
              </h1>
            </div>
            <div className="col-lg-6">
              <p className="toc-fpara">
                This Privacy Policy and Terms Of Use document is provided by
                Meritus AI Learning Experiences Pvt Ltd Chennai India
                (hereinafter known as Meritus).
              </p>
              <p>
                The Meritus web services on www.meritus.ai, Meritus Forum an
                online doubt clearance and learning experiences service, Meritus
                Centre for creativity and Innovation and Meritus Academy engaged
                in the coaching of learners are all business units of Meritus.
                This Privacy Policy and Terms of Use is also applicable for
                Robotics education products and services from Robotix USA |
                India a unit of Meritus AI Learning experiences Pvt Ltd. Robotix
                USA | India offers STEM, Coding, Maker Space & Robotics classes
                and offers for sale STEM, Maker Space, Robotics, AI & Coding
                educational products & tools for students including children
                under the age of 18 for the global connected educational market.
              </p>
              <p>
                Meritus reserves the right at its sole discretion to invite
                students, teachers, staff, Alumni, parents and any user to
                become registered users of Meritus web services. The access to
                and use of the Meritus Forum Services, services of the Meritus
                Academy and use of its web services and website is subject to
                compliance with these Terms and conditions as herein. We reserve
                the right, at any time and without prior notice, to remove or
                disable access to Meritus web services at our discretion for any
                reason. Some of the reasons we may remove or disable access to
                Meritus web services are including but not limited to : not
                being in good standing, finding the content of the user post to
                be objectionable, in violation of these Terms & Conditions, or
                otherwise harmful to the Services or to our users.
              </p>
              <p>
                Users of Meritus web services viz Teachers, Staff, Students,
                Alumni, Parents and other users agree and accept the terms and
                conditions as set out herein. Meritus may update these terms
                from time to time and any changes will be effective immediately
                on being set out here. Please ensure you are aware of the
                current terms.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="term_section pt-5 mt-4">
          <h2 className="">
            Terms & Conditions <span>of Service </span>
          </h2>
          <p>
            Meritus rules and regulations apply to all transactions along with
            special conditions imposed for all activities including internet
            based application. Please go through the conditions carefully and if
            you accept them, you may continue to transact on the website. Please
            note that using the online payment facility on this website
            indicates that you are deemed to have agreed to the terms and
            conditions set forth below. If you do not agree with all these terms
            and conditions, you must not transact on this website. Once you have
            clicked the 'I Agree' button at the bottom of these Terms and
            Conditions you have entered into a formal agreement with Meritus for
            the purpose of all transactions as set out herein on this website.
            This agreement constitutes the entire agreement between the user and
            Meritus and it supersedes all prior or contemporaneous
            communications and proposals, whether electronic, oral, or written,
            between the user and Meritus with respect to all transactions
            including online payment on this website.
          </p>
          <p>
            A printed version of this agreement and of any notice given in
            electronic form shall be admissible in judicial or administrative
            proceedings based upon or relating to this agreement to the same
            extent and subject to the same conditions as other business
            documents and records originally generated and maintained in printed
            form this agreement is made between: Meritus (“Us”) and The User
            (“You”), the individual, whose details are set out in the User
            Registration page.
          </p>
          <p>
            Scope of Service: Meritus makes no guarantee that any product or
            service will be uninterrupted, timely, secure or error-free.
          </p>
          <p>
            In no event will Meritus be liable for any damages whatsoever
            arising out of the use, inability to use, or the results of use of
            this site, any websites linked to this site, or the materials or
            information contained at any or all such sites, whether based on
            warranty, contract, tort or any other legal theory and whether or
            not advised of the possibility of such damages.
          </p>
          <p>
            You agree and warrant that you will not use the Services in a manner
            that is illegal or otherwise inconsistent with the Terms. In
            addition, you will not use the Services in a manner that is deemed
            objectionable by us. We reserve the right to restrict, suspend, or
            terminate your access to the Services at any time without notice for
            any reason, in our sole discretion. You agree that you will only
            access the Services through the Website, ecommerce marketplace.
          </p>
          <p>
            You agree not to do any of the following: attempt to decipher,
            decompile, disassemble or reverse engineer or otherwise hack the
            Services or any software used to provide the Services; attempt to
            breach any security or authentication measures of the Services;
            avoid, bypass, remove, deactivate, impair, descramble or otherwise
            circumvent any technological measure implemented by us or any other
            third party to protect the Services or the Content; use the Services
            or the Content for any commercial purpose or the benefit of any
            third party or in any manner not permitted by these Terms; take any
            actions that damage or tamper with the Services; interfere or
            attempt to interfere with other users’ use of the Services;
            impersonate or misrepresent your affiliation with any person;
            violate any applicable law of regulation; or encourage or enable any
            other individual to do any of the foregoing. We will have the right
            to investigate and prosecute violations of any of the above to the
            fullest extent of the law. We may involve and cooperate with law
            enforcement authorities in prosecuting users who violate these
            Terms. You acknowledge that we has no obligation to monitor your
            access to or use of the Services or the Content, but has the right
            to do so for the purpose of operating the Services, to ensure your
            compliance with these Terms, or to comply with applicable law or the
            order or requirement of a court, administrative agency or other
            governmental body. Meritus reserves the right, at any time and
            without prior notice, to remove or disable access to the Services or
            any Content, in its sole discretion, considered to be in violation
            of these Terms or otherwise harmful to the Services.
          </p>
        </div>
      </section>
      <section className="container" id="privacyPolicy">
        <div className="term_section">
          <h5 className="fw-700 mb-3">
            YOUR OBLIGATIONS AS A USER (Teacher, Staff, Student, Alumni or
            Parent or any User)
          </h5>
          <h2 className="">
            2a) General <span>Obligations:</span>
          </h2>
          <p>
            You shall access our website/LMS/web services only for lawful
            purposes and you shall be responsible for complying with all
            applicable laws, statutes and regulations in connection with the use
            of our web site and your ward studying in our offline centre.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            2b) Information <span>Provided</span>
          </h2>
          <p>
            The information you provide must be complete and accurate. MERITUS
            reserves the right at all times to disclose any information as
            MERITUS deems necessary to satisfy any applicable law, regulation,
            legal process, or Governmental request. Non-authorization of payment
            more than once by payment gateway for processing fee paid by you is
            liable to result in deregistration of your account with this site,
            without any notice.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            2c) Applicant <span>Responsibility</span>
          </h2>
          <p>
            The information you provide must be complete and accurate. MERITUS
            reserves the right at all times to disclose any information as
            MERITUS deems necessary to satisfy any applicable law, regulation,
            legal process, or Governmental request. Non-authorization of payment
            more than once by payment gateway for processing fee paid by you is
            liable to result in deregistration of your account with this site,
            without any notice.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            Rules and <span>regulations</span>
          </h2>
          <p>
            ll rules and regulations applicable for application to Meritus will
            apply to both offline and Online Application through the web
            services/Internet. Confirmation of Submission on Application and
            Payment: Your purchase will be confirmed online, after you
            completely fill the Online Registration Form & make payment of
            product price or service fee successfully. Further, the system will
            issue you a unique Transaction ID for future reference. We accept
            product price and service fee in various modes including credit/
            debit card, net banking through payment gateway. The Choice of
            Payment Mode lies with you. If any one of the modes is not
            functioning please try the other one available. Processing of
            Application is subject to realization of payment and the service
            charges (including GST if from India) from concerned Bank through
            the Payment Gateway. You will be responsible for all charges, fees,
            duties, taxes and assessments arising out of your use of this
            Website.
          </p>
        </div>
      </section>
      <section className="container" id="refundandcanc">
        <div className="term_section">
          <h2>
            Refund & Cancellation
            <span> Policy</span>
          </h2>
          <ol style={{ listStyle: "lower-alpha" }}>
            <li>
              Delivery. We shall deliver the Products once purchased to the
              place of delivery designated by you in coordination with our
              courier partner. If you choose to request any type of express or
              expedited courier or shipping services, you shall be responsible
              for all additional shipping fees. International orders may also
              involve customs, import duties or other charges which vary from
              country to country. You are solely responsible for any such
              additional charges. In addition, it is your responsibility to
              check with your local customs office to verify whether the country
              to which you are shipping permits the shipment of Products to you.
            </li>
            <li>
              Pricing. Except where otherwise noted, the list price displayed
              for any Product represents the full price which includes shipping.
              For India, the price on the website includes GST and is in INR.
              For the rest of the world any applicable duties and taxes as
              levied by the destination country will have to be borne by you
              including freight and price is shown in USD. Despite our best
              efforts, a small number of items in our catalogue may be mispriced
              and, in the event of a mispricing, Meritus reserves the right to
              charge the correct price.
            </li>
            <li>
              Order Cancellation. Once an order is placed in our online store,
              it is not eligible for cancellation. After the transaction has
              been done successfully on Meritus Learning Solutions Pvt. Ltd
              website no request for refund or cancellation will be entertained.
            </li>
            <li>
              Refunds and Exchanges. Meritus does not take title to returned or
              refunded Products until the Products arrive at the India
              headquarters. You may return our Product within thirty (30) days
              of purchase if such Product is unopened, unused, and in its
              original condition for a full refund, less any applicable shipping
              or other charges. If you believe that you have received a faulty
              or broken Product, please contact us at admin@meritus.ai . We will
              endeavour to exchange the Product for a new one; please note that
              we must receive your Product within fourteen (14) days of purchase
              and that the exchange is contingent upon our review and inspection
              of the Product. If you are returning a Product internationally,
              please contact admin@meritus.ai before you ship the Product.
              Please note that with international orders, you are solely
              responsible for all shipping costs for returns, exchanges, customs
              and import duties and such costs will be at your expense. Returns
              beyond the thirty (30) day period will NOT be accepted by Meritus.
              We recommend you return the Products using a shipping method that
              is traceable and insured to protect your shipment.
            </li>
            <li>
              Returns. A box once shipped cannot be returned or initiate a
              refund. In case you find that a few components are missing, then
              you should contact us at admin@meritus.ai, following which we will
              proceed to send you the missing parts. In case you observe that
              the box is visibly damaged during shipping, then you must refuse
              to accept the shipment, and contact us admin@meritus.ai
              immediately.
            </li>
          </ol>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            Code of <span>Conduct</span>
          </h2>
          <p>
            Definition: Institution/web services/online/Offline centre refers to
            MERITUS (including its affiliates, sister organisations and or
            subsidiaries) and Staff of the offline centre includes teaching,
            non-teaching, sub-staff, support staff and domestic staff of this
            institution (including its affiliates, sister organisations and or
            subsidiaries)
          </p>
          <h6 className="fw-600">All users agrees to :</h6>
          <ol style={{ listStyle: "lower-roman" }}>
            <li>
              Respect and follow all applicable rules and regulations of the web
              services and in the offline centre as in existence and that may be
              amended from time to time.
            </li>
            <li>
              Respect the rights of others and treat members of Teaching staff,
              members of online & offline centre community, especially those in
              position of authority regardless of race, ancestry, place of
              origin, colour, ethnic origin, religion, gender, sexual
              orientation, age or disability.
            </li>
            <li>
              Show care and respect for online/offline centre property and the
              property of others.
            </li>
            <li>
              Respect the need of others to work in an environment that
              encourages learning and teaching.
            </li>
            <li>
              Seek help from online/offline centre staff, if necessary, to
              resolve conflict peacefully and not swear at a teacher or at
              another person in a position of authority.
            </li>
            <li>
              Not divulge or disclose or handover to any person whomsoever
              including competitors, make any use whatsoever for own purpose or
              for any other purpose any confidential information or secrets or
              proprietary information or intellectual property, know-how, trade
              secrets, operations know how, or knowledge learnt/ gained /
              obtained from the online/offline centre MERITUS (including its
              affiliates, sister organisations and or subsidiaries during the
              course of their ward studying in the offline centre and for a
              period of 12 months after the User leaves the offline centre or
              stops using the web services) including on curriculum, syllabus,
              educational technology, all online/offline centre data including
              fee structure, confidential information, technical specifications,
              drawings, processes, and systems etc .
            </li>
            <li>
              Not transact with or solicit or continue existing trade/business
              or start a new one individually, or in association with the
              Teaching and Non- Teaching staff or with other past or present
              staff of this institution.
            </li>
            <li>
              Not to directly or indirectly solicit for employment any person
              who is now employed with MERITUS including its affiliates, sister
              organisations and or subsidiaries during the course of their ward
              studying in the online/offline centre and for a period of 12
              months after the User leaves the online/offline centre or stops
              using the web services.
            </li>
            <li>
              Not to employ the existing staff of Meritus for tuition or
              coaching or for any other purpose including agencies, consultancy,
              retainer etc.
            </li>
            <li>
              Not to directly or indirectly continue with or associate or engage
              in financial activities such as lending, borrowing money,
              investment in chits or in mutual funds or in share market
              individually or in association with existing employees of this
              institution.
            </li>
            <li>
              Not to directly or indirectly offer any kind of monetary amounts
              or non-Monetary gifts etc to Teaching Staff /sub-staff/support
              Staff /domestic staff of the offline centre.
            </li>
            <li>
              Not to lend or avail loan to/from the teaching staff
              /sub-staff/support staff/ domestic staff of the offline centre in
              the form of kind or cash.
            </li>
            <li>
              Not use defamatory, offensive or derogatory comments regarding the
              offline centre or any of the pupils/parents/staff at the
              online/offline centre on social media sites.
            </li>
            <li>Not to do anything harmful to Meritus web services.</li>
            <li>
              Handle the assets of online/offline centre with utmost care and
              should not intentionally damage or destroy offline centre property
              or the property of a teacher, administrator.
            </li>
            <li>
              Not disrupt the orderly conduct of online/offline classes,
              online/offline centre programs or other online/offline centre
              activities.
            </li>
            <li>
              Not intimidate, harass or discriminate against any staff of the
              institution on the basis of race, color, creed, national origin,
              religion, age, gender, sexual orientation or disability.
            </li>
            <li>
              Not enter any portion of the offline centre or Meritus office
              without authorization or remain in any building or facility after
              it is normally closed.
            </li>
            <li>
              Not consume, sell, distribute or exchange cigarettes/tobacco or
              alcoholic beverages, controlled substances, or be under the
              influence of either on offline centre property or at a offline
              centre function.
            </li>
            <li>
              Not use weapons in or on offline centre property or at offline
              centre function, except in the case of law enforcement officers on
              official duty. Not loiter on or about during offline centre
              functions.
            </li>
            <li>
              Not to gamble on offline centre property or at offline centre
              functions.
            </li>
            <li>
              Refrain from provoking others to commit any of the acts prohibited
              by this code.
            </li>
            <li>
              That the online web services and offline centre has the sole
              right, title and interest over such pedagogy, curriculum design,
              development, classroom transactions / management, inventions, know
              – how, improvements, discoveries and online web services and the
              offline centre owns the intellectual property rights over them.
            </li>
            <li>
              That MERITUS & related parties (including its affiliates, sister
              organisations and or subsidiaries) own many trademarks, word
              marks, copy rights & brand name(s) and parents agree & undertake
              not to use or misuse these trademarks, word marks, copyrights and
              brand name(s) at all times.
            </li>
            <li>
              That during the course of studies, Users or thereafter, may and
              are likely to know certain information about MERITUS, its online
              web services and offline centres, its operation, clients,
              prospects, plans, products & service, financial condition,
              properties, proprietary information, intellectual property, trade
              secrets and/or know-how. Users agree that all such information
              whether oral, written, or recorded/electronic, and regardless of
              the manner in which it is furnished is known as "Proprietary
              Information." The term "Proprietary Information" shall also
              include all reports, summaries, compilations, analyses, notes or
              other information. Users agree not to disclose the Proprietary
              Information to any third parties and agree to not divert or
              attempt to divert any business or customer of MERITUS. Users agree
              that they shall not directly or indirectly reverse-engineer,
              decompile, or disassemble any technical knowledge, know-how, or
              software disclosed to them. Further Users specifically agree that
              any non-written Proprietary Information is also subject to the
              terms of this agreement.
            </li>
            <li>
              Users understand that violating the code of conduct may result in
              serious consequences. Users specifically agree that failure to
              adhere to code of conduct or found in violation may result in
              legal action against the Users, parents or guardian and the
              person(s) to whom the information was divulged. Users agree that
              violating the Secrecy, Confidentiality and Code of Conduct may
              result in serious legal consequences against them.
            </li>
          </ol>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            Limitation of <span>Liability</span>
          </h2>
          <p>
            MERITUS shall not be liable to you save as expressly provided for in
            these terms and conditions and shall have no other obligations,
            duties or liabilities whatsoever in contract, tort or otherwise
            arising out of the use of the service or connection to the website.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            Accuracy of <span>Information</span>
          </h2>
          <p>
            We shall use reasonable endeavours to check the accuracy of the
            information published on this website. We give no warranty as to the
            accuracy of such information given on our website and reserve the
            right to amend and vary the contents of this web site from time to
            time without notice. The MERITUS web Sites/web Services may contain
            links to third party websites ("Linked Sites").The Linked Sites are
            not under the control of MERITUS and MERITUS is not responsible for
            the contents of any Linked Site, including without limitation any
            link contained in a Linked Site, or any changes or updates to a
            Linked Site. We shall use reasonable endeavours to check the
            accuracy of the information furnished by you as a parent. We reserve
            the right to amend and vary the contents of the terms and
            conditions, admission process and procedures from time to time
            without notice
          </p>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            Limited <span>Warranty</span>
          </h2>
          <p>
            To the fullest extent possible by law, Meritus makes no
            representations or warranties that the products and services will
            operate error-free, be available, or that the products and services
            and Meritus’s servers are free of computer viruses or other harmful
            mechanisms. If your use of the products and services results in the
            need for servicing or replacing equipment or data or any other
            costs, we are not responsible for those costs. Except for any
            limited warranties in the user guide and other packaging materials
            included with your Meritus products at the time of purchase, the
            services are provided “as is” and “as available” without any
            warranties of any kind and Meritus, to the fullest extent permitted
            by law, disclaims all warranties, whether express or implied,
            including the warranty of merchantability, fitness for particular
            purpose, and non-infringement. Meritus makes no warranties about the
            accuracy, reliability, completeness, or timeliness of the content in
            the services. Some jurisdictions do not allow the exclusion of
            implied warranties, so the above exclusion may not apply to you.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            Exclusion of <span>Liability</span>
          </h2>
          <p>
            MERITUS shall not be liable to you for any of the following types of
            loss or damage arising out of your use of MERITUS website and the
            web service whether in contract or tort (including any liability for
            negligence howsoever arising out of or in connection with the
            performance of our obligations in the provision of the website and
            this service):
          </p>
          <ul>
            <li>
              Loss of revenue, business, anticipated savings or profits; or
            </li>
            <li> Any indirect or consequential loss however arising.</li>
          </ul>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">
            Force <span>Majeure</span>
          </h2>
          <p>
            We shall not be liable to you in respect of any delay in performance
            of these terms and conditions or delay in performance or breach of
            the terms and conditions due to any event or circumstances beyond
            our reasonable control. 
          </p>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">Indemnity</h2>
          <p>
            You agree to defend, indemnify, and hold harmless Meritus its
            associates,, subsidiaries, affiliates, and their respective
            officers, directors, successors, subsidiaries, contractors,
            technology providers, advertisers, employees and agents, from and
            against any claims, actions, losses, costs, liability or demands,
            including without limitation reasonable legal and accounting fees,
            alleging or resulting from (i) Your use of the Services, (ii) Your
            breach of these Terms, including Your representations and warranties
            herein or (iii) Your violation of any applicable laws. Meritus
            reserves the right, at your expense, to assume the exclusive defense
            and control of any matter for which you are required to indemnify us
            and you agree to cooperate with our defence of these claims. You
            agree not to settle any matter without the prior written consent of
            Meritus. Meritus will use reasonable efforts to notify you of any
            such claim, action or proceeding upon becoming aware of it.
          </p>
        </div>
      </section>
      <section className="container">
        <div className="term_section">
          <h2 className="">Disclaimer</h2>
          <p>
            MERITUS is not responsible for wrong application due to incorrect
            details furnished by the user.
          </p>
        </div>
      </section>
    </div>
  );
};
export default TermOfUse;
