// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.up-tab-heading h1 {
    color: var(--dBlue);
}

.details-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-btn a {
    text-decoration: underline !important;
    color: var(--cSPink);
    text-transform: capitalize;
}

.user-details-table {
    width: 100%;
}

.table-data-l {
    width: 50%;
}

.table-data-r {
    padding-left: 15%;
}

/* Change password section */
.link-txt button {
    color: var(--cSPink);
    text-transform: capitalize;
    background-color: transparent;
    background-color: initial;
    border: medium none currentColor;
    border: initial;
    padding: 0px;
    font-family: 'Poppins';
}

.link-txt button:hover {
    color: var(--cSPink) !important;
    background-color: transparent !important;
    background-color: initial !important;
    text-decoration: underline;
}

.w-40 {
    width: 40%;
}

.change-pwd-btn {
    background-color: var(--dBlue) !important;
    padding: 10px 25px !important;
    font-weight: 600;
    border-radius: 50px;
}

@media (max-width: 767px) {
    .privacy-sec {
        padding-top: 50px !important;
    }

    .w-100-mob {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/user-profile/AccountSetting.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,qCAAqC;IACrC,oBAAoB;IACpB,0BAA0B;AAC9B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA,4BAA4B;AAC5B;IACI,oBAAoB;IACpB,0BAA0B;IAC1B,6BAAuB;IAAvB,yBAAuB;IACvB,gCAAa;IAAb,eAAa;IACb,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,+BAA+B;IAC/B,wCAAkC;IAAlC,oCAAkC;IAClC,0BAA0B;AAC9B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,yCAAyC;IACzC,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".up-tab-heading h1 {\n    color: var(--dBlue);\n}\n\n.details-edit {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.edit-btn a {\n    text-decoration: underline !important;\n    color: var(--cSPink);\n    text-transform: capitalize;\n}\n\n.user-details-table {\n    width: 100%;\n}\n\n.table-data-l {\n    width: 50%;\n}\n\n.table-data-r {\n    padding-left: 15%;\n}\n\n/* Change password section */\n.link-txt button {\n    color: var(--cSPink);\n    text-transform: capitalize;\n    background-color: unset;\n    border: unset;\n    padding: 0px;\n    font-family: 'Poppins';\n}\n\n.link-txt button:hover {\n    color: var(--cSPink) !important;\n    background-color: unset !important;\n    text-decoration: underline;\n}\n\n.w-40 {\n    width: 40%;\n}\n\n.change-pwd-btn {\n    background-color: var(--dBlue) !important;\n    padding: 10px 25px !important;\n    font-weight: 600;\n    border-radius: 50px;\n}\n\n@media (max-width: 767px) {\n    .privacy-sec {\n        padding-top: 50px !important;\n    }\n\n    .w-100-mob {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
