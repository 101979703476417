
import { createSlice } from "@reduxjs/toolkit";

const blogReducer = createSlice({
    name: 'blog',
    initialState: {
        blogs: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Blog
        getBlogStart: (state) => {
            state.isFetching = true;
        },
        getBlogSuccess: (state, action) => {
            state.isFetching = false;
            state.blogs = action.payload;
        },
        getBlogFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getBlogStart, getBlogSuccess, getBlogFailure } = blogReducer.actions;
export default blogReducer.reducer;