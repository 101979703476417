import { Link } from "react-router-dom";
import RenderHTMLContent from "../RenderHTMLContent";
import { changeDateFormat, truncateText } from "../config";
import "./ExploreCards.css";
function ExploreCards(props) {
  return (
    <div className="ExploreCard_Container">
      <img src={props.blog.blogImage} alt="img" />
      <div className="ExploreCard_txt">
        <p className="pSize fw-700">{props.blog.title}</p>
        <p className="pSize fw-400">
          {/* <RenderHTMLContent htmlContent={props.blog.description} /> */}
        </p>
        <div className="ExploreCardBtn">
          <Link to={`/blog/${props.blog._id}`}>Read more</Link>
          <span className="pSize dPink">
            {changeDateFormat(props.blog.createdAt)}
          </span>
        </div>
      </div>
    </div>
  );
}
export default ExploreCards;
