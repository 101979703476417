import React from 'react';
import './WishListHeart.css';

const WishListHeart = () => {
    return (
        <div className="elastic-love">
            <input type="checkbox" id="like-toggle" />
            <label for="like-toggle" className="heart"> </label>
            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <div className="ring"></div>
        </div>
    )
}

export default WishListHeart
