import React from "react";
import "./VideoModal.css"; // Import the CSS file
import { FaTimes } from "react-icons/fa"; // Import the close icon from Font Awesome

function VideoModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="video-modal-overlay">
      <div className="video-modal-content">
        <button className="close-button-about" onClick={onClose}>
          <FaTimes />
        </button>
        <video controls autoPlay>
          <source src="video/about.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default VideoModal;
