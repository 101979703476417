import TrendingNow from "../component/common/trending/TrendingNow";
import BlogCard from "../component/BlogCard";
import {
  FaCalendarAlt,
  FaLocationArrow,
  FaHandHoldingUsd,
  FaGlobeAmericas,
} from "react-icons/fa";
import "./UpcomingEvents.css";
const UpcomingEvents = () => {
  const items = [
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-1",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-2",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-3",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-4",
    },
  ];
  const blogcardData = [
    {
      id: 1,
      imageUrl: "images/latestNews/img-1.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and tyspesetting industry.",
      read: "/",
    },
    {
      id: 2,
      imageUrl: "images/latestNews/img-2.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
    {
      id: 3,
      imageUrl: "images/latestNews/img-3.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
  ];
  return (
    <div className="upcomingEvent_container">
      <div className="upcomingEvent_banner position-relative">
        <div className="container ">
          <img src="images/banner/upcoming.png" />
          <p className="fw-300 pSize">
            News<span> Latest News</span>
          </p>
          <h2 className="inh2">
            <span>Waicy</span> Competition
          </h2>
          <p className="fw-600 pSize">
            <b>Deadline:</b> June 30,2020, 5:00pm
          </p>
          <p className="fw-300 pSize">
            <b>Time Left </b> : 5 days:
          </p>
        </div>
      </div>

      <section className="news_section pt-100 pb-100">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 pe-5">
              <p>
                PLorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has
              </p>

              <p>
                PLorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has
              </p>
              <p>
                PLorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has
              </p>
            </div>
            <div className="col-md-5">
              <img
                src="images/news/news-2.jpg"
                alt="News"
                className="full-width-image img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="event_page_details_container">
          <div className="container">
            <div className="event_page_details_name">
              <span>
                <FaCalendarAlt />
              </span>
              <div>
                <div className="event_page_details_title">News Details</div>
                <div className="event_page_details_para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore
                </div>
              </div>
            </div>
            <div className="event_page_details_name">
              <span>
                <FaLocationArrow />
              </span>
              <div>
                <div className="event_page_details_title">News Details</div>
                <div className="event_page_details_para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore
                </div>
              </div>
            </div>
            <div className="event_page_details_name">
              <span>
                <FaHandHoldingUsd />
              </span>
              <div>
                <div className="event_page_details_title">News Details</div>
                <div className="event_page_details_para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore
                </div>
              </div>
            </div>
            <div className="event_page_details_name">
              <span>
                <FaCalendarAlt />
              </span>
              <div>
                <div className="event_page_details_title">News Details</div>
                <div className="event_page_details_para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore
                </div>
              </div>
            </div>
            <div className="event_page_details_name">
              <span>
                <FaGlobeAmericas />
              </span>
              <div>
                <div className="event_page_details_title">News Details</div>
                <div className="event_page_details_para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-100 pb-100 application_process">
        <div className="container">
          <h3>Application Processs</h3>
          <ul className="flex-column">
            <li>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Provident quam repudiandae ipsam esse nihil earum assumenda.
              Consequatur, rerum laudantium. Fuga accusantium, inventore
              cupiditate nesciunt repudiandae voluptatum asperiores cumque
              quidem consequatur.
            </li>
            <li>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
              veniam, fugiat assumenda beatae fugit ullam sapiente quae libero
              quo, voluptatum sunt recusandae at cum dignissimos! Eligendi
              obcaecati natus maiores voluptate.
            </li>
          </ul>
          <div className="application_process_btns">
            <a href="/" className="uniBtn uniBtnPink btnPLight">
              Apply Now
            </a>
            <a href="/" className="uniBtn uniBtnBlueBorder btnPLight ms-3">
              Read More
            </a>
          </div>
        </div>
      </section>
      <section className="pb-100">
        <h2 className="container inh2 pb-md-4">
          Top<span>News</span>
        </h2>
        <div className="container">
          <div className="row">
            {blogcardData.map((blogCard, index) => (
              <div className="col-md-4" key={blogCard.id}>
                <BlogCard {...blogCard} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpcomingEvents;
