import 'bootstrap/dist/css/bootstrap.css';
import '../cart-checkout-pay-header/CCPHeader.css';
import '../../../styles.css'
import { Col, Container, Row } from 'react-bootstrap';

const CCPHeader = (HeaderData) => {
    return (
        <div className='ccp-header'>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <div className='ccp-title'>
                            <h2>{HeaderData.HeaderTitle}</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CCPHeader;