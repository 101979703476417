import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CardUni from "../../pages/CardUni.jsx";
import "./Core.css";

const Core = () => {
  const cardData = [
    {
      imageUrl: "images/about/icon-1.png",
      title: "Excellence",
      spantxt: "",
      paragraph:
        "We strive for excellence in everything we do, from developing innovative curriculums to delivering exceptional learning experiences. Our commitment to excellence ensures that our students receive the best education possible.",
      backgroundColor: "white",
    },
    {
      imageUrl: "images/about/icon-2.png",
      title: "Innovation",
      spantxt: "",
      paragraph:
        "We embrace innovation and technology to revolutionize education. By constantly exploring new ideas and approaches, we stay at the forefront of educational advancements, providing cutting-edge solutions to our learners.",
      backgroundColor: "white",
    },
    {
      imageUrl: "images/about/icon-2.png",
      title: "Inclusivity",
      spantxt: "",
      paragraph:
        "We believe in inclusive education, where every individual, regardless of background or abilities, has equal access to learning opportunities. We foster a diverse and supportive learning environment that celebrates uniqueness and promotes inclusivity.",
      backgroundColor: "white",
    },
    {
      imageUrl: "images/about/icon-2.png",
      title: "Empowerment",
      spantxt: "",
      paragraph:
        "We empower our students to take charge of their learning journey, fostering self-confidence and independence. Through our courses, we equip learners with the skills and knowledge they need to succeed in a rapidly changing world.",
      backgroundColor: "white",
    },
    {
      imageUrl: "images/about/icon-2.png",
      title: "Creativity",
      spantxt: "",
      paragraph:
        "We value creativity as a catalyst for innovation and problem-solving. Our hands-on and imaginative approach encourages students to explore their creative potential, unleashing their imaginations and fostering a love for learning.",
      backgroundColor: "white",
    },
    {
      imageUrl: "images/about/icon-2.png",
      title: "Lifelong Learning",
      spantxt: "",
      paragraph:
        "We believe that learning is a lifelong pursuit. Our dedication to lifelong learning ensures that we provide opportunities for continuous growth, not just for students but also for educators, parents, and lifelong learners.",
      backgroundColor: "white",
    },
    {
      imageUrl: "images/about/icon-2.png",
      title: "Impact",
      spantxt: "",
      paragraph:
        "We are driven by the desire to make a positive impact on the lives of our students and society. Our efforts are focused on creating meaningful learning experiences that empower individuals to drive positive change.",
      backgroundColor: "white",
    },
    {
      imageUrl: "images/about/icon-2.png",
      title: "Collaboration",
      spantxt: "",
      paragraph:
        "We believe in the power of collaboration and partnerships. By working together with schools, educational institutions, parents, and communities, we create a network of support that enhances the learning journey for our students.",
      backgroundColor: "white",
    },
    // Add more card data as needed
  ];
  const responsiveConfig = {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 2,
    },
  };

  return (
    <div className="core_value">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <h2>Core Values</h2>
            <p>
              The core values of Meritus reflect the guiding principles and
              fundamental beliefs that shape the company's culture, behavior,
              and decision-making. Here are the core values for the Meritus
              brand
            </p>
          </div>
          <div className="col-md-8">
            <OwlCarousel
              className="owl-theme"
              margin={40}
              loop
              dots
              responsive={responsiveConfig}
            >
              {cardData.map((card, index) => (
                <div className="item" key={index}>
                  <CardUni {...card} />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Core;
