import "bootstrap/dist/css/bootstrap.css";
import "./TaCoPlaybits.css";
import { Col, Container, Row } from "react-bootstrap";
import VideoTestim from "../../../component/common/video-testimonial/VideoTestim";

const TaCoPlaybits = () => {
  // Responsive breakpoint configuration for the carousel
  const responsiveConfig = {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 2,
    },
  };

  // Card Slider Section Carousel Customizations
  const options = {
    className: "owl-theme tp-slider",
    // loop: true,
    center: true,
    margin: 30,
    // autoplay: true,
    smartSpeed: 450,
    // nav: true,
    responsive: responsiveConfig,
  };
  return (
    <div className="taco-playbits">
      {/* Top banner section  */}
      <section className="playbits-top-banner"></section>

      {/* What is Ta.Co Playbits section  */}
      <section className="what-is-palybits-wrapper pt-50 pb-50">
        <Container>
          {/* Section Heading  */}
          <Row>
            <Col lang={12}>
              <div className="heading-npgs dark-blue-head pb-30">
                <h2>
                  What is <span>Ta.Co Playbits?</span>
                </h2>
              </div>
            </Col>
          </Row>

          {/* Pointer and Timeline col  */}
          <Row>
            {/* Pointers with Certi icons  */}
            <Col lg={5}>
              <div className="pointers-auth-icons-wrapper">
                <div className="abt-playbits-pointers">
                  <ul>
                    <li>
                      <strong>Ta.Co Playbits is</strong> an interactive Screen
                      Free STEM educational gadget for kids ages 3+.
                    </li>
                    <li>
                      Simply tap on the coding Chips with the Smart Wand to
                      begin your child's journey of self-learning.
                    </li>
                    <li>
                      Learn ABC's, Music, Math, Coding, Memory & Logic games &
                      much more.
                    </li>
                    <li>Infinite play opportunities | Challenging games.</li>
                    <li>
                      Taco line of educational gadget enables learning
                      21st-century skills through hands-on tactile play and to
                      have natural interactions between the player & the Coding
                      educational tools.
                    </li>
                  </ul>
                </div>

                {/* Certi icons  */}
                <div className="playbits-auth-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/auth-icon-a.png`}
                    alt=""
                    srcset=""
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/auth-icon-b.png`}
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
            </Col>

            {/* Timeline cards */}
            <Col lg={7} className="p-rel">
              <Row className="gx-5 timeline-cards-wrapper">
                {/* Card -1 */}
                <Col lg={4} md={4}>
                  <div className="playbits-timeline-card text-center p-rel">
                    {/* card title  */}
                    <div className="playbits-card-title">
                      <p>Technology backed by pending u.s. patents</p>
                    </div>

                    {/* card icon  */}
                    <div className="playbit-card-icon playbits-card-icon-a">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/us-patents.png`}
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </Col>

                {/* Card -2 */}
                <Col lg={4} md={4}>
                  <div className="playbits-timeline-card text-center p-rel">
                    {/* card title  */}
                    <div className="playbits-card-title">
                      <p>Braille Compatible</p>
                    </div>

                    {/* card icon  */}
                    <div className="playbit-card-icon playbits-card-icon-b">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/Braille.png`}
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </Col>

                {/* Card -3 */}
                <Col lg={4} md={4}>
                  <div className="playbits-timeline-card text-center p-rel">
                    {/* card title  */}
                    <div className="playbits-card-title">
                      <p>Record in any language</p>
                    </div>

                    {/* card icon  */}
                    <div className="playbit-card-icon playbits-card-icon-c">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/Record.png`}
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </Col>
                <div className="arrow-right"></div>
              </Row>

              <Row className="gx-5 timeline-cards-wrapper timeline-cards-wrapper-copy timeline-arrow-right justify-content-center pt-50 pb-50">
                {/* Card -4 */}
                <Col lg={4} md={4}>
                  <div className="playbits-timeline-card playbits-timeline-card-copy text-center p-rel">
                    {/* card title  */}
                    <div className="playbits-card-title">
                      <p>Portable take it anywhere</p>
                    </div>

                    {/* card icon  */}
                    <div className="playbit-card-icon playbits-card-icon-a">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/Portable.png`}
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </Col>

                {/* Card -5 */}
                <Col lg={4} md={4}>
                  <div className="playbits-timeline-card playbits-timeline-card-copy text-center p-rel">
                    {/* card title  */}
                    <div className="playbits-card-title">
                      <p>100% screen free</p>
                    </div>

                    {/* card icon  */}
                    <div className="playbit-card-icon playbits-card-icon-b">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/Screen-Free.png`}
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </Col>

                <div className="arrow-right arrow-right-copy"></div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* What will your child learn  */}
      <section className="child-learn pt-50 pb-50">
        <Container>
          {/* Section Heading  */}
          <Row>
            <Col lang={12}>
              <div className="heading-npgs dark-blue-head pb-30">
                <h2>
                  What will your<span> Child learn ?</span>
                </h2>
              </div>
            </Col>
          </Row>

          {/* Child learn content and yt video */}
          <Row>
            {/* What child learn Content */}
            <Col lg={5}>
              <div className="what-will-your-child-learn-txt-content">
                <ul>
                  <li>
                    Ta.Co Playbits was conceptualized and created with
                    educational value at its core.
                  </li>
                  <li>
                    The gamified learning approach provides for some of the most
                    interesting challenges and to have natural interactions
                    between the child & the Smart connected Educational Gadget.
                  </li>
                  <li>
                    Parents or Educators can record their own questions in their
                    own voice in any language using the special customization
                    feature via our free software for Mac | Windows.
                  </li>
                  <li>
                    What more it is also Braille compatible, enabling visually
                    impaired children to play and learn.
                  </li>
                  <li>
                    Taco is a flexible & scalable learning educational gadget :
                    Use the same wand and buy extra Game chips only as & when
                    needed. Real value for money!
                  </li>
                </ul>
              </div>
            </Col>

            {/* YT Video  */}
            <Col lg={7}>
              <div className="child-learn-yt-video">
                <iframe
                  width="660"
                  height="410"
                  src="https://www.youtube.com/embed/uMap-Yn_Bc4?si=Sf3OT5dbGyQHED8x"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* C Stem Kit  */}
      <section className="cstem-kit pt-50 pb-50">
        <Container>
          {/* Section Heading  */}
          <Row>
            <Col lang={12}>
              <div className="heading-npgs dark-blue-head pb-30">
                <h2>
                  C Stem<span> Kit</span>
                </h2>
              </div>
            </Col>
          </Row>

          {/* Content and GIF  */}
          <Row>
            {/* Text Content  */}
            <Col lg={6}>
              <div className="cstem-txt-content">
                <ul>
                  <li>
                    <strong>Createful Art:</strong> Find Color | Mix Color |
                    Discover Shapes
                  </li>
                  <li>
                    <strong>Brain Training:</strong> Play Memory & Logical
                    thinking Games Play Learn to Code Games
                  </li>
                  <li>
                    <strong>Focus on mastering:</strong> Problem Solving |
                    Critical thinking STEM Skills | Decision Making
                  </li>
                  <li>
                    <strong>Yes or No Game:</strong> Number sense | Greater than
                    & Less than General Knowledge | Quiz | Trivia
                  </li>
                  <li>
                    <strong>Play & Learn in any language:</strong> Record your
                    own Questions
                  </li>
                </ul>
              </div>
            </Col>

            {/* GIF  */}
            <Col lg={6}>
              <div className="cstem-gif-content text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/taco-playbits/gifs/C-Stem-Gif.gif`}
                  alt=""
                />
              </div>
            </Col>
          </Row>

          {/* Text Row  */}
          <Row>
            <Col lg={12}>
              <div className="cstem-txt-row text-center">
                <p>Master Mind | Key to unlocking your child's inner genius!</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Video and download software */}
      <section className="video-playbits-soft text-center pb-50">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="playbits-video-tutorials">
                <div className="pvt-video pvt-video-a">
                  <iframe
                    src="https://player.vimeo.com/video/923284915?h=7b433413ae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    title="TACO 1"
                    width="500px"
                  ></iframe>
                </div>
                <div className="pvt-video pvt-video-b">
                  <iframe
                    src="https://player.vimeo.com/video/923284979?h=a782da3a32&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    title="TACO 2"
                    width="500px"
                  ></iframe>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>

              <div className="rec-que-txt">
                <p>
                  Record your Own Questions in any Language using Taco Playbits
                  Software
                </p>
              </div>

              {/* software download Available text */}
              <div className="software-download-txt-icon">
                <p>Software Download Available For</p>
                <div className="software-compatible-platforms pt-50 p-rel">
                  {/* windows  */}
                  <div className="scp-img-a">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/windows.png`}
                      alt=""
                      srcset=""
                    />
                    <p>Windows</p>
                  </div>

                  {/* Mac  */}
                  <div className="scp-img-b">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/mac-os-logo.png`}
                      alt=""
                      srcset=""
                    />
                    <p>Mac</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* C Stem Kit - Chips */}
      <section className="cstem-chips pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-npgs dark-blue-head">
                <h2>
                  C STEM <span>KIT</span>
                </h2>
              </div>
              <div className="sub-heading-npgs pb-20">
                <p>Ages 3+ 40 Chips</p>
              </div>
            </Col>
          </Row>

          <div className="wand-1-wrapper">
            <div className="wand-sequence text-center">
              <p>Wand* 1</p>
            </div>

            {/* Wand Icons  */}
            <div className="wand-icons">
              <img
                src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/cstem-kit-a.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/cstem-kit-b.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/cstem-kit-c.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/cstem-kit-d.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/cstem-kit-e.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/cstem-kit-f.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/cstem-kit-g.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/taco-playbits/icons/cstem-kit-h.png`}
                alt=""
                srcset=""
              />
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default TaCoPlaybits;
