import 'bootstrap/dist/css/bootstrap.css';
import '../../styles.css';
import './TopicsCard.css';
import { Card } from 'react-bootstrap';

const TopicsCard = (props) => {
    return (
        <Card className={`topics-card ${props.index % 4 === 0 || props.index % 4 === 3 ? "topics-card-a" : "topics-card-b"}`}>
            <Card.Body>
                <Card.Title>
                    <div className='card-title p-rel'>
                        <h2 className='fw-600'>
                            <span className='topic-number'>
                                {props.index + 1}
                            </span>
                            <span className='pink-head-txt'>
                                {props.topicDetails.topicName}
                            </span>
                        </h2>
                        {/* <h2 className='fw-600'>
                            <span className='topic-number'>             {TopicsCardData.TopicsCardNumber}
                            </span>
                            {TopicsCardData.TopicsCardHeading}<br />
                            <span className='pink-head-txt'>
                                {TopicsCardData.TopicsCardPinkTxt}
                            </span>
                        </h2> */}
                    </div>
                </Card.Title>
                <Card.Text>
                    <div className='card-text'>
                        <p>{props.topicDetails.topicDetails}</p>
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default TopicsCard;