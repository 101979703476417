// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h5p-course-presentation .h5p-wrapper{
    width: auto;
    /* height: 450px !important; */
}

#h5p-viewer{
    /* height: 600px !important; */
}

.WACStatusBarContainer {
    display: none!important;
}`, "",{"version":3,"sources":["webpack://./src/component/h5p-viewer/H5PViewer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".h5p-course-presentation .h5p-wrapper{\n    width: auto;\n    /* height: 450px !important; */\n}\n\n#h5p-viewer{\n    /* height: 600px !important; */\n}\n\n.WACStatusBarContainer {\n    display: none!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
