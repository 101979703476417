// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-type-section:last-child
{
padding-left: 40px;
}
.job-type-section span svg
{
margin-right: 7px;
}`, "",{"version":3,"sources":["webpack://./src/component/career/Job.css"],"names":[],"mappings":"AAAA;;AAEA,kBAAkB;AAClB;AACA;;AAEA,iBAAiB;AACjB","sourcesContent":[".job-type-section:last-child\n{\npadding-left: 40px;\n}\n.job-type-section span svg\n{\nmargin-right: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
