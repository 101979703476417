import { FaPersonBooth } from "react-icons/fa";
import { Link } from "react-router-dom";
const HighSchoolBundleOption = () => {
  return (
    <div>
      <section className="pt-100">
        {/* <div className="container"> */}
        <h2 className="text-center bundle_heading">
          High School<span> Bundle</span>{" "}
        </h2>
        <h3 className="text-center bundle_sub_heading mb-50">
          A holistic AI & Robotics learning platform for your school
        </h3>
        <div className="row">
          <div className="col-md-5">
            <div className="school_bundle_img">
              <img src="images/SchoolBundle/gif/High-school-bundle(India).gif" />
              {/* <img src="images/SchoolBundle/India/high-school-bundle-2.png" />
              <img src="images/SchoolBundle/India/high-school-bundle-3.png" /> */}
            </div>
          </div>
          <div className="ps-5 col-md-6 ">
            <div className="row">
              <div className="col-md-12 mb-2">
                <div className="school_bundle_usps">
                  <div>
                    <img src="images/SchoolBundle/India/icons/2-Icon.svg" />
                    <p>Supports 2 students in the classroom</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/5-Icon.svg" />
                    <p>24 + Hours of Lesson plans</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/3-Icon.svg" />
                    <p>Enabling hands-on STEM learning experiences</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/6-Icon.svg" />
                    <p>
                      Curriculum is aligned to NEP 2020 and to CBSE, ICSE &
                      State Boards
                    </p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/4-con.svg" />
                    <p>Teacher resources and Training Available</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/7-Icon.svg" />
                    <p>
                      Our kits are durable and have a service life of a minimum
                      of 5 years.
                    </p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/6-Icon.svg" />
                    <p>
                    Discover our AI curriculum: standards-aligned with the AI4K12 framework, endorsed by AAAI and CSTA.
                    </p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/8-Icon.svg" />
                    <p>
                      Learning resources are designed by in-house experts and
                      guided by our International Governing Board.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <h4 className="bundle_heading2 psb-sub-heading text-center">
                Create your own learning solution
              </h4>
              <h5 className="bundle_sub_heading2 text-center">
                We'll assist you in customizing and assembling your unique bund
              </h5>
              <div className="col-md-12 mt-3">
                <div className="school_bundle_usps2">
                  <div>
                    <div>
                      <h6>
                        <span>1. </span>Phiro Pro
                      </h6>
                      <p>Scratch, Snap and Phiro Code app enabled Robot</p>
                    </div>

                    <div>
                      <h6>
                        <span>2. </span>Micro: Code Plus
                      </h6>
                      <p>Electronic kit enabled by sensors + Python</p>
                    </div>

                    <div>
                      <h6>
                        <span>3. </span>Lego Mindstorm
                      </h6>
                      <p>Programmable robotics</p>
                    </div>

                    <div>
                      <h6>
                        <span>4. </span>RC Cars
                      </h6>

                      <p>Explore EV Car technology</p>
                    </div>

                    <div>
                      <h6>
                        {" "}
                        <span>5. </span>CV Pro competition kit
                      </h6>
                      <p>
                        Designed for WRO & other global Autonomous Vehicle
                        technology competitions
                      </p>
                    </div>

                    <div>
                      <h6>
                        <span>6. </span>NXG Drone
                      </h6>
                      <p>Learn to build, code and fly drones!</p>
                    </div>

                    <div>
                      <h6>
                        <span>7. </span>Data Science with Python
                      </h6>
                      <p>Virtual and On-Line course</p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h6>
                        <span>8. </span>AI Lite
                      </h6>
                      <p>Construction kit powered by MIT App Inventor</p>
                    </div>

                    <div>
                      <h6>
                        <span>9. </span>LearnIOT Pro
                      </h6>
                      <p>Powered by Arduino IDE </p>
                    </div>

                    <div>
                      <h6>
                        <span>10. </span>Humanoid
                      </h6>

                      <p>Discover how to build and control humanoid robots</p>
                    </div>

                    <div>
                      <h6>
                        <span>11. </span>CV Pro
                      </h6>
                      <p>
                        AI kit is a learning platform, which will expose
                        students to AI, Machine Learning & Data Science all
                        through the lens of self-driving cars
                      </p>
                    </div>

                    <div>
                      <h6>
                        <span>12. </span>VDC-Virtual Driverless Car
                      </h6>
                      <p>On-Line course</p>
                    </div>

                    <div>
                      <h6>
                        <span>13. </span>AI with Python
                      </h6>
                      <p>Virtual and On-Line course</p>
                    </div>

                    <div>
                      <Link to="/contact-us" className="bundle_btn">
                        GET STARTED
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </div>
  );
};
export default HighSchoolBundleOption;
