import React from 'react'
import './CheckInternetConnection.css'
import { Detector } from 'react-detect-offline'
import { MdSignalWifiStatusbarNotConnected } from 'react-icons/md'

const CheckInternetConnection = (props) => {
    return (
        <Detector render={({ online }) => (
            online ? props.children :
                <div className='connection-lost-wrapper'>
                    {/* <div className="connection-img-wrapper"> */}
                        {/* <img src={`${process.env.PUBLIC_URL}/images/no-wifi.png`} /> */}
                        <MdSignalWifiStatusbarNotConnected className='coneection-icon'/>
                    {/* </div> */}
                    <div className='heading'>
                        <h1 className='text-center'>OOPS! you are not connected to internet</h1>
                    </div>
                </div>
        )}

        />
    )
}

export default CheckInternetConnection
