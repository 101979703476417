// import 'bootstrap/dist/css/bootstrap.css';
// import './WishlistProduct.css';
// import { MdDeleteOutline } from "react-icons/md";
// import CommonButton from '../Button/CommonButton';
// import { useDispatch, useSelector } from 'react-redux';
// import { removeProductFromWishListFailure, removeProductFromWishListStart, removeProductFromWishListSuccess } from '../../../redux/wishListRedux';
// import { addToCartFailure, addToCartStart, addToCartSuccess } from '../../../redux/cartRedux';
// import { MEDIA_URL } from '../../../config';
// import { userRequest } from '../../../requestMethod';

// const WishlistProduct = (props) => {

//     const dispatch = useDispatch();

//     const user = useSelector((state) => state.loginUser.currentUser);

//     const removeProductFromWishList = async (dispatch, wishlistId) => {
//         dispatch(removeProductFromWishListStart());
//         try {
//             const res = await userRequest.delete(`wishlist/${user._id}`, { data: { wishlistId } });
//             dispatch(removeProductFromWishListSuccess(wishlistId));
//         } catch (error) {
//             dispatch(removeProductFromWishListFailure());
//         }
//     }

//     const handleRemoveProductFromWishList = (wishlistId) => {
//         removeProductFromWishList(dispatch, wishlistId)
//     }

//     const addProductToCart = async (dispatch, product) => {
//         dispatch(addToCartStart());
//         try {
//             dispatch(addToCartSuccess(product));
//             removeProductFromWishList(dispatch, product?.wishlistId)
//         } catch (error) {
//             dispatch(addToCartFailure());
//         }
//     };

//     const handleAddToCart = (product) => {
//         addProductToCart(dispatch, { ...product, quantity: 1 })

//     }

//     return (
//         <div className='wishlist-product ptb-20'>

//             {/* Product serial no */}
//             <div className='serial-no wishlist-product-index'>
//                 <h2>{props.index}</h2>
//             </div>

//             {/* Product image */}
//             <div className='product-thumbnail'>
//                 <img src={MEDIA_URL + props.itemDetails?.productImg} alt='product-img' />
//             </div>

//             {/* Product name */}
//             <div className='name-rating-container text-center width-m fw-600'>
//                 <p className='text-capitalize'>{props.itemDetails?.title}</p>
//                 {/* <div className='product-rating'>
//                     <span>{props.rating}+ </span>
//                     <span className="star">&#9733;</span>
//                     <span className="star">&#9733;</span>
//                     <span className="star">&#9733;</span>
//                     <span className="star">&#9733;</span>
//                     <span className="star">&#9733;</span>
//                 </div> */}
//             </div>

//             {/* product price */}
//             <div className='product-price'>
//                 <p>&#8377;{props.itemDetails?.price}</p>
//             </div>

//             {/* Add to cart btn  */}
//             <div className='add-to-cart-btn move-to-cart'>
//                 <CommonButton onClickAction = {() => handleAddToCart(props.itemDetails)}
//                     btnClass="add-to-cart-btn ff"
//                     btnName="move to cart"
//                 />
//             </div>

//             {/* Remove product from wishlist */}
//             <div className='delete-icon remove-product mb-0'>
//                 <MdDeleteOutline onClick={() => handleRemoveProductFromWishList(props.itemDetails?.wishlistId)} />
//             </div>
//         </div>
//     )
// }

// export default WishlistProduct;







// ==============new code below=====


import 'bootstrap/dist/css/bootstrap.css';
import './WishlistProduct.css';
import { MdDeleteOutline } from "react-icons/md";
import CommonButton from '../Button/CommonButton';
import { useDispatch } from 'react-redux';
import { removeProductFromWishListFailure, removeProductFromWishListStart, removeProductFromWishListSuccess } from '../../../redux/wishListRedux';
import { addToCartFailure, addToCartStart, addToCartSuccess } from '../../../redux/cartRedux';
import { MEDIA_URL } from '../../../config';

const WishlistProduct = (props) => {


    const dispatch = useDispatch();

    const removeProductFromWishList = (dispatch, productId) => {
        dispatch(removeProductFromWishListStart());
        try {
            // const res = await userRequest.post('user/create', product);
            dispatch(removeProductFromWishListSuccess(productId));
            // if (res.status === 200) {
            //     setSuccess(true);
            //     setSuccessMessage("product has been added!");
            //     setFirstName('');
            //     setLastName('');
            //     setEmail('');
            //     setPhone('');
            //     setDateOfBirth('');
            // }
        } catch (error) {
            dispatch(removeProductFromWishListFailure());
            // if (error.response.status === 409) {
            //     setEmailError(true);
            //     setEmailErrorMessage(error.response.data);
            // }

            // else {
            //     setError(true);
            //     setErrorMessage("Something went wrong!");
            // }
        }
    }

    const handleRemoveProductFromWishList = (productId) => {
        removeProductFromWishList(dispatch, productId)
    }

    const addProductToCart = async (dispatch, product) => {
        dispatch(addToCartStart());
        try {
            dispatch(addToCartSuccess(product));
            removeProductFromWishList(dispatch, product._id)
        } catch (error) {
            dispatch(addToCartFailure());
        }
    };

    const handleAddToCart = (product) => {
        addProductToCart(dispatch, { ...product, quantity: 1 })

    }

    return (
        <div className='wishlist-product ptb-20'>

            {/* Product serial no */}
            <div className='serial-no wishlist-product-index'>
                <h2>{props.index}</h2>
            </div>

            {/* Product image */}
            <div className='product-thumbnail'>
                <img src={MEDIA_URL + props.itemDetails.productImg[0]} alt='product-img' />
            </div>

            {/* Product name */}
            <div className='name-rating-container text-center width-m fw-600'>
                <p className='text-capitalize'>{props.itemDetails.title}</p>
                <div className='product-rating'>
                    <span>{props.rating}+ </span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                </div>
            </div>

            {/* product price */}
            <div className='product-price'>
                <p>&#8377;{props.itemDetails.price}</p>
            </div>

            {/* Add to cart btn  */}
            <div className='add-to-cart-btn move-to-cart'>
                <CommonButton onClickAction = {() => handleAddToCart(props.itemDetails)}
                    btnClass="add-to-cart-btn ff"
                    btnName="move to cart"
                />
            </div>

            {/* Remove product from wishlist */}
            <div className='delete-icon remove-product mb-0'>
                <MdDeleteOutline onClick={() => handleRemoveProductFromWishList(props.itemDetails._id)} />
            </div>
        </div>
    )
}

export default WishlistProduct;