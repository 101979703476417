import "bootstrap/dist/css/bootstrap.css";
import "../styles.css";
import "./UserProfile.css";
import TabWrapper from "../common/tab/TabWrapper";
import AcPageHeader from "../common/account-pgs-header/AcPageHeader";
import MyPurchases from "./MyPurchases";
import AccountSetting from "./AccountSetting";
import MyLearning from "./MyLearning";
import { Container } from "react-bootstrap";

const UserProfile = () => {
  const titles = ["My Orders", "My Learnings", "Account Settings"];
  const contents = [<MyPurchases />, <MyLearning />, <AccountSetting />];
  return (
    <div className="user-profile">
      <Container fluid>
        <AcPageHeader />
      </Container>
      <Container>
        <div className="tab-ac-page">
          <TabWrapper
            titles={titles}
            contents={contents}
            TabDataClass="user-profile-tab"
          />
        </div>
      </Container>
    </div>
  );
};

export default UserProfile;
