// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.core_value {
  background: rgb(151 124 255 / 10%);
  padding: 50px 0px;
}
.core_value div:first-child > h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dBlue);
}

`, "",{"version":3,"sources":["webpack://./src/component/about/Core.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".core_value {\r\n  background: rgb(151 124 255 / 10%);\r\n  padding: 50px 0px;\r\n}\r\n.core_value div:first-child > h2 {\r\n  font-size: 30px;\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n  color: var(--dBlue);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
