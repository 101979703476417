// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/blog-banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event_banner {
  height: 440px;
  background: #fff8e6;
  position: relative;
  overflow: hidden;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.blog-banner-title {
  background-color: transparent !important;
  background-color: initial !important;
  color: var(--dPink) !important;
}
.event_banner_img {
  position: absolute;
  right: -20px;
  bottom: -170px;
}
.event_banner .inh2 {
  position: absolute;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: #000000a1;
  width: 100%;
  text-align: center;
  padding: 20px;
  color: #ffffff !important;
  text-shadow: 2px 2px 15px #000000;
}
@media (max-width: 576px) {
  .event_banner .inh2 {
    padding-left: 10px;
  }
  .event_banner {
    height: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/events/EventBanner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,yDAA+D;EAC/D,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,wCAAkC;EAAlC,oCAAkC;EAClC,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,iCAAiC;AACnC;AACA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".event_banner {\n  height: 440px;\n  background: #fff8e6;\n  position: relative;\n  overflow: hidden;\n  background-image: url(\"../../../public/images/blog-banner.png\");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.blog-banner-title {\n  background-color: unset !important;\n  color: var(--dPink) !important;\n}\n.event_banner_img {\n  position: absolute;\n  right: -20px;\n  bottom: -170px;\n}\n.event_banner .inh2 {\n  position: absolute;\n  width: 50%;\n  top: 50%;\n  transform: translateY(-50%);\n  background: #000000a1;\n  width: 100%;\n  text-align: center;\n  padding: 20px;\n  color: #ffffff !important;\n  text-shadow: 2px 2px 15px #000000;\n}\n@media (max-width: 576px) {\n  .event_banner .inh2 {\n    padding-left: 10px;\n  }\n  .event_banner {\n    height: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
