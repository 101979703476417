export const validateRequired = (value, type) => {
    if (!value) {
        return 'This field is required!';
    }

    if (typeof value === "string") {
        if (value.trim() === '') {
            return 'Only white space not allowed!';
        }
    }

    if (type === "phone") {
        if (isNaN(value)) {
            return 'Phone number should be numeric only';
        }

        if (value.length > 10 || value.length < 10) {
            return 'Phone number should have 10 digits';
        }
    }

    if (type === "email") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
            return 'Please enter a valid email address';
        }
    }


    if(type === "number"){
        if (isNaN(value)) {
            return 'Value should be numeric only';
        }
    }
};