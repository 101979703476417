import { Link } from "react-router-dom";
import "./Cta.css";
export default function Cta() {
  return (
    <div className="ctaContainer">
      <div className="row m-0 p-0">
        <div className="ctaContainer1"></div>
        <div className="col-md-4 order-2 order-md-1 position-relative">
          <div className="cta">
            <img
              src="images/home/cta.png"
              alt="/"
              className="d-none d-md-block"
            />
          </div>
        </div>
        <div className="col-md-8 order-1 order-md-2 mt-md-4 px-lg-5">
          <div className="ctaContainerTxt">
            <h2 className="ff fw-700 dBlue hSize text-center">
              <span className="fw-500">Preferred by </span> <br />
              <span className="dPink">
                75,000 + Parents & Students Globally
              </span>
            </h2>
            <div className="ctaBtnn">
              {/* <Link href="/" className="uniBtn uniBtnBlue btnPLight">
              Enroll Now
            </Link> */}
              <div className="cta_certificates">
                <img src="images/home/footer/STM_BrandAsset.png" />
                <img src="images/home/footer/kidSAFE_listed_seal_large.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
