import "bootstrap/dist/css/bootstrap.css";
import CommonButton from "../common/Button/CommonButton";
import "./ProductPage.css";
import "../styles.css";
import TabWrapper from "../common/tab/TabWrapper";
import ProductOverview from "../common/tab/ProductOverview";
import Topics from "../common/tab/Topics";
import Skills from "../common/tab/Skills";
import ContactUs from "../home/ContactUs";
import ProductRating from "../common/product-rating/ProductRating";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartFailure,
  addToCartStart,
  addToCartSuccess,
} from "../../redux/cartRedux";
import { useEffect, useState } from "react";
import { getProducts, getSpecificCourseRating } from "../../redux/apiCalls";
import VimeoPlayer from "@vimeo/player";
import { useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import RenderHTMLContent from "../../RenderHTMLContent";
import { MEDIA_URL, isUrl } from "../../config";
import Loader from "../common/Loader/Loader";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';


// import { Col, Container, Row } from 'react-bootstrap';
function ProductPage() {
  const dispatch = useDispatch();
  // Cheking user is logged or not=====
  const user = useSelector((state) => state.loginUser.currentUser);

  useEffect(() => {
    getProducts(dispatch);
  }, []);

  // useEffect(() => {
  //   getSpecificCourseRating(dispatch )
  // },[])

  const location = useLocation();
  const productTitle = decodeURIComponent(location.pathname.split("/")[2]);

  const isProductsFetching = useSelector((state) => state.product.isFetching);

  const product = useSelector((state) =>
    state.product?.products?.find((item) => item.title == productTitle)
  );



  useEffect(() => {
    getSpecificCourseRating(dispatch, product?._id);
  }, [product]);

  const addProductToCart = async (dispatch, product) => {
    dispatch(addToCartStart());
    try {
      dispatch(addToCartSuccess(product));
    } catch (error) {
      dispatch(addToCartFailure());
    }
  };

  const handleAddToCart = () => {
    addProductToCart(dispatch, { ...product, quantity: 1 });
  };

  const productRatings = useSelector((state) => state?.rating?.ratings);
  const ratingForCurrentProduct = productRatings?.filter(
    (item) => item.productId === product?._id
  );

  // Calculate the sum of ratings
  const totalRating = ratingForCurrentProduct?.reduce(
    (acc, curr) => acc + curr.rating,
    0
  );
  // Calculate the average rating
  const averageRating = totalRating
    ? totalRating / ratingForCurrentProduct?.length
    : null;

  useEffect(() => {
    getSpecificCourseRating(dispatch, product?._id);
  }, [product]);

  const titles = ["Overview", "Topics", "Skills", "Rating"];
  const contents = [
    <ProductOverview
      overView={product && product.overview}
      overViewImages={product && product.overviewImages}
    />,
    <Topics topics={product && product.topics} />,
    <Skills skills={product && product.skills} />,
    <ProductRating productRatings={ratingForCurrentProduct} />,
  ];

  // const robotixTitles = ["Overview", "Learning", "Review"];
  // const robotixContents = [
  //   <ProductOverview overView={product && product.overview} />,
  //   <Skills skills={product && product.skills} />,
  //   <ProductRating />,
  // ];

  const robotixTitles = [
    "Overview",
    "What is in the box",
    "What will your child learn",
    "Reviews",
  ];
  const robotixContents = [
    <ProductOverview
      ProductOverviewImg="images/product-page/kit-img-b.png"
      overView={product?.overview}
      overViewImages={product && product.overviewImages}
    />,
    // <div>{RenderHTMLContent(product?.whatIsInTheBox)}</div> ,
    // <div>{RenderHTMLContent(product?.whatWillChildLearn)}</div> ,
    <div className="heading">
      <h1 className="mb-3">what is in the box</h1>
      <RenderHTMLContent htmlContent={product?.whatIsInTheBox} />
    </div>,
    <div className="heading mb-2">
      <h1 className="mb-3">what will child learn</h1>
      <RenderHTMLContent htmlContent={product?.whatWillChildLearn} />
    </div>,
    <ProductRating productRatings={productRatings} />,
  ];

  const playerRef = useRef(null);
  const carouselRef = useRef(null);

  const playerContainer = document.getElementById(`player-container`);

  const initializeVimeoPlayer = () => {
    if (playerContainer && product.videoThumbnail) {
      const player = new VimeoPlayer(playerContainer, {
        url: product?.videoThumbnail,
      });

      playerRef.current = player;
    }
  };

  useEffect(() => {
    initializeVimeoPlayer();
  }, [playerContainer]);

  const [currentIndex, setCurrentIndex] = useState(0);


  // const handleNext = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === 1 ? 0 : prevIndex + 1));
  // };

  // const handlePrev = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === 0 ? 1 : prevIndex - 1));
  // };

  const handleNext = (type) => {
    if (type == "havingVideo") {
      setCurrentIndex((prevIndex) => (prevIndex === product?.productImg?.length ? 0 : prevIndex + 1));
    } else {
      setCurrentIndex((prevIndex) => (prevIndex === product?.productImg?.length - 1 ? 0 : prevIndex + 1));
    }
  };

  const handlePrev = (type) => {
    if (type == "havingVideo") {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? product?.productImg?.length : prevIndex - 1));
    } else {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? product?.productImg?.length - 1 : prevIndex - 1));
    }
  };



  if (isProductsFetching) {
    return <Loader />;
  }

  return (
    product && (
      <>
        <Container>
          <Row className="pt-4">
            <Col lg={7} sm={12}>
              <div className="product-demo-v">
                {/* {product && product.videoThumbnail ? (
                  <div className="product-page-slider">
                    {
                      product?.productImg?.length > 0 && product?.productImg?.map((item, index) => {
                        return (
                          <div
                            className={`item ${currentIndex === index ? "active" : ""}`}
                          >
                            {isUrl(item) ? (
                              <img src={item} alt="product-img" />
                            ) : (
                              <img src={MEDIA_URL + item} />
                            )}
                          </div>
                        );
                      })
                    }
                    <div
                      
                    >
                      <div
                        ref={playerRef}
                        className="player-container"
                        id="player-container"
                      ></div>
                    </div>
                    <button className="prev" onClick={handlePrev}>
                      <FaAngleLeft />
                    </button>
                    <button className="next" onClick={handleNext}>
                      <FaAngleRight />
                    </button>
                  </div>
                ) : (
                  <div className="product-page-slider">
                    {
                      product?.productImg?.length > 0 && product?.productImg?.map((item, index) => {
                        return (
                          <div
                            className={`item ${currentIndex === index ? "active" : ""}`}
                          >
                            <img
                              src={MEDIA_URL + item}
                              alt="thumbnail"
                            />
                          </div>
                        );
                      })
                    }
                  </div>
                )} */}

                <div className="product-page-slider">
                  {product && product.videoThumbnail ? (
                    <>
                      {/* <AwesomeSlider>
                      {
                        product?.productImg?.length > 0 && product?.productImg?.map((item, index) => {
                          return (
                            <div>
                              {isUrl(item) ? (
                                <img src={item} alt="product-img" />
                              ) : (
                                <img src={MEDIA_URL + item} />
                              )}
                            </div>
                          );
                        })
                      }
                      <div ref={playerRef} className="player-container h-100" id="player-container"></div>
                      </AwesomeSlider> */}

                      {
                        product?.productImg?.length > 0 && product?.productImg?.map((item, index) => {
                          return (
                            <div
                              className={`item ${currentIndex === index ? "active" : ""}`}
                            >
                              {isUrl(item) ? (
                                <img src={item} alt="product-img" />
                              ) : (
                                <img src={MEDIA_URL + item} />
                              )}
                            </div>
                          );
                        })
                      }

                      <div className={`item h-100 ${currentIndex === product.productImg.length ? "active" : ""}`}>
                        <div ref={playerRef} className="player-container h-100" id="player-container"></div>
                      </div>
                      <button className="prev" onClick={() => handlePrev("havingVideo")}>
                        <FaAngleLeft />
                      </button>
                      <button className="next" onClick={() => handleNext("havingVideo")}>
                        <FaAngleRight />
                      </button>

                      <div className="slider-dots">
                        {[...Array(product?.productImg?.length + 1)].map((_, index) => (
                          <span
                            key={index}
                            className={`dot ${currentIndex === index ? "active" : ""}`}
                            onClick={() => setCurrentIndex(index)}
                          ></span>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      {
                        product?.productImg?.length > 0 && product?.productImg?.map((item, index) => {
                          return (
                            <div
                              className={`item ${currentIndex === index ? "active" : ""}`}
                            >
                              <img
                                src={MEDIA_URL + item}
                                alt="thumbnail"
                              />
                            </div>
                          );
                        })
                      }
                      {
                        product?.productImg?.length > 1 && (
                          <>
                            <button className="prev" onClick={() => handlePrev("nonVideo")}>
                              <FaAngleLeft />
                            </button>
                            <button className="next" onClick={() => handleNext("nonVideo")}>
                              <FaAngleRight />
                            </button>
                          </>
                        )
                      }

                      {product?.productImg?.map((item, index) => (
                        <span
                          key={index}
                          className={`dot ${currentIndex === index ? "active" : ""}`}
                          onClick={() => setCurrentIndex(index)}
                        ></span>
                      ))}
                    </>
                  )
                  }
                </div>

                {/* <OwlCarousel items={1} className="owl-theme" loop nav>
                  {product && product.videoThumbnail ? (
                    <>
                      {
                        product?.productImg?.length > 0 && product?.productImg.map((item) => {
                          return (
                            <div className="item">
                              <img
                                src={MEDIA_URL + item}
                                alt="thumbnail"
                              />
                            </div>
                          )
                        })
                      }
                      <div className="item">
                        <div
                          ref={playerRef}
                          className="player-container"
                          id="player-container"
                        ></div>
                      </div>
                    </>
                  ) : (
                    <>
                      {
                        product?.productImg?.length > 0 && product?.productImg.map((item) => {
                          return (
                            <div className="item">
                              <img
                                src={MEDIA_URL + item}
                                alt="thumbnail"
                              />
                            </div>
                          )
                        })
                      }
                    </>
                  )}
                </OwlCarousel> */}
              </div>
            </Col>
            <Col lg={5} sm={12}>
              <div className="product-des">
                <div className="product-name heading">
                  <h1>{product.title}</h1>
                  {product?._id === "65d45308097190d6f575a116" && (
                    <Link to={"/phiro"}>
                      <p>For more details click here</p>
                    </Link>
                  )}
                  {product?._id === "65d457d0097190d6f575b006" && (
                    <Link to={"/phiro"}>
                      <p>For more details click here</p>
                    </Link>
                  )}
                  {product?._id === "667695c1aa56f2eaf63f01c9" && (
                    <Link to={"/robobricks"}>
                      <p>For more details click here</p>
                    </Link>
                  )}
                  {product?._id === "65d47743097190d6f575d2fb" && (
                    <Link to={"/CVProCompetitonKit"}>
                      <p>For more details click here</p>
                    </Link>
                  )}
                  {product?._id === "65d47536097190d6f575cf2f" && (
                    <Link to={"/CVPro"}>
                      <p>For more details click here</p>
                    </Link>
                  )}
                  {product?._id === "65cf30489af164fbe0939e3e" && (
                    <Link to={"/STEMMakerKit"}>
                      <p>For more details click here</p>
                    </Link>
                  )}
                  {product?._id === "66766751aa56f2eaf63e22b6" && (
                    <Link to={"/Playbits"}>
                      <p>For more details click here</p>
                    </Link>
                  )}
                  {product?._id === "6544860fc74fbfe65196ff62" && (
                    <Link to={"/CSTEM"}>
                      <p>For more details click here</p>
                    </Link>
                  )}
                </div>
                <div className="product-info">
                  <div className="product-age-group">
                    {/* <p>Age: {product.ageGroup}</p> */}
                    <p>Age: {product.ageGroup.join(" | ")}</p>
                  </div>
                  {!product.isRobotixProduct && (
                    <div className="session-count">
                      <p>{product.numberOfSessions} Sessions</p>
                    </div>
                  )}

                  <div className="product-rating">
                    {averageRating && (
                      <>
                        <span>{averageRating?.toFixed(1)}</span>
                        {[...Array(5)].map((_, index) => {
                          // Calculate the difference between the index and the average rating
                          const diff = index + 0.5 - averageRating;
                          const className =
                            diff < 0
                              ? "rated-star"
                              : diff === 0
                                ? "rated-star"
                                : diff < 0.5
                                  ? "half-star"
                                  : "grey-star";
                          return (
                            <span key={index} className={className}>
                              &#9733;
                            </span>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                <div className="product-price-wd">
                  {product?.priceVisibility && (
                    <>
                      {product?.showPriceInRupees ? (
                        <>
                          {product.freeInIndia && product.price === 0 ? (
                            <p>
                              Free
                            </p>
                          ) : (
                            <p>&#8377; {product.price}</p>
                          )}
                        </>
                      ) : (
                        <>
                          {product.freeOutOfIndia && product.price === 0 ? (
                            <p>
                              Free
                            </p>
                          ) : (
                            <p>&#x24; {product.priceInDollar}</p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="about-product">
                  <p>{product.description}</p>
                </div>
                <div className="add-to-cart-btn text-center">
                  {product?.priceVisibility ? (
                    user ? (
                      <Link to={"/cart"}>
                        <CommonButton
                          onClickAction={handleAddToCart}
                          btnClass="explore-course-btn"
                          btnName={
                            product?.freeInIndia && product?.price === 0
                              ? "Learn Now"
                              : "Buy Now"
                          }
                        />
                      </Link>
                    ) : (
                      <Link to={"/login"}>
                        <CommonButton
                          btnClass="explore-course-btn"
                          btnName={
                            product?.freeInIndia && product?.price === 0
                              ? "Learn Now"
                              : "Buy Now"
                          }
                        />
                      </Link>
                    )
                  ) : (
                    <Link to={"/contact-us"}>
                      <CommonButton
                        btnClass="explore-course-btn"
                        btnName="Contact Us"
                      />
                    </Link>
                  )}
                </div>
              </div>
            </Col>
            <div className="product-pg-tab">
              {product.isRobotixProduct ? (
                <TabWrapper
                  titles={robotixTitles}
                  contents={robotixContents}
                  TabDataClass="product-page-tab"
                />
              ) : (
                <TabWrapper
                  titles={titles}
                  contents={contents}
                  TabDataClass="product-page-tab"
                />
              )}
            </div>
          </Row>
        </Container>
        <section className="contact_us pb-50">
          <div className="container">
            <ContactUs />
          </div>
        </section>
      </>
    )
  );
}

export default ProductPage;
