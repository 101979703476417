// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-loader-section {
    padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    cursor: pointer;
    transition: 0.3s linear;
}

@media (max-width: 768px) {
    .mini-loader-section {
        width: 50%;
    }
}

@media (max-width: 480px) {
    .mini-loader-section {
        width: 100%;
    }
}

.loader-1 {
    width: 48px;
    height: 48px;
    border: 5px solid var(--dPink);
    border-bottom-color: var(--dBlue);
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
}

/* keyFrames */
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/component/common/Loader/miniLoader/MiniLoader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,iCAAiC;IACjC,kBAAkB;IAClB,qBAAqB;IAErB,sCAAsC;AAC1C;;AAEA,cAAc;EASZ;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".mini-loader-section {\n    padding: 10px;\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #ccc;\n    cursor: pointer;\n    transition: 0.3s linear;\n}\n\n@media (max-width: 768px) {\n    .mini-loader-section {\n        width: 50%;\n    }\n}\n\n@media (max-width: 480px) {\n    .mini-loader-section {\n        width: 100%;\n    }\n}\n\n.loader-1 {\n    width: 48px;\n    height: 48px;\n    border: 5px solid var(--dPink);\n    border-bottom-color: var(--dBlue);\n    border-radius: 50%;\n    display: inline-block;\n    -webkit-animation: rotation 1s linear infinite;\n    animation: rotation 1s linear infinite;\n}\n\n/* keyFrames */\n@-webkit-keyframes rotation {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  @keyframes rotation {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
