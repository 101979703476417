// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS to your Login.css or create a new CSS file */
.welcome-popup {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s ease;
}

.welcome-popup p {
    margin: 5px 0;
}

.popup-close-button {
    width: 20px;
    height: 20px;
    margin: 0;
    /* position: absolute; */
    right: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dPink);
    border-radius: 50%;
    border: none;
    color: #fff;
    font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/component/common/popup/WelcomePopup.css"],"names":[],"mappings":"AAAA,4DAA4D;AAC5D;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,uCAAuC;IACvC,cAAc;IACd,UAAU;IACV,wBAAwB;IACxB,+BAA+B;AACnC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,wBAAwB;IACxB,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,eAAe;AACnB","sourcesContent":["/* Add this CSS to your Login.css or create a new CSS file */\n.welcome-popup {\n    position: fixed;\n    top: 10px;\n    right: 10px;\n    background-color: #fff;\n    padding: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n    z-index: 99999;\n    opacity: 1;\n    transform: translateX(0);\n    transition: transform 0.5s ease;\n}\n\n.welcome-popup p {\n    margin: 5px 0;\n}\n\n.popup-close-button {\n    width: 20px;\n    height: 20px;\n    margin: 0;\n    /* position: absolute; */\n    right: 0;\n    padding: 5px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: var(--dPink);\n    border-radius: 50%;\n    border: none;\n    color: #fff;\n    font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
