// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offering_value {
  background: rgb(255 86 133 / 10%);
  padding: 50px 0px;
}
.offering_value  .card_uni img {
  height: 200px;
  object-fit: contain;
}
.offering_value  .card_uni 
{
  border: 0;
}
.offering_value div:first-child > h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dBlue);
}
span.sc-bwzfXH.kVtMtA {
  /* display: none; */
}
`, "",{"version":3,"sources":["webpack://./src/landingPage/landing1/Offering.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;;EAEE,SAAS;AACX;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".offering_value {\r\n  background: rgb(255 86 133 / 10%);\r\n  padding: 50px 0px;\r\n}\r\n.offering_value  .card_uni img {\r\n  height: 200px;\r\n  object-fit: contain;\r\n}\r\n.offering_value  .card_uni \r\n{\r\n  border: 0;\r\n}\r\n.offering_value div:first-child > h2 {\r\n  font-size: 30px;\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n  color: var(--dBlue);\r\n}\r\nspan.sc-bwzfXH.kVtMtA {\r\n  /* display: none; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
