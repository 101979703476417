import React from "react";
import { FaLinkedin } from "react-icons/fa";
import "./Team.css";

const Team = ({ imageUrl, title, paragraph, paragraph2, linkedin }) => {
  return (
    <div className="team-member">
      <img src={imageUrl} alt="Team Member" className="team-member-image" />
      <h2 className="team-member-title f-20 fw-600 dBlue">{title}</h2>
      <p className="team-member-paragraph">
        {paragraph} <br />
        {paragraph2}
      </p>
      <a href={linkedin} target="_blank" className="team-member-linkedin">
        <FaLinkedin size={30} />
      </a>
    </div>
  );
};

export default Team;
