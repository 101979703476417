// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-rating-container p {
    margin: 0;
}

.wishlist-container {
    padding: 0 5%;
}

.wishlist-product {
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 50px;
    border-bottom: 2px solid var(--grey);
}

.add-to-cart-btn button {
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 30px;
    padding: 10px 25px;
    background-color: var(--dBlue);
}

.serial-no h2,
.product-name p,
.product-price p {
    margin: 0px;
}

.width-m {
    max-width: 100%;
    margin: auto;
}

.remove-product {
    padding: 10px;
}

.remove-product svg {
    font-size: 25px;
}

.move-to-cart button:hover{
    background-color: var(--dPink);
    border-color: var(--dPink);
}`, "",{"version":3,"sources":["webpack://./src/component/common/cards/WishlistProduct.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;;;IAGI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,8BAA8B;IAC9B,0BAA0B;AAC9B","sourcesContent":[".name-rating-container p {\r\n    margin: 0;\r\n}\r\n\r\n.wishlist-container {\r\n    padding: 0 5%;\r\n}\r\n\r\n.wishlist-product {\r\n    display: flex;\r\n    justify-content: start;\r\n    align-items: center;\r\n    column-gap: 50px;\r\n    border-bottom: 2px solid var(--grey);\r\n}\r\n\r\n.add-to-cart-btn button {\r\n    text-transform: uppercase;\r\n    font-weight: 700;\r\n    border-radius: 30px;\r\n    padding: 10px 25px;\r\n    background-color: var(--dBlue);\r\n}\r\n\r\n.serial-no h2,\r\n.product-name p,\r\n.product-price p {\r\n    margin: 0px;\r\n}\r\n\r\n.width-m {\r\n    max-width: 100%;\r\n    margin: auto;\r\n}\r\n\r\n.remove-product {\r\n    padding: 10px;\r\n}\r\n\r\n.remove-product svg {\r\n    font-size: 25px;\r\n}\r\n\r\n.move-to-cart button:hover{\r\n    background-color: var(--dPink);\r\n    border-color: var(--dPink);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
