// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_uni {
  border: 2px solid var(--dBlue);
  border-radius: 15px;
  padding: 20px;
}

.card_uni img {
  display: block;
  width: 40% !important;
  margin: 0 auto;
}

.card_uni h2 {
  font-size: var(--f20);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: var(--dBlue);
  margin-top: 20px;
}

.card_uni h2 span {
  color: var(--dPink);
}

.card_uni p {
  font-size: var(--pSize);
  font-weight: 400;
  color: #323232;
  text-align: left;
}

@media (max-width: 1024px) {
  .card_uni img {
    width: 20% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/CardUni.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".card_uni {\r\n  border: 2px solid var(--dBlue);\r\n  border-radius: 15px;\r\n  padding: 20px;\r\n}\r\n\r\n.card_uni img {\r\n  display: block;\r\n  width: 40% !important;\r\n  margin: 0 auto;\r\n}\r\n\r\n.card_uni h2 {\r\n  font-size: var(--f20);\r\n  font-weight: 700;\r\n  text-align: center;\r\n  text-transform: uppercase;\r\n  color: var(--dBlue);\r\n  margin-top: 20px;\r\n}\r\n\r\n.card_uni h2 span {\r\n  color: var(--dPink);\r\n}\r\n\r\n.card_uni p {\r\n  font-size: var(--pSize);\r\n  font-weight: 400;\r\n  color: #323232;\r\n  text-align: left;\r\n}\r\n\r\n@media (max-width: 1024px) {\r\n  .card_uni img {\r\n    width: 20% !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
