import React from "react";
import LatestBanner from "../component/latesNews/LatestBanner";
import BlogCard from "../component/BlogCard.jsx";
import TabWrapper from "../component/common/tab/TabWrapper";
import "../component/common/tab/TabWrapperone.css";
import TrendingNow from "../component/common/trending/TrendingNow";
const LatestNews = () => {
  const items = [
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-1",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-2",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-3",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-4",
    },
  ];
  const blogcardData = [
    {
      id: 1,
      imageUrl: "images/latestNews/img-1.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and tyspesetting industry.",
      read: "/",
    },
    {
      id: 2,
      imageUrl: "images/latestNews/img-2.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
    {
      id: 3,
      imageUrl: "images/latestNews/img-3.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
  ];

  const tabData = {
    titles: ["Tab 1", "Tab 2", "Tab 3"],
    contents: [
      <section className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            {blogcardData.map((blogCard, index) => (
              <div className="col-md-4" key={blogCard.id}>
                <BlogCard {...blogCard} />
              </div>
            ))}
          </div>
        </div>
      </section>,
      <section className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            {blogcardData.map((blogCard, index) => (
              <div className="col-md-4" key={blogCard.id}>
                <BlogCard {...blogCard} />
              </div>
            ))}
          </div>
        </div>
      </section>,
      <section className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            {blogcardData.map((blogCard, index) => (
              <div className="col-md-4" key={blogCard.id}>
                <BlogCard {...blogCard} />
              </div>
            ))}
          </div>
        </div>
      </section>,
    ],
    TabDataClass: "custom-tab", // Add any custom CSS class here
  };

  return (
    <div>
      <LatestBanner />
      <TabWrapper {...tabData} />
      <section className="pt-100 pb-100">
        <h2 className="container inh2 pb-md-4">
          Trending<span>now</span>
        </h2>
        <TrendingNow items={items} />
      </section>
      <section className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            {blogcardData.map((blogCard, index) => (
              <div className="col-md-4" key={blogCard.id}>
                <BlogCard {...blogCard} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default LatestNews;
