import "bootstrap/dist/css/bootstrap.css";
import "./TutorialVideos.css";
import TabWrapper from "../common/tab/TabWrapper";
import ProductOverview from "../common/tab/ProductOverview";
import Topics from "../common/tab/Topics";
import ProductRating from "../common/product-rating/ProductRating";
import Faq from "./cp-tab/Faqs";
import Notes from "./cp-tab/Notes";
import { Button, Col, Container, Row } from "react-bootstrap";
import { AiFillStar, AiFillCloseCircle } from "react-icons/ai";
import VimeoPlayer from "@vimeo/player";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { userRequest } from "../../requestMethod";
import { useRef } from "react";
import StarButton from "../common/star-button/StarButton";
import {
  addRatingFailure,
  addRatingStart,
  addRatingSuccess,
} from "../../redux/ratingRedux";
import { async } from "@vimeo/player";
import { validateRequired } from "../../formValidation";
import { getAllOrders, getAllRatings, getProducts, getSpecificCourseRating } from "../../redux/apiCalls";
import CourseContent from "./cp-tab/CourseContent";
import Quiz from "../../Quiz/Quiz";
import Loader from "../common/Loader/Loader";
import { getProductFailure } from "../../redux/productRedux";
import axios, { all } from "axios";
import PDFViewer from "../pdf-viewer/PDFViewer";
import Certificate from "../certificate/Certificate";
// import { toPng } from 'html-to-image';
import * as htmlToImage from 'html-to-image';
// import { AiFillCloseCircle } from "react-icons/ai";
import CryptoJS from "crypto-js";
import { BsTrophyFill } from "react-icons/bs";
import { updateResourceProgressOfCourse } from "../../redux/orderRedux";
import { MdClose } from "react-icons/md";
import H5PViewer from "../h5p-viewer/H5PViewer";
import { MEDIA_URL, calculateCompletionPercentage, changeDateFormat } from "../../config";
import MiniLoader from "../common/Loader/miniLoader/MiniLoader";

const TutorialVideos = () => {
  const dispatch = useDispatch();

  const id = useSelector((state) => state.loginUser.currentUser._id);


  useEffect(() => {
    getAllOrders(dispatch, id);
  }, [dispatch]);

  const { isFetching, orders } = useSelector((state) =>
    state.order);

  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [courseProgress, setCourseProgress] = useState(null);
  const [openingSnap, setOpeningSnap] = useState(false);
  const [openingSnapOrScratch, setOpeningSnapOrScratch] = useState(false);
  // const [isVideoPlayerGivingError, setIsVideoPlayerGivingError] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const courseParam = params.get("courseId");
    const hashedCurrentCourseId = CryptoJS.AES.decrypt(
      courseParam,
      process.env.REACT_APP_UNIQUE_ID_SEC_KEY
    );

    const originalCurrentCourseId = hashedCurrentCourseId.toString(CryptoJS.enc.Utf8);
    setCurrentCourseId(originalCurrentCourseId);
  }, [currentCourseId]);




  // const currentOrderInfo = useSelector((state) => state.order.orders.find((item) => item._id === id));
  // const course = useSelector((state) => state.order.orders.find((item) => item._id === orderId && orderId));
  const foundOrder = useSelector((state) => state.order.orders.find((order) => order?.products.some(product => product._id === currentCourseId)));

  const [course, setCourse] = useState(null);



  // const findCourse = foundOrder?.products.find((product, index) => {
  //   if (product._id === currentCourseId) {
  //     const fetchedCourse = { ...product.productId, courseIndexInProductArray: index }
  //     return fetchedCourse;
  //   } else {
  //     return null
  //   }
  // });




  // let course = null;
  useEffect(() => {
    const findCourse = foundOrder?.products.find((product, index) => {
      if (product._id === currentCourseId) {
        const fetchedCourse = { ...product.productId, courseIndexInProductArray: index }
        setCourse(fetchedCourse)
        // return fetchedCourse;
      } else {
        return null
      }
    });
  }, [foundOrder])

  const isProductFetching = useSelector((state) =>
    state.product.isFetching);
  // const course = useSelector((state) => state.product.products.find((item) => item._id === courseId));
  const currentUser = useSelector((state) => state.loginUser.currentUser);
  const accessToken = currentUser && currentUser.accessToken;

  useEffect(() => {
    getAllRatings(dispatch);
  }, [])

  const allRatings = useSelector((state) => state.rating.ratings?.find((rating) => rating.userId === currentUser._id && rating.productId === course?._Id));

  useEffect(() => {
    getProducts(dispatch);
  }, [dispatch]);

  const user = useSelector((state) => state?.loginUser?.currentUser);
  // const user = useSelector((state) => state?.loginUser?.currentUser);
  const [videos, setVideos] = useState([]);
  // const [playVideo, setPlayVideo] = useState("548370467");
  const playerRef = useRef(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const isMobile = window.innerWidth <= 767; // Define your mobile breakpoint
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const [isCourseCompleted, setIsCourseCompleted] = useState(true);
  // Add a state variable to track progress
  const [progress, setProgress] = useState([]);
  const [lastSent, setLastSent] = useState(null);

  const [isLoadingStart, setIsLoadingStart] = useState(false);
  const [showThumbnailImage, setShowThumbnailImage] = useState(true);

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };


  const certificateRef = useRef(null);


  const [isDocViewerVisible, setIsDocViewerVisible] = useState(false);
  const [documentData, setDocumentData] = useState([]);


  const handleDocViewer = (url, fileType, fileName, chapterIndex, resourceIndex) => {
    if (playerRef?.current.origin) {
      playerRef?.current.destroy();
    }
    const data = { url, fileType, fileName, chapterIndex, resourceIndex };

    setIsDocViewerVisible(false);  // Temporarily hide the viewer
    setDocumentData(data);         // Update the document data

    setTimeout(() => {
      setIsDocViewerVisible(true); // Show the viewer again after a short delay
    }, 100); // Adjust the delay as needed
  }

  const closeDocViewer = () => {
    setIsDocViewerVisible(false);
  }



  // Fetch all rating and review of current product

  useEffect(() => {
    getSpecificCourseRating(dispatch, course?._id);
  }, [dispatch]);


  const getCurrentProgressFromDatabase = async (chapterIndex, resourceIndex) => {
    try {
      const res = await userRequest.get(`/update-course-progress/${id}/${foundOrder?._id}/${course?.courseIndexInProductArray}/${chapterIndex}/${resourceIndex}/progress`);
      return res?.data?.progress
    } catch (error) {
    }
  }
  // =========****************** old method when updating in course ***********================
  // const getCurrentProgressFromDatabase = async (chapterIndex, resourceIndex) => {
  //   try {
  //     const res = await userRequest.get(`/update-course-progress/${course._id}/${chapterIndex}/${resourceIndex}/progress`);
  //     return res?.data?.progress
  //   } catch (error) {
  //   }
  // }

  const updateProgressOfResource = async (chapterIndex, resourceIndex, newProgress) => {
    try {
      const res = await userRequest.put(`/update-course-progress/${id}/${foundOrder._id}/progress`, { chapterIndex, resourceIndex, newProgress, courseIndexInOrder: course.courseIndexInProductArray });


      if (res.status === 200) {
        dispatch(updateResourceProgressOfCourse({ orderId: foundOrder._id, courseIndexInOrder: course.courseIndexInProductArray, chapterIndex, resourceIndex, newProgress }));
      }

    } catch (error) {
    }
  }

  const handleUpdateResourceProgress = async (chapterIndex, resourceIndex, newProgress) => {
    if (newProgress == 50 || newProgress == 100) {
      const newProgressInInteger = Math.floor(newProgress)
      try {
        // Retrieve the current progress from the database
        const currentProgress = await getCurrentProgressFromDatabase(chapterIndex, resourceIndex);

        // Check if the new progress is greater than the current progress
        if (newProgressInInteger > currentProgress) {
          // const finalNewProgress = newProgress / 10;
          // Update the progress in the database
          await updateProgressOfResource(chapterIndex, resourceIndex, newProgressInInteger);

        }
      } catch (error) {
        console.error("Error updating progress:", error);
      }
    };
  }


  const handleVideoTimeUpdate = async (data, chapterIndex, resourceIndex) => {
    // const currentProgress = data.percent; // The percentage of the video that has played
    const newProgress = (data.percent * 100).toFixed(1);

    if (newProgress) {
      await handleUpdateResourceProgress(chapterIndex, resourceIndex, newProgress);
    }
  };

  useEffect(() => {
    const progress = calculateCompletionPercentage(course);
    setCourseProgress(progress);
  }, [course]);

  const handleVideoClick = (videoId, chapterIndex, resourceIndex) => {
    // setIsVideoPlayerGivingError(false);
    setIsLoadingStart(true);
    setShowThumbnailImage(true);

    if (isDocViewerVisible) {
      setIsDocViewerVisible(false)
    }

    // Destroy the existing player instance if it exists
    if (playerRef?.current.origin) {
      playerRef?.current.destroy();
    }

    // let player;
    // if (firstResourceUrl?.url) {
    const player = new VimeoPlayer("player-container", {
      url: videoId
    });

    // player = new VimeoPlayer("player-container",);

    playerRef.current = player;
    // Add an event listener for the 'timeupdate' event
    player.on("timeupdate", (data) => handleVideoTimeUpdate(data, chapterIndex, resourceIndex));
    if (player) {

      player
        .loadVideo(videoId)
        .then((results) => {
          setIsLoadingStart(false);
          setShowThumbnailImage(false)
        })
        .catch((error) => {
          setIsLoadingStart(false)
          // setIsVideoPlayerGivingError(true)
          // You can handle the error here, such as displaying a message to the user.
        });
    }
  };

  // ===========************old method to handle video click*********===========
  // const handleVideoClick = (videoId) => {
  //   const player = playerRef.current;
  //   if (player) {
  //     player
  //       .loadVideo(videoId)
  //       .then((results) => {
  //       })
  //       .catch((error) => {
  //         console.error("Error loading video:", error);
  //         // You can handle the error here, such as displaying a message to the user.
  //       });
  //   }
  // };

  const [desktopTitles, setDesktopTitles] = useState(["Overview", "Topics",])
  const [mobileViewTitles, setMobileViewTitles] = useState(["Course Content", "Overview", "Topics",])
  const [mobileViewContents, setMobileViewContents] = useState([<ProductOverview
    overView={course && course.overview}
    ProductOverviewImg="images/product-page/overview-img.png"
  />,
  <Topics topics={course && course.topics} />,]);
  const [desktopContents, setDesktopContents] = useState([<ProductOverview
    overView={course && course.overview}
    ProductOverviewImg="images/product-page/overview-img.png"
  />,
  <Topics topics={course && course.topics} />,]);

  useEffect(() => {
    setDesktopContents([<ProductOverview overView={course && course.overview} ProductOverviewImg="images/product-page/overview-img.png" />, <Topics topics={course && course.topics} />,]);

    setMobileViewContents([<CourseContent
      lectureDetails={course && course?.chapters} videos={course && videos} onClickAction={handleVideoClick} AccordionClass="accordion-container" updateResourceProgressFunction={handleUpdateResourceProgress} handleDocViewer={handleDocViewer} closeDocViewer={closeDocViewer} courseProgress={courseProgress}
    />, <ProductOverview overView={course && course.overview} ProductOverviewImg="images/product-page/overview-img.png" />, <Topics topics={course && course.topics} />,]);

  }, [course])






  // Define a function to check if it's a mobile view
  function getDisplayRatio() {
    return window.innerWidth;
  }

  const [displayRatio, setDisplayRatio] = useState(getDisplayRatio());

  useEffect(() => {
    function handleResize() {
      setDisplayRatio(getDisplayRatio());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array means this effect will only run once after the component mounts

  let mobileView;

  const handleViewCertificate = (status) => {
    setShowCertificate(status);
  }

  const addRating = async (dispatch, rating) => {
    dispatch(addRatingStart());
    try {
      const res = await userRequest.post("/rating/create", rating);
      if (res.status === 200) {
        setSuccess(true);
        setSuccessMessage("Your review has been submitted!");
        // setRating(5);
        setReview("");
        // setStatus(false);
        // setTestimonialImage(null);
        setIsSubmitted(true);
      }
      dispatch(addRatingSuccess(res.data));
    } catch (error) {
      dispatch(addRatingFailure());
      // if (error.response.status === 409) {
      //     setParentNameError(true);
      //     setParentNameErrorMessage(error.response.data);
      // }

      // else {
      setError(true);
      setErrorMessage("Something went wrong!");
      // }
    }
  };

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const ratingErr = validateRequired(rating);
    const reviewErr = validateRequired(review);

    if (ratingErr) {
      formIsValid = false;
      newErrors.rating = ratingErr;
    }
    if (reviewErr) {
      formIsValid = false;
      newErrors.review = reviewErr;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleAddRating = () => {
    setSuccess(false);
    setError(false);
    // setParentNameError(false);
    // const trimmedTitle = parentName.trim();
    if (validateForm()) {
      addRating(dispatch, { rating, review, userId: user?._id, userName: user?.firstName + " " + user?.lastName, userProfileImage: user?.profileImg, productId: course?._id });
    }
  };

  const [showQuizPopup, setShowQuizPopup] = useState(false);

  const handleOpenQuiz = () => {
    setShowQuizPopup(true);
  };

  const handleCloseQuiz = () => {
    setShowQuizPopup(false);
  };

  const quizData = [
    {
      question: "Current Year",
      options: ["2020", "2021", "2022", "2023"],
      answer: "2020",
    },
    {
      question: "Current Month?",
      options: ["jan", "feb", "mar", "apr"],
      answer: "apr",
    },
  ];

  // if (isFetching) {
  //     return (
  //         <Loader />
  //     )
  // }

  // const handleOpenSnap = () => {
  //     // if (!accessToken) {
  //     //     alert('Please log in to access Snap.');
  //     //     return;
  //     // }
  //     // Define the Snap URL
  //     const snapUrl = `http://139.59.4.119/`;
  //     // const snapUrl = `http://localhost:5500`;
  //     // Get the "userIn" cookie
  //     const userInCookie = document.cookie
  //         .split('; ')
  //         .find((cookie) => cookie.startsWith('userIn='));

  //     if (!userInCookie) {
  //         alert('Authentication cookie not found.');
  //         return;
  //     }
  //     // Include the "userIn" cookie in the request headers
  //     axios.get(snapUrl, {
  //         headers: {
  //             Cookie: userInCookie,
  //         },
  //     })
  //         .then((response) => {
  //             // Handle the response, which may include Snap content

  //             // Open the Snap URL in a new window/tab
  //             window.open(snapUrl, '_blank');
  //         })
  //         .catch((error) => {
  //             // Handle errors, such as authentication failure or network issues
  //             console.error('Error:', error);
  //         });
  // };

  // let handleOpenSnap;
  // let handleOpenScratch;

  const handleOpenSnap = async () => {
    try {
      setOpeningSnap(true)
      const res = await userRequest.get('/auth/redirect-to-scratch');
      if (res.status === 200 && res.data) {
        const { uniqueId } = res.data;
        // window.open(`http://127.0.0.1:5501/?id=${uniqueId}`, '_blank'); // Change to your actual URL
        window.open(`https://snap.meritus.ai/?id=${uniqueId}`, '_blank'); // Change to your actual URL
        setOpeningSnap(false)
      } else {
        setOpeningSnap(false)
        alert('Failed to open scratch right now.');
      }
    } catch (error) {
      setOpeningSnap(false)
      // console.error('Error:', error);
      alert('An error occurred during open scratch.');
    }
  };

  const handleOpenScratch = async () => {
    try {
      setOpeningSnapOrScratch(true)
      const res = await userRequest.get('/auth/redirect-to-scratch');
      if (res.status === 200 && res.data) {
        const { uniqueId } = res.data;
        window.open(`https://scratch4aiws.meritus.ai/?id=${uniqueId}`, '_blank'); // Change to your actual URL
        setOpeningSnapOrScratch(false)
      } else {
        setOpeningSnapOrScratch(false)
        alert('Failed to open scratch right now.');
      }
    } catch (error) {
      setOpeningSnapOrScratch(false)
      // console.error('Error:', error);
      alert('An error occurred during open scratch.');
    }
    // try {
    //   const res = await userRequest.get('/auth/redirect-to-scratch');
    //   console.log("coming response ==>", res)
    //   if (res.status == 200 && res.data?.scratchToken) {
    //     // Remove any existing token from session storage
    //     sessionStorage.removeItem('scratchToken');

    //     // Save the new token in session storage
    //     sessionStorage.setItem('scratchToken', res.data.scratchToken);

    //     // Open Scratch in a new tab
    //     window.open('http://localhost:5500/', '_blank');
    //   } else {
    //     alert('Failed to generate token.');
    //   }
    // } catch (error) {
    //   console.log("getting error ==>", error)
    // }
    // window.open(`http://127.0.0.1:5500/?access_token=${encodedToken}`, '_blank');
    // window.open(`https://scratch4aiws.meritus.ai/?access_token=${encodedToken}`, '_blank');

    // // Create a hidden form
    // const form = document.createElement('form');
    // form.method = 'POST';
    // // form.action = 'https://scratch4aiws.meritus.ai';
    // form.action = 'http://localhost:5500/';

    // // Create a hidden input to hold the token
    // const input = document.createElement('input');
    // input.type = 'hidden';
    // input.name = 'access_token';
    // input.value = encodedToken;
    // form.target = '_blank';  // Open in new tab


    // form.appendChild(input);
    // document.body.appendChild(form);
    // form.submit();
  };

  // const handleOpenScratch = () => {
  //     if (!accessToken) {
  //       alert('Please log in to access Snap.');
  //       return;
  //     }

  //     // Redirect to the proxy route on your live website
  //     window.open('http://localhost:3003/api/snap/open/scratch', '_blank');
  //   };

  // const handleOpenSnap = () => {
  //   if (!accessToken) {
  //     alert("Please log in to access Snap.");
  //     return;
  //   }

  //   // Construct the URL with the accessToken parameter
  //   const snapUrl = `http://localhost:5500?token=${accessToken}`;


  //   // Open the Snap URL in a new window/tab
  //   window.open(snapUrl, "_blank");
  // };

  // const handleOpenScratch = () => {
  //     if (!accessToken) {
  //         alert('Please log in to access Scratch.');
  //         return;
  //     }

  //     // Construct the URL with the accessToken parameter
  //     const scratchUrl = `http://139.59.4.119/?token=${accessToken}`;

  //     // Open the Scratch URL in a new window/tab
  //     window.open(scratchUrl, '_blank');
  // };

  const handleCertificateDownload = () => {
    setIsLoaderActive(true);
    if (certificateRef.current) {
      htmlToImage.toPng(certificateRef.current)
        .then(function (dataUrl) {
          const link = document.createElement('a');
          link.download = 'certificate.png';
          link.href = dataUrl;
          link.click();
          setIsLoaderActive(false);
        });
    } else {
      console.error('Certificate element not found');
    }
  };


  if (!course) {
    return <Loader />
  }
  // if (isFetching) {
  //   return <Loader />
  // }

  return (
    <div className="course-tutorial">
      <Container fluid className="ct-page-bg">
        <Row>
          {/* Course title Column */}
          <Col lg={8} className="p-">
            <div className="course-title-tvp heading">
              <h1>{course && course.title}</h1>
            </div>
          </Col>

          {/* Course rating and Progress Column */}
          <Col lg={4}>
            <div className="rating-progress-btns">
              {/* Rating Button for rating page */}
              <div className="leave-rating">
                {
                  allRatings ? (
                    <></>
                  ) : (
                    <Button onClick={() => setIsOpen(!isOpen)}>
                      <span className="btn-icon-star">
                        <AiFillStar />
                      </span>
                      Leave a rating
                    </Button>
                  )
                }

              </div>

              {/* <div className='course-progress'>
                <div className='progressbar-img-wrapped wrap-circles'>
                  <div className="circle progress-per">
                    <div className="inner">
                      <BsTrophyFill />
                    </div>
                  </div>
                </div>
                <div className='progress-txt'>
                  <p>your progress</p>
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>

      {/* Video and course contents */}
      <div className="video-course-content pt-50 pb-50">
        <Container fluid>
          <Row className="gx-4">
            {/* Video with Tab  */}

            {/* Course contents */}
            <Col lg={4}>
              <CourseContent
                lectureDetails={course?.chapters}
                onClickAction={handleVideoClick}
                AccordionClass="hide-accordion"
                updateResourceProgressFunction={handleUpdateResourceProgress}
                handleDocViewer={handleDocViewer}
                closeDocViewer={closeDocViewer}
                courseProgress={courseProgress}
              />
              <div className="button-wrapper mt-3">
                {/* <button onClick={handleOpenQuiz} className='btn quiz-btn'>Start Quiz</button>
                                {showQuizPopup && (
                                    <Quiz quizData={quizData} onClose={handleCloseQuiz} />
                                )} */}
                <div className={`snap-scratch-wrapper ${course?.snapAccess && course?.scratchAccess ? 'multiple-buttons' : 'single-button'}`}>
                  {course?.snapAccess && (
                    // <a href={`https://snap.meritus.ai/snap.html`} target="_blank">
                    <button className="btn common-btn mr-2" onClick={() => handleOpenSnap(accessToken)}>open snap</button>
                    // </a>
                  )}

                  {course?.scratchAccess && (
                    // <a href={`https://scratchaiws.meritus.ai/`} target="_blank">
                    //  {/* <a href={`http://127.0.0.1:5500/?access_token=${accessToken}`} target="_blank"> */}
                    <>
                      {
                        openingSnapOrScratch ? (
                          <button className="btn common-btn" >openinng...</button>
                        ) : (
                          <button className="btn common-btn" onClick={() => handleOpenScratch(accessToken)}>open scratch</button>
                        )
                      }
                    </>
                    // {/* </a> */}
                  )}

                  {
                    courseProgress === 100 && (
                      <button className="btn common-btn" onClick={() => handleViewCertificate(true)}>View Certificate</button>
                    )
                  }
                </div>
              </div>
              {/* <div className="pdf-viewr">
                                <PDFViewer />
                            </div> */}
            </Col>
            <Col lg={8}>
              {/* {
                isVideoPlayerGivingError && (
                  <p className="error-text">Error : Something went wrong with video url</p>
                )
              } */}
              <div ref={playerRef} id="player-container" style={{ background: `${showThumbnailImage ? `url(${MEDIA_URL + (typeof course?.productImg === "object" ? course?.productImg[0] : course?.productImg)})` : 'none'}`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                {
                  isLoadingStart && (
                    <div className="player-loader-wrapper">
                      <MiniLoader />
                    </div>
                  )
                }

                {
                  isDocViewerVisible && (
                    <div className="pdf-viewr" onContextMenu={(e) => e.preventDefault()}>
                      <div className="pdf-inner-wrapper">
                        <button className="close-viewer-btn" onClick={closeDocViewer}>
                          <MdClose />
                        </button>
                        {
                          documentData.fileType === 'html' ? (
                            <H5PViewer docData={documentData} updateResourceProgressFunction={handleUpdateResourceProgress} />
                          ) : (
                            // <div>
                            <PDFViewer docData={documentData} updateResourceProgressFunction={handleUpdateResourceProgress} />
                            // </div>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </div>
              {/* Tutoraial Page Tab  */}
              <div className="tutorial-page-tab">
                {
                  displayRatio <= 767 ? (
                    <TabWrapper
                      titles={mobileViewTitles}
                      contents={mobileViewContents}
                      TabDataClass="tutorial-pg-tab"
                    />
                  ) : (
                    <TabWrapper
                      titles={desktopTitles}
                      contents={desktopContents}
                      TabDataClass="tutorial-pg-tab"
                    />
                  )
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`modal ${isOpen ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-head-wrapper d-flex justify-content-between p-0">
            <h5>Leave a Rating and Review</h5>
            <AiFillCloseCircle
              className="close-rating-btn"
              onClick={() => setIsOpen(false)}
            />
          </div>
          {isSubmitted ? (
            <>
              <div className="star-rating">
                {[1, 2, 3, 4, 5].map((index) => (
                  <StarButton key={index} selected={rating} />
                ))}
              </div>
              <h2 className="success-review-text">{success && successMessage}</h2>
            </>
          ) : (
            <>
              <div className="star-rating">
                {[1, 2, 3, 4, 5].map((index) => (
                  <StarButton
                    key={index}
                    selected={index <= rating}
                    onClick={() => handleRatingClick(index)}
                  />
                ))}
              </div>
              <div className="review-section">
                <h6>Your Review:</h6>
                <textarea
                  rows="4"
                  placeholder="Write your review here..."
                  value={review}
                  onChange={handleReviewChange}
                />
                <span className="error-text">{errors.review}</span>
              </div>
              {/* <div className="submit-button-wrapper text-left">
                <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={handleAddRating}><span>Submit</span></button>
              </div> */}
              <div className="coupon-btn-wrapper text-left ">
                <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={handleAddRating}><span>Submit</span></button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={`modal ${showCertificate ? "open" : ""}`}>
        <div className="modal-content ceritficate-modal-content">
          <div className="modal-head-wrapper d-flex justify-content-between p-0">
            <AiFillCloseCircle
              className="close-ceritificate-btn"
              onClick={() => handleViewCertificate(false)}
            />
          </div>
          <div ref={certificateRef}>
            <Certificate firstName={currentUser?.firstName} lastName={currentUser?.lastName} courseTitle={course?.title} compeletionDate={changeDateFormat(foundOrder?.updatedAt)} />
          </div>

          <div className="coupon-btn-wrapper text-center mt-2">
            <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={handleCertificateDownload}><span>Download Certificate</span></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialVideos;
