import "bootstrap/dist/css/bootstrap.css";
import "../../../styles.css";
import "../STEM Maker Kit/STEMMakerKit.css";
import { Container, Row, Col } from "react-bootstrap";

const STEMMakerKit = () => {
  return (
    <div className="stem-maker-kit">
      {/* Top banner */}
      <section className="smk-top-banner"></section>

      {/* Step in the world of science  */}
      <section className="step-in-world-wrapper pt-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={12}>
                  <div class="sub-heading-npgs smk-subhead">
                    <p>First steps into the world of</p>
                  </div>

                  {/* heading  */}
                  <div class="heading-npgs dark-blue-head dark pb-30">
                    <h2>
                      <span>Science, Mechanics & Engineering</span>
                    </h2>
                  </div>
                </Col>
              </Row>

              {/* content  */}
              <Row>
                <Col lg={6}>
                  <ul className="smk-pointers-wrapper">
                    <li>
                      <strong>STEM Maker Kit</strong> is an amazing new
                      construction set for children ages 4 & above.
                    </li>
                    <li>
                      Children can build <strong>30 + Models</strong> using the
                      instructions provided. Additionally using their own
                      imagination, kids can make an infinite number of Models.
                    </li>
                    <li>
                      Engage your child in variety of hands-on STEM challenges
                      enabling 21st century learning via patented plastic
                      building bricks.
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  <ul className="smk-pointers-wrapper">
                    <li>
                      Constructing STEM models promotes critical thinking and
                      problem-solving skills. Wrap Quality play learning into
                      Magical and fun family time.
                    </li>
                    <li>
                      Discover STEM concepts that stimulate Creativity and
                      Curiosity.
                    </li>
                    <li>
                      Building STEM models at a young age helps students, think
                      logically and develop computational thinking skills.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Icons  */}
      <section className="smk-icons-wrapper pt-30 pb-30">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {/* levers  */}
                <Col lg={3} md={6}>
                  <div className="smk-icon-wrapper">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/leavers.png`}
                      alt=""
                    />
                  </div>
                </Col>

                {/* pulleys  */}
                <Col lg={3} md={6}>
                  <div className="smk-icon-wrapper">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/pulleys.png`}
                      alt=""
                    />
                  </div>
                </Col>

                {/* gears  */}
                <Col lg={3} md={6}>
                  <div className="smk-icon-wrapper">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/gears.png`}
                      alt=""
                    />
                  </div>
                </Col>

                {/* wedges  */}
                <Col lg={3} md={6}>
                  <div className="smk-icon-wrapper">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/wedges.png`}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={6}>
                  <div className="smk-icon-wrapper text-end">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/wheels.png`}
                      alt=""
                    />
                  </div>
                </Col>

                <Col lg={4} md={6}>
                  <div className="smk-icon-wrapper siw-sl text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/axles.png`}
                      alt=""
                    />
                  </div>
                </Col>

                <Col lg={4} md={12}>
                  <div className="smk-icon-wrapper siw-last text-start">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/inclined-plane.png`}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Fun & Creation  */}
      <section id="fun-creation" className="smk-fun-creation pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={12}>
                  {/* heading  */}
                  <div class="heading-npgs dark-blue-head dark">
                    <h2>
                      Enter a Magical World of <span>Fun & Creation!</span>
                    </h2>
                  </div>

                  <div class="sub-heading-npgs smk-subhead">
                    <p>Process of Learning</p>
                  </div>
                </Col>
              </Row>

              {/* images */}
              <Row className="fun-creation-row">
                <Col lg={1} className="p-rel">
                  <div className="fc-txt text-center">
                    <p>Discovering STEM</p>
                  </div>
                </Col>
                <Col lg={11}>
                  <Row>
                    {/* Play col  */}
                    <Col lg={12}>
                      <div className="fc-imgs-wrapper">
                        <div className="fc-img fc-img-a">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-play.png`}
                            alt=""
                          />
                        </div>
                        <div className="fc-col-text text-center">
                          <p>
                            Bring your
                            <br />
                            creations to Life
                          </p>
                        </div>
                        <div className="fc-img fc-img-c">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-cart.png`}
                            alt=""
                          />
                        </div>
                        <div className="fc-img fc-img-d">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-play-b.png`}
                            alt=""
                          />
                        </div>
                        <div className="fc-img fc-img-e">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-robo.png`}
                            alt=""
                          />
                        </div>
                      </div>
                    </Col>

                    {/* Green arrow  */}
                    <Col lg={12}>
                      <div className="fc-green-arrow">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/green-arrow.png`}
                          alt=""
                        />
                      </div>
                    </Col>

                    {/* Build Col  */}
                    <Col lg={12}>
                      <div className="fc-build-icons-wrapper">
                        {/* Icon -1 */}
                        <div className="fcb-icons-a">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-build.png`}
                            alt=""
                          />
                        </div>

                        {/* Icon-2  */}
                        <div className="fcb-icons-b">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-stem-kit.png`}
                            alt=""
                          />
                        </div>

                        {/* Icon - 3 */}
                        <div className="fcb-icons-c">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-build-stem.png`}
                            alt=""
                          />
                        </div>
                      </div>
                    </Col>

                    {/* REd arrow  */}
                    <Col lg={12}>
                      <div className="fc-red-arrow">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-red-arrow.png`}
                          alt=""
                        />
                      </div>
                    </Col>

                    {/* imagine col  */}
                    <Col lg={12}>
                      <div className="fc-imagine-icons-wrapper">
                        {/* icon -1 */}
                        <div className="fc-imagine-icon-a">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-kids.png`}
                            alt=""
                          />
                        </div>

                        {/* icons -2 */}
                        <div className="fc-imagine-icon-b">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-imagine.png`}
                            alt=""
                          />
                        </div>

                        {/* icon - 3 */}
                        <div className="fc-imagine-icon">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-cycle.png`}
                            alt=""
                          />
                        </div>

                        {/* icon - 4  */}
                        <div className="fc-imagine-icon">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-car.png`}
                            alt=""
                          />
                        </div>

                        {/* icon - 5  */}
                        <div className="fc-imagine-icon">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/fc-house.png`}
                            alt=""
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Child learn  */}
      <section className="smk-child-learn pt-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={12}>
                  {/* heading  */}
                  <div class="heading-npgs dark-blue-head smk-ch-head dark">
                    <h2>
                      What will your <span>Child learn?</span>
                    </h2>
                  </div>

                  <div class="sub-heading-npgs smk-ch-subhead">
                    <p>
                      <span className="skm-stem-s">s</span>
                      <span className="skm-stem-t">t</span>
                      <span className="skm-stem-e">e</span>
                      <span className="skm-stem-m">m&nbsp;</span>maker kit
                    </p>
                  </div>

                  <div class="sub-heading-npgs ">
                    <p className="fw-500">Activates the Whole Brain</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="smk-cl-img text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/child-learn.png`}
                    alt=""
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* special about blocks  */}
      <section className="special-abt-blocks-wrapper pt-50">
        <Container className="special-abt-blocks-container">
          <Row className="p-rel">
            <Col lg={6}>
              <div className="special-abt-block sab-right-line">
                {/* que */}
                <div className="whts-special-abt-blocks">
                  <p>What is Special about our blocks?</p>
                </div>

                {/* ans  */}
                <div className="ans-special-abt-blocks">
                  <p>
                    The wide range of patented rectangular and triangular
                    plastic bricks & parts uses the unique side joining &
                    locking bricks.
                  </p>

                  <p>
                    In addition the X | T | R joiners are innovative plastic
                    parts which can be used as direction changers.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="special-abt-block">
                {/* que */}
                <div className="whts-special-abt-blocks">
                  <p>What will your child create today?</p>
                </div>

                {/* ans  */}
                <div className="ans-special-abt-blocks">
                  <p>
                    The plastic parts we supply fully use all sides of the brick
                    whereas traditional bricks only use two.
                  </p>

                  <p>
                    This allows young learners so much more opportunity to build
                    & create projects in 3 dimensions and is therefore
                    infinitely customisable.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          {/* certified: toy safety */}
          <Row>
            <Col lg={12} className="toy-safety-col">
              <div className="toy-safety-wrapper text-center">
                <p>
                  The plastic bricks & parts are certified
                  <br />
                  and conforms to&nbsp;<span>EN71 | European Toy Safety</span>
                  &nbsp;and&nbsp;<span>ASTM 963 | USA Toy Safety.</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* images  */}
      <section className="smk-img-section pt-50 pb-30">
        <Container className="smk-img-container">
          <Row>
            <Col lg={6}>
              <div className="smk-img-wrapper smk-img-wrapper-br">
                <Row>
                  <Col lg={4} sm={4}>
                    <div className="smk-img-a smk-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-icon-a.png`}
                        alt=""
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={4}>
                    <div className="smk-img-b smk-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-icon-b.png`}
                        alt=""
                      />
                    </div>
                  </Col>

                  <Col lg={4} sm={4}>
                    <div className="smk-img-c smk-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-icon-c.png`}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={6}>
              <div className="smk-img-wrapper">
                <Row>
                  <Col lg={4} sm={4}>
                    <div className="smk-img-d smk-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-icon-d.png`}
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg={4} sm={4}>
                    <div className="smk-img-e smk-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-icon-e.png`}
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg={4} sm={4}>
                    <div className="smk-img-f smk-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-icon-f.png`}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* STEM Maker Kit inclusions  */}
      <section className="smk-inclusions pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row className="smk-inclusions-row-bg">
                <Col lg={4} sm={4}>
                  <div className="smk-inclusion-img smk-inclusion-img-a">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-102-parts.png`}
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={4} sm={4}>
                  <div className="smk-inclusion-img smk-inclusion-img-b">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-30+-modals.png`}
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={4} sm={4}>
                  <div className="smk-inclusion-img smk-inclusion-img-c">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/stem-maker-kit/icons/smk-no-battery.png`}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default STEMMakerKit;
