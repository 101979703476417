import React, { useState } from "react";
import "./Register.css";
import {
  addUserFailure,
  addUserStart,
  addUserSuccess,
} from "../../redux/registeredUserRedux";
import { useDispatch, useSelector } from "react-redux";
import { publicRequest } from "../../requestMethod";
import { validateRequired } from "../../formValidation";
import { FaUnlockAlt, FaUserCog } from "react-icons/fa";
import { Link } from "react-router-dom";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [grade, setGrade] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const state = useSelector((state) => state);

  const dispatch = useDispatch();

  const addNewUser = async (dispatch, user) => {
    dispatch(addUserStart());
    try {
      const res = await publicRequest.post("auth/register", user);
      dispatch(addUserSuccess(res.data));

      if (res && res.status === 200) {
        setSuccess(true);
        setSuccessMessage("Your registration is successful! You can login now.");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setGrade("");
        setDateOfBirth("");
        setPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      dispatch(addUserFailure());
      if (error && error.response?.status === 409) {
        setEmailError(true);
        setEmailErrorMessage(error.response.data);
      } else {
        setError(true);
        setErrorMessage("Something went wrong!");
      }
    }
  };

  // Validate function for form validation start =======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const firstNameValidErr = validateRequired(firstName);
    const lastNameValidErr = validateRequired(lastName);
    const emailValidErr = validateRequired(email, "email");
    const phoneValidErr = validateRequired(phone, "phone");
    const dobValidErr = validateRequired(dateOfBirth);
    const gradeValidErr = validateRequired(grade);
    const passwordValidErr = validateRequired(password);
    const confirmPasswordValidErr = validateRequired(confirmPassword);
    // let confirmPassword;

    if (firstNameValidErr) {
      formIsValid = false;
      newErrors.firstName = firstNameValidErr;
    }
    if (lastNameValidErr) {
      formIsValid = false;
      newErrors.lastName = lastNameValidErr;
    }
    if (emailValidErr) {
      formIsValid = false;
      newErrors.email = emailValidErr;
    }
    if (phoneValidErr) {
      formIsValid = false;
      newErrors.phone = phoneValidErr;
    }
    if (gradeValidErr) {
      formIsValid = false;
      newErrors.grade = gradeValidErr;
    }
    if (dobValidErr) {
      formIsValid = false;
      newErrors.dateOfBirth = dobValidErr;
    }
    if (passwordValidErr) {
      formIsValid = false;
      newErrors.password = passwordValidErr;
    }

    if (confirmPasswordValidErr) {
      formIsValid = false;
      newErrors.confirmPassword = confirmPasswordValidErr;
    }

    if (password !== confirmPassword) {
      formIsValid = false;
      newErrors.confirmPassword = "Password did not matched";
    }

    setErrors(newErrors);
    return formIsValid;
  };
  // Validate function for form validation end======

  const handleSchoolData = () => {
    setEmailError(false);
    setSuccess(false);

    // if (validateForm()) {
    //     props.isFranchise ? (
    //         addSchool(dispatch, { firstName, lastName, email, schoolName, phone, franchiseId, isFranchiseSchool, isSchoolPrincipal })
    //     ) : (
    //         addSchool(dispatch, { firstName, lastName, email, schoolName, phone, isSchoolPrincipal, isMeritusSchool })
    //     )
    // }
  };

  const submitData = (e) => {
    e.preventDefault();
    setEmailError(false);
    setSuccess(false);

    if (validateForm()) {
      const trimmmedFirstName = firstName.trim();
      addNewUser(dispatch, {
        firstName: trimmmedFirstName,
        lastName,
        email,
        phone,
        dateOfBirth,
        password,
      });
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 pt-5">
            <img src="../../images/pages/register.png" />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="register-container">
              <div className="register-wrapper">
                <h2 className="text-uppercase text-center my-5">
                  Fill your <span>details here</span>
                </h2>
                <form>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <span className="error-text">{errors.firstName}</span>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <span className="error-text">{errors.lastName}</span>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="error-text">{errors.email}</span>
                    {emailError && (
                      <p className="error-text text-danger">
                        {emailErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Phone
                    </label>
                    <input
                      className="form-control"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <span className="error-text">{errors.phone}</span>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                    <span className="error-text">{errors.dateOfBirth}</span>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Grade
                    </label>
                    <select
                      name="grade"
                      id="grade"
                      onChange={(e) => setGrade(e.target.value)}
                      className=""
                    >
                      <option disabled selected>
                        Select Grade
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="0">Not Available</option>
                    </select>
                    <span className="error-text">{errors.grade}</span>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputPassword1" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="error-text">{errors.password}</span>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputPassword1" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span className="error-text">{errors.confirmPassword}</span>
                  </div>
                  <div className="col-12 mt-3">
                    {success ? (
                      <div className="alert alert-success">
                        {successMessage}
                      </div>
                    ) : (
                      error && (
                        <div className="alert alert-danger">{errorMessage}</div>
                      )
                    )}
                  </div>
                  <div className="register-btn-wrapper d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={submitData}
                    >
                      Register Now
                    </button>
                    {/* <Link to={"/login"}>
                      <button type="submit" className="btn btn-primary">
                        Login
                      </button>
                    </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="pb-100 pt-100 loginPage_login_signup">
        <div class="row d-flex justify-content-center m-5">
          <div class="col-md-4">
            <div class="text-center">
              <Link to={"/login"}>
                <div class="loginPage_icons">
                  <div>
                    <FaUnlockAlt size={40} />
                  </div>
                  <h5>Login</h5>
                  <p>Sign in to Your Account</p>
                </div>
              </Link>
            </div>
          </div>
          <div class="col-md-4">
            <div class="text-center">
              <Link to={"/register"}>
                <div class="loginPage_icons">
                  <div>
                    <FaUserCog size={40} />
                  </div>
                  <h5>Register</h5>
                  <p>Start Your Journey Here</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
