import { useState } from "react";
import "./Computational.css";
import "../Custom.js";
function Computational() {
  // Function to hide all tab content
  const [activeContent1, setActiveContent1] = useState(true);
  const [activeContent2, setActiveContent2] = useState(false);
  const [activeContent3, setActiveContent3] = useState(false);
  const [activeContent4, setActiveContent4] = useState(false);
  const [activeContent5, setActiveContent5] = useState(false);

  const handleContent1 = () => {
    setActiveContent1(true);
    setActiveContent2(false);
    setActiveContent3(false);
    setActiveContent4(false);
    setActiveContent5(false);
  };
  const handleContent2 = () => {
    setActiveContent1(false);
    setActiveContent2(true);
    setActiveContent3(false);
    setActiveContent4(false);
    setActiveContent5(false);
  };
  const handleContent3 = () => {
    setActiveContent1(false);
    setActiveContent2(false);
    setActiveContent3(true);
    setActiveContent4(false);
    setActiveContent5(false);
  };
  const handleContent4 = () => {
    setActiveContent1(false);
    setActiveContent2(false);
    setActiveContent3(false);
    setActiveContent4(true);
    setActiveContent5(false);
  };
  const handleContent5 = () => {
    setActiveContent1(false);
    setActiveContent2(false);
    setActiveContent3(false);
    setActiveContent4(false);
    setActiveContent5(true);
  };
  const getImageForActiveContent = () => {
    if (activeContent1) {
      return "images/home/comp/computational.svg";
    }
    if (activeContent2) {
      return "images/home/comp/Delivery.svg";
    }
    if (activeContent3) {
      return "images/home/comp/AI.svg";
    }
    if (activeContent4) {
      return "images/home/comp/Stem.svg";
    }
    if (activeContent5) {
      return "images/home/comp/Coding.svg";
    }
    return "";
  };
  const getHeadingForActiveContent = () => {
    if (activeContent1) {
      return "COMPUTATIONAL THINKING";
    }
    if (activeContent2) {
      return "ROBOTICS SYSTEMS";
    }
    if (activeContent3) {
      return "ARTIFICIAL INTELLIGENCE";
    }
    if (activeContent4) {
      return "STEM EDUCATION";
    }
    if (activeContent5) {
      return "CODING SKILLS";
    }
  };
  return (
    <div className="Computational_container">
      <h2 className="inh2 text-center pb-50">
        Data <span> Science</span>
      </h2>
      <section className="Computational_home">
        <div className="wrapper">
          <div
            className={`${activeContent1 ? "active" : ""} sector`}
            id="sec1"
            onClick={handleContent1}
          >
            <img src="images/home/computational.svg" />
          </div>
          <div
            className={`${activeContent2 ? "active" : ""} sector`}
            id="sec2"
            onClick={handleContent2}
          >
            <img src="images/home/Delivery.svg" />
          </div>
          <div
            className={`${activeContent3 ? "active" : ""} sector`}
            id="sec3"
            onClick={handleContent3}
          >
            <img src="images/home/AI.svg" />
          </div>
          <div
            className={`${activeContent4 ? "active" : ""} sector`}
            id="sec4"
            onClick={handleContent4}
          >
            <img src="images/home/Stem.svg" />
          </div>
          <div
            className={`${activeContent5 ? "active" : ""} sector`}
            id="sec5"
            onClick={handleContent5}
          >
            <img src="images/home/Coding.svg" />
          </div>
          <div className="middle">
            {/* Conditionally render the image */}
            <div className="middle_con">
              <img src={getImageForActiveContent()} />
              <p>{getHeadingForActiveContent()}</p>
            </div>
          </div>
        </div>

        <div className="content">
          {activeContent1 && (
            <div className="tab-content" id="content1">
              <h2 className="hSize fw-700 dBlue text-uppercase mb-4">
                Computational <span className="dPink">Thinking</span>
              </h2>
              <p className="pSize Unigray lh-lg">
                Meritus pioneers computational modeling by integrating it into
                educational programs. Students engage in virtual simulations,
                fostering deep understanding of complex concepts. Practical
                applications range from physics simulations to climate modeling,
                enhancing problem-solving skills and real-world insights.
              </p>
            </div>
          )}
          {activeContent2 && (
            <div className="tab-content" id="content2">
              <h2 className="hSize fw-700 dBlue text-uppercase mb-4">
                Robotics <span className="dPink">Systems</span>
              </h2>
              <p className="pSize Unigray lh-lg">
                Meritus empowers students with hands-on robotics experiences.
                Through interactive kits and workshops, learners develop logical
                thinking and engineering skills. These experiences nurture
                creativity and innovation, preparing students for a future where
                robotics is integral to various industries.
              </p>
            </div>
          )}

          {activeContent3 && (
            <div className="tab-content" id="content3">
              <h2 className="hSize fw-700 dBlue text-uppercase mb-4">
                Artificial<span className="dPink"> Intelligence</span>
              </h2>
              <p className="pSize Unigray lh-lg">
                In the AI realm, Meritus leads by introducing students to
                foundational AI concepts. Exploring machine learning, neural
                networks, and deep learning, learners gain insights into AI's
                transformative potential. By bridging theory with practical
                projects, Meritus cultivates the next generation of AI
                enthusiasts and innovators.
              </p>
            </div>
          )}

          {activeContent4 && (
            <div className="tab-content" id="content4">
              <h2 className="hSize fw-700 dBlue text-uppercase mb-4">
                STEM <span className="dPink"> Education</span>
              </h2>
              <p className="pSize Unigray lh-lg">
                Meritus excels in STEM education, offering comprehensive courses
                that blend science, technology, engineering, and math. Through
                hands-on experiments and interactive learning, students develop
                critical skills for solving complex problems and driving
                innovation across disciplines.
              </p>
            </div>
          )}

          {activeContent5 && (
            <div className="tab-content" id="content5">
              <h2 className="hSize fw-700 dBlue text-uppercase mb-4">
                Coding <span className="dPink"> Skills</span>
              </h2>
              <p className="pSize Unigray lh-lg">
                Meritus equips students with essential coding skills, enabling
                them to create digital solutions. From web development to app
                design, learners grasp coding languages and algorithms. Meritus
                emphasizes creative thinking, enabling students to translate
                ideas into functional, tangible projects.
              </p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
export default Computational;
