// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/lifeAt/life-of.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/images/lifeAt/life-of-meritus.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.life_banner .inb {
  margin-bottom: 40px;
}
.life_banner {
  display: flex;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 23.8vw;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .life_banner {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    align-items: start;
    height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .life_banner .pt-50 {
    padding-top: 10px;
  }

  .life_banner .inb {
    font-size: 22px;
    margin: 30px 0px 0px 0px;
  }

  .mb-5 {
    margin-bottom: 1rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/lifeAt/LifeBanner.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mDAA4D;EAC5D,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,cAAc;EACd,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,mDAAqE;IACrE,kBAAkB;IAClB,YAAY;IACZ,4BAA4B;IAC5B,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,wBAAwB;EAC1B;;EAEA;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".life_banner .inb {\r\n  margin-bottom: 40px;\r\n}\r\n.life_banner {\r\n  display: flex;\r\n  background: url(\"../../../public/images/lifeAt/life-of.png\");\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n  height: 23.8vw;\r\n  width: 100%;\r\n  align-items: center;\r\n}\r\n\r\n@media screen and (max-width: 576px) {\r\n  .life_banner {\r\n    background: url(\"../../../public/images/lifeAt/life-of-meritus.webp\");\r\n    align-items: start;\r\n    height: 60vh;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n  }\r\n\r\n  .life_banner .pt-50 {\r\n    padding-top: 10px;\r\n  }\r\n\r\n  .life_banner .inb {\r\n    font-size: 22px;\r\n    margin: 30px 0px 0px 0px;\r\n  }\r\n\r\n  .mb-5 {\r\n    margin-bottom: 1rem !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
