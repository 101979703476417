import "bootstrap/dist/css/bootstrap.css";
import "../../../styles.css";
import "../phiro/Phiro.css";
import {
  Col,
  Row,
  Container,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "react-bootstrap";
import VideoTestim from "../../../component/common/video-testimonial/VideoTestim";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllSuccessStoriesVideo } from "../../../redux/apiCalls";

const Phiro = () => {
  const phiroVideos = [
    {
      id: 1,
      // thumbnail: "/video/thumbnails/Learners-1.jpg",
      url: "https://vimeo.com/511901426/94174ca873?share=copy",
    },
    {
      id: 2,
      // thumbnail: "/video/thumbnails/Learners-2.jpg",
      url: "https://youtu.be/jSf45jpvC2Y?si=-Oqmsq5LKlBOD0GS",
    },
    {
      id: 3,
      // thumbnail: "/video/thumbnails/Learners-3.jpg",
      url: "https://youtu.be/H1JuVy3dXss?si=xhna5g4XUviRm5D4",
    },
    {
      id: 4,
      // thumbnail: "/video/thumbnails/Learners-3.jpg",
      url: "https://youtu.be/wH-9-_Wqtcs?si=oEzNesoZEIH4A7L6",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    getAllSuccessStoriesVideo(dispatch);
  }, [dispatch]);

  return (
    <div className="phiro-product">
      {/* Top banner */}
      <section className="phiro-banner-top"></section>

      {/* About Phiro: What is Phiro? */}
      <section className="about-phiro pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {/* heading  */}
                <Col lg={12}>
                  <div className="heading-npgs dark-blue-head pb-30 pbm">
                    <h2>
                      What is <span>Phiro ?</span>
                    </h2>
                  </div>
                </Col>

                {/* content and imgs  */}
                <Col lg={6}>
                  <div className="about-phiro-icons">
                    <div className="txt-about-phiro">
                      <p>
                        <strong>Phiro</strong> is a fun coding and robotics
                        platform for children ages <strong>4 to 18</strong>.
                        Phiro helps teach computer science, technology and
                        engineering through easy hands-on projects. With built
                        in progression and versatility, Phiro reaches all ages
                        and skill levels. Children can learn to code, Phiro
                        Unplugged with tangible Sequential Keys and colorful
                        Coding Swish Cards.
                      </p>
                      <p>
                        Children can code Phiro Pro, wirelessly using Scratch
                        2.0, Snap! or Phiro Code mobile app programming
                        languages. Phiro is Lego&#174; and Arduino compatible.
                        We believe that design thinking is an essential part of
                        digital transfomation. The phiro curriculum created by
                        us uses design thinking to deliver a creative learning
                        process. Lesson plans and educator guides developed by
                        Meritus are multi-discplinary and free.
                      </p>
                    </div>
                    <div className="about-icons">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Phiro/logo2.png`}
                        alt=""
                        srcset=""
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Phiro/logo1.png`}
                        alt=""
                        srcset=""
                      />

                      <img
                        src={`${process.env.PUBLIC_URL}/images/Phiro/logo3.png`}
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="phiro-variants">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Phiro/phiro-age-variants.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* More About Phiro: 3 cards  */}
      <section className="more-about-phiro-cards pt-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {/* Card: 1 */}
                <Col lg={4} md={6}>
                  <Card className="mbp-cards">
                    <CardImg
                      className="mbp-card-img"
                      variant="top"
                      src={`${process.env.PUBLIC_URL}/images/Phiro/play.png`}
                    />
                    <CardBody className="mbp-card-body">
                      <CardTitle className="mbp-card-title">Play</CardTitle>
                      <CardText>
                        With endless imagination and creativity kids play with
                        Phiro and together they go on adventures, create
                        stories, games, explore new worlds, all while having
                        fun.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                {/* Card: 2 */}
                <Col lg={4} md={6}>
                  <Card className="mbp-cards">
                    <CardImg
                      className="mbp-card-img"
                      variant="top"
                      src={`${process.env.PUBLIC_URL}/images/Phiro/code.png`}
                    />
                    <CardBody className="mbp-card-body">
                      <CardTitle className="mbp-card-title">Code</CardTitle>
                      <CardText>
                        As kids play with Phiro, they learn to code and watch
                        Phiro enact their programing. As kids learn to code,
                        they foster computational thinking and problem solving
                        skills, crucial for every field in the 21st century.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                {/* Card: 3 */}
                <Col lg={4}>
                  <Card className="mbp-cards">
                    <CardImg
                      variant="top"
                      className="mbp-card-img"
                      src={`${process.env.PUBLIC_URL}/images/Phiro/innovate.png`}
                    />
                    <CardBody className="mbp-card-body">
                      <CardTitle className="mbp-card-title">Innovate</CardTitle>
                      <CardText>
                        Coding and playing with Phiro empowers and inspires kids
                        to be creators and innovators of the world.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Play with Phiro  */}
      <section className="play-phiro pt-50 pb-50 mt-50">
        <Container>
          {/* heading  */}
          <Row>
            <div className="heading-npgs dark-blue-head pb-30">
              <h2>
                Play with <span>Phiro?</span>
              </h2>
            </div>
          </Row>

          {/* icons and text row -1 */}
          <Row className="align-items-center justify-content-center">
            {/* Col - A  */}
            <Col llg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/compass.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Navigate a Room</p>
                </div>
              </div>
            </Col>

            {/* Col - B  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/music.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Create Music & Dance</p>
                </div>
              </div>
            </Col>

            {/* Col - C  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/film-slate.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Make a Movie</p>
                </div>
              </div>
            </Col>
          </Row>

          {/* Row - 2  */}
          <Row className="pt-30 align-items-center justify-content-center ptm">
            {/* Col - D  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/dice.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>
                    Transform Phiro into anything you imagine with LEGO&#174;
                  </p>
                </div>
              </div>
            </Col>

            {/* Col - E  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/colour.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Flash lights of different colors</p>
                </div>
              </div>
            </Col>

            {/* Col - F  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/console.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Create Games</p>
                </div>
              </div>
            </Col>
          </Row>

          {/* Row - 3 */}
          <Row className="pt-30 ptm align-items-center justify-content-center">
            {/* Col - G  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/identity.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Detect and follow faces</p>
                </div>
              </div>
            </Col>

            {/* Col - H  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/camera.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Take Pictures</p>
                </div>
              </div>
            </Col>

            {/* Col - I  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/Follow-LIght.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Follow Light</p>
                </div>
              </div>
            </Col>
          </Row>

          {/* Row - 4 */}
          <Row className="pt-30 ptm align-items-center justify-content-center">
            {/* Col - J  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/Avoid-Obstacles.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Detect and avoid obstacles</p>
                </div>
              </div>
            </Col>

            {/* Col - K */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/Detect-Edge.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Detect Edges</p>
                </div>
              </div>
            </Col>

            {/* Col - L  */}
            <Col lg={3} md={3}>
              <div className="icon-text-wrapper">
                <div className="play-icons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/follow-a-line.png`}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="play-txt">
                  <p>Follow a Line</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Learn to code with Phiro  */}
      <section className="code-with-phiro pt-50 pb-50">
        <Container>
          <Col lg={10} className="mx-auto">
            {/* heading  */}
            <Row>
              <div className="heading-npgs dark-blue-head pb-30">
                <h2>
                  Learn to Code with <span>Phiro ?</span>
                </h2>
              </div>
            </Row>

            {/* Cards  */}
            <Row>
              {/* Card: 1 */}
              <Col lg={6} md={6}>
                <Card className="mbp-cards">
                  <CardImg
                    className="mbp-card-img"
                    variant="top"
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/sequential.png`}
                  />
                  <CardBody className="mbp-card-body pt-0">
                    <CardTitle className="mbp-card-title pb-20">
                      Sequential Programming
                    </CardTitle>
                    <CardText>
                      Kids can learn programing with Phiro’s easy sequential
                      keys to navigate a room, maneuver a maze and watch Phiro
                      act out your code.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>

              {/* Card: 2 */}
              <Col lg={6} md={6}>
                <Card className="mbp-cards swish-card">
                  <CardImg
                    className="mbp-card-img"
                    variant="top"
                    src={`${process.env.PUBLIC_URL}/images/Phiro/icons/swish-cards.png`}
                  />
                  <CardBody className="mbp-card-body pt-0">
                    <CardTitle className="mbp-card-title pb-20">
                      Swish card binary coding
                    </CardTitle>
                    <CardText>
                      Coding is like a fun game! Use swish cards of different
                      colors to make Phiro solve fun challenges, sing a song or
                      flash lights. Create your own commands for Phiro by simply
                      coloring binary code on cards.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Container>
      </section>

      {/* USP Cards  */}
      <section className="phiro-usps pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {/* Col - 1 */}
                <Col lg={4} md={6}>
                  <Card className="mbp-cards usp-card">
                    <CardImg
                      className="mbp-card-img usp-card-img"
                      variant="top"
                      src={`${process.env.PUBLIC_URL}/images/Phiro/pc1.png`}
                    />
                    <CardBody className="mbp-card-body usp-card-body pt-0">
                      <CardImg
                        className="usp-card-icon pb-20"
                        src={`${process.env.PUBLIC_URL}/images/Phiro/icons/icon1.png`}
                      ></CardImg>
                      <CardText>
                        Snap blocks of code together and send Phiro on missions.
                        Snap! is a free open source visual programming language
                        created by UC Berkeley, USA and adapted to Arduino by
                        Citilab Spain.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                {/* Col - 2  */}
                <Col lg={4} md={6}>
                  <Card className="mbp-cards usp-card usp-card-b">
                    <CardImg
                      className="mbp-card-img usp-card-img"
                      variant="top"
                      src={`${process.env.PUBLIC_URL}/images/Phiro/pc2.png`}
                    />
                    <CardBody className="mbp-card-body usp-card-body pt-0">
                      <CardImg
                        className="usp-card-icon pb-20"
                        src={`${process.env.PUBLIC_URL}/images/Phiro/icons/icon2.png`}
                      ></CardImg>
                      <CardText>
                        Drag & drop blocks of code to make Phiro clean your
                        room. Scratch 2.0 is a free open source visual
                        programming language created by MIT, Boston USA.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                {/* Col - 3  */}
                <Col lg={4}>
                  <Card className="mbp-cards usp-card usp-card-c">
                    <CardImg
                      className="mbp-card-img usp-card-img"
                      variant="top"
                      src={`${process.env.PUBLIC_URL}/images/Phiro/pc3.png`}
                    />
                    <CardBody className="mbp-card-body usp-card-body pt-0">
                      <CardImg
                        className="usp-card-icon pb-20"
                        src={`${process.env.PUBLIC_URL}/images/Phiro/icons/icon3.png`}
                      ></CardImg>
                      <CardText>
                        Build your own apps to control Phiro to record a movie
                        or take pictures. A free open source visual programming
                        mobile application created by Graz University of
                        Technology, Austria.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* What kids can do with Phiro  */}
      <section className="do-with-phiro pt-50 pb-50">
        <Container>
          <Row>
            <VideoTestim
              sectionHeading="What kids can do with"
              headingSpan="Phiro"
              // videos={phiroVideos}
              category="phiro"
            />
          </Row>
        </Container>
      </section>

      {/* create and innovate with Phiro accessories */}
      <section className="phiro-accessories p-rel pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={12}>
                  <div className="sub-heading-npgs dark-blue-head">
                    <p>Create and Innovate with</p>
                  </div>
                  <div className="heading-npgs dark-pink-head">
                    <h2>Phiro Accessories</h2>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6}>
                  <div className="accessories-wrapper-a">
                    <div className="accessories-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Phiro/newdevice.png`}
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div className="accessories-img-caption text-center">
                      <p>LEGO &#174; Compatible Phiro Connector</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="accessories-wrapper-a">
                    <div className="accessories-img-b">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Phiro/newdevice1.png`}
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div className="accessories-img-caption-b text-center">
                      <p>Smartphone Mount</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="accessories-text text-center">
                    <p>
                      Kids can transform Phiro into anything they can imagine or
                      want to play with, like a race car, alien, animal or even
                      a friend at a tea party
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Phiro Front view and rear view banner  */}
      <section className="front-rear-view-banner"></section>

      {/* Phiro includes  */}
      <section className="phiro-inclusions">
        {/* Phiro img  */}
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={12}>
                  <div className="phiro-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Phiro/phiro-meritus.png`}
                      alt=""
                      srcset=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="phiro-inclusion-cards">
          <Container>
            <Row className="gx-5 inclusion-cards-row">
              <Col lg={10} className="mx-auto">
                <Row>
                  <Col lg={3} md={6}>
                    <div className="inclusion-card ic-a">
                      <p>STEM Learning</p>
                    </div>
                  </Col>

                  <Col lg={3} md={6}>
                    <div className="inclusion-card ic-b">
                      <p>Unlimited Lessions</p>
                    </div>
                  </Col>

                  <Col lg={3} md={6}>
                    <div className="inclusion-card ic-c">
                      <p>Educator Guides- Multi Discilinary</p>
                    </div>
                  </Col>

                  <Col lg={3} md={6}>
                    <div className="inclusion-card ic-d">
                      <p>Multiple Learning Avenues</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* Phiro product testimonails  */}
      <section className="phiro-testimonials-wrapper pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                <Col lg={4} md={4}>
                  <div className="phiro-testimonial-card-wrapper ptc-right-border text-center p-rel">
                    <div className="phiro-testimonial-card">
                      <div className="ptc-img">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/Phiro/icons/mit.png`}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="phiro-testimonial-txt">
                        <p>
                          “By engaging in robotics, children play to learn as
                          they learn to play in creative contexts."
                        </p>
                      </div>
                      <div className="client-name-info-wrapper">
                        <p className="client-name m-0">
                          <strong>Dr. Mitch Resnick,</strong>
                        </p>
                        <p className="designation">Education Update, 2003.</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={4} md={4}>
                  <div className="phiro-testimonial-card-wrapper ptc-right-border text-center p-rel">
                    <div className="phiro-testimonial-card">
                      <div className="ptc-img">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/Phiro/icons/tufts.png`}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="phiro-testimonial-txt">
                        <p>
                          “Children as young as 4 years old can learn to program
                          robots while learning concepts in coding and
                          engineering & building their computational thinking
                          and problem solving skills."
                        </p>
                      </div>
                      <div className="client-name-info-wrapper">
                        <p className="client-name m-0">
                          <strong>Dr. Marina Bers,</strong>
                        </p>
                        <p className="designation">
                          Computers & Education, 2014.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={4} md={4}>
                  <div className="phiro-testimonial-card-wrapper text-center">
                    <div className="phiro-testimonial-card">
                      <div className="ptc-img">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/Phiro/icons/unh.png`}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="phiro-testimonial-txt">
                        <p>
                          “Programming robots in the context of problem solving
                          provides a visual and physical way to see the outcome
                          of problem solving."
                        </p>
                      </div>
                      <div className="client-name-info-wrapper">
                        <p className="client-name m-0">
                          <strong>Dr. Scott Turner,</strong>
                        </p>
                        <p className="designation">
                          Proceedings of 8th Annual Conference of the Subject
                          Centre for Information and Computer Sciences, 2007.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Phiro;
