import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Card2 from "../Card2";
import "../home/featured.css";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import StemProductCard from "../common/cards/StemProductCard";
import MiniLoader from "../common/Loader/miniLoader/MiniLoader";

const Featured = () => {
  // const [featuredProduct, setFeaturedProduct] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getProducts(dispatch);
  }, [dispatch]);


  const isFetching = useSelector((state) => state.product.isFetching);

  const products = useSelector(
    (state) =>
      state.product.products.length > 0 &&
      state.product.products.filter((item) => item.status === true)
  );

  // Responsive breakpoint configuration for the carousel
  const responsiveConfig = {
    0: {
      items: 1, // Show 1 item on screens between 0px and 767px (inclusive)
    },
    768: {
      items: 3, // Show 3 items on screens between 768px and 991px (inclusive)
    },
    992: {
      items: 3, // Show 4 items on screens between 992px and 1199px (inclusive)
    },
    1200: {
      items: 3, // Show 5 items on screens larger than 1200px
    },
  };

  if (isFetching) {
    return <MiniLoader />;
  }

  return (
    <div className="featured-container">
      <div className="container">
        <OwlCarousel
          className="owl-theme"
          // loop
          margin={30}
          nav
          responsive={responsiveConfig}
        >
          {products.length > 0 &&
            products
              .filter((product) => product.bestSeller === true)
              .map((product, index) => {
                <div className="item px-2" key={index}>
                  <StemProductCard {...product} />
                </div>
              }
              )}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Featured;
