// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pastEvents_banner {
    background: #FFF8E6;
}
.pastEvents_container .paragraphs p
{
margin-bottom: 30px;
}
.pastEvents_container .pastEvents_banner .container {
    overflow: hidden;
    position: relative;
    padding: 100px 250px  100px 0px;
    z-index: 2;
}
.pastEvents_container .pastEvents_banner img {
    width: 100%;
    height: 116%;
    position: absolute;
    object-fit: contain;
    z-index: -1;
    top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PastEvents.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;;AAEA,mBAAmB;AACnB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,+BAA+B;IAC/B,UAAU;AACd;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,QAAQ;AACZ","sourcesContent":[".pastEvents_banner {\n    background: #FFF8E6;\n}\n.pastEvents_container .paragraphs p\n{\nmargin-bottom: 30px;\n}\n.pastEvents_container .pastEvents_banner .container {\n    overflow: hidden;\n    position: relative;\n    padding: 100px 250px  100px 0px;\n    z-index: 2;\n}\n.pastEvents_container .pastEvents_banner img {\n    width: 100%;\n    height: 116%;\n    position: absolute;\n    object-fit: contain;\n    z-index: -1;\n    top: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
