import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error (optional)
    console.error('ErrorBoundary caught an error', error, errorInfo);

    // Show toast notification
    toast.error('Something went wrong!', {
      position: 'bottom-center',
      autoClose: 3000, // Auto close after 3 seconds
      hideProgressBar: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          {/* Render nothing or fallback UI */}
          <ToastContainer />
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
