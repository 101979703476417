// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-icon {
    margin-left: 5px;
}

.cart-icon svg {
    color: var(--white);
    font-size: 22px;
}`, "",{"version":3,"sources":["webpack://./src/component/product-page/ProductPageKit.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".cart-icon {\r\n    margin-left: 5px;\r\n}\r\n\r\n.cart-icon svg {\r\n    color: var(--white);\r\n    font-size: 22px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
