import React from "react";
import { Link } from "react-router-dom";
import "./AwardSection.css";

const AwardSection = ({ awardImage, blogLink }) => {
  return (
    <div className="AwardSection">
      <Link to={blogLink}>
        <img src={awardImage} alt="Award" />
      </Link>
    </div>
  );
};

export default AwardSection;
