// WelcomePopup.js
import React from 'react';
import './WelcomePopup.css';
import { FaTimes } from 'react-icons/fa'; // Import the close icon from react-icons


const WelcomePopup = ({ customerName, onClose }) => {
    return (
        <div className="welcome-popup">
            <div className="welcome-header d-flex justify-content-between">
                <p className='text-capitalize'>Hi {customerName}!</p>
                <button className="popup-close-button" onClick={onClose}>
                    <FaTimes /> {/* Close icon */}
                </button>
            </div>

            <p>Welcome to our platform.</p>
        </div>
    );
};

export default WelcomePopup;
