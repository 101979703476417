// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.latest_banner {
  height: 550px;
  background: #fff8e6;
  position: relative;
  overflow: hidden;
}
.latest_banner_img {
  position: absolute;
  right: -20px;
  bottom: -170px;
}
.latest_banner .inh2 {
  position: absolute;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/component/latesNews/LatestBanner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,mBAAmB;AACrB","sourcesContent":[".latest_banner {\r\n  height: 550px;\r\n  background: #fff8e6;\r\n  position: relative;\r\n  overflow: hidden;\r\n}\r\n.latest_banner_img {\r\n  position: absolute;\r\n  right: -20px;\r\n  bottom: -170px;\r\n}\r\n.latest_banner .inh2 {\r\n  position: absolute;\r\n  width: 50%;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n  padding-left: 200px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
