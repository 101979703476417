import { createSlice } from "@reduxjs/toolkit";

const orderRedux = createSlice({
    name: 'order',
    initialState: {
        orders: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        getOrdersStart: (state) => {
            state.isFetching = true;
        },

        getOrdersSuccess: (state, action) => {
            state.isFetching = false;
            state.orders = action.payload;
        },

        getOrdersFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add order actions 
        addOrderStart: (state) => {
            state.isFetching = true;
        },

        addOrderSuccess: (state, action) => {
            state.isFetching = false;
            const newOrder = action.payload;
            state.orders = [...state.orders, newOrder];
        },

        addOrderFailure: (state) => {
            state.error = true;
            state.isFetching = false;
        },

        clearOrder: (state) => {
            state.orders = [];
        },

        // updateResourceProgressOfCourse: (state, action) => {
        //     // const data = action.payload;
        //     const { chapterIndex, courseIndexInOrder, newProgress, orderId, resourceIndex } = action.payload;
        //     const orderToUpdate = state.orders.find(order => order._id === orderId);

        //     // Check if the order to update exists
        //     if (orderToUpdate) {
        //         // const productToUpdate = orderToUpdate.products[courseIndexInOrder].productId;
        //         // if (productToUpdate && productToUpdate.chapters) {
        //         //     const chapterToUpdate = productToUpdate.chapters[chapterIndex];
        //         //     if (chapterToUpdate && chapterToUpdate.resources) {
        //         //         // Update the progress property
        //         //         chapterToUpdate.resources[resourceIndex].progress = newProgress;
        //         //     }
        //         // }
        //         // state.orders = state.orders.map(order => {
        //         //     // If cartId matches newItem.cartItemId, replace it with newItem
        //         //     // if (cartItem._id === newItem.cartItemId) {
        //         //     //     state.total += newItem.price * newItem.quantity;
        //         //     //     state.totalInDollar += newItem.priceInDollar * newItem.quantity;
        //         //     //     return newItem;
        //         //     // }
        //         //     // // Otherwise, return the original cartItem
        //         //     // return cartItem;
        //         //     if(order._id === orderId){

        //         //     }
        //         // });


        //         return orderToUpdate.products[courseIndexInOrder].productId.chapters[chapterIndex].resources[resourceIndex].progress = newProgress;
        //         // const progressOfNow = orderToUpdate.products[courseIndexInOrder].productId.chapters[chapterIndex].resources[resourceIndex].progress
        //     }

        // }

        updateResourceProgressOfCourse: (state, action) => {

            const { chapterIndex, courseIndexInOrder, newProgress, orderId, resourceIndex } = action.payload;

            // Find the index of the order to update
            const orderIndex = state.orders.findIndex(order => order._id === orderId);

            // =================************ old method to  accessing the chapters array without verifying that it is exists or not ***************=============
            // // Check if the order exists
            // if (orderIndex !== -1) {
            //     // Create a deep copy of the state using JSON serialization/deserialization
            //     const nextState = JSON.parse(JSON.stringify(state));

            //     // Update the progress property in the copied state
            //     nextState.orders[orderIndex].products[courseIndexInOrder].productId.chapters[chapterIndex].resources[resourceIndex].progress = newProgress;


            //     return nextState;
            // }


            // =================************ new method to  accessing the chapters array after verifying that it is exists or not ***************=============
            // Check if the order exists
            if (orderIndex !== -1) {
                // Create a deep copy of the state using JSON serialization/deserialization
                const nextState = JSON.parse(JSON.stringify(state));

                const order = nextState.orders[orderIndex];
                const product = order?.products?.[courseIndexInOrder];
                const productId = product?.productId;
                const chapter = productId?.chapters?.[chapterIndex];
                const resource = chapter?.resources?.[resourceIndex];

                // Check if all required properties exist before attempting the update
                if (resource) {
                    resource.progress = newProgress;
                    return nextState;
                }
            }

            // Return the original state if the order is not found
            return state;
        }


    },
});

export const { getOrdersStart, getOrdersSuccess, getOrdersFailure, addOrderStart, addOrderSuccess, addOrderFailure, clearOrder, updateResourceProgressOfCourse } = orderRedux.actions;
export default orderRedux.reducer




