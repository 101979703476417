// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.token-expire-container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    background: #0000003d;
    overflow: hidden;
    z-index: 9999999999 !important;
    flex-direction: column;
    align-items: center;
}

.expired-popup{
    box-shadow: 0px 0px 31px -4px #ccc;
    row-gap: 20px;
    background: #fff;
    padding: 50px;
    text-align: center;
    display: flex;
    height: 200px;
    width: 40%;
    flex-direction: column;
    justify-content: center;
    border-radius: 30px;
}

.expired-popup h1{
    font-size: 25px;
}

.expired-popup .uniBtnBlue{
    padding: 10px 30px;
    border-radius: 25px;
}

/* .expired-popup{
    padding: 50px;
    text-align: center;
    display: inline-block;
} */`, "",{"version":3,"sources":["webpack://./src/component/global/TokenExpirationInterceptor.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,qBAAqB;IACrB,gBAAgB;IAChB,8BAA8B;IAC9B,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;IAClC,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,UAAU;IACV,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;;;GAIG","sourcesContent":[".token-expire-container{\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    position: fixed;\n    background: #0000003d;\n    overflow: hidden;\n    z-index: 9999999999 !important;\n    flex-direction: column;\n    align-items: center;\n}\n\n.expired-popup{\n    box-shadow: 0px 0px 31px -4px #ccc;\n    row-gap: 20px;\n    background: #fff;\n    padding: 50px;\n    text-align: center;\n    display: flex;\n    height: 200px;\n    width: 40%;\n    flex-direction: column;\n    justify-content: center;\n    border-radius: 30px;\n}\n\n.expired-popup h1{\n    font-size: 25px;\n}\n\n.expired-popup .uniBtnBlue{\n    padding: 10px 30px;\n    border-radius: 25px;\n}\n\n/* .expired-popup{\n    padding: 50px;\n    text-align: center;\n    display: inline-block;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
