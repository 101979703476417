// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 35%;
  left: -5%;
  font-size: 60px;
}

.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 35%;
  right: -5%;
  font-size: 60px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
}

@media (max-width: 767px) {
  .featured-conatiner .owl-carousel .owl-nav button.owl-next {
    right: 0%;
  }

  .featured-conatiner .owl-carousel .owl-nav button.owl-prev {
    left: 0%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/home/featured.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,iFAAiB;EAAjB,mBAAiB;AACnB;;AAEA;EACE;IACE,SAAS;EACX;;EAEA;IACE,QAAQ;EACV;AACF","sourcesContent":[".owl-carousel .owl-nav button.owl-prev {\n  position: absolute;\n  top: 35%;\n  left: -5%;\n  font-size: 60px;\n}\n\n.owl-carousel .owl-nav button.owl-next {\n  position: absolute;\n  top: 35%;\n  right: -5%;\n  font-size: 60px;\n}\n\n.owl-theme .owl-nav [class*=\"owl-\"]:hover {\n  background: unset;\n}\n\n@media (max-width: 767px) {\n  .featured-conatiner .owl-carousel .owl-nav button.owl-next {\n    right: 0%;\n  }\n\n  .featured-conatiner .owl-carousel .owl-nav button.owl-prev {\n    left: 0%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
