import React from 'react';

function RenderHTMLContent({ htmlContent, isItQuestion }) {
    return <div id={isItQuestion ? 'question-html-box' :`rendered-html-box`} dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}
export default RenderHTMLContent;



// import React from 'react';

// function RenderHTMLContent({ _ref }) {
//   // Check if _ref is defined and contains htmlContent
//   if (_ref && _ref.htmlContent) {
//     return <div dangerouslySetInnerHTML={{ __html: _ref.htmlContent }} />;
//   } else {
//     return null; // Return null if _ref or htmlContent is undefined
//   }
// }

// export default RenderHTMLContent;
