import { FaMapMarkerAlt } from "react-icons/fa";
import "./ContactUs.css";
import { useState } from "react";
import { validateRequired } from "../../formValidation";
import { publicRequest } from "../../requestMethod";
export default function ContactUs() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const submitFormQuery = async (user) => {
    // dispatch(addUserStart());
    try {
      setIsLoaderActive(true);
      const res = await publicRequest.post("contactForm/create", user);
      // dispatch(addUserSuccess(res.data));

      if (res && res.status === 200) {
        setIsLoaderActive(false);
        setSuccess(true);
        setSuccessMessage("We have recieved your query.");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      }
    } catch (error) {
      setIsLoaderActive(false);
      setError(true);
      setErrorMessage("Something went wrong!");
    }
  }

  // Validate function for form validation start =======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const nameValidErr = validateRequired(name);
    const emailValidErr = validateRequired(email);
    const phoneValidErr = validateRequired(phone, "phone");

    if (nameValidErr) {
      formIsValid = false;
      newErrors.name = nameValidErr;
    }
    if (emailValidErr) {
      formIsValid = false;
      newErrors.email = emailValidErr;
    }
    if (phoneValidErr) {
      formIsValid = false;
      newErrors.phone = phoneValidErr;
    }

    setErrors(newErrors);
    return formIsValid;
  };
  // Validate function for form validation end======

  const submitData = async () => {
    setSuccess(false);
    setError(false)
    if (validateForm()) {
      submitFormQuery({
        name,
        email,
        phone,
        message,
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && validateForm()) {
      submitData();
    }
  };



  return (
    <div className="row home_page_form_row pb-50">
      <div className="col-md-6">
        <h2 className="ff fw-700 mb-5 text-start text-uppercase">
          Visit <span>Us</span>
        </h2>
        {/* <p className="ff f-20 fw-400 text-start mt-3 mb-0 dPink">
          Meritus Centre for Creativity & Innovation,
        </p>
        <p className="ff f-20 fw-400 text-start mb-3 dPink">
          44, 1st Main Road, Gandhi Nagar,
          <br /> Adyar, Chennai - 600020.
        </p> */}

        <div className="d-flex justify-content-between align-items-center">
          <div className="map_card mt-4 mt-md-0">
            <FaMapMarkerAlt size={40} />
            <div className="map-body">
              <h5 className="map-title">India HO Address</h5>
              <p className="map-text">
                Meritus
                <br />
                2nd Floor, 44, First Main Road,
                <br /> Gandhi Nagar, Adyar, Chennai - 600020,
                <br />
                India.
              </p>
            </div>
          </div>
        </div>
        <div className="map_card">
          <FaMapMarkerAlt size={40} />
          <div className="map-body">
            <h5 className="map-title">USA Address</h5>
            <p className="map-text">
              Robotix USA - Meritus
              <br />
              651 N Broad St, Suite 201, Middletown,
              <br />
              Delaware 19709 USA.
            </p>
          </div>
        </div>
        <div className="map_card">
          <FaMapMarkerAlt size={40} />
          <div className="map-body">
            <h5 className="map-title">Delhi NCR Office</h5>
            <p className="map-text">
              4th Floor, FBD One Complex,
              <br />
              Sector 37, Fridabad, Haryana, India.
            </p>
          </div>
        </div>
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.759080385138!2d80.2581142!3d13.0076434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265e624edc7c3%3A0xc7e45ee8372ff6c1!2sRobotix%20Learning%20Solutions!5e0!3m2!1sen!2sin!4v1694260311788!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe> */}
      </div>
      <div className="col-md-6 home_page_form">
        {/* <form> */}
          <div className="row d-flex align-items-center">
            <div className="col-12">
              <div className="form-group">
                <label for="fullName" class="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <span className="error-text">{errors.name}</span> 
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label for="fullName" class="form-label">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="error-text">{errors.email}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label for="fullName" class="form-label">
                  Phone Number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <span className="error-text">{errors.phone}</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label for="fullName" class="form-label">
              Message
            </label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="5"
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyPress}
            ></textarea>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              {success ? (
                <div className="alert alert-success">
                  {successMessage}
                </div>
              ) : (
                error && (
                  <div className="alert alert-danger">{errorMessage}</div>
                )
              )}
            </div>
          </div>
          <div className="coupon-btn-wrapper text-center ">
            <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={submitData}><span>Submit</span></button>
          </div>
          {/* <button
            className="home_form_btn uniBtn uniBtnBlue btnPLight"
          >
            Submit Now
          </button> */}
        {/* </form> */}
      </div>
    </div>
  );
}
