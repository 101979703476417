import { createSlice } from "@reduxjs/toolkit";

const productReducer = createSlice({
    name: 'product',
    initialState: {
        products: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Product
        getProductStart: (state) => {
            state.isFetching = true;
        },
        getProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        getProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
});

export const { getProductStart, getProductSuccess, getProductFailure } = productReducer.actions;
export default productReducer.reducer;