import { FaPersonBooth } from "react-icons/fa";
import { Link } from "react-router-dom";
import ContactUs from "../home/ContactUs";
const PrimarySchoolBundleOption = () => {
  return (
    <div>
      <section className="pt-100">
        {/* <div className="container"> */}
        <h2 className="text-center bundle_heading">
          Primary School<span> Bundle</span>{" "}
        </h2>
        <h3 className="text-center bundle_sub_heading mb-50">
          A comprehensive AI & Robotics Education platform for your school
        </h3>
        <div className="row">
          <div className="col-lg-5 col-md-5">
            <div className="school_bundle_img">
              <img src="images/SchoolBundle/gif/Primary-Bundle-India).gif" />
            </div>
          </div>

          <div className="ps-5 col-lg-7 col-md-7">
            <div className="row">
              <div className="col-md-12 mb-2">
                <div className="school_bundle_usps">
                  <div>
                    <img src="images/SchoolBundle/India/icons/1-Icon.svg" />
                    <p>100% Screen - Free</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/5-Icon.svg" />
                    <p>24 + Hours of Lesson plans</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/2-Icon.svg" />
                    <p>Supports 2 students in the classroom.</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/6-Icon.svg" />
                    <p>
                      Curriculum is aligned to NEP 2020 and to CBSE, ICSE &
                      State Boards
                    </p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/3-Icon.svg" />
                    <p>Enabling hands-on STEM learning experiences</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/7-Icon.svg" />
                    <p>
                      Our kits are durable and have a service life of a minimum
                      of 5 years.
                    </p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/4-con.svg" />
                    <p>Teacher resources and Training Available</p>
                  </div>
                  <div>
                    <img src="images/SchoolBundle/India/icons/8-Icon.svg" />
                    <p>
                      Learning resources are designed by in-house experts and
                      guided by our International Governing Board.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <h4 className="bundle_heading2 psb-sub-heading text-center">
                Create your own learning solution
              </h4>
              <h5 className="bundle_sub_heading2 text-center">
                We'll assist you in customizing and assembling your unique
                bundle.
              </h5>
              <div className="col-md-12 mt-3">
                <div className="school_bundle_usps2">
                  <div>
                    <h6>
                      <span>1. </span>STEM Math Kit
                    </h6>
                    <p>Learn Math brick by Brick </p>
                  </div>
                  <div>
                    <h6>
                      <span>5. </span>STEM Maker Kit
                    </h6>
                    <p>Build simple & compound machines</p>
                  </div>
                  <div>
                    <h6>
                      {" "}
                      <span>2. </span>Playbits
                    </h6>
                    <p>Learn creative coding</p>
                  </div>
                  <div>
                    <h6>
                      {" "}
                      <span>6. </span>Beebot
                    </h6>
                    <p>Programmable floor robot</p>
                  </div>
                  <div>
                    <h6>
                      <span>3. </span>Phiro Unplugged
                    </h6>
                    <p>Learn innovative robotics with Swish card technology</p>
                  </div>
                  <div>
                    <h6>
                      <span>7. </span>Ta. Co Robobricks
                    </h6>
                    <p>Construction kit powered by Tangible coding</p>
                  </div>
                  <div>
                    <h6>
                      <span>4. </span>AI with Scratch AI extensions
                    </h6>

                    <p>Virtual and On-Line course</p>
                  </div>

                  <div>
                    <Link to="/contact-us" className="bundle_btn">
                      GET STARTED
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </div>
  );
};
export default PrimarySchoolBundleOption;
