import { createSlice } from "@reduxjs/toolkit";

const userReducer = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        isFetching: false,
        error: false,
    },

    reducers: {
        // login actions
        loginStart: (state) => {
            state.isFetching = true;
        },
        loginSuccess: (state, action) => {
            state.isFetching = false;
            state.currentUser = action.payload;
        },
        loginFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        // logout action
        logoutUser: (state) => {
            state.currentUser = null;
            state.isFetching = true;
        },
        // Update user actions
        updateUserStart: (state) => {
            state.isFetching = true;
            
        },

        updateUserSuccess: (state, action) => {
            state.isFetching = false;
            // const adminIndex = state.user.findIndex((admin) => admin._id === action.payload._id);
            // if (adminIndex !== -1) {
            //     state.user[adminIndex] = action.payload;
            // }
            state.currentUser = action.payload
        },

        updateUserFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // update user password actions
        updatePasswordStart : (state) => {
            state.isFetching = true;
            state.error = false;
        },

        updatePasswordSuccess : (state, action) => {
            state.isFetching = false;
            state.currentUser = action.payload;
        },
        updatePasswordFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

    },
});

export const { loginStart, loginSuccess, loginFailure, logoutUser, updateUserStart, updateUserSuccess, updateUserFailure, updatePasswordStart, updatePasswordSuccess, updatePasswordFailure } = userReducer.actions;
export default userReducer.reducer;