import "./LandingBanner.css";
function Banner() {
  return (
    <div>
      <section className="landing_banner pb-4">
        <div className="container custom-container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 order-1 order-lg-0">
              <h1 className="banner_txt">
              Embrace the <span>future</span> of Education With<span>AI  Powered</span> Solution
              </h1>
              <a href="/" className="landing_banner_btn">
                Explore AI Courses
              </a>
            </div>
            <div className="col-lg-6">
              <div className="banner_img">
                <img src="images/banner/landin1_banner.png" alt="Hello" />
                <img src="images/landing/mob-banner.jpg" alt="Hello" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Banner;
