import "bootstrap/dist/css/bootstrap.css";
import "../CV Pro Competion Kit/CVProCompetitonKit.css";
import { Container, Row, Col } from "react-bootstrap";
import CommonButton from "../../../component/common/Button/CommonButton";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const CVProCompetitonKit = () => {
  // Responsive breakpoint configuration for the carousel
  const responsiveConfig = {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  };

  // Card Slider Section Carousel Customizations
  const CVSlider = {
    className: "owl-theme cv-pro-slider",
    loop: true,
    center: true,
    margin: 0,
    autoplay: true,
    smartSpeed: 450,
    nav: true,
    dots: false,
    responsive: responsiveConfig,
  };

  return (
    <div className="cv-pro-competition-kit">
      {/* Top banner section  */}
      <section className="cpck-banner-top p-rel">
        <Container>
          <Row>
            <Col lg={6} className="p-rel mob-position">
              <div className="cpck-banner-content-wrapper">
                <p className="m-0 fw-bold d-none-mob">Introducing</p>
                <img
                  className="d-none-mob"
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/cvprologo.png`}
                  alt=""
                  srcset=""
                />
                <h1 className="pb-20 d-none-mob">
                  self driving vehicle competition kit
                </h1>
                <p className="d-none-mob">
                  Designed for{" "}
                  <span className="fw-bold">
                    WRO Future Engineers
                    <br />
                    Competition.
                  </span>
                </p>
                <div className="cpck-banner-text-btn-wrapper">
                  <p className="d-none-mob">
                    And for other Global Autonomus
                    <br />
                    Vehicle-Robotics Competitions
                  </p>
                  {/* Button on banner  */}
                  <CommonButton
                    btnClass="cvpc-banner-btn"
                    btnName="Get Your Kit Now!"
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}></Col>
          </Row>
        </Container>
      </section>

      {/* Meet CV Pro Section  */}
      <section className="meet-cv-pro pt-50">
        <Container>
          {/* Heading and Sub heading  */}
          <Row>
            <Col lg={12}>
              {/* heading  */}
              <div class="heading-npgs dark-blue-head pbm">
                <h2>
                  Meet <span>CV Pro</span>
                </h2>
              </div>

              {/* Sub-heading  */}
              <div className="sub-heading-npgs cvpc-sub-head">
                <p>AUTONOMOUS | CONNECTED | ELECTRIC</p>
              </div>
            </Col>
          </Row>

          {/* Content with video  */}
          <div className="about-cvpro-content-wrapper">
            <div className="about-cvpro-txt">
              <p>
                Harness the potential of Self Driving Vehicle technology using
                Cutting-edge AI with smartphone computer vision.
              </p>
              <p>
                Equipped with sensors, computation power, and seamless
                communication, delve into advanced AI, like machine learning,
                deep learning & neural networks.
              </p>
              <p>
                Ideal for all Robotics competitions and{" "}
                <span>WRO Future Engineers, </span>
                combining simplicity and versatility in design.
              </p>
            </div>

            {/* Video */}
            <div className="wro-video mb-50">
              <iframe
                width="100%"
                height="350"
                src="https://player.vimeo.com/video/861227185?h=d26cd30dc2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title="CV Pro - WRO ver v"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </Container>
      </section>

      {/* What is in the box */}
      <section className="cvpro-box pt-50 pb-50">
        <Container>
          {/* heading  */}
          <Row>
            <Col lg={12}>
              {/* heading  */}
              <div class="heading-npgs dark-blue-head pbm">
                <h2>
                  What's in the<span>&nbsp;Box ?</span>
                </h2>
              </div>
            </Col>
          </Row>

          {/* Image  */}
          <Row>
            <div className="cvpro-box-img">
              <img
                src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/cvpro-box.png`}
                alt=""
                srcset=""
              />
            </div>
          </Row>
        </Container>
      </section>

      {/* Code | Train | Drive | Navigate */}
      <section id="code-train-drive" className="code-train-drive pt-50 pb-50">
        <Container>
          <Row>
            <Col lg={12}>
              {/* heading  */}
              <div class="heading-npgs ctdn-heading">
                <h2>
                  <span class="purple-text">Code </span>|
                  <span class="blue-text"> Train </span>|
                  <span class="orange-text"> Drive </span>|
                  <span class="green-text"> Navigate</span>
                </h2>
              </div>

              {/* sub heading  */}
              <div class="sub-heading-npgs pb-20">
                <p>
                  Elevate your expertise with the
                  <span> CV Pro Self-Driving Kit</span>, advancing your skills
                  in autonomous technology
                </p>
              </div>
            </Col>
          </Row>

          {/* technology icons  */}
          <Row>
            <div className="cvpc-tech-icons">
              <img
                src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/open-cv-a.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/python-b.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/tensorflow-lite-c.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/android-studio-d.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/flutter-e.png`}
                alt=""
                srcset=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/icons/tensorflow-f.png`}
                alt=""
                srcset=""
              />
              <img
                className="deeplearn-img"
                src={`${process.env.PUBLIC_URL}/images/cv-pro/icons/deep-learning.svg`}
                alt=""
                srcset=""
              />
            </div>
          </Row>
        </Container>
      </section>

      {/* CV Pro Competition Kit Images  Slider*/}
      <section
        id="cv-pro-competition-kit-images-slider"
        className="cv-pro-competition-kit-images-slider pb-50"
      >
        <Container>
          <Row>
            <OwlCarousel {...CVSlider}>
              <div className="cv-pro-kit-img cvpk-img-a">
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/2. Image.png`}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="cv-pro-kit-img cvpk-img-b">
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/3. Image.png`}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="cv-pro-kit-img cvpk-img-c">
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/4. Image.png`}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="cv-pro-kit-img cvpk-img-d">
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/5. Image.png`}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="cv-pro-kit-img cvpk-img-e">
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/6. Image.png`}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="cv-pro-kit-img cvpk-img-f">
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/7. Image.png`}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="cv-pro-kit-img cvpk-img-g">
                <img
                  src={`${process.env.PUBLIC_URL}/images/cv-pro-competition-kit/8. Image.png`}
                  alt=""
                  srcset=""
                />
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CVProCompetitonKit;
