import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import Testimonial from "../component/home/Testimonial";
import TabWrapper from "../component/common/tab/TabWrapper";
import "../component/home/Testimonial.css";
import "./TestimonialPage.css";
import { publicRequest } from "../requestMethod";

const TestimonialPage = () => {

  // const testimonials = useSelector((state) => state.testimonial)
  const [testimonials, setTestimonials] = useState([]);
  const getTestimonials = async () => {
    try {
      const response = await publicRequest.get('/testimonial/all');


      if (response && response.status == 200) {
        setTestimonials(response.data)
      }
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);


  const [activeTab, setActiveTab] = useState(0);
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <TabWrapper
        titles={[
          <div className="testimonial_tab1">
            <img src="images/q.svg" className="" />
            <p className="inh2">
              What Parents <span>Say</span>
            </p>
          </div>,
          <div className="testimonial_tab2">
            <img src="images/q.svg" className="" />
            <p className="inh2 fw-600">
              What Trainers <span>Say</span>
            </p>
          </div>,
        ]}
        contents={[
          <Masonry
            key="tab1"
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {testimonials.filter((testimonial) => testimonial.category === 'parent' && testimonial.status === true).map((testimonial, index) => {
              return (
                <div className="testimonials_item testimonial-page-item" key={index}>
                  {/* <h2 className="hSize fw-700 ff dBlue text-uppercase">
                    what trainers <span>Say</span>
                  </h2> */}
                  <img
                    src={testimonial.testimonialImage ? testimonial.testimonialImage : `${process.env.PUBLIC_URL}/images/profile.png`}
                    alt="img"
                    className="mt-lg-5 mb-lg-4 testimonial-img"
                  />
                  <h3 className="ff dPink fw-600 f-20 text-capitalize">{testimonial.parentName} </h3>
                  <h4 className="ff fw-600 f-20 dBlue text-capitalize">{testimonial.childName}</h4>
                  <img src="images/q.svg" className="quotation" />
                  <p className="text-start ff">{testimonial.description}</p>
                </div>
              )
            }
            )}
          </Masonry>,
          <div key="tab2">
            {" "}
            <Masonry
              key="tab1"
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid tab2Data"
              columnClassName="my-masonry-grid_column"
            >
              {testimonials.filter((testimonial) => testimonial.category === 'trainer' && testimonial.status === true).map((testimonial, index) => {
                return (
                  <div className="testimonials_item testimonial-page-item" key={index}>
                    {/* <h2 className="hSize fw-700 ff dBlue text-uppercase">
                      what trainers <span>Say</span>
                    </h2> */}
                    <img
                      src={testimonial.testimonialImage ? testimonial.testimonialImage : `${process.env.PUBLIC_URL}/images/profile.png`}
                      alt="img"
                      className="mt-lg-5 mb-lg-4 testimonial-img"
                    />
                    <h3 className="ff dPink fw-600 f-20 text-capitalize">{testimonial.parentName} </h3>
                    <h4 className="ff fw-600 f-20 dBlue text-capitalize">{testimonial.childName}</h4>
                    <img src="images/q.svg" className="quotation" />
                    <p className="text-start ff">{testimonial.description}</p>
                  </div>
                )
              }
              )}
            </Masonry>
          </div >,
          // <div key="tab3">Content for Tab 3</div>,
        ]}
        TabDataClass="testimonials_page_item"
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      />
    </div >
  );
};

export default TestimonialPage;
