import LifeBanner from "../component/lifeAt/LifeBanner";
import LifeCta from "../component/lifeAt/LifeCta";
// import Carousel from "react-grid-carousel";
import CardUni from "./CardUni.jsx";
import AboutUs from "./AboutUs";
import ContactUs from "../component/home/ContactUs";
import "./LifeAt.css";
import "./CardUni.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const LifeAt = () => {
  const dynamicComponentPropsArray = [
    {
      imageUrl: "images/lifeAt/Working-at-Meritus.jpg",
      title: "Working at ",
      span: "Meritus",
      paragraph:
        "Join our team at Meritus AI Learning Experiences and be a part of a dynamic environment where innovation, education, and technology converge to create meaningful learning experiences. We're passionate about transforming education through AI and coding, and we're looking for driven individuals who share our vision.",
      // btnText: "Click Here",
      // btnLink: "google.com",
      imageColClass: "col-md-6",
      textColClass:
        "col-md-6 d-flex flex-column align-items-start justify-content-center mt-3 mt-md-0",
      reverse: true,
    },
  ];
  const MeritusData = [
    {
      imageUrl: "images/lifeAt/What-to-expect at-Meritus.jpg ",
      title: "What to expect at ",
      span: "Meritus",
      paragraph:
        "At Meritus AI, you'll find yourself immersed in an innovative and passionate team dedicated to shaping the future of education. Your role goes beyond just a job – it's about making a meaningful impact. Every contribution you make directly contributes to transforming education and equipping learners worldwide with the essential skills for the future.",
      paragraph2:
        "Our collaborative work culture emphasizes inclusivity and values your ideas. You'll have the opportunity to collaborate with experts from diverse fields, fostering a dynamic and supportive environment. Additionally, we prioritize your professional growth. With access to continuous learning opportunities, cutting-edge technologies, and the chance to work on challenging projects, you'll be at the forefront of AI and coding education innovation. Join us at Meritus AI to be part of a global mission that revolutionizes education, all while experiencing a career journey filled with innovation and purpose.",
      // btnText: "Click Here",
      // btnLink: "google.com",
      imageColClass: "col-md-6",
      textColClass:
        "col-md-6 d-flex flex-column align-items-start justify-content-center mt-3 mt-md-0",
      reverse: true,
    },
  ];
  const cardData = [
    {
      imageUrl: "images/lifeAt/icon_1.png",
      title: "Empowering Future ",
      spantxt: "Generations",
      paragraph:
        "At Meritus AI, we are driven by the vision of empowering future generations with essential skills, enabling them to thrive in an AI-driven world. ",
      backgroundColor: "",
    },
    {
      imageUrl: "images/lifeAt/icon_2.png",
      title: "Accessible  ",
      spantxt: "Education",
      paragraph:
        "We believe in making AI and coding education accessible to learners of all ages, breaking down barriers and democratizing knowledge.Innovation and Creativity: Our commitment to innovation and creativity fuels our development of cutting-edge educational programs and solutions that inspire learners to think outside the box. ",
      backgroundColor: "white",
    },
    {
      imageUrl: "images/lifeAt/icon_3.png",
      title: "Collaboration and  ",
      spantxt: "Inclusion",
      paragraph:
        "We foster a collaborative and inclusive learning environment, where diverse perspectives come together to create a rich and dynamic educational experience.",
      backgroundColor: "white",
    },
  ];
  const cardData1 = [
    {
      imageUrl: "images/lifeAt/icon_1.png",
      spantxt: " and Seminars ",
      title: "Workshops",
      paragraph:
        "Engage in workshops, seminars, and training sessions conducted by industry experts, enabling you to deepen your knowledge and explore emerging trends.",
      backgroundColor: "#FFF7FB",
    },
    {
      imageUrl: "images/lifeAt/icon_2.png",
      spantxt: " Advanced Technologies ",
      title: "Access to ",
      paragraph:
        "Access cutting-edge technologies and tools that are transforming the education landscape. Stay informed about the latest advancements and implement them in your work.",
      backgroundColor: "#FFF7FB",
    },
    {
      imageUrl: "images/lifeAt/icon_2.png",
      spantxt: "Experience",
      title: "Hands-On ",
      paragraph:
        "Dive into hands-on learning experiences, allowing you to apply theoretical concepts to real-world projects. This practical approach accelerates your learning and problem-solving abilities.",
      backgroundColor: "#FFF7FB",
    },
    {
      imageUrl: "images/lifeAt/icon_2.png",
      spantxt: "Growth",
      title: "Professional  ",
      paragraph:
        "Meritus AI values your growth. As you contribute to our mission, we provide opportunities for career advancement and recognition, rewarding your dedication and efforts.",
      backgroundColor: "#FFF7FB",
    },
    // Add more card data as needed
  ];
  const responsiveConfig = {
    0: {
      items: 1, // Show 1 item on screens between 0px and 767px (inclusive)
    },
    768: {
      items: 2, // Show 3 items on screens between 768px and 991px (inclusive)
    },
    992: {
      items: 2, // Show 4 items on screens between 992px and 1199px (inclusive)
    },
    1200: {
      items: 2, // Show 5 items on screens larger than 1200px
    },
  };
  const responsiveConfig1 = {
    0: {
      items: 1, // Show 1 item on screens between 0px and 767px (inclusive)
    },
    768: {
      items: 2, // Show 3 items on screens between 768px and 991px (inclusive)
    },
    992: {
      items: 2, // Show 4 items on screens between 992px and 1199px (inclusive)
    },
    1200: {
      items: 3, // Show 5 items on screens larger than 1200px
    },
  };
  return (
    <div id="LifeAtPage">
      <LifeBanner />
      <section className="we_are_do pt-100" id="lifeAt_working">
        <div className="container">
          {dynamicComponentPropsArray.map((card, index) => (
            <AboutUs key={index} {...card} />
          ))}
        </div>
      </section>
      <section className="stand_for pt-50 pb-50">
        <div className="container">
          <h2 className="inh2 mb-5">
            What we <span>stand for</span>
          </h2>
          <OwlCarousel
            className="owl-theme"
            responsive={responsiveConfig1}
            margin={40}
            loop
            dots
          >
            {cardData.map((card, index) => (
              <div className="item" key={index}>
                <CardUni {...card} />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>
      <section className="learning pt-100 pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h2 className="inh2 mb-5">
                LEARNING <span>OPPORTUNITIES</span>
              </h2>
              <p>
                Continuous Skill Enhancement: Join us to embark on a journey of
                continuous skill enhancement. We provide regular opportunities
                for you to upskill and stay at the forefront of AI, coding, and
                education.
              </p>
            </div>
            <div className="col-md-8">
              <OwlCarousel
                className="owl-theme"
                // items={2}
                responsive={responsiveConfig}
                margin={40}
                loop
                dots
              >
                {cardData1.map((card, index) => (
                  <div className="item" key={index}>
                    <CardUni {...card} />
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <section className="we_are_do pt-100">
        <div className="container">
          {MeritusData.map((card, index) => (
            <AboutUs key={index} {...card} />
          ))}
        </div>
      </section>
      <section className="contact_us pb-50">
        <div className="container">
          <ContactUs />
        </div>
      </section>
      {/* <section className="life_cta">
        <LifeCta />
      </section> */}
    </div>
  );
};

export default LifeAt;
