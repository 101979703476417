import "bootstrap/dist/css/bootstrap.css";
import "../../../styles.css";
import "./SchoolBundleCarousel.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const SchoolBundleCarousel = () => {
  // Card Slider Section Carousel Customizations
  const options = {
    className: "owl-theme sb-carousel",
    loop: true,
    center: true,
    // margin: 30,
    autoplay: true,
    smartSpeed: 450,
    nav: false,
    items: 1,
    // navText: [<ArrowForwardIosIcon />],
    // responsive: responsiveConfig,
  };
  // Responsive breakpoint configuration for the carousel
  const responsiveConfig = {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 1,
    },
    1200: {
      items: 1,
    },
  };
  return (
    <div>
      <OwlCarousel {...options}>
        {/* Slide -1  */}
        <div className="sb-carousel-item sb-carousel-item-a">
          <div className="sb-carousel-item-inner-content text-center">
            <div className="sbc-heading">
              <h2>AI LAB</h2>
            </div>
            <div className="sbc-subhead">
              <h3>Go beyond robotics and coding</h3>
              <p>Future ready AI</p>
            </div>
            <div className="sbc-para">
              <p>
                Let your students discover the world of AI
                <br />
                Explore futuristic learning resources with AI Lab in your school
              </p>
            </div>
          </div>
        </div>

        {/* Slide -2 */}
        <div className="sb-carousel-item sb-carousel-item-b">
          <div className="sb-carousel-item-inner-content text-center">
            <div className="sbc-subhead">
              <h3 className="sbc-3-para">Preparing the class of 2024</h3>
            </div>
          </div>
        </div>

        {/* Slide -3 */}
        <div className="sb-carousel-item sb-carousel-item-c">
          <div className="sb-carousel-item-inner-content text-center">
            <div className="sbc-subhead">
              <h3>Empowering schools with</h3>
              <p className="sbc-3-para">Robotics & STEM Lab</p>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default SchoolBundleCarousel;
