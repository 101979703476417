// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq {
    list-style: none;
    padding-top: 20px;
}

.que-ans {
    padding: 10px 0px;
}

.question {
    font-weight: 700;
}

.answer {
    color: #667085;
}`, "",{"version":3,"sources":["webpack://./src/component/course-page/cp-tab/Faq.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".faq {\r\n    list-style: none;\r\n    padding-top: 20px;\r\n}\r\n\r\n.que-ans {\r\n    padding: 10px 0px;\r\n}\r\n\r\n.question {\r\n    font-weight: 700;\r\n}\r\n\r\n.answer {\r\n    color: #667085;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
