// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-arrow {
    background: none;
}

.skill-card-carousel .bghjHX {
    margin: 0px;
}

.skill-card-carousel span.sc-bwzfXH.kVtMtA,
.skill-card-carousel span.sc-bwzfXH.bhRnqO {
    display: block;
    top: calc(50% - -20px);
    height: 35px;
    width: 35px;
    background: none;
    border-radius: 0;
    border-radius: initial;
    box-shadow: none;
    opacity: 1;
    font-size: 16px;
    left: -5%;
}

.skill-card-carousel span.sc-bwzfXH.bhRnqO {
    left: auto;
    left: initial;
    right: -5%;
}

@media (max-width: 767px) {
    .skill-card-carousel .sc-ifAKCX .jWpUJH {
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/common/tab/Skills.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;;IAEI,cAAc;IACd,sBAAsB;IACtB,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,gBAAoB;IAApB,sBAAoB;IACpB,gBAAgB;IAChB,UAAU;IACV,eAAe;IACf,SAAS;AACb;;AAEA;IACI,UAAa;IAAb,aAAa;IACb,UAAU;AACd;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".right-arrow {\r\n    background: none;\r\n}\r\n\r\n.skill-card-carousel .bghjHX {\r\n    margin: 0px;\r\n}\r\n\r\n.skill-card-carousel span.sc-bwzfXH.kVtMtA,\r\n.skill-card-carousel span.sc-bwzfXH.bhRnqO {\r\n    display: block;\r\n    top: calc(50% - -20px);\r\n    height: 35px;\r\n    width: 35px;\r\n    background: none;\r\n    border-radius: unset;\r\n    box-shadow: none;\r\n    opacity: 1;\r\n    font-size: 16px;\r\n    left: -5%;\r\n}\r\n\r\n.skill-card-carousel span.sc-bwzfXH.bhRnqO {\r\n    left: initial;\r\n    right: -5%;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .skill-card-carousel .sc-ifAKCX .jWpUJH {\r\n        display: block;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
