// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-img-wrapper img {
  height: 80vh;
  width: 100%;
}

.back-to-previous-btn a button,
.back-to-home a button {
  width: 100%;
  border: medium none currentColor;
  border: initial;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
}

.back-to-previous-btn a button span,
.back-to-home a button span {
  color: var(--dPink);
}

.back-to-previous-btn button {
  background-color: #142153;
}

.back-to-home button {
  background-color: #7380ff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/404-page/Error404.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;;EAEE,WAAW;EACX,gCAAa;EAAb,eAAa;EACb,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".error-img-wrapper img {\n  height: 80vh;\n  width: 100%;\n}\n\n.back-to-previous-btn a button,\n.back-to-home a button {\n  width: 100%;\n  border: unset;\n  color: white;\n  padding: 10px;\n  text-transform: uppercase;\n  font-weight: 700;\n  font-size: 24px;\n}\n\n.back-to-previous-btn a button span,\n.back-to-home a button span {\n  color: var(--dPink);\n}\n\n.back-to-previous-btn button {\n  background-color: #142153;\n}\n\n.back-to-home button {\n  background-color: #7380ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
