import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import './TrendingNow.css';

const TrendingNow = ({ items }) => {
  return (
    <div className="trending-now">
      <div className="ticker-container">
        {items.map((item, index) => (
          <div className="ticker-item" key={index}>
            <div className="serial-number">{index + 1}</div>
            <div>
              <div className="item-text">{item.text}</div>
              <a href={item.link} className="down-arrow">
                <FiChevronDown />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingNow;
