import "bootstrap/dist/css/bootstrap.css";
import "./MyWishlist.css";
import CCPHeader from "../common/cart-checkout-pay-header/CCPHeader";
import WishlistProduct from "../common/cards/WishlistProduct";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommonButton from "../common/Button/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllWishlistProducts, getProducts } from "../../redux/apiCalls";
import MiniLoader from "../common/Loader/miniLoader/MiniLoader";

const MyWishlist = () => {

  const dispatch = useDispatch();

  // useEffect(() => {
  //   // if(user){
  //     getAllWishlistProducts(dispatch, "648f50bdc3b82dd3ae7caadc")
  //   // }
  // },[dispatch])

  // useEffect(() => {
  //   // if(user){
  //     getProducts(dispatch)
  //   // }
  // },[dispatch])


  const state = useSelector((state) => state);
  const {isFetching : isProductFetching,  products} = state.product
  // const wishListItems = useSelector((state) => state.wishList.wishListItems);
  const {isFetching,  wishListItems : wishListProducts} = useSelector((state) => state.wishList);

  // const wishListItems = wishListProducts?.map(wishListItem => {
  //   // Find the product in the products array based on productId
  //   return products?.find(product => product._id === wishListItem.productId);
  // });

  const wishListItems = wishListProducts?.map(wishListItem => {
    // Find the product in the products array based on productId
    const product = products?.find(product => product._id === wishListItem.productId);
    if (product) {
        // Add wishlistId property to the product
        return {
            ...product,
            wishlistId: wishListItem._id
        };
    }
    return null; // Handle case where product is not found
}).filter(Boolean); // Filter out any null values if product is not found
  
  if(isProductFetching){
    return <MiniLoader />
  }

  return (
    <div className="wishlist-pg pb-100">
      <div className="wishlist-pg-header">
        <CCPHeader HeaderTitle="my wishlist" />
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="heading ptb-50 pt-5">
              <h1>My Wishlist</h1>
            </div>

            <div className="wishlist-container">
              {wishListProducts &&
                wishListProducts.length > 0 &&
                wishListProducts.map((item, index) => {
                  return (
                    <WishlistProduct
                      itemDetails={item}
                      index={index + 1}
                      rating="4.7"
                    />
                  );
                })}
              {wishListProducts.length < 1 && (
                <div className="empty-cart-wrapper text-center pt-50  pb-50">
                  <p className="text-center">
                    No product added in wishlist yet
                  </p>
                  <Link to="/all-courses">
                    <CommonButton
                      btnClass="continue-shopping"
                      btnName="Continue Shopping"
                    />
                  </Link>
                </div>
              )}
              {/* {wishListItems &&
                wishListItems.length > 0 &&
                wishListItems.map((item, index) => {
                  return (1
                    <WishlistProduct
                      itemDetails={item}
                      index={index + 1}
                      rating="4.7"
                    />
                  );
                })}
              {wishListItems.length < 1 && (
                <div className="empty-cart-wrapper text-center pt-50  pb-50">
                  <p className="text-center">
                    No product added in wishlist yet
                  </p>
                  <Link to="/all-courses">
                    <CommonButton
                      btnClass="continue-shopping"
                      btnName="Continue Shopping"
                    />
                  </Link>
                </div>
              )} */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyWishlist;
