// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-card {
  background-color: transparent;
  background-color: initial;
  border: medium none currentColor;
  border: initial;
  text-align: center;
}

.sc-img {
  height: 150px !important;
  width: 100%;
}

.slider-card-name h3 {
  font-weight: 600;
  color: var(--dBlue);
}

.slider-card-desc {
  padding-top: 5%;
}
.slider-card-desc p {
  color: var(--Unigray) !important;
}

.slider-card img {
  border-radius: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/component/common/cards/SliderCard.css"],"names":[],"mappings":"AAAA;EACE,6BAAuB;EAAvB,yBAAuB;EACvB,gCAAa;EAAb,eAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".slider-card {\n  background-color: unset;\n  border: unset;\n  text-align: center;\n}\n\n.sc-img {\n  height: 150px !important;\n  width: 100%;\n}\n\n.slider-card-name h3 {\n  font-weight: 600;\n  color: var(--dBlue);\n}\n\n.slider-card-desc {\n  padding-top: 5%;\n}\n.slider-card-desc p {\n  color: var(--Unigray) !important;\n}\n\n.slider-card img {\n  border-radius: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
