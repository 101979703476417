import { Link } from "react-router-dom";
import {
  FaInstagram,
  FaLinkedinIn,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaWhatsapp,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import React, { useState } from "react";
import "./Footer.css";
import BackToTopButton from "./common/BackToTopButton";
import CommonButton from "./common/Button/CommonButton";
export default function Footer() {
  return (
    <div className="footer">
      <BackToTopButton />
      <div className="container">
        <div className="row">
          <h2 className="hSize fw-700 color-white text-uppercase mb-50">
            Explore <span className="dPink">Meritus</span>
          </h2>
          <div className="col">
            <h3>Quick Links</h3>
            <ul>
              <Link to={"/"}>
                <li>Home</li>
              </Link>
              <Link to={"/AboutUsPage"}>
                <li>Know Us</li>
              </Link>
              <Link to={"/contact-us"}>
                <li>Contact Us</li>
              </Link>
              <Link to={"/AboutUsPage#our-advisory-board"}>
                <li>Team</li>
              </Link>
              <Link to={"/AboutUsPage#our-philosophy"}>
                <li>Philosophy</li>
              </Link>
              <Link to={"/AboutUsPage#milestone"}>
                <li>Journey</li>
              </Link>
              {/* <Link to={"/category-blog"}>
                <li>Blogs</li>
              </Link> */}
              {/* <Link to={"/"}>
                <li>Awards & Recognitions</li>
              </Link> */}
              <Link to={"/Events"}>
                <li>News & Events</li>
              </Link>
              {/* <Link to={"/LatestNews"}>
                <li>Latest News & Events</li>
              </Link> */}
            </ul>
          </div>

          <div className="col">
            <h3>Shop</h3>
            <ul>
              {/* <li>
                <Link to="/ComingSoon">Shop Programs </Link>
              </li> */}
              <li>
                <Link to="/stem-shop">Shop Products </Link>
              </li>
              <li>
                <Link to="/LearnAI">Shop Courses </Link>
              </li>
              <li>
                <Link to="/ComingSoon">For Distributors</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3>Policies</h3>
            <ul>
              <Link to={"/TermOfUse#returnAndRefund"}>
                {/* <li>Return & Refund</li> */}
                <li>Terms & Conditions</li>
              </Link>
              <Link to={"/TermOfUse#privacyPolicy"}>
                <li>Privacy Policy</li>
              </Link>

              <Link to={"/TermOfUse#refundandcanc"}>
                <li>Shipping & Refund</li>
              </Link>
            </ul>
          </div>
          <div className="col">
            <h3>Partner with us</h3>
            <ul>
              <li></li>
              <li>
                {" "}
                <Link to="/ComingSoon">Distributor</Link>
              </li>
              <li>
                {" "}
                <Link to="/ComingSoon">Franchise</Link>
              </li>
              {/* <li>For Toy/Book Stores</li> */}
            </ul>
          </div>
          <div className="col">
            <h3>Resources</h3>
            <ul>
              <Link to={"/category-blog/Indian%20Mamas%20Code"}>
                <li>Indian Mamas Code</li>
              </Link>
              <Link to={"/"}>
                <li>
                  {" "}
                  <Link to="/contact-us">Book Free Demo</Link>
                </li>
              </Link>
              <Link to={"/"}>
                <li>
                  {" "}
                  <Link to="/ComingSoon">Competitions</Link>
                </li>
              </Link>
              <Link
                to={"https://www.facebook.com/PlayLearnFoundation/"}
                target="_blank"
              >
                <li>CSR</li>
              </Link>
              <Link to="/ComingSoon">
                <li>Meritus Near You</li>
              </Link>
            </ul>
          </div>
        </div>

        {/* footer social media strip */}
        <div className="footer_overlay_bg">
          <div className="footer_overlay_bg_item">
            <h3>Connect with us</h3>
            {/* <div>
              <Link to={"/LifeAt"}>Life At Meritus</Link>
              <Link to={"/Career"}>Current Openings</Link>
            </div> */}
          </div>
          {/* <div className="contact-btn-footer">
            <Link to={"/contact-us"} className="uniBtn uniBtnPink btnPLight">
              <CommonButton btnName="Contact Us" btnClass="footer-cta" />
            </Link>
          </div> */}
          <div className="footer_icons">
            <Link to={"https://www.facebook.com/meritusai/"} target="_blank">
              <FaFacebookF />
            </Link>
            <Link to={"https://www.instagram.com/meritus.ai/"} target="_blank">
              <FaInstagram />
            </Link>
            <Link
              to={"https://www.linkedin.com/company/meritusai/"}
              target="_blank"
            >
              <FaLinkedinIn />
            </Link>
            <Link to={"https://twitter.com/MeritusAI"} target="_blank">
              <BsTwitterX />
            </Link>
            <Link
              to={"https://www.youtube.com/@meritusai6766/playlists"}
              target="_blank"
            >
              <FaYoutube />
            </Link>
          </div>
        </div>
      </div>
      <div className="float-sm">
        <div className="fl-fl float-wt wa-chat">
          <a
            href="https://wa.me/919382155525"
            className="float"
            target="_blank"
          >
            <FaWhatsapp size={30} />
            <span className="text-white text-capitalize">Chat with us</span>
          </a>
        </div>
      </div>
    </div>
  );
}
